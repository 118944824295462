import { setState } from "../../../../reactStateManagement";
import delay from "../../../delay";

const RESOURCES = {
  MINE: "mine",
};

const handleApiResponse = async (resource, error, data) => {
  if (resource === RESOURCES.MINE) {
    if (error) {
      const errorMessage = error;
      setState(["mining"], false);
      setState(["snackMessage"], errorMessage);
      setState(["snackTime"], 6);
      return;
    }

    setState(["snackMessage"], "Mined Successfully");
    setState(["snackTime"], 2);

    await delay(1000 * 2);

    setState(["snackMessage"], "Attempting mine again in 4 minutes");
    setState(["snackTime"], 10);
    return;
  }

  console.log(
    "handleApiResponse: unexpected response [UNKNOWN-RESOURCE]:",
    resource,
    error,
    data
  );
};

export default handleApiResponse;
