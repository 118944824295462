import {
  getState,
  //getState,
  setState,
} from "../../../../reactStateManagement";
import getDoPromiseInQueue from "../../../getDoPromiseInQueue";

const doPromiseInQueue = getDoPromiseInQueue();

/*
{
          "action2": [
              {
                  "lastUpdated": 1718770475537,
                  "partitionKey": "bot2Action2_3935b175-ee94-4c98-99e2-f054aac88e27_1718770475537",
                  "output": {
                      "commands": [
                          {
                              "parameters": {
                                  "direction": "up"
                              },
                              "command": "move"
                          }
                      ]
                  },
                  "secondarySortKey": 1718770475537,
                  "input": "BOTOCOMMAND_GENERALIZED_COMMAND_V1_|{\"type\":\"BOTO\",\"subtype\":\"INTERACT_VPOWER\",\"value\":{\"promptAIDataIndex\":0,\"contextAIDataIndex\":-1,\"gptSelection\":\"gpt-4o\"}}|",
                  "address": "0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
                  "secondaryPartitionKey": "bot2Action2_BOTOXXXXXXINTERACT_VPOWERXXXXXXbot0_0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
                  "id": "3935b175-ee94-4c98-99e2-f054aac88e27",
                  "type": "BOT2_ACTION_2"
              },

          ],
          "thereAreMoreResults": false
      }
*/

const _handleBot2Interactions = (bot2InteractionsResult) => {
  /*

    new format
    "bot2InteractionsResult": {
        "botNumber": 0,
        "bot2InteractionsResult": {

    new state key:

      botNumberToBot2InteractionData: v({}),
  
  */
  // setState("bot2Interactions", bot2InteractionsResult.action2);
  // setState(
  //   "bot2InteractionsThereAreMoreResults",
  //   bot2InteractionsResult.thereAreMoreResults
  // );

  const botNumberToBot2InteractionData = getState(
    "botNumberToBot2InteractionData"
  );

  const botNumber = bot2InteractionsResult.botNumber;

  const newBotNumberToBot2InteractionData = {
    ...botNumberToBot2InteractionData,
    [botNumber]: {
      bot2Interactions: bot2InteractionsResult.bot2InteractionsResult.action2,
      thereAreMoreResults:
        bot2InteractionsResult.bot2InteractionsResult.thereAreMoreResults,
    },
  };

  setState("botNumberToBot2InteractionData", newBotNumberToBot2InteractionData);
};

const handleBot2Interactions = async ({ bot2InteractionsResult }) => {
  await doPromiseInQueue({
    operation: async () => {
      _handleBot2Interactions(bot2InteractionsResult);
    },
  });
};

export { handleBot2Interactions };
