import React from "react";
import { COLOR_0, COLOR_1, Z_INDICES } from "../../../constants";
import useGameComponent from "../../../utils/effects/useGameComponent";
import { setState } from "../../../reactStateManagement";

const CloseButton = () => {
  const id = "close-bot2-mode-button";

  useGameComponent({
    id,
  });

  return (
    <button
      id={id}
      style={{
        position: "absolute",

        top: 0,
        right: 0,

        width: 40,
        height: 40,

        backgroundColor: "red",

        zIndex: 2323232,

        fontSize: 20,
      }}
      onClick={() => {
        setState(["bot2Mode"], false);
      }}
    >
      {"x"}
    </button>
  );
};

const Bot2ModeContent = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        backgroundColor: "transparent",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "42%",

          backgroundColor: "transparent",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "50%",
            height: "100%",

            backgroundColor: "violet",
            color: "black",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            fontSize: 20,
          }}
        >
          {"Interact"}
        </button>
      </div>
    </div>
  );
};

export const Bot2Mode = () => {
  //   const windowWidth = getState("windowWidth");

  return (
    <div
      style={{
        // width: mobileMode ? MESSAGE_BOX_WIDTH : MESSAGE_BOX_WIDTH * 1.7,
        width: "50%",
        height: "100%",
        backgroundColor: COLOR_0,
        color: COLOR_1,

        // position: "absolute",
        // bottom: 0,
        // left: (windowWidth - MESSAGE_BOX_WIDTH) / 2,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        zIndex: Z_INDICES.TOP_LAYER,
      }}
    >
      <CloseButton />
      <Bot2ModeContent />
    </div>
  );
};
