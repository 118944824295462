import React from "react";
import { COLOR_3, DIALOG_TYPES, Z_INDICES } from "../../constants";
import { getState, setState } from "../../reactStateManagement";
import { EventTriggerButtonsSection2 } from "./EventTriggerButtonsSection2";
import { EventTriggerButtonsSection3 } from "./EventTriggerButtonsSection3";
import {
  HEIGHT_PERCENTAGE,
  WIDTH_PERCENTAGE,
} from "./BUTTON_HEIGHT_PERCENTAGE";
import { EventTriggerButtonsSection } from "./EventTriggerButtonsSection";
import { EventTriggerButtonsSection4 } from "./EventTriggerButtonsSection4";

const EventViewerSection = ({
  stateKey = "eventSenderEvents",
  backgroundColor = "midnightblue",
}) => {
  const eventSenderEvents = getState(stateKey);

  eventSenderEvents.sort((a, b) => {
    return b.time - a.time;
  });

  const eventSenderEventsContent = JSON.stringify(eventSenderEvents, null, 2);

  return (
    <div
      style={{
        width: "100%",
        height: "50%",

        position: "relative",
        display: "inline-block",
        backgroundColor,

        overflowY: "scroll",
      }}
    >
      <pre
        style={{
          width: "100%",
          height: "100%",

          backgroundColor,

          color: "white",

          userSelect: "text",
        }}
      >
        {eventSenderEventsContent}
      </pre>
    </div>
  );
};
const buttonModeToButtonSection = {
  0: EventTriggerButtonsSection,
  1: EventTriggerButtonsSection2,
  2: EventTriggerButtonsSection3,
  3: EventTriggerButtonsSection4,
};

const EventSender = ({ buttonMode, setButtonMode }) => {
  const ButtonSection = buttonModeToButtonSection[buttonMode];
  // typeof buttonMode;
  // typeof setButtonMode;
  // typeof buttonModeToButtonSection;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        // backgroundColor: "black",
      }}
    >
      <div
        style={{
          width: "50%",
          height: "100%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          // backgroundColor: "salmon",
        }}
      >
        <ButtonSection
          {...{
            buttonMode,
            setButtonMode,
          }}
        />
      </div>
      {![1, 2, 3].includes(buttonMode) ? (
        <div
          style={{
            width: "50%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            backgroundColor: "turquoise",
          }}
        >
          <EventViewerSection
            stateKey="eventSenderEventsOutput"
            backgroundColor="darkslategrey"
          />
          <EventViewerSection />
        </div>
      ) : null}
    </div>
  );
};

const Dialog = ({ dialog }) => {
  const [buttonMode, setButtonMode] = React.useState(0);
  const dialogType = dialog?.type;

  let content;

  switch (dialogType) {
    case DIALOG_TYPES.EVENT_SENDER:
      content = (
        <EventSender
          {...{
            buttonMode,
            setButtonMode,
          }}
        />
      );
      break;
    default:
      content = null;
      break;
  }

  let heightPercentage;

  if (dialog && dialog.heightPercentage) {
    heightPercentage = dialog.heightPercentage;
  } else {
    heightPercentage = HEIGHT_PERCENTAGE;
  }

  let widthPercentage;

  if (dialog && dialog.widthPercentage) {
    widthPercentage = dialog.widthPercentage;
  } else {
    widthPercentage = WIDTH_PERCENTAGE;
  }

  return (
    <div
      id="dialog-background"
      style={{
        position: "absolute",

        top: 0, // temporary coords

        left: 0, // temporary coords

        // backgroundColor: 'transparent',
        backgroundColor: "rgba(0, 0, 0, 0.5)",

        width: `100%`,
        height: `100%`,

        // zIndex: Z_INDICES.TOP_LAYER * 2,
        zIndex: Number.MAX_SAFE_INTEGER - 22,
      }}
      onClick={(event) => {
        if (event.target.id !== "dialog-background") {
          return;
        }

        setState(["dialog"], null);
      }}
    >
      <div
        id="dialog"
        style={{
          position: "absolute",

          top: `calc(50% - ${heightPercentage / 2}%)`, // temporary coords

          left: `calc(50% - ${widthPercentage / 2}%)`, // temporary coords

          // backgroundColor: COLOR_3,

          backgroundColor: [1, 2, 3].includes(buttonMode)
            ? "transparent"
            : COLOR_3,

          width: `${widthPercentage}%`,
          height: `${heightPercentage}%`,

          zIndex: Z_INDICES.TOP_LAYER * 2,
        }}
      >
        {content}
      </div>
    </div>
  );
};

export default Dialog;
