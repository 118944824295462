// import React from "react";
import {
  // getState,
  setState,
} from "../../../../../../reactStateManagement";
import { emitEvent } from "../../../../../../utils/emitEvent";
import { EVENT_TYPES } from "../../../../../../constants";
import getAuth from "../../../../../../utils/getAuth";
import { getMTokenData } from "../../../../../../utils/mToken";
import delay from "../../../../../../utils/delay";
// import { COLOR_1, COLOR_4, COLOR_5 } from "../../../../../../constants";

export const requestListNFT = async ({
  loading,
  smartContractAddress,
  tokenId,
  priceString,
  sellerMemo,
}) => {
  if (loading) {
    return;
  }

  setState(["loading"], true);

  try {
    const auth = getAuth();

    if (!auth) {
      return;
    }

    const mTokenData = getMTokenData();

    if (!mTokenData) {
      setState(["browserAlertData"], {
        message: "Please login first",
      });

      return;
    }

    // const smartContractAddress = document.getElementById(
    //   "nft-list-smart-contract-address-input"
    // ).value;

    if (
      !smartContractAddress ||
      typeof smartContractAddress !== "string" ||
      smartContractAddress.length < 1 ||
      smartContractAddress.length > 420
    ) {
      setState(["browserAlertData"], {
        message: "Please enter a valid smart contract address",
      });

      return;
    }

    if (
      !tokenId ||
      typeof tokenId !== "string" ||
      tokenId.length < 1 ||
      tokenId.length > 2000
    ) {
      setState(["browserAlertData"], {
        message: "Please enter a valid NFT number or token ID",
      });

      return;
    }

    const price = parseFloat(priceString);

    if (isNaN(price) || price < 0 || price > 222000000000) {
      setState(["browserAlertData"], {
        message: "Please enter a valid price",
      });

      return;
    }

    emitEvent({
      type: EVENT_TYPES.LIST_NFT,
      data: {
        auth,
        address: mTokenData.address,
        mToken: mTokenData.mToken,
        smartContractAddress,
        tokenId,
        price,
        sellerMemo,
      },
    });

    setState(["marketplaceListNFTSmartContractAddressInput"], "");
    setState(["marketplaceListNFTTokenIdInput"], "");
    setState(["marketplaceListNFTPriceInput"], "");
    setState(["marketplaceListNFTSellerMemoInput"], "");
  } catch (error) {
    console.error("error in NFTList button click:");
    console.error(error);
  } finally {
    await delay(3000);
    setState(["loading"], false);
  }
};
