import { BROVENT_KEYS } from "../../constants";
// import { getState } from "../../reactStateManagement";
import updateBChar from "../../utils/bChar/updateBChar";

export const doBMoveUpdate = async ({ key, on }) => {
  await updateBChar({
    mutateFunction: (bCharDatum) => {
      if (!bCharDatum) {
        // safeguard: should not happen in normal operation
        return null;
      }

      const newBCharDatum = {
        ...bCharDatum,
        [key]: on,
        lastUpdated: Date.now(),
      };

      if (key === BROVENT_KEYS.rightDown && on) {
        newBCharDatum.leftDown = false;
        // newBCharDatum.jumpDown = false;
      } else if (key === BROVENT_KEYS.leftDown && on) {
        newBCharDatum.rightDown = false;
        // newBCharDatum.jumpDown = false;
      } else if (key === BROVENT_KEYS.rightDown && !on) {
        newBCharDatum.rightDown = false;
        // newBCharDatum.jumpDown = false;
        // newBCharDatum.leftDown = false;
      } else if (key === BROVENT_KEYS.leftDown && !on) {
        newBCharDatum.leftDown = false;
        // newBCharDatum.jumpDown = false;
        // newBCharDatum.rightDown = false;
      }

      return newBCharDatum;
    },
  });
};
