const getRandomHex = (length) => {
  const randomHex = Math.random()
    .toString(16)
    .slice(2, 2 + length);

  return randomHex;
};

const getEventRequestId = () => {
  const requestId = `requestId-${getRandomHex(8)}-${getRandomHex(
    4
  )}-4${getRandomHex(3)}-${getRandomHex(4)}-${getRandomHex(12)}`;

  return requestId;
};

export default getEventRequestId;
