import React from "react";
import { getState } from "../../../reactStateManagement";
import { Bot } from "./Bot";
import { getXPixelsFromXPercentString } from "../../../coordsMapper";
import { PRE_RENDER_WIDTH } from "../../../constants";
// import { Bot } from "./Bot";
// import { UNIT_WIDTH } from "../nzwConstants";

const Bots = ({ minX, nzwW }) => {
  const botData = getState("botData");

  //   typeof botData;
  /*

  sample:
    [
        {
            "x": "0%",
            "y": "0%",
            "botId": "b5bc1b60-6b93-4ffd-afcc-58ff15150429",
            "lastUpdated": 1710191882391,
            "doubleJumped": false
        }
    ]
  */

  const botElements = [];

  if (botData.length > 0) {
    const botDatum = botData[0];

    const xPixels = getXPixelsFromXPercentString(botDatum.x);

    const botIsInXRange =
      xPixels >= minX - PRE_RENDER_WIDTH &&
      xPixels <= minX + nzwW + PRE_RENDER_WIDTH;

    if (botIsInXRange) {
      botElements.push(
        <Bot
          key={botDatum.botId}
          botId={botDatum.botId}
          x={botDatum.x}
          y={botDatum.y}
          botNumber={botDatum.botNumber}
          tauntMode={botDatum.tauntMode}
          tauntModeEnd={botDatum.tauntModeEnd}
          minX={minX}
        />
      );
    }
  }
  // console.log(`

  //     MEGA LOG: ${JSON.stringify(
  //       {
  //         "Bot Data Count": botData.length,
  //         "Bots to Consider Count": botElements.length,
  //       },
  //       null,
  //       4
  //     )}

  // `);

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      {botElements}
    </div>
  );
};

export default Bots;
