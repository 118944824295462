// import React from "react";

import {
  IMAGE_DISPLAY_CANVAS_ID,
  //   IMAGE_TO_PIXELS_SECTION_HEIGHT,
  //   MAX_SIZE,
} from "../imageToPixelsConstants";
import {
  //  COLOR_0, COLOR_1, COLOR_5,
  EVENT_TYPES,
} from "../../../constants";
import { emitEvent } from "../../../utils/emitEvent";
import { getState, setState } from "../../../reactStateManagement";
import getAuth from "../../../utils/getAuth";
import { downsampleSquare } from "./downsampleSquare";
import { getPixels } from "./getPixels";
import { doBrowserMint } from "../../../utils/pixels/doBrowserMint";

const doImageToPixels = async ({ image, mintDirect = false }) => {
  if (!image) {
    setState(["browserAlertData"], {
      message: `Please upload an image first.`,
    });

    return;
  }

  const canvas = document.getElementById(IMAGE_DISPLAY_CANVAS_ID);

  const context = canvas.getContext("2d");

  let width = canvas.width;
  let height = canvas.height;

  // must be square
  if (width !== height) {
    setState(["browserAlertData"], {
      message: `Image dimensions must be square`,
    });
    return;
  }

  //   if (width > MAX_SIZE || height > MAX_SIZE) {
  //     setState(["browserAlertData"], {
  //       message: `Image dimensions must be less than or equal to ${MAX_SIZE} x ${MAX_SIZE}`,
  //     });

  //     return;
  //   }

  // const imageData = context.getImageData(0, 0, width, height);
  // do not scale
  const imageData = context.getImageData(0, 0, width, height);

  const imageDataData = imageData.data;

  let pixels = getPixels({
    width,
    height,
    imageDataData,
  });

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       pixels,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  // const DOWNSIZE_SAMPLE_THRESHOLD_SIZE = 6;
  // const DOWNSIZE_SAMPLE_THRESHOLD_SIZE = 42;
  const DOWNSIZE_SAMPLE_THRESHOLD_SIZE = 100;

  if (width > DOWNSIZE_SAMPLE_THRESHOLD_SIZE) {
    // get highest factor below threshold

    let highestIntegerN = 1;

    for (
      let sizeI = 2;
      // sizeI <= DOWNSIZE_SAMPLE_THRESHOLD_SIZE;
      // sizeI <= Math.floor(width / 2);
      sizeI <= Math.min(DOWNSIZE_SAMPLE_THRESHOLD_SIZE, Math.floor(width / 2));
      sizeI++
    ) {
      if (width % sizeI === 0) {
        // if width is a multiple of sizeI

        // highestFactor = i;
        //   const size = Math.max(1, Math.ceil(m / n));
        // implies Math.ceil-1 size = m/n
        // implies n = m/(Math.ceil-1 size)
        // Math.ceil-1 does not need inverse
        // implies Math.ceil-1 size = size
        // implies n = m/size

        highestIntegerN = Math.max(highestIntegerN, sizeI);
      }
    }

    // highestIntegerN = width / highestIntegerN;
    // const downsizeThreshold = highestFactor;

    // use highest factor for highest

    const downsampled = downsampleSquare(pixels, highestIntegerN);

    pixels = downsampled;

    width = highestIntegerN;
    height = highestIntegerN;
  }

  // console.log(`

  //     MEGA LOG ${JSON.stringify(
  //       {
  //         pixels,
  //       },
  //       null,
  //       4
  //     )}
  //     )

  //   `);

  //   const TEST_END_EARLY = true;

  //   if (TEST_END_EARLY) {
  //     return;
  //   }

  const auth = getAuth(true);

  if (!auth) {
    // safeguard: should not happen in normal operation
    setState(["browserAlertData"], {
      message: "Unexpected issue, please refresh the page and try again",
    });

    return;
  }

  // const data = {
  //   auth,
  //   makeshiftBotData: [{}],
  //   isTrueBotoCommand: true,
  //   commands: [
  //     {
  //       action: "pixel",
  //       pixelSpec: {
  //         width,
  //         height,
  //         data: pixels,
  //       },
  //     },
  //   ],
  // };

  const TEST_SKIP_EMIT = false;
  // const TEST_SKIP_EMIT = true;

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       type: EVENT_TYPES.PUT_PIXELS,
  //       data: {
  //         pixelSpec: {
  //           width,
  //           height,
  //           data: pixels,
  //         },
  //       },
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);
  const pixelSpec = {
    width,
    height,
    data: pixels,
  };

  if (mintDirect) {
    await doBrowserMint({
      pixelSpecData: pixels,
    });
  } else {
    if (!TEST_SKIP_EMIT) {
      const imageToPixelsPutBlackPixels = getState(
        "imageToPixelsPutBlackPixels"
      );

      emitEvent({
        type: EVENT_TYPES.PUT_PIXELS,
        data: {
          auth,
          options: {
            blackIsDelete: !imageToPixelsPutBlackPixels,
          },
          pixelSpec,
        },
      });
    }
  }
};

export default doImageToPixels;
