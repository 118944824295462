import { NWORLD_UNIT_WIDTH_PERCENT_DECIMAL } from "../../../worldConstants";

function intersect({ xa0, xa1, ya0, ya1, xb0, xb1, yb0, yb1 }) {
  // case where a is directly above b
  if (ya0 === yb1) {
    return false;
  }
  // case where a is directly below b
  else if (ya1 === yb0) {
    return false;
  }
  // case where a is directly to the left of b
  else if (xa1 === xb0) {
    return false;
  }
  // case where a is directly to the right of b
  else if (xa0 === xb1) {
    return false;
  }

  if (xa0 > xb1 || xa1 < xb0 || ya0 > yb1 || ya1 < yb0) {
    return false;
  } else {
    return true;
  }
}

export const getIsColliding = ({
  proposedXNumber,
  proposedYNumber,
  nWorldData,
  characterWidthPercentDecimal,
  characterHeightPercentDecimal,
}) => {
  const proposedXDecimalPercent = proposedXNumber / 100;

  const xa0 = proposedXDecimalPercent - characterWidthPercentDecimal / 2;
  const xa1 = proposedXDecimalPercent + characterWidthPercentDecimal / 2;

  const proposedYDecimalPercent = proposedYNumber / 100;

  const ya0 = proposedYDecimalPercent;
  const ya1 = proposedYDecimalPercent + characterHeightPercentDecimal;

  for (let i = 0; i < nWorldData.length; i++) {
    const nWorldDatum = nWorldData[i];

    const nWorldXDecimalPercent = nWorldDatum.xDecimalPercent;
    const nWorldYDecimalPercent = nWorldDatum.yDecimalPercent;

    const xb0 = nWorldXDecimalPercent;
    const xb1 = nWorldXDecimalPercent + NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;

    const yb0 = nWorldYDecimalPercent;
    const yb1 = nWorldYDecimalPercent + NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;

    const intersects = intersect({
      xa0,
      xa1,
      ya0,
      ya1,
      xb0,
      xb1,
      yb0,
      yb1,
    });

    if (intersects) {
      return true;
    }
  }

  return false;
};
