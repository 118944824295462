import { EVENT_TYPES } from "../../constants";
// import { emitEvent } from "../emitEvent";
// import getAuth from "../getAuth";

// export const moveLeft = () => {
//   const auth = getAuth();
//   emitEvent({
//     data: {
//       direction: Math.PI, // in radians
//       auth,
//     },
//     type: EVENT_TYPES.MOVE,
//   });
// };

// export const moveRight = () => {
//   const auth = getAuth();
//   emitEvent({
//     data: {
//       direction: 0, // in radians
//       auth,
//     },
//     type: EVENT_TYPES.MOVE,
//   });
// };

// export const moveUp = () => {
//   const auth = getAuth();
//   emitEvent({
//     data: {
//       direction: Math.PI / 2, // in radians
//       auth,
//     },
//     type: EVENT_TYPES.MOVE,
//   });
// };

// export const moveDown = () => {
//   const auth = getAuth();
//   emitEvent({
//     data: {
//       direction: (3 * Math.PI) / 2, // in radians
//       auth,
//     },
//     type: EVENT_TYPES.MOVE,
//   });
// };

const moveWCharAbstract = (
  //direction,
  eventType
) => {
  // const auth = getAuth(true);
  // if (!auth) {
  //   return;
  // }

  // emitEvent({
  //   data: {
  //     // direction: Math.PI, // in radians
  //     // direction, // in radians
  //     auth,
  //   },
  //   // type: EVENT_TYPES.W_MOVE_RIGHT_ON,
  //   type: eventType,
  // });

  // Emit bEvent
  const $ = window.$;

  const $body = $("body");

  if (!$body.length) {
    return;
  }

  // console.log("DOING EVENT TYPE: " + eventType);

  $body.trigger(eventType);
};

export const moveWCharRight = () => {
  moveWCharAbstract(EVENT_TYPES.W_MOVE_RIGHT_ON);
};

export const moveWCharLeft = () => {
  moveWCharAbstract(EVENT_TYPES.W_MOVE_LEFT_ON);
};

export const moveWCharRightOff = () => {
  moveWCharAbstract(EVENT_TYPES.W_MOVE_RIGHT_OFF);
};

export const moveWCharLeftOff = () => {
  moveWCharAbstract(EVENT_TYPES.W_MOVE_LEFT_OFF);
};

export const moveWCharUp = () => {
  moveWCharAbstract(EVENT_TYPES.W_MOVE_JUMP_ON);
};

export const moveWCharUpOff = () => {
  moveWCharAbstract(EVENT_TYPES.W_MOVE_JUMP_OFF);
};
