import React from "react";
import { FONT_0, COLOR_0, COLOR_1, Z_INDICES } from "../../constants";
import SocketIOComponent from "../SocketIOComponent";
import { Core } from "./Core";

const WORLD_WIDTH = "100%";
const WORLD_HEIGHT = WORLD_WIDTH;

const NewWorld = () => {
  return (
    <div
      style={{
        width: WORLD_WIDTH,
        height: WORLD_HEIGHT,
        backgroundColor: COLOR_0,
        color: COLOR_1,

        fontFamily: FONT_0,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        zIndex: Z_INDICES.MIDDLE_LAYER,
      }}
    >
      <SocketIOComponent />
      <Core />
    </div>
  );
};

export { NewWorld };
