import { UNIT_WIDTH } from "../nzwConstants";

export const getLowestX = ({ minX, quantizeMode, halfWidth }) => {
  if (quantizeMode) {
    const lowestX = minX - (minX % UNIT_WIDTH) - minX - 2 * UNIT_WIDTH;

    return lowestX;
  }

  const lowestX = halfWidth - UNIT_WIDTH / 2 - halfWidth;

  return lowestX;
};
