import {
  getDecimalPercentFromPercentString,
  getNWorldPartitionDataFromXandYDecimalPercent,
} from "../../../coordsMapper";
import { getState } from "../../../reactStateManagement";
import getDoPromiseInQueue from "../../../utils/getDoPromiseInQueue";
import getNextPartitionDecimalPercent from "../../../utils/world/getNextPartitionDecimalPercent";

const scan = async () => {
  const worldNumber = 0;
  const levelNumber = 0;

  const doPromiseInQueue = getDoPromiseInQueue();

  const bCharDatum = getState("bCharDatum");
  /*
const initialBCharDatum = {
  x: "50%",
  y: "0%",
  lastUpdated: 0,
  metaStateA: false,
  lastDirection: 'right' | 'left'
};

    */

  const positiveXDirection = bCharDatum.lastDirection === "right";

  const xDecimalPercentToUse = getDecimalPercentFromPercentString(bCharDatum.x);

  const yDecimalPercentToUse = getDecimalPercentFromPercentString(bCharDatum.y);

  const partitionData = getNWorldPartitionDataFromXandYDecimalPercent(
    worldNumber,
    levelNumber,
    xDecimalPercentToUse,
    yDecimalPercentToUse
  );

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       xDecimalPercentToUse,
  //       yDecimalPercentToUse,
  //       partitionData,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  const xPartitionDecimalPercentToUse = partitionData.xPartitionDecimalPercent;
  const yPartitionDecimalPercentToUse = partitionData.yPartitionDecimalPercent;

  const incrementsToGetPure = [
    // [0, 0, 25, true],
    // [1, 0, 75],
    // [-1, 0, 75],
    // [0, 1, 25, true],
    // [1, 1, 100],
    // [-1, 1, 100],
    // [0, 2, 100],
    // [1, 2],
    // [-1, 2],
    // [0, 3],
    // [1, 3, 1000],
    // [-1, 3, 1000],
    // [0, 4],
    // [1, 4, 1000],
    // [-1, 4, 1000],
    // [0, 5, 400],
    // [1, 5, 400],
    // [-1, 5, 400],
    // [0, 6, 400],
    // [1, 6, 1000],
    // [-1, 6, 1000],
    // [0, 7, 1000],
    // [1, 7, 1000],
    // [-1, 7, 1000],
    // [0, 8, 1000],
    // [1, 8, 1000],
    // [-1, 8, 1000],
    // [0, 9, 1000],
    // [1, 9, 1000],
    // [-1, 9, 1000],
    //
    //
    // instead of above, want outward spiral - clockwise from [0, 0]
    // always use queue for these (item at index position 3 is always undefined)

    /*
        OUTWARD SPIRAL
        -6 -5  -4  -3  -2  -1 0 1 2 3 4 5 6
    6
    5
    4
    3
    2   17[-2, 2] 16[-1, 2] 15[0, 2] 14[1,2]    13[2,2]
    1   18[-2, 1] 5[-1,1] 4[0, 1] 3[1, 1]    12[2,1]
    0    19[-2, 0] 6[-1,0] 1[0, 0] 2[1, 0]    11[2,0]
    -1    20[-2, -1]7[-1,-1] 8[0, -1] 9[1, -1] 10[2, -1]
    -2    21[-2, -2]22[-1,-2]23[0, -2]24[1, -2] 25[2, -2] 
    -3
    -4
    -5
    -6

    WIDE SEARCH IN FRONT ONLY



        -6 -5  -4  -3  -2  -1 0 1 2 3 4 5 6
    6
    5
    4                                        17[2, 4]
    3                              8[1, 3]   15[2, 3] 24[3, 3]
    2                              6[1, 2]   13[2, 2] 22[3, 2]   29[4, 2]
    1                       1[0,  1]4[1, 1]  11[2, 1] 20[3, 1]   27[4, 1] 32[5, 1]
    0                       0[0,  0]3[1, 0]  10[2, 0] 19[3, 0]   26[4, 0] 31[5, 0] 34[6, 0]
    -1                      2[0, -1]5[1, -1] 12[2, -1] 21[3, -1] 28[4, -1] 33[5, -1]
    -2                              7[1, -2] 14[2, -2] 23[3, -2] 30[4, -2]
    -3                              9[1, -3] 16[2, -3] 25[3, -3]
    -4                                       18[2, -4]
    -5
    -6

    */

    // use WIDE SEARCH IN FRONT ONLY
    [0, 0, 25, true], // 0
    [0, 1],
    [1, 1, 100],
    // no delay (no 2nd index is required - default is 250)

    // non standard patterned vertical gets
    [0, 2], // 0a
    [0, -2],
    [0, 3], // 2a
    [0, -3],

    [1, 0], // 3
    [1, 1],
    [1, -1],
    [1, 2],
    [1, -2],
    [1, 3],
    [1, -3],
    [2, 0], // 10
    [2, 1],
    [2, -1],
    [2, 2],
    [2, -2],
    [2, 3],
    [2, -3],
    [2, 4],
    [2, -4],
    [3, 0], // 19
    [3, 1],
    [3, -1],
    [3, 2],
    [3, -2],
    [3, 3],
    [3, -3],
    [4, 0], // 26
    [4, 1],
    [4, -1],
    [4, 2],
    [4, -2],
    [5, 0], // 31
    [5, 1],
    [5, -1],
    [6, 0], // 34

    // bonus gets
    [2, 5],
    [2, -5],
    [3, 4],
    [3, -4],
    [4, 3],
    [4, -3],
  ];

  // const TEST_ONLY_GET_FIRST = true;
  const TEST_ONLY_GET_FIRST = false;

  const increments = TEST_ONLY_GET_FIRST
    ? [incrementsToGetPure[0]]
    : incrementsToGetPure;

  const incrementsToGet = increments.map((increment, index) => {
    // const xIncrementToGet = increment[0];

    const xIncrementToGet = positiveXDirection ? increment[0] : -increment[0];
    const yIncrementToGet = increment[1];

    // return [xIncrementToGet, yIncrementToGet];

    return {
      xIncrementToGet,
      yIncrementToGet,
      customDelay: increment[2],
      shouldDoGetInQueue: !increment[3],
      index,
      increment, // provide original for debugging
    };
  });

  for (let i = 0; i < incrementsToGet.length; i++) {
    const incrementToGet = incrementsToGet[i];

    const { shouldDoGetInQueue } = incrementToGet;

    if (!shouldDoGetInQueue) {
      await getNextPartitionDecimalPercent({
        xPartitionDecimalPercentToUse,
        yPartitionDecimalPercentToUse,
        incrementToGet,
      });
    } else {
      doPromiseInQueue({
        operation: async () => {
          await getNextPartitionDecimalPercent({
            xPartitionDecimalPercentToUse,
            yPartitionDecimalPercentToUse,
            incrementToGet,
            multiplyDelay: 1.5,
          });
        },
      });
    }
  }
};

export default scan;
