import React, { useEffect } from "react";
import {
  BROWSER_ONLY_EVENT_TYPES,
  COLOR_0,
  // COLOR_2,
  EVENT_TYPES,
  FONT_0,
  WORLD_NUMBER_TO_DATA,
  // Z_INDICES,
} from "../../../constants";
import { getState, setState } from "../../../reactStateManagement";
// import { Character } from "./Character";
// import Mine from "./Mine";
import { connectSocket } from "../../../utils/browserRealtime/connectSocket/connectSocket";
// import BuildGrid from "./BuildGrid";
// import { Bot } from "./Bot";
// import CuteControls from "./CuteControls";
// import { WChar as WCharExperimental } from "./WCharExperimental";
// import { WChar } from "./WChar";
// import StagingComponent from "../../../StagingComponent";

// import delay from "../../../utils/delay";
import { usePress } from "./usePress";
// import { usePress2 } from "./usePress2";

// const MAX_HEIGHT_PERCENT = 0.75;
// const MAX_HEIGHT_PERCENT = 0.75 / 2;

const getHandleEsc = ({ buildMode }) => {
  return () => {
    if (buildMode) {
      return;
    }

    //     selectRectTopLeftX: v(null),
    // selectRectTopLeftY: v(null),
    // selectRectBottomRightX: v(null),
    // selectRectBottomRightY: v(null),

    // // Select Square (in Decimal Percent)
    // selectSquareTopLeftX: v(null),
    // selectSquareTopLeftY: v(null),
    // selectSquareBottomRightX: v(null),
    // selectSquareBottomRightY: v(null),

    setState(["selectedItems"], []);
    setState(["selectRectTopLeftX"], null);
    setState(["selectRectTopLeftY"], null);
    setState(["selectRectBottomRightX"], null);
    setState(["selectRectBottomRightY"], null);

    setState(["selectSquareTopLeftX"], null);
    setState(["selectSquareTopLeftY"], null);
    setState(["selectSquareBottomRightX"], null);
    setState(["selectSquareBottomRightY"], null);
    setState(["pixelSpec"], null);
  };
};

const Core = () => {
  const worldNumber = getState("worldNumber");
  const socket = getState("socket");
  // const mapData = getState("mapData");
  const buildMode = getState("buildMode");
  // const windowWidth = getState("windowWidth");
  // const windowHeight = getState("windowHeight");
  const calculatedUnitWidth = getState("calculatedUnitWidth");
  const calculatedUnitHeight = getState("calculatedUnitHeight");

  const worldData = WORLD_NUMBER_TO_DATA[worldNumber];
  const worldWidth = worldData.w * calculatedUnitWidth;
  const worldHeight = worldData.h * calculatedUnitHeight;

  usePress("d", EVENT_TYPES.W_MOVE_RIGHT_ON, true);
  usePress("D", EVENT_TYPES.W_MOVE_RIGHT_ON, true);
  usePress("ArrowRight", EVENT_TYPES.W_MOVE_RIGHT_ON, true);
  // usePress2("d", "rightDown", true);

  // typeof usePress2;

  usePress("d", EVENT_TYPES.W_MOVE_RIGHT_OFF, false);
  usePress("D", EVENT_TYPES.W_MOVE_RIGHT_OFF, false);
  usePress("ArrowRight", EVENT_TYPES.W_MOVE_RIGHT_OFF, false);
  // usePress2("d", "rightDown", false);

  usePress("a", EVENT_TYPES.W_MOVE_LEFT_ON, true);
  usePress("A", EVENT_TYPES.W_MOVE_LEFT_ON, true);
  usePress("ArrowLeft", EVENT_TYPES.W_MOVE_LEFT_ON, true);
  // usePress2("a", "leftDown", true);

  usePress("a", EVENT_TYPES.W_MOVE_LEFT_OFF, false);
  usePress("A", EVENT_TYPES.W_MOVE_LEFT_OFF, false);
  usePress("ArrowLeft", EVENT_TYPES.W_MOVE_LEFT_OFF, false);
  // usePress2("a", "leftDown", false);

  usePress("w", EVENT_TYPES.W_MOVE_JUMP_ON, true);
  usePress("W", EVENT_TYPES.W_MOVE_JUMP_ON, true);
  usePress(" ", EVENT_TYPES.W_MOVE_JUMP_ON, true);
  usePress("ArrowUp", EVENT_TYPES.W_MOVE_JUMP_ON, true);

  usePress("w", EVENT_TYPES.W_MOVE_JUMP_OFF, false);
  usePress("W", EVENT_TYPES.W_MOVE_JUMP_OFF, false);
  usePress(" ", EVENT_TYPES.W_MOVE_JUMP_OFF, false);
  usePress("ArrowUp", EVENT_TYPES.W_MOVE_JUMP_OFF, false);

  usePress("e", BROWSER_ONLY_EVENT_TYPES.E_PRESS, true);
  usePress("E", BROWSER_ONLY_EVENT_TYPES.E_PRESS, true);

  // esc

  // const handleEsc = getHandleEsc({ buildMode });
  // usePress("Escape", handleEsc,

  useEffect(() => {
    const $ = window.$;

    const $window = $(window);

    if ($window.length === 0) {
      // safety check
      return;
    }

    const handleEsc = getHandleEsc({ buildMode });

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleEsc();
      }
    };

    $window.on("keydown", handleKeyDown);

    return () => {
      $window.off("keydown", handleKeyDown);
    };
  }, [buildMode]);

  // useEffect(() => {
  //   const clickAndFocusOnNeoControls = () => {
  //     const $ = window.$;

  //     const neoControls = $("#neo-controls");

  //     if (!neoControls.length) {
  //       return;
  //     }

  //     // console.log("CF");

  //     neoControls.click();
  //     neoControls.focus();
  //   };

  //   Promise.resolve().then(async () => {
  //     clickAndFocusOnNeoControls();
  //     await delay(100);
  //     clickAndFocusOnNeoControls();
  //     await delay(200);
  //     clickAndFocusOnNeoControls();
  //     await delay(500);
  //     clickAndFocusOnNeoControls();
  //     await delay(800);
  //     clickAndFocusOnNeoControls();
  //     await delay(1000);
  //   });
  // }, []);

  // useEffect(() => {
  //   /* TODO/Strategy:

  //   1. get unit width and height from worldData
  //   2. unit width and height should be the same
  // */

  //   // const $ = window.$;

  //   // const buildGrid = $("#build-grid");

  //   // if (!buildGrid.length) {

  //   // if (!buildGrid.length) {
  //   // setState(["calculatedUnitWidth"], windowWidth);
  //   // setState(["calculatedUnitHeight"], windowHeight * 0.6);

  //   //   return;
  //   // }

  //   // const buildGridWidth = buildGrid.width();
  //   // const buildGridHeight = buildGrid.height();

  //   const buildGridWidth = windowWidth;
  //   const buildGridHeight = windowHeight * MAX_HEIGHT_PERCENT;

  //   const unitWidthCandidate = buildGridWidth / worldData.w;
  //   const unitHeightCandidate = buildGridHeight / worldData.h;

  //   const unitSize = Math.min(unitWidthCandidate, unitHeightCandidate);

  //   // setCalculatedUnitWidth(unitSize);
  //   // setCalculatedUnitHeight(unitSize);
  //   setState(["calculatedUnitWidth"], unitSize);
  //   setState(["calculatedUnitHeight"], unitSize);
  // }, [worldNumber, mapData, windowWidth, windowHeight]);

  if (isNaN(worldWidth) || isNaN(worldHeight)) {
    return null;
  }

  if (!socket) {
    return (
      <div
        id={"core-disconnected"}
        style={{
          position: "relative",
          width: "100%",

          height: "100%",
          // backgroundColor: COLOR_2,
          backgroundColor: COLOR_0,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          font: FONT_0,
        }}
        onClick={() => {
          connectSocket();
        }}
      >
        {/* {"disconnected"} */}
      </div>
    );
  }

  return null;
};

export { Core };
