// import {
//   getState,
//   //getState,
//   setState,
// } from "../../../../reactStateManagement";
import getDoPromiseInQueue from "../../../getDoPromiseInQueue";
import { addRequestIdToCompletedRequestIds } from "../../addRequestIdToCompletedRequestIds";

const doPromiseInQueue = getDoPromiseInQueue();

const _handleRequestIdEventCompleted = (data) => {
  if (data && data.requestId) {
    addRequestIdToCompletedRequestIds({
      requestId: data.requestId,
    });
  }
};

const handleRequestIdEventCompleted = async ({ data }) => {
  await doPromiseInQueue({
    operation: async () => {
      _handleRequestIdEventCompleted(data);
    },
  });
};

export { handleRequestIdEventCompleted };
