import React from "react";
import {
  // COLOR_0,
  COLOR_1,
  COLOR_5,
} from "../../../../constants";

const GenericBot6Button = ({
  text,
  onClick,
  width = "50%",
  height = "25%",
  maxHeight = 40,
  disabled,
}) => {
  return (
    <button
      style={{
        width,
        height,
        maxHeight,
        // backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 0,
        borderStyle: "solid",

        // backgroundColor: "#880000",
        backgroundColor: disabled ? "#888888" : "#880000",

        borderRadius: 5,

        boxSizing: "border-box",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default GenericBot6Button;
