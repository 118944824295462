import React, { useState, useEffect } from "react";
import {
  moveWCharLeft,
  moveWCharLeftOff,
  moveWCharRight,
  moveWCharRightOff,
  moveWCharUp,
  moveWCharUpOff,
} from "../../../../utils/wCharacter/moveWChar";
import useGameComponent from "../../../../utils/effects/useGameComponent";
import {
  COLOR_2,
  COLOR_4,
  CSS_DEFAULT_ANIMATION_TRANSITION,
} from "../../../../constants";
import StagingComponent from "../../../../StagingComponent";
typeof COLOR_2;
typeof COLOR_4;

// const MAX_MOVE_X_THRESHOLD = 50;

const DEBUG_LOG_OFF = true;
// const DEBUG_LOG_OFF = false;

const debugLog = (...args) => {
  if (DEBUG_LOG_OFF) {
    return;
  }

  debugLog(...args);
};

export const JoySquare = ({
  width,
  height,
  xDeviation,
  yDeviation,
  position,
  moveXThreshold,
  jumpYThreshold,
  circleContainerHeight,
  circleContainerWidth,
}) => {
  const [priorXDeviation, setPriorXDeviation] = useState(xDeviation);
  const [priorYDeviation, setPriorYDeviation] = useState(yDeviation);
  const JUMP_Y_THRESHOLD = jumpYThreshold;
  // const JUMP_Y_THRESHOLD = -height / 5;
  // const JUMP_Y_THRESHOLD = -height / 6;

  // joystick movement must be greater than this threshold to move the character
  let MOVE_X_THRESHOLD = moveXThreshold;

  // if (MOVE_X_THRESHOLD > MAX_MOVE_X_THRESHOLD) {
  //   MOVE_X_THRESHOLD = MAX_MOVE_X_THRESHOLD;
  // }

  // const MOVE_X_THRESHOLD = width / 10;

  // visual effect

  // switch cases
  useEffect(() => {
    // from right to left
    if (priorXDeviation > 0 && xDeviation < 0) {
      debugLog("RIGHT TO LEFT");
      moveWCharRightOff();
      // moveWCharLeft();
    }

    // from left to right
    if (priorXDeviation < 0 && xDeviation > 0) {
      debugLog("LEFT TO RIGHT");
      moveWCharLeftOff();
      // moveWCharRight();
    }

    setPriorXDeviation(xDeviation);
  }, [xDeviation, priorXDeviation]);

  // right
  useEffect(() => {
    if (xDeviation > MOVE_X_THRESHOLD) {
      debugLog("RIGHT ON");
      moveWCharRight();
      // moveWCharLeftOff();
    }
  }, [xDeviation]);

  // left
  useEffect(() => {
    if (xDeviation < -MOVE_X_THRESHOLD) {
      debugLog("LEFT ON");
      moveWCharLeft();
      // moveWCharRightOff();
    }
  }, [xDeviation]);

  // right and left off
  useEffect(() => {
    if (xDeviation <= MOVE_X_THRESHOLD && xDeviation >= -MOVE_X_THRESHOLD) {
      debugLog("R/L OFF");
      moveWCharRightOff();
    }
  }, [xDeviation]);

  // origin
  useEffect(() => {
    if (xDeviation === 0) {
      debugLog("R/L OFF");
      moveWCharRightOff();
      moveWCharLeftOff();
      moveWCharUpOff();
    }
  }, [xDeviation]);

  typeof moveWCharUpOff;
  useEffect(() => {
    if (yDeviation < JUMP_Y_THRESHOLD) {
      debugLog("UP ON");
      moveWCharUp();
    }
  }, [yDeviation]);

  useEffect(() => {
    if (yDeviation >= JUMP_Y_THRESHOLD) {
      debugLog("UP OFF");

      moveWCharUpOff();
    }
  }, [yDeviation]);

  // switch cases
  useEffect(() => {
    // from up to down
    if (priorYDeviation < 0 && yDeviation > 0) {
      debugLog("UP TO DOWN");
      moveWCharUpOff();
    }

    setPriorYDeviation(yDeviation);
  }, [yDeviation, priorYDeviation]);

  useGameComponent({
    id: "square-joypad",
  });

  // useEffect(() => {
  //   if (priorYDeviation !== yDeviation) {
  //     debugLog(
  //       "yDeviation has changed from",
  //       priorYDeviation,
  //       "to",
  //       yDeviation
  //     );
  //     setPriorYDeviation(yDeviation);
  //   }
  // }, [yDeviation, priorYDeviation]);

  return (
    <div
      style={{
        position: "absolute",
        top: position.y,
        left: position.x,
        // width: width / 3,
        width: circleContainerWidth,
        // height: height / 3,
        height: circleContainerHeight,
        // backgroundColor: "transparent",
        // backgroundColor: "black",
        // borderRadius: "1%",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        transition: CSS_DEFAULT_ANIMATION_TRANSITION,
      }}
    >
      {/* middle circle */}
      <div
        style={{
          // max width is 100% of parent height

          // maxWidth: height / 3,
          maxWidth: circleContainerHeight,
          width: "100%",
          height: "100%",
          backgroundColor: COLOR_4,
          borderRadius: "50%",
        }}
      />

      <StagingComponent
        productionElement={
          <div
            style={{
              width: 0,
              height: 0,
            }}
          />
        }
        overrideStagingOff={true}
      >
        <div>
          {`x: ${String(position.x).substring(0, 6)}, y: ${String(
            position.y
          ).substring(0, 6)}, w: ${width}, h: ${height}, xd: ${String(
            xDeviation
          ).substring(0, 6)}, yd: ${String(yDeviation).substring(0, 6)}`}
        </div>
      </StagingComponent>
    </div>
  );
};
