import React from "react";
import { COLOR_2 as BGC, FONT_0 } from "../../../../constants";
// import { getState, setState } from "../../../../reactStateManagement";
// import Spacer from "../../../../commonComponents/Spacer";
import { AutoSignInButton } from "./AutoSignInButton";

// const SPACER_HEIGHT = 20;

export const SignInBox = () => {
  // const executiveBoxUsernameInput = getState("executiveBoxUsernameInput");
  // const executiveBoxPasswordInput = getState("executiveBoxPasswordInput");
  // const setExecutiveBoxUsernameInput = (text) => {
  //   setState(["executiveBoxUsernameInput"], text);
  // };
  // const setExecutiveBoxPasswordInput = (text) => {
  //   setState(["executiveBoxPasswordInput"], text);
  // };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        // backgroundColor: loading ? "teal" : COLOR_0,
        backgroundColor: BGC,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        fontFamily: FONT_0,
      }}
    >
      {/* <SignInBoxInput
        loading={loading}
        text={executiveBoxUsernameInput}
        setText={setExecutiveBoxUsernameInput}
      /> */}
      {/* <Spacer height={SPACER_HEIGHT} /> */}
      {/* <SignInBoxInput
        type={"password"}
        loading={loading}
        text={executiveBoxPasswordInput}
        setText={setExecutiveBoxPasswordInput}
      /> */}
      {/* <Spacer height={SPACER_HEIGHT} /> */}
      <div
        style={{
          width: "100%",
          height: 35,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <SignInBoxButton
          loading={loading}
          executiveBoxUsernameInput={executiveBoxUsernameInput}
          executiveBoxPasswordInput={executiveBoxPasswordInput}
        /> */}
        <AutoSignInButton />
      </div>
    </div>
  );
};
