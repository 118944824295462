import React from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // EVENT_TYPES,
} from "../../../../../constants";
import { loadInteractions } from "./Bot6InteractionsDisplay/loadInteractions";

export const Bot6InteractionsDisplayHeader = ({
  bot6InteractionData,
  tokenId,
  thereIsBot6InteractionData,
}) => {
  // const TEMPORARY_TEST_MODE = true;
  const TEMPORARY_TEST_MODE = false;

  if (
    !bot6InteractionData ||
    bot6InteractionData.length === 0 ||
    !thereIsBot6InteractionData ||
    !tokenId ||
    TEMPORARY_TEST_MODE
  ) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: 40,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          // width: "calc(100% - 70px)",
          width: thereIsBot6InteractionData ? "calc(100% - 70px)" : "100%",
          height: "100%",

          backgroundColor: COLOR_0,
          color: COLOR_1,
          borderColor: COLOR_5,
          borderWidth: 1,
          borderStyle: "solid",

          boxSizing: "border-box",

          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {"Recent"}
      </div>
      {thereIsBot6InteractionData || Math.abs(1) === 1 ? (
        <button
          style={{
            width: 70,
            height: "100%",

            backgroundColor: COLOR_0,
            color: COLOR_1,
            borderColor: COLOR_5,
            borderWidth: 1,
            borderStyle: "solid",

            boxSizing: "border-box",
          }}
          onClick={() => {
            if (!tokenId) {
              return;
            }

            loadInteractions({
              tokenId,
            });
          }}
        >
          {"Refresh"}
        </button>
      ) : null}
    </div>
  );
};
