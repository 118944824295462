import React from "react";
import {
  //EVENT_TYPES,
  Z_INDICES,
} from "../../constants";
// import { uploadImageAIData } from "../../utils/aiBoto/uploadImageAIData";
// import delay from "../../utils/delay";

const MainCanvas = ({ canvasId }) => {
  // const canvasRef = React.useRef(null);

  // React.useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");

  //   ctx.fillStyle = "purple";
  //   ctx.fillRect(22, 33, 400, 500);

  //   ctx.fillStyle = "pink";
  //   ctx.fillRect(50, 50, 220, 240);
  // }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "blue",
        // zIndex: Z_INDICES.DIALOG_LAYER,
      }}
      onClick={async () => {
        // uploadImageAIData({ canvasRef });
      }}
    >
      <canvas
        id={canvasId}
        // ref={canvasRef}
        width={200}
        height={200}
      />
    </div>
  );
};

const DEFAULT_CANVAS_ID = "defaultCanvasId";

export const GlobalIFrame = ({ globalIFrameDatum = {} }) => {
  const canvasId = globalIFrameDatum.canvasId || DEFAULT_CANVAS_ID;

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,

        width: "50%",
        height: "50%",

        backgroundColor: "red",

        zIndex: Z_INDICES.DIALOG_LAYER,
      }}
    >
      <MainCanvas canvasId={canvasId} />
    </div>
  );
};
