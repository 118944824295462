import React from "react";
import {
  // COLOR_0,
  // COLOR_1,
  // COLOR_5,
  MARKETPLACE_MODES,
} from "../../../../constants";
import { getState, setState } from "../../../../reactStateManagement";
import { MarketplaceButton } from "./MarketplaceButton";
import { Listings } from "./Listings";
import { marketplaceInitialStart } from "./marketplaceInitialStart";

const SellMode = () => {
  return (
    <div
      style={{
        width: "100%",
        // height: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",

        minHeight: 250,
      }}
    >
      <div>{"Sell Mode"}</div>
    </div>
  );
};

const Marketplace = ({ fullMarketplaceExpandMode }) => {
  const marketplaceMode = getState("marketplaceMode");

  marketplaceInitialStart();

  let contents = [];

  switch (marketplaceMode) {
    case MARKETPLACE_MODES.SELL: {
      contents = [
        <SellMode key={"sellMode"} />,

        <MarketplaceButton
          key={"back"}
          text="Back"
          onClick={() => {
            setState("marketplaceMode", null);
          }}
        />,
      ];

      break;
    }

    default: {
      contents = [
        <Listings
          key={"listings"}
          fullMarketplaceExpandMode={fullMarketplaceExpandMode}
        />,

        // <MarketplaceButton
        //   key={"sell"}
        //   text="Sell"
        //   onClick={() => {
        //     setState("marketplaceMode", MARKETPLACE_MODES.SELL);
        //   }}
        // />,
      ];

      break;
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",

        width: "100%",
      }}
    >
      {contents}
    </div>
  );
};

export default Marketplace;
