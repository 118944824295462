import React from "react";
import {
  // BOT6_PAGE_MODES,
  COLOR_0,
  COLOR_1,
  COLOR_5,
  STANDARD_BUTTON_HEIGHT,
  // EVENT_TYPES
} from "../../../../constants";
import { getState, setState } from "../../../../reactStateManagement";
import { doBrowserMint } from "../../../../utils/pixels/doBrowserMint";
// import { getState } from "../../../../reactStateManagement";

const StandardButton = ({
  text,

  backgroundColor = COLOR_0,
  onClick,
  marginTop = 0,
}) => {
  return (
    <button
      style={{
        width: "100%",
        height: STANDARD_BUTTON_HEIGHT,
        marginTop,

        border: `1px solid ${COLOR_5}`,
        borderRadius: 5,
        backgroundColor,
        color: COLOR_1,

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

const TitleSection = () => {
  return (
    <div
      style={{
        width: "100%",

        height: 30,

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: "calc( 100% - 30px )",
          // width: "100%",
          height: "100%",

          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {"Mint Pixels"}
      </div>

      <button
        style={{
          width: 30,
          height: 30,

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",

          border: `1px solid ${COLOR_5}`,
          borderRadius: 5,
          backgroundColor: COLOR_0,
          color: COLOR_1,
          paddingBottom: 3,
        }}
        onClick={() => {
          setState(["loginMode"], false);
        }}
      >
        {"x"}
      </button>
    </div>
  );
};

const StatusText = ({ statusText }) => {
  return (
    <div
      style={{
        width: "100%",
        // height: STANDARD_BUTTON_HEIGHT,

        borderLeft: `1px solid ${COLOR_5}`,
        borderRight: `1px solid ${COLOR_5}`,
        borderTop: `1px solid ${COLOR_5}`,
        borderRadius: 5,
        backgroundColor: COLOR_0,
        color: COLOR_1,

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        fontSize: 14,
      }}
      // onClick={onClick}
    >
      <div
        style={{
          width: "100%",
          height: "100%",

          display: "inline-block",
        }}
      >
        {statusText}
      </div>
    </div>
  );
};

/*
  MintStatusDisplay Overview:

  - pressing the mint button enables mint mode

  - minting is in one of the following states:  
    1) select first corner`
    2) select second corner
    3) confirm mint

  - the user can cancel at any stage



*/

// const MintStatusDisplay

const MintPage = ({
  zIndex = "unset",

  executiveBoxHeight,

  // windowWidth,
  // windowHeight,
}) => {
  //   // Select Square (in Decimal Percent)
  // selectSquareTopLeftX: v(null),
  // selectSquareTopLeftY: v(null),
  // selectSquareBottomRightX: v(null),
  // selectSquareBottomRightY: v(null),

  const mintMode = getState("mintMode");
  const selectRectTopLeftX = getState("selectRectTopLeftX");
  const selectRectTopLeftY = getState("selectRectTopLeftY");
  const selectSquareTopLeftX = getState("selectSquareTopLeftX");
  const selectSquareTopLeftY = getState("selectSquareTopLeftY");
  const selectSquareBottomRightX = getState("selectSquareBottomRightX");
  const selectSquareBottomRightY = getState("selectSquareBottomRightY");
  const pixelSpec = getState("pixelSpec");

  let statusText;

  const readyToMint =
    mintMode &&
    selectSquareTopLeftX !== null &&
    selectSquareTopLeftY !== null &&
    selectSquareBottomRightX !== null &&
    selectSquareBottomRightY !== null &&
    pixelSpec;

  const isInCancelableState =
    selectRectTopLeftX !== null || selectRectTopLeftY !== null || readyToMint;

  if (!mintMode) {
    statusText =
      "Turn on Mint Mode to mint pixels! " +
      "In mint mode, you first need to select the first corner of the pixel area, " +
      "then the second corner, and finally confirm the mint. " +
      "You can only mint squares, and you can cancel at any time.";
  } else if (readyToMint) {
    statusText = `Press "Mint" to mint the selected square.`;
  } else if (selectRectTopLeftX === null && selectRectTopLeftY === null) {
    statusText = "Select the first corner of the pixel area.";
  } else if (selectRectTopLeftX !== null || selectRectTopLeftY !== null) {
    statusText = "Select the second corner of the pixel area.";
  }

  return (
    <div
      style={{
        width: "100%",

        // maxHeight: 420,
        height: executiveBoxHeight - STANDARD_BUTTON_HEIGHT,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        zIndex,

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <TitleSection />
      <StandardButton
        backgroundColor={mintMode ? "#660000" : COLOR_0}
        // text={"Mint Mode On"}
        text={mintMode ? "Mint Mode On" : "Mint Mode Off"}
        onClick={() => {
          setState(["mintMode"], !mintMode);

          if (mintMode) {
            setState(["selectedItems"], []);
            setState(["selectRectTopLeftX"], null);
            setState(["selectRectTopLeftY"], null);
            setState(["selectRectBottomRightX"], null);
            setState(["selectRectBottomRightY"], null);

            setState(["selectSquareTopLeftX"], null);
            setState(["selectSquareTopLeftY"], null);
            setState(["selectSquareBottomRightX"], null);
            setState(["selectSquareBottomRightY"], null);
            setState(["pixelSpec"], null);
          }
        }}
      />
      <StatusText statusText={statusText} />
      {readyToMint ? (
        <StandardButton
          text={"Mint"}
          backgroundColor={COLOR_5}
          onClick={async () => {
            setState(["selectedItems"], []);
            setState(["selectRectTopLeftX"], null);
            setState(["selectRectTopLeftY"], null);
            setState(["selectRectBottomRightX"], null);
            setState(["selectRectBottomRightY"], null);

            setState(["selectSquareTopLeftX"], null);
            setState(["selectSquareTopLeftY"], null);
            setState(["selectSquareBottomRightX"], null);
            setState(["selectSquareBottomRightY"], null);
            setState(["pixelSpec"], null);

            await doBrowserMint({
              pixelSpecData: pixelSpec,
            });
          }}
          marginTop={25}
        />
      ) : null}

      {isInCancelableState ? (
        <StandardButton
          text={"Cancel"}
          backgroundColor={"#330000"}
          onClick={async () => {
            setState(["selectedItems"], []);
            setState(["selectRectTopLeftX"], null);
            setState(["selectRectTopLeftY"], null);
            setState(["selectRectBottomRightX"], null);
            setState(["selectRectBottomRightY"], null);

            setState(["selectSquareTopLeftX"], null);
            setState(["selectSquareTopLeftY"], null);
            setState(["selectSquareBottomRightX"], null);
            setState(["selectSquareBottomRightY"], null);
            setState(["pixelSpec"], null);
          }}
          marginTop={25}
        />
      ) : null}
    </div>
  );
};

export default MintPage;
