import { EVENT_TYPES } from "../../../../constants";
import { emitEvent } from "../../../../utils/emitEvent";
import getAuth from "../../../../utils/getAuth";

export const getListings = async () => {
  // console.log("getListings");

  const auth = getAuth();

  emitEvent({
    data: {
      auth,
    },
    type: EVENT_TYPES.GET_NFT_LISTINGS,
  });
};
