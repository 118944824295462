import { EVENT_TYPES } from "../../constants";
import { setState } from "../../reactStateManagement";
import { getUserData } from "../../api/getUserData";
import doSignIn from "../../api/doSignIn";
import { emitEvent } from "../emitEvent";
import getAuth from "../getAuth";
// import setUserToRespawn from "../bChar/setUserToRespawn";
import setBCharDatumToMetaverseState from "../bChar/setBCharDatumToMetaverseState";

const MIN_TIME_BETWEEN_AUTO_SIGN_INS = 7000;
let lastAutoSignIn = 0;

export const safeSignIn = async (messageData) => {
  console.log("running safeSignIn");

  if (Date.now() - lastAutoSignIn < MIN_TIME_BETWEEN_AUTO_SIGN_INS) {
    console.log("safeSignIn: too soon");
    return;
  }
  lastAutoSignIn = Date.now();

  // if (messageData) {
  //   return; ///temporarily disabled
  // }
  setState(["loading"], true);

  try {
    const { mToken } = await doSignIn({
      username: messageData.address,
      password: messageData.powerKey,
    });

    const userData = await getUserData({
      addToState: true,
    });

    // setUserToRespawn({
    //   userData,
    // });

    setBCharDatumToMetaverseState({
      userData,
    });

    const auth = getAuth();

    emitEvent({
      type: EVENT_TYPES.GET_USER_LAND_DATA,
      data: {
        auth,
        address: messageData.address,
        mToken,
      },
    });

    console.log("safeSignIn success");
  } catch (error) {
    console.log("error in safeSignIn:");
    console.log(error);
  }

  setState(["loading"], false);
};
