import React, { useEffect } from "react";
import { getState, setState } from "../../../../../../reactStateManagement";
// import { emitEvent } from "../../../../../../utils/emitEvent";
// import { EVENT_TYPES } from "../../../../../../constants";
// import getAuth from "../../../../../../utils/getAuth";
import { requestListNFT } from "./requestListNFT";

const MAX_SELLER_MEMO_LENGTH = 4000;

/* additional states:

marketplaceListNFTSmartContractAddressInput
marketplaceListNFTTokenIdInput
marketplaceListNFTPriceInput
marketplaceListNFTSellerMemoInput

*/

const NFTList = () => {
  const loading = getState("loading");

  const smartContractAddress = getState(
    "marketplaceListNFTSmartContractAddressInput"
  );
  const tokenId = getState("marketplaceListNFTTokenIdInput");
  const price = getState("marketplaceListNFTPriceInput");
  const sellerMemo = getState("marketplaceListNFTSellerMemoInput");

  useEffect(() => {
    const noNumberInputScrollListener = function () {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    };

    document.addEventListener("wheel", noNumberInputScrollListener);

    return () => {
      document.removeEventListener("wheel", noNumberInputScrollListener);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        // height: "200px",
        backgroundColor: "#220000",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        overflow: "hidden",
      }}
    >
      <input
        id={"nft-list-smart-contract-address-input"}
        type="text"
        placeholder="Smart Contract Address"
        style={{
          width: "90%",
          height: "30px",
          borderRadius: "5px",
          backgroundColor: "#444",
          color: "white",
          marginTop: "20px",
        }}
        onFocus={() => {
          setState(["focus"], true);
        }}
        onBlur={() => {
          setState(["focus"], false);
        }}
        value={smartContractAddress}
        onChange={(e) => {
          setState(
            ["marketplaceListNFTSmartContractAddressInput"],
            e.target.value
          );
        }}
      />
      <input
        id={"nft-list-nft-number-input"}
        placeholder="NFT Number (Token ID)"
        style={{
          width: "90%",
          height: "30px",
          borderRadius: "5px",
          backgroundColor: "#444",
          color: "white",
        }}
        onFocus={() => {
          setState(["focus"], true);
        }}
        onBlur={() => {
          setState(["focus"], false);
        }}
        value={tokenId}
        onChange={(e) => {
          setState(["marketplaceListNFTTokenIdInput"], e.target.value);
        }}
      />
      <input
        id={"nft-list-nft-price-input"}
        placeholder="Price"
        type="number"
        style={{
          width: "90%",
          height: "30px",
          borderRadius: "5px",
          backgroundColor: "#444",
          color: "white",
        }}
        onFocus={() => {
          setState(["focus"], true);
        }}
        onBlur={() => {
          setState(["focus"], false);
        }}
        value={price}
        onChange={(e) => {
          setState(["marketplaceListNFTPriceInput"], e.target.value);
        }}
      />
      <input
        id={"nft-list-nft-seller-memo-input"}
        placeholder="Seller Memo (optional)" // (optional)
        style={{
          width: "90%",
          height: "30px",
          borderRadius: "5px",
          backgroundColor: "#444",
          color: "white",
        }}
        onFocus={() => {
          setState(["focus"], true);
        }}
        onBlur={() => {
          setState(["focus"], false);
        }}
        value={sellerMemo}
        onChange={(e) => {
          let newSellerMemo = e.target.value;

          if (newSellerMemo.length > MAX_SELLER_MEMO_LENGTH) {
            newSellerMemo = newSellerMemo.slice(0, MAX_SELLER_MEMO_LENGTH);
          }

          setState(["marketplaceListNFTSellerMemoInput"], e.target.value);
        }}
      />
      <button
        style={{
          width: "90%",
          height: "30px",
          borderRadius: "5px",
          // backgroundColor: "#660000",
          backgroundColor: loading ? "#ccc" : "#660000",
          color: "white",

          marginTop: "20px",
          marginBottom: "20px",
        }}
        disabled={loading}
        onClick={() => {
          requestListNFT({
            loading,
            smartContractAddress,
            tokenId,
            priceString: price,
            sellerMemo,
          });
        }}
      >
        {"List NFT"}
      </button>
    </div>
  );
};

export default NFTList;

// export const AddListingSection = () => {
//   const height = 200;

//   return (
//     <div
//       style={{
//         width: "100%",
//         height,

//         backgroundColor: COLOR_4,
//         color: COLOR_1,
//         borderColor: COLOR_5,
//         borderWidth: 1,
//         borderStyle: "solid",

//         boxSizing: "border-box",
//       }}
//     ></div>
//   );
// };
