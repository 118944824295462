import React from "react";
import { getState } from "../../../../reactStateManagement";
import {
  COLOR_0,
  // COLOR_0,
  FONT_0,
} from "../../../../constants";

import { SignOutButton } from "./SignOutButton";

const DisplayText = ({
  text,

  style = {},
}) => {
  return (
    <div
      style={{
        // height: "100%",

        width: "90%",
        // backgroundColor: COLOR_0,
        color: "white",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        fontFamily: FONT_0,

        ...style,
      }}
    >
      {text}
    </div>
  );
};

export const SignedInBox = ({ loading }) => {
  const userData = getState("userData");
  const mobile = getState("mobile");

  const fontSize = mobile ? 12 : 16;

  const address = userData?.address || "";

  // display with first 6 characters and last 4 characters

  const addressDisplay = `${address.slice(0, 6)}...${address.slice(-4)}`;

  const primePoints = userData?.primePoints || 0;

  // display with 4 decimal places max

  let primePointsDisplay = primePoints.toFixed(4);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: loading ? "darkslateblue" : COLOR_0,

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",

        // fontFamily: FONT_0,
      }}
    >
      <DisplayText
        text={addressDisplay}
        style={{
          wordBreak: "break-all",
          fontSize,
        }}
      />
      <DisplayText
        text={`Prime Points: ${primePointsDisplay}`}
        style={{
          fontSize,
        }}
      />
      <SignOutButton />
    </div>
  );
};
