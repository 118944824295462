import { getState, setState } from "../../../../reactStateManagement";
import getDoPromiseInQueue from "../../../getDoPromiseInQueue";

const doPromiseInQueue = getDoPromiseInQueue();

const MAX_CREATE_IMAGE_RESPONSES_TO_STORE = 200;

const handleCreateImageResponse = async (event) => {
  await doPromiseInQueue({
    operation: async () => {
      const createImageResponses = getState("createImageResponses");

      /*
        response format: 
          [
            {
              imageKey: '...',
              requestId: '...'
            },
            ...
          ]
      */

      const newCreateImageResponses = [event.data, ...createImageResponses];

      // if (
      //   newCreateImageResponses.length > MAX_CREATE_IMAGE_RESPONSES_TO_STORE
      // ) {
      //   newCreateImageResponses.pop();
      // }
      while (
        newCreateImageResponses.length > MAX_CREATE_IMAGE_RESPONSES_TO_STORE
      ) {
        newCreateImageResponses.pop();
      }

      setState(["createImageResponses"], newCreateImageResponses);
    },
  });
};

export default handleCreateImageResponse;
