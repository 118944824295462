import React, { useEffect, useState } from "react";
import { COLOR_0, COLOR_1, COLOR_5, Z_INDICES } from "../../constants";
import { getState, setState } from "../../reactStateManagement";
import { doBrowserMint } from "../../utils/pixels/doBrowserMint";

const SIZE_UNIT = 40;

// if multiple status, use mini version of each status
const topStatusDisplayModeData = {
  mint: {
    full: "Mint",
    mini: "M",
    getIsEnabledTriggers: () => {
      const mintMode = getState("mintMode");
      const selectSquareTopLeftX = getState("selectSquareTopLeftX");
      const selectSquareTopLeftY = getState("selectSquareTopLeftY");
      const selectSquareBottomRightX = getState("selectSquareBottomRightX");
      const selectSquareBottomRightY = getState("selectSquareBottomRightY");
      const pixelSpec = getState("pixelSpec");

      return [
        mintMode,
        selectSquareTopLeftX,
        selectSquareTopLeftY,
        selectSquareBottomRightX,
        selectSquareBottomRightY,
        pixelSpec,
      ];
    },
    getIsEnabled: () => {
      const mintMode = getState("mintMode");
      // const selectRectTopLeftX = getState("selectRectTopLeftX");
      // const selectRectTopLeftY = getState("selectRectTopLeftY");
      const selectSquareTopLeftX = getState("selectSquareTopLeftX");
      const selectSquareTopLeftY = getState("selectSquareTopLeftY");
      const selectSquareBottomRightX = getState("selectSquareBottomRightX");
      const selectSquareBottomRightY = getState("selectSquareBottomRightY");
      const pixelSpec = getState("pixelSpec");

      const readyToMint =
        mintMode &&
        selectSquareTopLeftX !== null &&
        selectSquareTopLeftY !== null &&
        selectSquareBottomRightX !== null &&
        selectSquareBottomRightY !== null &&
        pixelSpec;

      return readyToMint;
    },
    onClick: async () => {
      // alert("POWER OF THE NEW MONKEY");
      // // TODO: implement
      const pixelSpec = getState("pixelSpec");

      if (!pixelSpec) {
        console.error("mint: pixelSpec is falsy");

        return;
      }

      setState(["selectedItems"], []);
      setState(["selectRectTopLeftX"], null);
      setState(["selectRectTopLeftY"], null);
      setState(["selectRectBottomRightX"], null);
      setState(["selectRectBottomRightY"], null);

      setState(["selectSquareTopLeftX"], null);
      setState(["selectSquareTopLeftY"], null);
      setState(["selectSquareBottomRightX"], null);
      setState(["selectSquareBottomRightY"], null);
      setState(["pixelSpec"], null);

      await doBrowserMint({
        pixelSpecData: pixelSpec,
      });
    },

    onClickExit: () => {
      setState(["mintMode"], false);
      setState(["selectedItems"], []);
      setState(["selectRectTopLeftX"], null);
      setState(["selectRectTopLeftY"], null);
      setState(["selectRectBottomRightX"], null);
      setState(["selectRectBottomRightY"], null);

      setState(["selectSquareTopLeftX"], null);
      setState(["selectSquareTopLeftY"], null);
      setState(["selectSquareBottomRightX"], null);
      setState(["selectSquareBottomRightY"], null);
      setState(["pixelSpec"], null);
    },
  },
};

const TopStatus = ({ status, isEnabled, setIsEnabled }) => {
  const data = topStatusDisplayModeData[status];

  if (data.getIsEnabled && data.getIsEnabledTriggers) {
    const triggers = data.getIsEnabledTriggers();

    useEffect(() => {
      const isEnabledBool = data.getIsEnabled();

      setIsEnabled(isEnabledBool);
    }, triggers);
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",

        // backgroundColor: COLOR_0,
        background: isEnabled ? COLOR_5 : COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 2,
        borderStyle: "solid",

        borderRadius: 4,

        boxSizing: "border-box",
      }}
    >
      <button
        style={{
          width: SIZE_UNIT,
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",

          // backgroundColor: COLOR_0,
          background: COLOR_0,
          color: COLOR_1,
          borderColor: COLOR_5,
          borderWidth: 2,
          borderStyle: "solid",

          borderRadius: 4,

          boxSizing: "border-box",
        }}
        onClick={data.onClickExit}
        // disabled={!isEnabled}
      >
        {"x"}
      </button>
      <button
        style={{
          // width: "70%",
          width: `calc( 100% - ${SIZE_UNIT}px )`,
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",

          // backgroundColor: COLOR_0,
          background: isEnabled ? COLOR_5 : COLOR_0,
          color: COLOR_1,
          borderColor: COLOR_5,
          borderWidth: 2,
          borderStyle: "solid",

          borderRadius: 4,

          boxSizing: "border-box",
        }}
        onClick={data.onClick}
        disabled={!isEnabled}
      >
        {data.full}
      </button>
    </div>
  );
};

export const TopStatusDisplay = () => {
  const statuses = [];

  const mintMode = getState("mintMode");
  const [isEnabled, setIsEnabled] = useState(false);

  if (mintMode) {
    statuses.push("mint");
  }

  if (statuses.length === 0) {
    return null;
  }

  let content;

  if (statuses.length === 1) {
    const status = statuses[0];

    content = (
      <TopStatus
        status={status}
        isEnabled={isEnabled}
        setIsEnabled={setIsEnabled}
      />
    );
  }
  // else if (statuses.length > 1) { // NOT IMPLEMENTED YET

  return (
    <div
      style={{
        position: "absolute",

        top: 0,
        left: SIZE_UNIT,

        width: SIZE_UNIT * 3,

        // maxHeight: 420,
        height: SIZE_UNIT,

        boxSizing: "border-box",

        zIndex: Z_INDICES.NEW_ZOOM_WORLD_LAYER,

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {content}
    </div>
  );
};
