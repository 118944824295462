import React, { useState } from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_4,
  COLOR_5,
  STANDARD_BUTTON_HEIGHT,
  // EVENT_TYPES
  characterToData,
  CHARACTERS,
  CHARACTERS_ARRAY,
  PRODUCTION_MODE,
} from "../../../../constants";
typeof COLOR_0;
// import { emitEvent } from "../../../../utils/emitEvent";
// import getAuth from "../../../../utils/getAuth";
// import { getMTokenData } from "../../../../utils/mToken";
import { getState } from "../../../../reactStateManagement";
import updateBChar from "../../../../utils/bChar/updateBChar";
import updateMetaverseUserState from "../../../../utils/state/updateMetaverseUserState";
import { getMTokenData } from "../../../../utils/mToken";

const RIGHT = "RIGHT";
const LEFT = "LEFT";

const MAIN_SECTION_PERCENT = 1;
const IMAGE_SECTION_PERCENT = 0.5;
const CHOOSE_BUTTON_SECTION_PERCENT = 0.5 / 3;
const SELECT_BUTTON_SECTION_PERCENT = 0.5 / 3;
const SELECT_FASHION_SECTION_PERCENT = 0.5 / 3;

const CharacterPreview = ({ character }) => {
  const parsedCharacter = CHARACTERS[character];

  const characterData = characterToData[parsedCharacter];

  if (!characterData) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={characterData.imageUrlA}
        alt="Character"
        style={{
          // width: "100%",
          // height: "100%",

          maxWidth: "100%",
          maxHeight: "100%",
        }}
      />
    </div>
  );
};

const CharacterScrollButton = ({
  direction,
  characterIndex,
  setCharacterIndex,
}) => {
  return (
    <button
      style={{
        width: "50%",
        height: "100%",

        backgroundColor: COLOR_4,
        color: COLOR_0,
      }}
      onClick={() => {
        if (direction === RIGHT) {
          setCharacterIndex((characterIndex + 1) % CHARACTERS_ARRAY.length);
        } else if (direction === LEFT) {
          setCharacterIndex(
            (characterIndex - 1 + CHARACTERS_ARRAY.length) %
              CHARACTERS_ARRAY.length
          );
        }
      }}
    >
      {direction === RIGHT ? ">" : "<"}
    </button>
  );
};

const SetToFashionSelectModeButton = ({ setFashionSelectMode }) => {
  if (PRODUCTION_MODE) {
    return null;
  }

  return (
    <button
      style={{
        width: "100%",
        height: "100%",

        backgroundColor: "green",
        color: "white",
      }}
      onClick={() => {
        setFashionSelectMode(true);
      }}
    >
      {"Select Fashion"}
    </button>
  );
};

const SelectCharacterButton = ({ character }) => {
  return (
    <button
      style={{
        width: "100%",
        height: "100%",

        backgroundColor: "red",
        color: "white",
      }}
      onClick={async () => {
        await updateBChar({
          mutateFunction: (bChar) => {
            const newBChar = {
              ...bChar,
              character,
            };

            const mTokenData = getMTokenData();
            if (mTokenData) {
              updateMetaverseUserState({
                key: "bCharDatum",
                value: newBChar,
              });
            }

            return newBChar;
          },
          bBroadcast: true,
        });
      }}
    >
      {"Select Character"}
    </button>
  );
};

const LocalBackButton = ({ setFashionSelectMode }) => {
  return (
    <button
      style={{
        width: "100%",
        height: "100%",

        backgroundColor: "red",
        color: "white",
      }}
      onClick={() => {
        setFashionSelectMode(false);
      }}
    >
      {"Back"}
    </button>
  );
};

const CharacterSelect = ({ zIndex = "unset", executiveBoxHeight }) => {
  const [characterIndex, setCharacterIndex] = useState(0);
  const [fashionSelectMode, setFashionSelectMode] = useState(false);

  typeof setFashionSelectMode;

  const userData = getState("userData");

  // const mobile = getState("mobile");

  if (!userData) {
    return;
  }

  let contents;

  if (fashionSelectMode) {
    contents = [
      <div
        key="back-button"
        style={{
          width: "100%",
          height: `${SELECT_FASHION_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          backgroundColor: "turquoise",
          // fontSize: 20,
        }}
      >
        <LocalBackButton setFashionSelectMode={setFashionSelectMode} />
      </div>,
    ];
  } else {
    const character = CHARACTERS_ARRAY[characterIndex];

    contents = [
      <div
        key="character-preview"
        style={{
          width: "100%",
          height: `${IMAGE_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          // fontSize: 20,
        }}
      >
        <CharacterPreview character={character} />
      </div>,
      <div
        key="character-scroll"
        style={{
          width: "100%",
          height: `${CHOOSE_BUTTON_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          // fontSize: 20,
        }}
      >
        <CharacterScrollButton
          direction={LEFT}
          characterIndex={characterIndex}
          setCharacterIndex={setCharacterIndex}
        />
        <CharacterScrollButton
          direction={RIGHT}
          characterIndex={characterIndex}
          setCharacterIndex={setCharacterIndex}
        />
      </div>,
      <div
        key="select-button"
        style={{
          width: "100%",
          height: `${SELECT_BUTTON_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          backgroundColor: "violet",
          // fontSize: 20,
        }}
      >
        <SelectCharacterButton character={character} />
      </div>,

      <div
        key="select-fashion"
        style={{
          width: "100%",
          height: `${SELECT_FASHION_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          backgroundColor: "black",
          // fontSize: 20,
        }}
      >
        <SetToFashionSelectModeButton
          setFashionSelectMode={setFashionSelectMode}
        />
      </div>,
    ];
  }

  return (
    <div
      style={{
        width: "100%",
        // height: 420,

        // maxHeight: 420,
        // height: mobile ? 190 : 420,

        height: executiveBoxHeight - STANDARD_BUTTON_HEIGHT,

        // backgroundColor: COLOR_0,
        // backgroundColor: "pink",
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        zIndex,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          // height: "80%",
          height: `${MAIN_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          // fontSize: 20,
        }}
      >
        {contents}
      </div>

      {/* <div
        style={{
          width: "100%",

          // height: "60%",
          height: "calc(100% - 80px)",

          display: "block",

          overflowY: "scroll",
        }}
      ></div> */}
    </div>
  );
};

export default CharacterSelect;
