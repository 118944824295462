import React from "react";
import { COLOR_0, COLOR_1, COLOR_5 } from "../../../../../constants";
import { getState } from "../../../../../reactStateManagement";
import extractBotoGeneralizedCommandV1Data from "../../../../../utils/aiBoto/extractBotoGeneralizedCommandV1Data";
import AiInteractionRecord from "./AiInteractionRecord";
import loadInteractions from "../../../../../utils/aiBoto/loadBot2Interactions";

// const MOCK_AI_INTERACTIONS = [
//   {
//     time: Date.now(),
//     actionType: "Interact",
//     userInput: null,
//     result: {
//       commands: [
//         {
//           command: "move",
//           parameters: {
//             direction: "right",
//           },
//         },
//       ],
//     },
//   },
// ];

/*
{
          "action2": [
              {
                  "lastUpdated": 1718770475537,
                  "partitionKey": "bot2Action2_3935b175-ee94-4c98-99e2-f054aac88e27_1718770475537",
                  "output": {
                      "commands": [
                          {
                              "parameters": {
                                  "direction": "up"
                              },
                              "command": "move"
                          }
                      ]
                  },
                  "secondarySortKey": 1718770475537,
                  "input": "BOTOCOMMAND_GENERALIZED_COMMAND_V1_|{\"type\":\"BOTO\",\"subtype\":\"INTERACT_VPOWER\",\"value\":{\"promptAIDataIndex\":0,\"contextAIDataIndex\":-1,\"gptSelection\":\"gpt-4o\"}}|",
                  "address": "0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
                  "secondaryPartitionKey": "bot2Action2_BOTOXXXXXXINTERACT_VPOWERXXXXXXbot0_0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
                  "id": "3935b175-ee94-4c98-99e2-f054aac88e27",
                  "type": "BOT2_ACTION_2"
              },

          ],
          "thereAreMoreResults": false
      }
*/

let hasFirstLoadedVar = false;

const Header = ({ thereIsBot2Data }) => {
  return (
    <div
      style={{
        width: "100%",
        height: 40,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          // width: "calc(100% - 70px)",
          width: thereIsBot2Data ? "calc(100% - 70px)" : "100%",
          height: "100%",

          backgroundColor: COLOR_0,
          color: COLOR_1,
          borderColor: COLOR_5,
          borderWidth: 1,
          borderStyle: "solid",

          boxSizing: "border-box",

          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {"Recent"}
      </div>
      {thereIsBot2Data ? (
        <button
          style={{
            width: 70,
            height: "100%",

            backgroundColor: COLOR_0,
            color: COLOR_1,
            borderColor: COLOR_5,
            borderWidth: 1,
            borderStyle: "solid",

            boxSizing: "border-box",
          }}
          onClick={loadInteractions}
        >
          {"Refresh"}
        </button>
      ) : null}
    </div>
  );
};

const BotoInteractionsDisplay = ({ thereIsBot2Data }) => {
  const botNumberToBot2InteractionData = getState(
    "botNumberToBot2InteractionData"
  );

  // temporarily hardcoding botNumber
  const botNumber = 0;

  const bot2InteractionData = botNumberToBot2InteractionData[botNumber];

  const hasFirstLoadedRef = React.useRef(hasFirstLoadedVar);

  React.useEffect(() => {
    if (!thereIsBot2Data || hasFirstLoadedRef.current) {
      return;
    }
    hasFirstLoadedRef.current = true;

    loadInteractions();
  }, [thereIsBot2Data]);

  if (!bot2InteractionData || !thereIsBot2Data) {
    return <Header thereIsBot2Data={thereIsBot2Data} />;
  }

  const bot2Interactions = bot2InteractionData.bot2Interactions;

  const interactions = [];

  if (bot2Interactions && bot2Interactions.length > 0) {
    for (let i = 0; i < bot2Interactions.length; i++) {
      const bot2Interaction = bot2Interactions[i];

      let actionType;

      try {
        const commandData = extractBotoGeneralizedCommandV1Data(
          bot2Interaction.input
        );

        if (
          commandData &&
          commandData.type === "BOTO" &&
          commandData.subtype === "INTERACT_VPOWER"
        ) {
          actionType = `Interact`;
        }
      } catch (e) {
        console.error("error in extractBotoGeneralizedCommandV1Data:", e);

        actionType = "Interact - General";
      }

      const interactionParams = {
        time: bot2Interaction.lastUpdated,
        actionType,
        userInput: null,
        result: bot2Interaction.output,
      };

      interactions.push(<AiInteractionRecord key={i} {...interactionParams} />);
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Header thereIsBot2Data={thereIsBot2Data} />

      <div
        style={{
          width: "100%",
          height: "calc(100% - 40px)",

          display: "block",
          overflowY: "scroll",
        }}
      >
        {interactions}
      </div>
    </div>
  );
};

export default BotoInteractionsDisplay;
