import React, { useEffect } from "react";
import {
  // BOTTOM_SECTION_HEIGHT_MOBILE,
  // BOTTOM_SECTION_HEIGHT_DESKTOP,
  // MESSAGE_BOX_WIDTH,
  EVENT_TYPES,
  COLOR_2,
  COLOR_1,
} from "../../../constants";
import { emitEvent } from "../../../utils/emitEvent";
import getAuth from "../../../utils/getAuth";
import { getState } from "../../../reactStateManagement";
import useGameComponent from "../../../utils/effects/useGameComponent";

export const SelectButton = ({
  mobile,
  characterNumber,
  text,
  customAction,
  backgroundColor = COLOR_2,
  color = COLOR_1,
  triggerKeys = [],
  badge,
}) => {
  const id = `select-button-${text}`;

  useGameComponent({
    id,
  });

  React.useEffect(() => {
    //     document.getElementById('yourElement').oncontextmenu = function(event) {
    //     event.preventDefault();
    //     event.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available?
    //     event.stopImmediatePropagation();
    //     return false;
    // };
    const $ = window.$;

    const $button = $(`#${id}`);

    const handleContextMenu = function (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      return false;
    };

    $button.bind("contextmenu", handleContextMenu);

    return () => {
      $button.unbind("contextmenu", handleContextMenu);
    };
  }, []);

  const isBlank = characterNumber === "blank";

  const action = () => {
    if (isBlank) {
      return;
    }

    if (customAction) {
      customAction();
      return;
    }

    const auth = getAuth();

    emitEvent({
      data: {
        auth,
        characterNumber,
      },
      type: EVENT_TYPES.CHARACTER_UPDATE,
    });
  };

  const focus = getState("focus");

  useEffect(() => {
    const $ = window.$;

    for (const triggerKey of triggerKeys) {
      const handleKeyDown = (e) => {
        if (focus) {
          return;
        }

        if (e.key === triggerKey) {
          action();
        }
      };

      $(document).keydown(handleKeyDown);

      return () => {
        $(document).off("keydown", handleKeyDown);
      };
    }
  }, [focus]);

  // const text = isBlank ? "" : characterNumber;
  return (
    <div
      id={id}
      className="no-drag"
      style={{
        width: "calc(100% / 3)",
        height: "100%",
        backgroundColor: isBlank ? "transparent" : backgroundColor,
        color: isBlank ? "transparent" : color,
        borderRadius: 5,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: isBlank ? "unset" : "pointer",

        position: "relative",
      }}
      // onClick={action}
      onClick={mobile ? null : action}
      onTouchEnd={mobile ? action : null}
    >
      {badge && (
        <div
          style={{
            position: "absolute",
            top: -18,
            right: 0,
            width: 20,
            height: 20,
            backgroundColor: "red",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          {badge}
        </div>
      )}

      {text}
    </div>
  );
};
