import { EVENT_TYPES } from "../../constants";
import { emitEvent } from "../emitEvent";
import getAuth from "../getAuth";

export const moveLeft = () => {
  const auth = getAuth();
  emitEvent({
    data: {
      direction: Math.PI, // in radians
      auth,
    },
    type: EVENT_TYPES.MOVE,
  });
};

export const moveRight = () => {
  const auth = getAuth();
  emitEvent({
    data: {
      direction: 0, // in radians
      auth,
    },
    type: EVENT_TYPES.MOVE,
  });
};

export const moveUp = () => {
  const auth = getAuth();
  emitEvent({
    data: {
      direction: Math.PI / 2, // in radians
      auth,
    },
    type: EVENT_TYPES.MOVE,
  });
};

export const moveDown = () => {
  const auth = getAuth();
  emitEvent({
    data: {
      direction: (3 * Math.PI) / 2, // in radians
      auth,
    },
    type: EVENT_TYPES.MOVE,
  });
};
