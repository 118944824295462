import React from "react";
// import { DisplayText } from "./DisplayText";
import { NCharBase } from "./NCharBase";

export const NChar = ({
  yPixels,
  minY,
  characterDatum,
  botoScreenCaptureMode,
}) => {
  if (!characterDatum) {
    return null;
  }

  return (
    <NCharBase
      yPixels={yPixels}
      index={0}
      rightDown={characterDatum.rightDown}
      leftDown={characterDatum.leftDown}
      lastDirection={characterDatum.lastDirection}
      minY={minY}
      botoScreenCaptureMode={botoScreenCaptureMode}
      character={characterDatum.character}
    />
  );
};
