import React from "react";
import { UNIT_WIDTH } from "../nzwConstants";
import { DisplayText } from "../DisplayText";
import StagingComponent from "../../../StagingComponent";
// import { CSS_DEFAULT_ANIMATION_TRANSITION } from "../../../constants";
import { handlePutNObject } from "./handlePutNObject";
import useGameComponent from "../../../utils/effects/useGameComponent";
import {
  getXCoordFromXPixels,
  getYCoordFromYPixels,
} from "../../../coordsMapper";
import {
  // COLOR_1,
  // COLOR_0,
  // COLOR_1,
  Z_INDICES,
  //COLOR_0,
  // COLOR_1,
} from "../../../constants";
// import { getState } from "../../../reactStateManagement";

const DEBUG_LOG_OFF = true;

const getHandleClickOrPress = ({
  x,
  y,
  minX,
  minY,
  buildMode,
  putObjectMode,
  botoScreenCaptureMode,
}) => {
  return async () => {
    if (!DEBUG_LOG_OFF) {
      console.log(`pressN ${x}, ${y}`);
    }

    if (botoScreenCaptureMode) {
      return null;
    }

    return handlePutNObject({
      active: buildMode,
      x,
      minX,
      minY,
      y,
      putObjectMode,
    });
  };
};

/**
 *
 * BotoDisplayInfo
 * - Displays info for each BuildUnit to be
 * - viewed by Boto
 * - Boto can then make decisions based on this visual info
 * - (e.g. "place pixel here", "move to this location")
 * @param {*} param0
 */

const MOD = 1;
const DISPLAY_TEXT_WIDTH = 50;

const BotoDisplayInfo = ({ x, y }) => {
  // currently want to display coordinates

  const xCoord = getXCoordFromXPixels(x);
  const yCoord = getYCoordFromYPixels(y);

  if (xCoord % MOD !== 0 || yCoord % MOD !== 0) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        // width: "100%",
        height: "100%",
        display: "block",
        overflow: "visible",
      }}
    >
      {/* <DisplayText
        // text={xCoord}
        text={`x: ${xCoord * 1000000}`}
        top={0}
        left={0}
        backgroundColor="transparent"
        color={COLOR_0}
      />
      <DisplayText
        text={`y: ${yCoord}`}
        top={25}
        left={0}
        backgroundColor="transparent"
        color={COLOR_0}
      /> */}

      {/* allow overflow */}

      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: DISPLAY_TEXT_WIDTH,
          // height: "100%",
          display: "block",
          overflow: "visible",
          color: "black",

          zIndex: Z_INDICES.NEW_ZOOM_WORLD_LAYER,

          // backgroundColor: "rgba(0, 0, 0, 0.8)",
          fontSize: 9,
        }}
      >
        {/* {`(${xCoord * 100000}, ${yCoord * 100000})`} */}
        {`(${xCoord}, ${yCoord})`}
      </div>
    </div>
  );
};

export const BuildUnit = ({
  x,
  y,
  minX,
  minY,
  displayX = null,
  displayY = null,
  putObjectMode,
  // quantizeMode,
  botoScreenCaptureMode,
  isScreenCaptureCoordinateGridMode,
  mobile,
  mouseIsDown,
}) => {
  const active = putObjectMode?.itemType ? true : false;

  const artificialXOffset = 0;

  const id = `buildUnit-${x}-${y}`;
  const idJuice = `buildUnit-${x}-${y}-juice`;

  useGameComponent({
    id,
  });

  useGameComponent({
    id: idJuice,
  });

  const handleClickOrPress = getHandleClickOrPress({
    x,
    y,
    minX,
    minY,
    buildMode: active,
    putObjectMode,
    botoScreenCaptureMode,
  });

  let backgroundColor;
  if (botoScreenCaptureMode) {
    if (isScreenCaptureCoordinateGridMode) {
      backgroundColor = "white";
    } else {
      backgroundColor = "none";
    }
  } else {
    if (!active) {
      // backgroundColor = "purple";
      backgroundColor = "none";
    } else {
      // backgroundColor = "pink";
      backgroundColor = "#131365";
    }
  }

  // const TEST_NO_COORD_DISPLAY = true;
  const TEST_NO_COORD_DISPLAY = false;

  return (
    <div
      id={id}
      style={{
        position: "absolute",

        width: UNIT_WIDTH,
        height: UNIT_WIDTH,

        // backgroundColor: "black",

        backgroundColor,

        border:
          active || isScreenCaptureCoordinateGridMode
            ? "1px solid blue"
            : "none",
        boxSizing: "border-box",

        // backgroundColor: active ? "black" : "black",
        left: x + artificialXOffset,

        bottom: y,
        // transition: quantizeMode
        //   ? "all 0.5s ease"
        //   : CSS_DEFAULT_ANIMATION_TRANSITION,

        cursor: active ? "pointer" : "default",

        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",

        display: "block",

        overflow: "visible",
      }}
      disabled={!active}
      onClick={mobile ? null : handleClickOrPress}
      onTouchEnd={mobile ? handleClickOrPress : null}
      onMouseEnter={() => {
        if (mouseIsDown && active) {
          handleClickOrPress();
        }
      }}
    >
      {/* <div
        id={idJuice}
        style={{
          width: "90%",
          height: "90%",

          backgroundColor: "black",
        }}
        // onClick={handleClickOrPress}
      ></div> */}

      {botoScreenCaptureMode && !TEST_NO_COORD_DISPLAY ? (
        <BotoDisplayInfo x={displayX} y={displayY} />
      ) : null}

      <StagingComponent overrideStagingOff={true}>
        {displayX ? (
          <DisplayText
            text={String(displayX).substring(0, 222)}
            top={0}
            left={0}
          />
        ) : null}
      </StagingComponent>
    </div>
  );
};
