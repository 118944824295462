// import {
// //   // EVENT,
// //   EVENT_TYPES,
// // } from "../../constants.mjs";
// import setValue from "../../dino/setValue.mjs";
import { EVENT_TYPES } from "../../constants";
import { getState, setState } from "../../reactStateManagement";
import { emitEvent } from "../emitEvent";
import getAuth from "../getAuth";
// import getDoPromiseInQueue from "../getDoPromiseInQueue";
// import { emitEvent } from "../emitEvent.mjs";

// import { getWCharacterData } from "./getWCharacterData.mjs";

// const doPromiseInQueue = getDoPromiseInQueue();

const updateBCharCore = ({
  // cterData,
  mutateFunction,
  bBroadcast,
}) => {
  const bCharDatum = getState("bCharDatum");

  const newBCharDatum = mutateFunction(bCharDatum);

  if (!newBCharDatum) {
    return;
  }

  setState(["bCharDatum"], newBCharDatum);

  if (bBroadcast) {
    const auth = getAuth(true);

    if (!auth) {
      return;
    }

    // console.log("BCHAR");

    emitEvent({
      type: EVENT_TYPES.BCHAR_BROADCAST,
      data: {
        auth,
        bCharDatum: newBCharDatum,
      },
    });
  }
};

const updateBChar = async ({ mutateFunction, bBroadcast = false }) => {
  // await doPromiseInQueue({
  //   operation: async () => {
  updateBCharCore({
    mutateFunction,
    bBroadcast,
  });
  //   },
  // });
};

export default updateBChar;
