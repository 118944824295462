import React from "react";
// import { DisplayText } from "./DisplayText";
import {
  UNIT_WIDTH,
  //UNIT_WIDTH,
  Z_1,
} from "./nzwConstants";
import {
  NWORLD_STANDARD_USER_HEIGHT_PERCENT_DECIMAL,
  NWORLD_STANDARD_USER_WIDTH_PERCENT_DECIMAL,
  NWORLD_TOTAL_WIDTH,
  NWORLD_TOTAL_HEIGHT,
} from "../../worldConstants";
import { CSS_DEFAULT_ANIMATION_TRANSITION } from "../../constants";
// import { setState } from "../../reactStateManagement";

export const Bot6 = ({
  xPixels,
  xMin,
  yPixels,
  minY,
  tokenId,
  botoScreenCaptureMode,
}) => {
  const w = NWORLD_STANDARD_USER_WIDTH_PERCENT_DECIMAL * NWORLD_TOTAL_WIDTH;
  const h = NWORLD_STANDARD_USER_HEIGHT_PERCENT_DECIMAL * NWORLD_TOTAL_HEIGHT;

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       xPixels,
  //       xMin,
  //       yPixels,
  //       minY,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  let left;

  left = xPixels - xMin - w / 2 + (UNIT_WIDTH / 2) * 0;

  // special case, if xPixels is 0, then we want to offset to the right
  if (xPixels === 0) {
    left = left + 1 * UNIT_WIDTH;
  }

  const bottom = yPixels - minY;

  return (
    <div
      id={`bot6-${tokenId}`}
      style={{
        position: "absolute",
        bottom,
        // left: "50%",

        backgroundColor: botoScreenCaptureMode ? "green" : "transparent",

        border: botoScreenCaptureMode ? "5px solid red" : "none",

        boxSizing: "border-box",

        left,
        // left: `calc($5 - ${UNIT_WIDTH / 2}px)`,
        width: w,
        height: h,

        // backgroundColor: "green",
        transition: CSS_DEFAULT_ANIMATION_TRANSITION,
        zIndex: Z_1,
      }}
      // onClick={(e) => {
      //   e.stopPropagation();
      //   console.log("bot6 clicked");

      //   setState(["bot2Mode"], true);
      // }}
    >
      {botoScreenCaptureMode ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {"B"}
          <br />
          {"O"}
          <br />
          {"T"}
          <br />
          {tokenId}
        </div>
      ) : (
        <img
          alt="boto"
          src="https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/boto_cool_3.png"
          style={{
            height: "100%",
          }}
        />
      )}
    </div>
  );
};
