// import {
//   getXPixelsFromXDecimalPercent,
//   getYPixelsFromYDecimalPercent,
// } from "../../coordsMapper";
import {
  getXDecimalPercentFromXPixel,
  getYDecimalPercentFromYPixel,
} from "../../coordsMapper";
import { getState, setState } from "../../reactStateManagement";

/*
    handleNZWClick Rules:

    1. if both corners are selected
        - if user clicks again, deselect both corners

*/
const X_OFFSET = 0; // 0 because NZW has width of 100%

const getX = (e, minX) => {
  //   return e.clientX + X_OFFSET;

  const clientX = e.clientX;

  const mobileClientX = e.touches && e.touches[0] && e.touches[0].clientX;

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       clientX,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  const clientXToUse = clientX || mobileClientX;

  if (typeof clientXToUse !== "number") {
    return null;
  }

  const x = clientXToUse + X_OFFSET + minX;

  return x;
};

const getY = (e, minY) => {
  const $ = window.$;

  const $newControlsContainer = $("#new-controls-container");

  if ($newControlsContainer.length === 0) {
    return null;
  }

  const newControlsContainerHeight = $newControlsContainer.height();

  const windowHeight = $(window).height();

  const clientY = e.clientY;

  const mobileClientY = e.touches && e.touches[0] && e.touches[0].clientY;

  const clientYToUse = clientY || mobileClientY;

  if (typeof clientYToUse !== "number") {
    return null;
  }

  return windowHeight - clientYToUse - newControlsContainerHeight + minY;
};

export const getHandleNZWClick = ({ minX, minY, scale }) => {
  return (e) => {
    const buildMode = getState("buildMode");

    if (buildMode) {
      return;
    }

    const mintMode = getState("mintMode");

    if (!mintMode) {
      return;
    }

    if (scale !== 1) {
      setState(["browserAlertData"], {
        message:
          "Currently can only mint NFTs at 100% zoom. Please adjust zoom to mint NFTs.",
      });

      return;
    }

    const selectedItems = getState("selectedItems");

    if (selectedItems.length > 0) {
      setState(["selectedItems"], []);

      return;
    }

    const selectRectTopLeftX = getState("selectRectTopLeftX");
    const selectRectTopLeftY = getState("selectRectTopLeftY");
    const selectRectBottomRightX = getState("selectRectBottomRightX");
    const selectRectBottomRightY = getState("selectRectBottomRightY");

    const bothCornersSelected =
      typeof selectRectTopLeftX === "number" &&
      typeof selectRectTopLeftY === "number" &&
      typeof selectRectBottomRightX === "number" &&
      typeof selectRectBottomRightY === "number";

    if (bothCornersSelected) {
      setState(["selectRectTopLeftX"], null);
      setState(["selectRectTopLeftY"], null);
      setState(["selectRectBottomRightX"], null);
      setState(["selectRectBottomRightY"], null);

      // setState(["selectedItems"], []);
      // console.log(`

      //   CANCEL LOG ${JSON.stringify({}, null, 4)}
      //   )

      // `);

      return;
    }

    const noCornerSelected =
      typeof selectRectTopLeftX !== "number" &&
      typeof selectRectTopLeftY !== "number" &&
      typeof selectRectBottomRightX !== "number" &&
      typeof selectRectBottomRightY !== "number";

    if (noCornerSelected) {
      const x = getX(e, minX);
      const y = getY(e, minY);
      if (typeof x !== "number" || typeof y !== "number") {
        return;
      }

      const xDecimalPercent = getXDecimalPercentFromXPixel(x);
      const yDecimalPercent = getYDecimalPercentFromYPixel(y);

      // console.log(`

      //   TL LOG ${JSON.stringify(
      //     {
      //       x,
      //       y,
      //       xDecimalPercent,
      //       yDecimalPercent,
      //     },
      //     null,
      //     4
      //   )}
      //   )

      // `);

      setState(["selectedItems"], []);
      setState(["selectRectTopLeftX"], xDecimalPercent);
      setState(["selectRectTopLeftY"], yDecimalPercent);

      return;
    }

    // if only one corner is selected, select the other corner
    const onlyOneCornerSelected =
      typeof selectRectTopLeftX === "number" &&
      typeof selectRectTopLeftY === "number";

    if (onlyOneCornerSelected) {
      const x = getX(e, minX);
      const y = getY(e, minY);
      if (typeof x !== "number" || typeof y !== "number") {
        return;
      }

      const xDecimalPercent = getXDecimalPercentFromXPixel(x);
      const yDecimalPercent = getYDecimalPercentFromYPixel(y);

      //   console.log(`

      //     B  LOG ${JSON.stringify(
      //       {
      //         x,
      //         y,
      //         xDecimalPercent,
      //         yDecimalPercent,
      //       },
      //       null,
      //       4
      //     )}
      //     )

      // `);

      // todo: check which corner is more to the left/top
      // form a rectangle from the two corners and get the top left and bottom right corners

      const point1 = {
        x: selectRectTopLeftX,
        y: selectRectTopLeftY,
      };

      const point2 = {
        x: xDecimalPercent,
        y: yDecimalPercent,
      };

      // console.log(`

      //     MEGA LOG ${JSON.stringify(
      //       {
      //         point1,
      //         point2,
      //       },
      //       null,
      //       4
      //     )}
      //     )

      //   `);

      const x1 = Math.min(point1.x, point2.x);
      const y1 = Math.max(point1.y, point2.y);

      const x2 = Math.max(point1.x, point2.x);
      const y2 = Math.min(point1.y, point2.y);

      setState(["selectRectTopLeftX"], x1);
      setState(["selectRectTopLeftY"], y1);
      setState(["selectRectBottomRightX"], x2);
      setState(["selectRectBottomRightY"], y2);

      return;
    }
  };
};
