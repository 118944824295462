import React from "react";
import { roundToDecimal } from "../../../../../../utils/roundToDecimal";
// import {
//   COLOR_0,
//   COLOR_1,
//   COLOR_5,
//   EVENT_TYPES,
// } from "../../../../../constants";
// import { emitEvent } from "../../../../../utils/emitEvent";
// import { getMTokenData } from "../../../../../utils/mToken";
// import getAuth from "../../../../../utils/getAuth";
// import { getState } from "../../../../../reactStateManagement";
// import extractBotoGeneralizedCommandV1Data from "../../../../../utils/aiBoto/extractBotoGeneralizedCommandV1Data";

/*

DATA STRUCTURE:
{
  {
    partitionKey: "bot6InteractionXXXXXX104XXXXXX1719161154969",
    input: {
      command: {
        subtype: "INTERACT_VIPOWER",
        type: "BOTO",
        value: {
          contextAIDataIndex: -1,
          gptSelection: "gpt-4o",
          promptAIDataIndex: 0,
          tokenId: "104",
        },
      },
    },
    lastUpdated: 1719161154969,
    output: {
      commands: [
        {
          command: "move",
          parameters: {
            direction: "left",
          },
        },
        {
          command: "move",
          parameters: {
            direction: "up",
          },
        },
      ],
      costData: {
        pricePerToken: 0.000059999999999999995,
        totalTokensUsed: 133,
      },
    },
    secondaryPartitionKey: "bot6InteractionXXXXXX104",
    secondarySortKey: 1719161154969,
    type: "BOT6_INTERACTION",
  }
*/

const EVEN_BG_COLOR = "#440000";
const ODD_BG_COLOR = "#770000";

const MONTH_NUMBER_TO_NAME = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const commonPStyle = {
  paddingRight: 0,
  paddingLeft: 0,
  paddingTop: 0,
  paddingBottom: 0,
  marginRight: 0,
  marginLeft: 0,
  marginTop: 10,
  marginBottom: 5,
  width: "96%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignSelf: "flex-end",
};

const getStringWithFirstLetterCapitalized = (string) => {
  if (!string || typeof string !== "string" || string.length === 0) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getFormattedDateFromDateObject = (dateObject) => {
  // `${dateObject.getHours() % 12}:${dateObject.getMinutes()} ${
  //           dateObject.getHours() >= 12 ? "PM" : "AM"
  //         } ${
  //           MONTH_NUMBER_TO_NAME[dateObject.getMonth() + 1]
  //         } ${dateObject.getDate()} ${dateObject.getFullYear()}`

  let hour = dateObject.getHours();

  if (hour === 0) {
    hour = 12;
  } else if (hour > 12) {
    hour -= 12;
  }

  let minute = dateObject.getMinutes();

  if (minute < 10) {
    minute = `0${minute}`;
  }

  return `${hour}:${minute} ${dateObject.getHours() >= 12 ? "PM" : "AM"} ${
    MONTH_NUMBER_TO_NAME[dateObject.getMonth() + 1]
  } ${dateObject.getDate()} ${dateObject.getFullYear()}`;
};

const CommandDataDisplay = ({ data }) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#000000",
      }}
    >
      {data.commands.map((command, index) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",

            backgroundColor: index % 2 === 0 ? EVEN_BG_COLOR : ODD_BG_COLOR,
          }}
          key={index}
        >
          <p
            style={{
              ...commonPStyle,
            }}
          >
            {`Boto ${getStringWithFirstLetterCapitalized(command.command)}`}
          </p>
          {command.parameters && Object.keys(command.parameters).length > 0 ? (
            <>
              <p
                style={{
                  ...commonPStyle,
                  fontSize: 14,
                }}
              >
                {"Parameters:"}
              </p>
              <ul>
                {Object.entries(command.parameters).map(([key, value]) => (
                  <li key={key}>
                    {getStringWithFirstLetterCapitalized(key)}:{" "}
                    {getStringWithFirstLetterCapitalized(value)}
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      ))}
    </div>
  );
};

const AiInteractionRecord = ({
  time,
  actionType,
  userInput,
  result,
  imageUrl,
}) => {
  /*
      "costData": {
        "pricePerToken": 0.000059999999999999995,
        "totalTokensUsed": 133
      }
  */

  const dateObject = new Date(time);

  return (
    <div
      style={{
        width: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {/* <p><strong>Time:</strong> {time}</p> */}
      {/* use local time */}
      {Math.abs(1) === 0 ? null : (
        <div
          style={{
            height: 40,
            width: "100%",

            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            marginTop: 9,

            // backgroundColor: "#550000",
          }}
        >
          <div
            style={{
              width: "60%",
              backgroundColor: "#550000",
              height: "75%",

              borderTopRightRadius: 4,

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",

              fontSize: 18,
            }}
          >
            {actionType}
          </div>
        </div>
      )}
      {Math.abs(1) === 0 ? null : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            backgroundColor: "#550000",
          }}
        >
          <p
            style={{
              ...commonPStyle,

              // height: 20,
              // width: "100%",

              backgroundColor: "#330000",
              paddingTop: 4,
              paddingBottom: 4,
              paddingLeft: 4,

              fontSize: 14,
              // paddingTop: 4,
              // paddingBottom: 2,
              // paddingLeft: 10,
              // paddingRight: -10,

              // display: "flex",

              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            {/*  want h:mm AM/PM month day, year */}

            {/* {`${dateObject.getHours() % 12}:${dateObject.getMinutes()} ${
            dateObject.getHours() >= 12 ? "PM" : "AM"
          } ${
            MONTH_NUMBER_TO_NAME[dateObject.getMonth() + 1]
          } ${dateObject.getDate()} ${dateObject.getFullYear()}`} */}

            {getFormattedDateFromDateObject(dateObject)}
          </p>
        </div>
      )}

      {Math.abs(1) === 1 &&
      result?.costData &&
      typeof result.costData === "object" &&
      typeof result.costData.pricePerToken === "number" &&
      typeof result.costData.totalTokensUsed === "number" ? (
        <div
          style={{
            //   height: 40,
            width: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",

            //   overflowY: "scroll",

            backgroundColor: "#440000",

            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <div
            style={{
              width: "96%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignSelf: "flex-end",

              fontSize: 14,
            }}
          >{`Cost: ${roundToDecimal(
            result.costData.pricePerToken * result.costData.totalTokensUsed,
            6
          )} PP`}</div>
        </div>
      ) : null}

      {Math.abs(1) === 0 || !userInput ? null : (
        <div
          style={{
            //   height: 40,
            width: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",

            //   overflowY: "scroll",

            paddingTop: 4,
            backgroundColor: "#550000",
            paddingBottom: 4,
          }}
        >
          <strong
            style={{
              paddingTop: 4,
              paddingBottom: 4,

              width: "96%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignSelf: "flex-end",
            }}
          >
            {"Input:"}
          </strong>

          <div
            style={{
              width: "100%",

              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "96%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",

                backgroundColor: "#440000",
              }}
            >
              {userInput}
            </div>
          </div>
        </div>
      )}

      {Math.abs(1) === 0 || !imageUrl ? null : (
        <div
          style={{
            //   height: 40,
            width: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",

            //   overflowY: "scroll",

            paddingTop: 4,
            backgroundColor: "#550000",
            paddingBottom: 4,
          }}
        >
          <strong
            style={{
              paddingTop: 4,
              paddingBottom: 4,

              width: "96%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignSelf: "flex-end",
            }}
          >
            {"Vision:"}
          </strong>

          <div
            style={{
              width: "100%",

              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "96%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",

                backgroundColor: "#440000",
              }}
            >
              <a href={imageUrl} target="_blank" rel="noreferrer">
                {`${imageUrl.slice(0, 16)}...`}
              </a>
            </div>
          </div>
        </div>
      )}

      {Math.abs(1) === 0 ? null : (
        <div
          style={{
            //   height: 40,
            width: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            backgroundColor: "#550000",

            //   overflowY: "scroll",
          }}
        >
          <strong
            style={{
              paddingTop: 4,
              paddingBottom: 4,

              width: "96%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignSelf: "flex-end",
            }}
          >
            {"Output:"}
          </strong>

          {result?.commands?.length > 0 && <CommandDataDisplay data={result} />}

          {/* {result?.commands?.length > 0 && (
          <div>
            {result?.commands?.map((command, index) => (
              <div key={index}>
                <p>
                  <strong>{command.command}:</strong>
                </p>
                <ul>
                  {Object.entries(command.parameters)?.map(([key, value]) => (
                    <li key={key}>
                      {key}: {value}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )} */}
        </div>
      )}

      <div
        style={{
          width: "100%",
          // height: 1,
          height: 4,

          backgroundColor: "#550000",
        }}
      />
    </div>
  );
};

export default AiInteractionRecord;
