import { getState, setState } from "../../../../reactStateManagement";

const handleBCharData = (bCharData) => {
  const selfUserId = getState("userId");

  if (!selfUserId) {
    console.log("handleBCharData: userId not set");

    return;
  }

  const userIdToBCharDatum = bCharData.userIdToBCharDatum;

  const wCharacterData = [];

  for (const userId in userIdToBCharDatum) {
    // console.log(`

    //     MEGA LOG ${JSON.stringify(
    //       {
    //         userId,
    //         selfUserId,
    //       },
    //       null,
    //       4
    //     )}
    //     )

    // `);

    if (userId !== selfUserId) {
      const wCharacterDatum = Object.assign({}, userIdToBCharDatum[userId], {
        userId,
      });

      wCharacterData.push(wCharacterDatum);
    }
  }

  // console.log(`set wCharacterData - count: ${wCharacterData.length}`);

  setState(["wCharacterData"], wCharacterData);
};

export default handleBCharData;
