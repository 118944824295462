import { io } from "socket.io-client";
import { EVENT, EVENT_TYPES, SOCKET_SERVER_URL } from "../../../constants.js";
import { handleEventCore } from "./handleEventCore/index.js";
import { setState } from "../../../reactStateManagement.js";
import { getEvent } from "../../getEvent.js";
import { emitEvent } from "../../emitEvent.js";
import getBrowserFingerprint from "../../processPlus/getBrowserFingerPrint.js";
import updateBChar from "../../bChar/updateBChar.js";
// import getAuth from "../../getAuth.js";
// import delay from "../../delay.js";

const MIN_TIME_BETWEEN_GUEST_JOIN = 1000 * 1;

let lastGuestJoinTime = 0;

const handleEvent = async (event) => {
  if (!event || !event.data || !event.type) {
    console.log("handleEvent: invalid event:");
    console.log(event);
    return;
  }

  try {
    await handleEventCore(event);
  } catch (error) {
    console.log("handleEvent: error:");
    console.log(error);
  }
};

let globalSocketTracker = [];

export const connectSocket = () => {
  const timeSinceLastGuestJoin = Date.now() - lastGuestJoinTime;

  if (timeSinceLastGuestJoin < MIN_TIME_BETWEEN_GUEST_JOIN) {
    // console.log(
    //   "connectSocket: timeSinceLastGuestJoin < MIN_TIME_BETWEEN_GUEST_JOIN"
    // );
    // console.log("timeSinceLastGuestJoin:", timeSinceLastGuestJoin);
    // console.log("MIN_TIME_BETWEEN_GUEST_JOIN:", MIN_TIME_BETWEEN_GUEST_JOIN);
    return;
  }
  lastGuestJoinTime = Date.now();

  for (let i = 0; i < globalSocketTracker.length; i++) {
    if (
      globalSocketTracker[i] &&
      typeof globalSocketTracker[i].disconnect === "function"
    ) {
      console.log(`disconnecting socket: ${i} - ${globalSocketTracker[i].id}`);
      globalSocketTracker[i].disconnect();
    }
  }

  let newSocket;
  newSocket = io(SOCKET_SERVER_URL);

  globalSocketTracker.push(newSocket);

  newSocket.on("connect", () => {
    console.log("⚡️");

    setState(["socket"], newSocket);

    newSocket.emit(
      EVENT,
      getEvent({
        type: EVENT_TYPES.MESSAGE,
        data: {
          text: `XXXXXXnullXXXXXX`,
          sender: "babebabe-babe-4bae-babe-babebabebabe",
        },
      })
    );

    setTimeout(async () => {
      const fingerprint = getBrowserFingerprint();

      await updateBChar({
        mutateFunction: (bCharDatum) => {
          if (!bCharDatum) {
            return null;
          }

          const newBCharDatum = {
            ...bCharDatum,
            lastUpdated: Date.now(),
          };

          return newBCharDatum;
        },
      });

      emitEvent({
        type: EVENT_TYPES.GUEST_JOIN,
        data: { fingerprint },
      });

      // setTimeout(() => {
      //   const safeUpdateWorldNumber = async () => {
      //     try {
      //       const auth = getAuth();

      //       newSocket.emit(
      //         EVENT,
      //         getEvent({
      //           type: EVENT_TYPES.UPDATE_WORLD_NUMBER,
      //           data: {
      //             auth,
      //             worldNumber: 0,
      //           },
      //         })
      //       );
      //     } catch (e) {
      //       console.log("safeUpdateWorldNumber: error:");
      //       console.log(e);
      //     }
      //   };

      //   safeUpdateWorldNumber().then(async () => {
      //     await delay(1000);
      //     await safeUpdateWorldNumber();
      //   });
      // }, 300);
    }, 300);
  });

  // // Listen for the 'message' event and update the message state
  newSocket.on("event", (event) => {
    // console.log("Received message:", msg);
    // setMessage(msg);
    handleEvent(event);
  });

  newSocket.on("disconnect", async () => {
    setState(["socket"], null);
  });

  // Return a cleanup function to disconnect the socket when the component unmounts
  // return () => {
  //   if (newSocket) {
  //     // socket.disconnect();
  //     newSocket.disconnect();
  //     // console.log("Disconnected from the Socket.IO server");
  //     console.log("🔌TTT");
  //   }
  // };
  return newSocket;
};
