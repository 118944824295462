import { putNObject } from "./putNObject";
import {
  getXDecimalPercentFromXPixel,
  getYDecimalPercentFromYPixel,
} from "../../../coordsMapper";

export const handlePutNObject = ({
  active,
  x,
  minX,
  minY,
  y,
  putObjectMode,
}) => {
  if (!active) {
    // console.log(`
    //     MEGA LOG: ${JSON.stringify(
    //       {
    //         x: x + minX,
    //         y,
    //       },
    //       null,
    //       4
    //     )}
    // `);
    return;
  }

  // console.log(`
  //           MEGA LOG: ${JSON.stringify(
  //             {
  //               x: Math.round(x + minX),
  //               y: Math.round(y),
  //               itemType: putObjectMode.itemType,
  //             },
  //             null,
  //             4
  //           )}
  //       `);
  const xUpdated = Math.round(x + minX);
  const yUpdated = Math.round(y + minY);

  const xDecimalPercent = getXDecimalPercentFromXPixel(xUpdated);
  const yDecimalPercent = getYDecimalPercentFromYPixel(yUpdated);

  putNObject({
    xDecimalPercent,
    yDecimalPercent,
    itemType: putObjectMode.itemType,
  });
};
