import { getState, setState } from "../../reactStateManagement";
import getDoPromiseInQueue from "../getDoPromiseInQueue";

const doPromiseInQueue = getDoPromiseInQueue();

const MAX_COMPLETED_REQUEST_IDS = 1000;

const _addRequestIdToCompletedRequestIds = (requestId) => {
  const completedRequestIds = getState("completedRequestIds");

  const newCompletedRequestIds = [requestId, ...completedRequestIds].slice(
    0,
    MAX_COMPLETED_REQUEST_IDS
  );

  console.log("newCompletedRequestIds:", newCompletedRequestIds.length);

  setState(["completedRequestIds"], newCompletedRequestIds);
};

export const addRequestIdToCompletedRequestIds = async ({ requestId }) => {
  await doPromiseInQueue({
    operation: async () => {
      _addRequestIdToCompletedRequestIds(requestId);
    },
  });
};
