import React from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // EVENT_TYPES,
  // EVENT_TYPES
} from "../../../../../constants";
import { loadBot6s } from "./loadBot6s";

export const Title = () => {
  return (
    <div
      style={{
        width: "100%",
        height: 40,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          // width: "calc(100% - 70px)",
          width: "calc(100% - 70px)",
          height: "100%",

          backgroundColor: COLOR_0,
          color: COLOR_1,
          borderColor: COLOR_5,
          borderWidth: 1,
          borderStyle: "solid",

          boxSizing: "border-box",

          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {"My Botos"}
      </div>

      <button
        style={{
          width: 70,
          height: "100%",

          backgroundColor: COLOR_0,
          color: COLOR_1,
          borderColor: COLOR_5,
          borderWidth: 1,
          borderStyle: "solid",

          boxSizing: "border-box",
        }}
        onClick={() => {
          loadBot6s();
        }}
      >
        {"Refresh"}
      </button>
    </div>
  );
};
