import React from "react";
import { getState } from "../reactStateManagement";
import {
  // COLOR_0,
  COLOR_2,
  COLOR_3,
  // COLOR_3,
  COLOR_4,
  COORDS_HEIGHT,
  EXECUTIVE_BOX_MODES,
  Z_INDICES,
  // NWORLD_UNIT_WIDTH_PIXELS
} from "../constants";
// import { quantize } from "../../utils/quantize";
import {
  getXCoordFromXPixels,
  getXPixelsFromXPercentString,
  getYCoordFromYPixels,
  getYPixelsFromYPercentString,
} from "../coordsMapper";

const size = COORDS_HEIGHT;

const Coords = () => {
  const showCoords = getState("showCoords");
  const executiveBoxMode = getState("executiveBoxMode");

  if (
    !showCoords ||
    executiveBoxMode === EXECUTIVE_BOX_MODES.MARKETPLACE ||
    executiveBoxMode === EXECUTIVE_BOX_MODES.BOT222 ||
    executiveBoxMode === EXECUTIVE_BOX_MODES.BOT6
  ) {
    return null;
  }

  const bCharDatum = getState("bCharDatum");
  const slideMenuData = getState("slideMenuData");
  const loginMode = getState("loginMode");

  const xPixels = getXPixelsFromXPercentString(bCharDatum.x);
  const yPixels = getYPixelsFromYPercentString(bCharDatum.y);

  // const xPixelInt = parseInt(xPixels);
  // const yPixelInt = parseInt(yPixels);

  // const xCoord = Math.round(
  //   quantize(xPixelInt, NWORLD_UNIT_WIDTH_PIXELS) / NWORLD_UNIT_WIDTH_PIXELS
  // );

  const xCoord = getXCoordFromXPixels(xPixels, {
    floor: true,
  });

  // const yCoord = Math.round(
  //   quantize(yPixelInt, NWORLD_UNIT_WIDTH_PIXELS) / NWORLD_UNIT_WIDTH_PIXELS
  // );

  const yCoord = getYCoordFromYPixels(yPixels, {
    floor: true,
  });

  // temporary set x to 2 trillion
  //   xPixelInt = 2000000000000;

  const borderMode = slideMenuData || loginMode;

  return (
    <div
      style={{
        width: 4 * size,
        height: size,

        position: "fixed",
        top: 0,
        right: 0,
        backgroundColor: borderMode ? COLOR_4 : COLOR_2,
        color: "white",

        boxSizing: "border-box",
        borderStyle: borderMode ? "solid" : "none",
        borderLeftWidth: 2,
        borderBottomWidth: 2,
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderColor: COLOR_3,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",

        zIndex: Math.round(Z_INDICES.DIALOG_LAYER * 2),
      }}
    >
      {/* {`x: ${xPixels}, y: ${yPixels}`} */}

      <div>{`x: ${xCoord}`}</div>
      <div>{`y: ${yCoord}`}</div>
    </div>
  );
};

export { Coords };
