export const IMAGE_TO_PIXELS_HEIGHT = 260;
export const TITLE_HEIGHT = IMAGE_TO_PIXELS_HEIGHT / 2 / 3;
export const IMAGE_UPLOADER_HEIGHT = IMAGE_TO_PIXELS_HEIGHT / 2 / 3;
export const IMAGE_DISPLAY_CANVAS_HEIGHT = IMAGE_TO_PIXELS_HEIGHT / 2;
export const IMAGE_TO_PIXELS_SECTION_HEIGHT = IMAGE_TO_PIXELS_HEIGHT / 2 / 3;

export const IMAGE_DISPLAY_CANVAS_ID = "image-display-canvas";
export const IMAGE_UPLOADER_ID = "image-uploader";

export const MAX_SIZE = 100;
