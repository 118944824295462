import React from "react";
import {
  COLOR_0,
  // COLOR_0,
  COLOR_1,
  COLOR_5,
} from "../../../../../constants";
import {
  // getState,
  setState,
} from "../../../../../reactStateManagement";

export const CollapseExpandBar = ({
  width = "100%",

  show,
  stateKey,
  listings = [],
  name,
}) => {
  //   const hide = !getState(stateKey);

  const hide = !show;

  let content;

  if (listings.length === 0) {
    content = (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>{`No ${name}`}</div>
      </div>
    );
  } else {
    content = (
      <div
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div>{hide ? "↓" : "↑"}</div>

        <div>{hide ? "Show" : "Hide"}</div>

        <div>{hide ? "↓" : "↑"}</div>
      </div>
    );
  }

  return (
    <button
      style={{
        width,
        height: 25,

        // backgroundColor: COLOR_0,

        backgroundColor: hide ? "#440000" : COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        setState([stateKey], hide);
      }}
    >
      {/* {hide ? "↓ Show ↓" : "↑ Hide ↑"} */}
      {content}
    </button>
  );
};
