import { getState, setState } from "../../../../reactStateManagement";
import getDoPromiseInQueue from "../../../getDoPromiseInQueue";
import { PRODUCTION_MODE } from "../../../../constants.js";
import { getNWorldPartitionDataFromXandYDecimalPercent } from "../../../../coordsMapper.js";

// const MAX_NUMBER_OF_PARTITIONS = 10;
// const MAX_NUMBER_OF_PARTITIONS = 225;
// const DEFAULT_MAX_NUMBER_OF_PARTITIONS = 1000;
// const DEFAULT_MAX_NUMBER_OF_PARTITIONS = Infinity;

const doPromiseInQueue = getDoPromiseInQueue();

const DEBUG_LOG_ON = false;
// const DEBUG_LOG_ON = true;

const worldNumber = 0;
const levelNumber = 0;

const handlePowerNWorldData = async (event) => {
  await doPromiseInQueue({
    operation: async () => {
      const partitionId = event.data.partitionId;
      const nWorldData = event.data.nWorldData;

      if (DEBUG_LOG_ON && !PRODUCTION_MODE) {
        console.log(`incoming nWorldData (BATCH): ${partitionId}`);
      }

      const partitionIdToNWorldData = getState("partitionIdToNWorldData");

      // console.log("L:", Object.keys(partitionIdToNWorldData).length);

      // partitionIdToNWorldData[partitionId] = nWorldData;

      // TO ADD, LIMIT THE NUMBER OF PARTITIONS TO 1000

      const newPartitionIdToNWorldData = {
        ...partitionIdToNWorldData,
        // [partitionId]: newNWorldData,
      };

      for (const item of nWorldData) {
        const { partitionId } = getNWorldPartitionDataFromXandYDecimalPercent(
          worldNumber,
          levelNumber,
          item.xDecimalPercent,
          item.yDecimalPercent
        );

        if (!newPartitionIdToNWorldData[partitionId]) {
          newPartitionIdToNWorldData[partitionId] = {
            time: Date.now(),
            nWorldData: [item],
          };
        } else {
          newPartitionIdToNWorldData[partitionId].nWorldData.push(item);
        }
      }
      /*
  "partitionIdToNWorldData": {
        "p_0_0_0D5_0": {

      for each item, add to correct partition

             const newNWorldData = {
        time: Date.now(),
        nWorldData,
      };

      */

      // const maxNumberOfPartitionsInMemory =
      //   (getState("settings") || {}).maxNumberOfPartitionsInMemory ||
      //   DEFAULT_MAX_NUMBER_OF_PARTITIONS;

      // if (
      //   Object.keys(newPartitionIdToNWorldData).length >
      //   maxNumberOfPartitionsInMemory
      // ) {
      //   console.log(
      //     "DEBUG LOG: handlePowerNWorldData: partitionIdToNWorldData.length > MAX_NUMBER_OF_PARTITIONS"
      //   );

      //   const partitionIds = Object.keys(newPartitionIdToNWorldData);

      //   // Sort the partitionIds by time in descending order
      //   // this means the oldest partition will be at the end
      //   const partitionIdsSorted = partitionIds.sort((a, b) => {
      //     return (
      //       newPartitionIdToNWorldData[b].time -
      //       newPartitionIdToNWorldData[a].time
      //     );
      //   });

      //   // console.log(`

      //   //   MEGA LOG ${JSON.stringify(
      //   //     {
      //   //       partitionIdsSorted,
      //   //     },
      //   //     null,
      //   //     4
      //   //   )}
      //   //   )

      //   // `);

      //   const newPartitionIdToNWorldDataTrimmed = {};

      //   for (let i = 0; i < maxNumberOfPartitionsInMemory; i++) {
      //     const partitionId = partitionIdsSorted[i];

      //     newPartitionIdToNWorldDataTrimmed[partitionId] =
      //       newPartitionIdToNWorldData[partitionId];
      //   }

      //   // console.log(`

      //   //   MEGA LOG ${JSON.stringify(
      //   //     {
      //   //       SIZE: `${
      //   //         JSON.stringify(newPartitionIdToNWorldDataTrimmed).length
      //   //       } (in bytes)`,
      //   //     },
      //   //     null,
      //   //     4
      //   //   )}
      //   //   )

      //   // `);

      //   setState(
      //     ["partitionIdToNWorldData"],
      //     newPartitionIdToNWorldDataTrimmed
      //   );

      //   return;
      // }

      setState(["partitionIdToNWorldData"], newPartitionIdToNWorldData);
    },
  });
};

export default handlePowerNWorldData;
