import { EVENT_TYPES } from "../../../constants";
import { getState, setState } from "../../../reactStateManagement";
import { emitMTokenEvent } from "../../browserRealtime/emitMTokenEvent";
import getEventRequestId from "../../processPlus/getEventRequestId";

const TOTAL_WAIT_TIME_SECONDS = 10;

const DELAY_TIME_BETWEEN_RESPONSE_CHECKS = 100;

const MAX_ITERATION_COUNT =
  (TOTAL_WAIT_TIME_SECONDS * 1000) / DELAY_TIME_BETWEEN_RESPONSE_CHECKS;

const waitForResponse = async ({ requestId }) => {
  let iterationCount = 0;

  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      console.log(
        "running waitForResponse interval: " +
          iterationCount +
          " of " +
          MAX_ITERATION_COUNT
      );

      const createImageResponses = getState("createImageResponses");

      const response = createImageResponses.find(
        (response) => response?.requestId === requestId
      );

      if (response) {
        clearInterval(interval);

        resolve(response);
      } else if (iterationCount >= MAX_ITERATION_COUNT) {
        clearInterval(interval);
        reject("waitForResponse timed out");
      }

      iterationCount++;
    }, DELAY_TIME_BETWEEN_RESPONSE_CHECKS);
  });
};

export const browserCreateImage = async ({ canvasRef, dataUrl }) => {
  const requestId = getEventRequestId();

  let imageBuffer;

  if (!dataUrl) {
    const canvas = canvasRef.current;

    imageBuffer = canvas.toDataURL("image/png");
  } else {
    imageBuffer = dataUrl;
  }

  emitMTokenEvent({
    type: EVENT_TYPES.CREATE_IMAGE,
    data: {
      requestId,
      imageBuffer,
    },
  });

  try {
    const response = await waitForResponse({ requestId });

    if (!response || !response.imageKey) {
      console.log("error in browserCreateImage: no response or no imageKey");
      throw new Error(
        "error in browserCreateImage: no response or no imageKey"
      );
    }

    console.log("browserCreateImage response:");
    console.log(response);

    // return response.imageKey;

    return {
      imageKey: response.imageKey,
    };
  } catch (error) {
    console.log("error in browserCreateImage:");
    console.log(error);

    setState(["browserAlertData"], {
      message: "Error in creating image",
    });

    throw new Error("error in browserCreateImage: " + error.message);
  }
};
