import React, { useEffect, useState } from "react";

/*
    {
        "actionables": [
            {
            "metadata": {
                "name": "Basic Item 147",
                "description": "Basic item",
                "image": "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/sword_1.png",
                "external_url": "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/sword_1.png",
                "attributes": [
                    {
                        "value": "Basic Item",
                        "trait_type": "Type"
                    }
                ]
            },
            "lastUpdated": 1718011629715,
            "tokenId": "147",
            "smartContractAddress": "0xc3844c3aea8458e21bdeee7ba6ed328637fef8cb",
            "symbol": "NFIUF",
            "secondarySortKey": 1718011563391,
            "blockNumber": "57892655",
            "purchaseAddress": "0xfcf42185fb42cfbad2fc3c1ac0d87ecef37f4185",
            "url": "https://mathbitcoin.com/new-world/flamin/147.json",
            "name": "NFT Item UltraFlamin",
            "nftType": "ERC721",
            "partitionKey": "marketplaceNftV3XXXXXX0xc3844c3aea8458e21bdeee7ba6ed328637fef8cbXXXXXX147",
            "purchaseExpiry": 1718616429715,
            "buyerMemo": "",
            "purchaseTime": 1718011629715,
            "expirationTime": 1720603563391,
            "possibleSpam": false,
            "secondaryPartitionKey": "marketplaceNftV3XXXXXX0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
            "price": 0.69,
            "sellerMemo": "",
            "type": "MARKETPLACE_NFT_PURCHASED_V3",
            "ownerAddress": "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e"
        }

        {
            "lastUpdated": 1717723580146,
            "partitionKey": "marketplaceNFTSaleReceiptV3XXXXXXmarketplaceNftV3XXXXXX0x2953399124f0cbb46d2cbacd8a89cf0599974963XXXXXX83337528321688202077871746597530122964344226551259186510292158874044814327809XXXXXX1717723580146",
            "nftTransferToBuyer": {
                "blockHash": "0x45b616225aa000f5aa0448d7b29160a2039d56c28870fd0a3378c5e7345d7a21",
                "amount": "1",
                "logIndex": 346,
                "verifiedCollection": false,
                "tokenId": "83337528321688202077871746597530122964344226551259186510292158874044814327809",
                "contractType": "ERC1155",
                "possibleSpam": true,
                "verified": 0,
                "transactionIndex": 98,
                "toAddress": "0xfcf42185fb42cfbad2fc3c1ac0d87ecef37f4185",
                "transactionHash": "0xcecf26ad4e86d6abf06bda12f4dfd30d5cd856ca8e850ff0ceba2f71e311b9c0",
                "operator": "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
                "transactionType": "Single",
                "tokenAddress": "0x2953399124f0cbb46d2cbacd8a89cf0599974963",
                "fromAddressLabel": null,
                "blockNumber": "57855769",
                "blockTimestamp": "2024-06-07T01:25:58.000Z",
                "fromAddress": "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
                "value": "0",
                "toAddressLabel": null
            },
            "secondarySortKey": 1717723580146,
            "nftListing": {
                "symbol": "OPENSTORE",
                "metadata": null,
                "tokenId": "83337528321688202077871746597530122964344226551259186510292158874044814327809",
                "possibleSpam": true,
                "secondaryPartitionKey": "marketplaceNftV3XXXXXX0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
                "type": "MARKETPLACE_NFT_PURCHASED_V3",
                "url": "https://api.opensea.io/api/v2/metadata/matic/0x2953399124F0cBB46d2CbACD8A89cF0599974963/0xb83f5e9656c427564177699fe2e0da2bacea2ef4000000000000780000000001",
                "nftType": "ERC1155",
                "lastUpdated": 1717723529364,
                "purchaseExpiry": 1718328329364,
                "secondarySortKey": 1717723495301,
                "partitionKey": "marketplaceNftV3XXXXXX0x2953399124f0cbb46d2cbacd8a89cf0599974963XXXXXX83337528321688202077871746597530122964344226551259186510292158874044814327809",
                "expirationTime": 1720315495301,
                "price": 1,
                "blockNumber": "46406387",
                "name": "OpenSea Collections",
                "smartContractAddress": "0x2953399124f0cbb46d2cbacd8a89cf0599974963",
                "purchaseTime": 1717723529364,
                "purchaseAddress": "0xfcf42185fb42cfbad2fc3c1ac0d87ecef37f4185",
                "sellerMemo": "",
                "ownerAddress": "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
                "buyerMemo": ""
            },
            "purchaseAddress": "0xfcf42185fb42cfbad2fc3c1ac0d87ecef37f4185",
            "saleAddress": "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
            "secondaryPartitionKey": "marketplaceNFTSaleReceiptV3XXXXXX0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
            "type": "MARKETPLACE_NFT_SALE_RECEIPT_V3"
        }
    ]
}
*/

const ValidImage = ({ name, listing }) => {
  const id = `nft-image-${listing.partitionKey}`;
  const [imageIsValid, setImageIsValid] = useState(true);

  useEffect(() => {
    const img = document.getElementById(id);

    if (img) {
      //   img.onload = () => {
      //     setImageIsValid(true);
      //   };

      img.onerror = () => {
        setImageIsValid(false);
      };
    }
  }, [id]);

  if (!imageIsValid) {
    return null;
  }

  let imageUrl;

  if (listing?.metadata?.image) {
    imageUrl = listing.metadata.image;
  }

  if (!imageUrl) {
    return null;
  }

  return (
    <img
      id={id}
      src={imageUrl}
      alt={name}
      style={{
        //   width: "100%",
        maxWidth: "100%",
        height: "125px",
        objectFit: "cover",
        marginTop: "20px",
        marginBottom: "20px",
      }}
    />
  );
};

export default ValidImage;
