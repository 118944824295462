import React, { useEffect, useState } from "react";
import { getState } from "../../reactStateManagement";
import { DisplayText } from "./DisplayText";

import StagingComponent from "../../StagingComponent";
import {
  getXPixelsFromXPercentString,
  getYDecimalPercentFromYPixel,
  getYPixelsFromYDecimalPercent,
  // getYPixelsFromYDecimalPercent,
  getYPixelsFromYPercentString,
} from "../../coordsMapper";
import { NZW } from "./NZW";
// import { NWORLD_UNIT_WIDTH_PERCENT_DECIMAL } from "../../worldConstants";
import {
  BELOW_CHARACTER_VIEW_OFFSET,
  BOTTOM_FACTOR,
  TOP_FACTOR,
} from "./nzwConstants";
import { Z_INDICES } from "../../constants";
// import { Coords } from "../Coords";
// import { NWORLD_UNIT_WIDTH_PERCENT_DECIMAL } from "../../worldConstants";

const setNewHeight = ({ windowHeight, setHeight }) => {
  const BYPASS_MODE = true;

  if (BYPASS_MODE) {
    return;
  }

  const $ = window.$;

  // const core-background
  const coreBackground = $("#core-background");

  if (coreBackground.length === 0) {
    return;
  }

  const coreBackgroundHeight = coreBackground.height();

  const newHeight = (windowHeight - coreBackgroundHeight) / 2;

  setHeight(newHeight);
};

typeof setNewHeight;

const setNewHeight2 = ({ windowHeight, setHeight }) => {
  const newHeight = windowHeight * TOP_FACTOR;

  setHeight(newHeight);
};

const NewZoomWorld = () => {
  const windowWidth = getState("windowWidth");
  const windowHeight = getState("windowHeight");
  const wCharacterData = getState("wCharacterData");
  const botoScreenCaptureMode = getState("botoScreenCaptureMode");

  // const screenY = getState("screenY");
  // const worldNumberToLevelNumberToNWorldData = getState(
  //   "worldNumberToLevelNumberToNWorldData"
  // );
  const bCharDatum = getState("bCharDatum");

  // const nWorldData = worldNumberToLevelNumberToNWorldData[0][0];

  const nWorldData = getState("nWorldData");

  // const [items /*setItems*/] = useState(SAMPLE_ITEM_DATA);

  // const scale = 1;
  // const scale = 0.5;
  const scale = getState("scale");

  const [pureNzwW, setNzwW] = useState(0);
  // const [height, setHeight] = useState(69);
  // const [height, setHeight] = useState("80%");
  const [pureHeight, setHeight] = useState(windowHeight * TOP_FACTOR);

  const height = pureHeight * 1;
  const nzwW = pureNzwW * 1;

  useEffect(() => {
    setNewHeight2({
      windowHeight,
      setHeight,
    });

    const t = setTimeout(() => {
      setNewHeight2({
        windowHeight,
        setHeight,
      });
    }, 500);

    return () => {
      clearTimeout(t);
    };
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    // const $ = window.$;

    // listen for window resize

    setNewHeight2({
      windowHeight,
      setHeight,
    });

    // const handleResize = () => {
    //   // set height

    //   // setNewHeight({
    //   //   windowHeight,
    //   //   setHeight,
    //   // });

    //   setNewHeight2();
    // };

    // const w = $(window);

    // if (w.length === 0) {
    //   return;
    // }

    // w.on("resize", handleResize);

    // return () => {
    //   w.off("resize", handleResize);
    // };
  }, [windowHeight]);

  // useGameComponent({
  //   id: "new-zoom-world-container",
  // });

  // if (wCharacterData.length === 0) {
  //   return null;
  // }

  const userId = getState("userId");

  if (!userId) {
    return null;
  }

  // let characterDatum;
  let characterDatum = bCharDatum;
  let frenCharacterData = [];

  for (let i = 0; i < wCharacterData.length; i++) {
    const wCharacterDatum = wCharacterData[i];

    if (wCharacterDatum.userId === userId) {
      // characterDatum = wCharacterDatum;
    } else {
      frenCharacterData.push(wCharacterDatum);
    }
  }

  if (!characterDatum) {
    return null;
  }

  const xPercentString = characterDatum.x;
  const yPercentString = characterDatum.y;

  const xPixels = getXPixelsFromXPercentString(xPercentString);
  const yPixels = getYPixelsFromYPercentString(yPercentString);

  const minX = xPixels - nzwW / 2;

  // const nzwH = windowHeight * (Number.parseFloat(height) / 100);
  const nzwH = height * scale;

  // Temporary Hardcoded Screen Y Position
  // const multiplierY = 1;
  // const minYDecimalPercent = multiplierY * NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;
  // const minY = getYPixelsFromYDecimalPercent(minYDecimalPercent);

  let minYPure;

  // if (screenY > 0) {
  // const BELOW_CHARACTER_VIEW_OFFSET_THRESHOLD =
  //   9 * NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;

  // minYPure = screenY - BELOW_CHARACTER_VIEW_OFFSET;

  const yDecimalPoint = getYDecimalPercentFromYPixel(yPixels);

  // const thresholdVal = yDecimalPoint - BELOW_CHARACTER_VIEW_OFFSET_THRESHOLD;

  // if (thresholdVal < 0) {
  //   minYPure = 0;
  // } else {
  // } else {
  //   minYPure = 0;
  // }

  minYPure = yDecimalPoint - BELOW_CHARACTER_VIEW_OFFSET;
  // }
  let minY = getYPixelsFromYDecimalPercent(minYPure);

  // const left = -windowWidth * 0.75;
  // const left =
  //   windowWidth * 0.5 -
  //   windowWidth * 0.25 -
  //   windowWidth * 0.25 -
  //   windowWidth * 0.25 -
  //   windowWidth * 0.25;

  /*

    the correct left value:

    if its left = 0 when scale is 1

    and

      when - 0.5 * windowWidth when scale is 0.5

    then

    left = -windowWidth * scale

  */

  const scaleToLeftFactor = {
    0.5: -0.5,
    1: 0,
  };

  const leftFactor = scaleToLeftFactor[scale];

  if (typeof leftFactor !== "number") {
    throw new Error("Invalid scale/leftFactor: " + leftFactor);
  }

  const left = windowWidth * leftFactor;

  const scaleToBottomOffsetFactor = {
    1: 0,
    0.5: TOP_FACTOR,

    // 0.5: 0.5,
  };

  const bottomOffsetFactor = scaleToBottomOffsetFactor[scale];

  if (typeof bottomOffsetFactor !== "number") {
    throw new Error("Invalid scale/bottomOffsetFactor: " + bottomOffsetFactor);
  }

  const scale1Bottom = windowHeight * BOTTOM_FACTOR;

  let bottomOffset = windowHeight * bottomOffsetFactor * scale;

  let TEST_USE_0_BOTTOM_OFFSET = false;
  // let TEST_USE_0_BOTTOM_OFFSET = true;

  if (TEST_USE_0_BOTTOM_OFFSET) {
    bottomOffset = 0;
  }

  const bottom = scale1Bottom - bottomOffset;

  // windowHeight  (BOTTOM_FACTOR - TOP_FACTOR * scale)

  return (
    <div
      id={"new-zoom-world-container"}
      style={{
        // backgroundColor: "green",
        position: "absolute",
        // bottom: height,
        // top: -height,

        // bottom: windowHeight * (1 - TOP_FACTOR),
        bottom,
        // left: left,
        left,
        width: windowWidth / scale,
        height: height / scale,
        // backgroundColor: "red",
        // backgroundColor: COLOR_1,

        zIndex: Z_INDICES.NEW_ZOOM_WORLD_LAYER,

        // color: COLOR_1,

        // fontFamily: FONT_0,

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        // zIndex: Z_INDICES.MIDDLE_LAYER,

        transform: `scale(${scale})`,
      }}
    >
      {/* <Coords xPixels={xPixels} yPixels={yPixels} /> */}

      <NZW
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        nzwW={nzwW}
        nzwH={nzwH}
        setNzwW={setNzwW}
        frenCharacterData={frenCharacterData}
        xPixels={xPixels}
        yPixels={yPixels}
        minX={minX}
        items={nWorldData}
        minY={minY}
        scale={scale}
        botoScreenCaptureMode={botoScreenCaptureMode}
      />

      <StagingComponent
      // overrideStagingOff={false}
      // overrideStagingOff={true}
      >
        {[
          <DisplayText
            text={`world minX: ${String(minX).substring(0, 15)}`}
            right={0}
            bottom={80}
            key={"display-text-1"}
          />,
          <DisplayText
            text={`world w: ${nzwW}`}
            right={0}
            bottom={60}
            key={"display-text-2"}
          />,
          <DisplayText
            text={`world w/2: ${nzwW / 2}`}
            right={0}
            bottom={40}
            key={"display-text-3"}
          />,
          // <DisplayText
          //   text={`window w: ${windowWidth}`}
          //   right={0}
          //   bottom={0}
          //   key={"display-text-4"}
          // />,
          <DisplayText
            text={`user x: ${xPixels.toPrecision(9)}`}
            right={0}
            bottom={20}
            key={"display-text-4"}
          />,
          <DisplayText
            text={`user y: ${yPixels.toPrecision(9)}`}
            right={0}
            bottom={0}
            key={"display-text-5"}
          />,
        ]}
      </StagingComponent>
    </div>
  );
};

export { NewZoomWorld };
