import React from "react";
// import { DisplayText } from "./DisplayText";
import { NCharBase } from "./NCharBase";

export const NCharFren = ({
  xMin,
  yMin,
  xPixels,
  yPixels,
  characterDatum,
  // xPixelsMainUser,
}) => {
  if (!characterDatum) {
    return null;
  }

  return (
    <NCharBase
      frenXPixels={xPixels}
      xMin={xMin}
      minY={yMin}
      yPixels={yPixels}
      index={1}
      rightDown={characterDatum.rightDown}
      leftDown={characterDatum.leftDown}
      lastDirection={characterDatum.lastDirection}
      character={characterDatum.character}
    />
  );
};
