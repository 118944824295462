import React from "react";
// import React, { useEffect, useState } from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // EVENT_TYPES
  BOT6_MAX_TEXT_INPUT_LENGTH as MAX_INPUT_LENGTH,
} from "../../../../../../constants";
import {
  getState,
  //getState,
  setState,
} from "../../../../../../reactStateManagement";
// import Bot6DatumDisplay from "./Bot6DatumDisplay";

const Bot6InteractInput = ({ tokenId, height, disabled }) => {
  const tokenIdToBot6InteractionInput = getState(
    "tokenIdToBot6InteractionInput"
  );

  // const tokenId = botoDatum && String(botoDatum.secondarySortKey);

  const bot6InteractionInput = tokenIdToBot6InteractionInput[tokenId] || "";

  return (
    <div
      style={{
        // width: "100%",

        // maxHeight: 420,
        position: "relative",
        // height: mobile ? 190 : 420,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",
        boxSizing: "border-box",

        //   position: "absolute",
        //   bottom: 0,
        //   left: 0,
        width: "100%",
        // height: 30,
        height,
      }}
    >
      <input
        style={{
          width: "100%",
          height: "100%",
          // maxHeight: 420,
          // position: "relative",
          // height: mobile ? 190 : 420,
          // backgroundColor: COLOR_0,
          color: COLOR_1,
          // borderColor: COLOR_5,
          borderColor: disabled ? "#888888" : COLOR_5,
          borderWidth: 1,
          borderStyle: "solid",
          boxSizing: "border-box",

          backgroundColor: COLOR_0,
          //   position: "absolute",
          //   bottom: 0,
          //   left: 0,
          // width: "100%",
          // height: 30,
        }}
        disabled={disabled}
        placeholder="Optional message"
        value={bot6InteractionInput}
        onFocus={() => {
          setState(["focus"], true);
        }}
        onBlur={() => {
          setState(["focus"], false);
        }}
        onChange={(e) => {
          const newBot6InteractionInput = e.target.value;

          if (newBot6InteractionInput.length > MAX_INPUT_LENGTH) {
            return;
          }

          setState("tokenIdToBot6InteractionInput", {
            ...tokenIdToBot6InteractionInput,
            [tokenId]: newBot6InteractionInput,
          });
        }}
      />
    </div>
  );
};

export default Bot6InteractInput;
