import React, {
  //  useState
  useEffect,
} from "react";
import {
  // BOTTOM_SECTION_HEIGHT_MOBILE,
  // BOTTOM_SECTION_HEIGHT_DESKTOP,
  COLOR_0,
  COLOR_1,
  COLOR_2,
  DIALOG_TYPES,
  // COLOR_3,
  EVENT,
  EVENT_TYPES,
  Z_INDICES,
} from "../../../constants";
import {
  getState,
  setState,
  // setState
} from "../../../reactStateManagement";
// import { SignInBox } from "./SignInBox";
import { getEvent } from "../../../utils/getEvent";
import Spacer from "../../../commonComponents/Spacer";
import handleCmdMessage from "./handleCmdMessage";
import usePreventSpacebarPress from "../../../utils/effects/usePreventSpacebarPress";
// import { SignedInBox } from "./SignedInBox";

const MESSAGE_BOX_WIDTH = 175;
// const MESSAGE_BOX_HEIGHT = 150;
// const MESSAGE_BOX_HEIGHT = BOTTOM_SECTION_HEIGHT_MOBILE;
const MAX_MESSAGE_LENGTH = 10000;

// const SAMPLE_MESSAGE_DATA = [
//   {
//     text: "hello",
//     sender: "babebabe-babe-4bae-babe-babebabebabe",
//   },
//   {
//     text: "hi",
//     sender: "beefbeef-dead-4bee-dead-deadbeefdead",
//   },

//   {
//     text: "yo",
//     sender: "babebabe-babe-4bae-babe-babebabebabe",
//   },

//   {
//     text: "hey",
//     sender: "beefbeef-dead-4bee-dead-deadbeefdead",
//   },
// ];

const specialMessageToAction = {
  adminPowerMonkey: () => {
    // opens dialog

    setState(["dialog"], {
      type: DIALOG_TYPES.EVENT_SENDER,
      heightPercentage: 100,
    });
  },
};

const sendMessage = () => {
  // const newMessages = [
  //   ...messages,
  //   {
  //     text: "new message",
  //     sender: "babebabe-babe-4bae-babe-babebabebabe",
  //   },
  // ];

  // setMessages(newMessages);

  const socket = getState("socket");

  let message = getState("messageBoxInput");
  message = message.trim();
  message = message.substring(0, MAX_MESSAGE_LENGTH);

  if (message.length === 0) {
    return;
  }

  if (specialMessageToAction[message]) {
    specialMessageToAction[message]();

    return;
  }

  handleCmdMessage(message);

  socket.emit(
    EVENT,
    getEvent({
      type: EVENT_TYPES.MESSAGE,
      data: {
        text: message,
        sender: "babebabe-babe-4bae-babe-babebabebabe",
      },
    })
  );

  setState(["messageBoxInput"], "");

  const $ = window.$;

  const $messagesSection = $("#messages-section");

  const scrollDown = () => {
    $messagesSection.scrollTop(2222222);
  };

  setTimeout(scrollDown, 25);
  setTimeout(scrollDown, 50);
  setTimeout(scrollDown, 100);
  setTimeout(scrollDown, 200);
  setTimeout(scrollDown, 500);
};

const Message = ({
  text,
  //sender
}) => {
  return (
    <div
      className={"no-drag"}
      style={{
        width: "100%",
        // height: 35,
        backgroundColor: COLOR_0,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          // height: 35,
          backgroundColor: COLOR_2,
          // color: COLOR_3,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <div
          style={{
            width: "95%",
            // height: 35,
            backgroundColor: COLOR_2,
            color: COLOR_1,
            marginTop: 10,
            marginBottom: 10,

            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",

            textAlign: "left",

            userSelect: "all",
          }}
        >
          {`${text}`}
        </div>
      </div>
      <Spacer width={"100%"} height={4} backgroundColor={COLOR_0} />
    </div>
  );
};

const MessagesSection = ({ messages }) => {
  // const messages = SAMPLE_MESSAGE_DATA;

  useEffect(() => {
    // scroll to bottom on mount

    const $ = window.$;

    const $messagesSection = $("#messages-section");

    if (!$messagesSection || $messagesSection.length === 0) {
      return;
    }

    const scrollDown = () => {
      $messagesSection.scrollTop(2222222);
    };

    setTimeout(scrollDown, 25);
    setTimeout(scrollDown, 50);
    setTimeout(scrollDown, 100);

    return () => {
      $messagesSection.scrollTop(2222222);
    };
  }, []);

  const messageElements = messages.map((messageData, index) => {
    return (
      <Message
        key={`${index}${messageData.sender}`}
        text={messageData.text}
        sender={messageData.sender}
      />
    );
  });

  return (
    <div
      id={"messages-section"}
      style={{
        width: "100%",
        height: "75%",
        backgroundColor: COLOR_0,

        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "flex-end",
        // alignItems: "center",

        display: "block",
        overflowY: "scroll",
      }}
    >
      {messageElements}
    </div>
  );
};

const CloseButton = () => {
  const id = "close-message-button";

  React.useEffect(() => {
    //     document.getElementById('yourElement').oncontextmenu = function(event) {
    //     event.preventDefault();
    //     event.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available?
    //     event.stopImmediatePropagation();
    //     return false;
    // };

    const $ = window.$;

    const $button = $(`#${id}`);

    const handleContextMenu = function (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      return false;
    };

    $button.bind("contextmenu", handleContextMenu);

    return () => {
      $button.unbind("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <button
      id={id}
      style={{
        position: "absolute",

        top: 0,
        right: 0,

        width: 40,
        height: 40,

        backgroundColor: "red",

        zIndex: 2323232,

        fontSize: 20,
      }}
      onClick={() => {
        setState(["messageMode"], false);
      }}
    >
      {"x"}
    </button>
  );
};

/**
 *  Contains input and button for sending messages
 */
const InputSection = (/*{ messages }*/) => {
  const loading = getState("loading");

  usePreventSpacebarPress({
    id: "send-message-button",
  });

  useEffect(() => {
    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    $messageBoxInput.on("keydown", (e) => {
      if (e.key === "Enter") {
        sendMessage();
      }
    });

    return () => {
      $messageBoxInput.off("keydown");
    };
  }, []);

  useEffect(() => {
    // if not focused and press "t" (for talk), focus on message box

    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    const $document = $(document);

    const listenerFunction = (e) => {
      if (e.key === "t") {
        setTimeout(() => {
          $messageBoxInput.focus();
        }, 225);
      }
    };

    $document.on("keydown", listenerFunction);

    return () => {
      $document.off("keydown", listenerFunction);
    };
  }, []);

  useEffect(() => {
    // on focus, set state key "focus" to true

    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    const handleFocus = () => {
      setState(["focus"], true);
    };

    $messageBoxInput.on("focus", handleFocus);

    return () => {
      $messageBoxInput.off("focus", handleFocus);
    };
  }, []);

  useEffect(() => {
    // on blur, set state key "focus" to false

    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    const handleBlur = () => {
      setState(["focus"], false);
    };

    $messageBoxInput.on("blur", handleBlur);

    return () => {
      $messageBoxInput.off("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    // if focused and press "esc", unfocus message box

    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    const $document = $(document);

    const listenerFunction = (e) => {
      if (e.key === "Escape") {
        $messageBoxInput.blur();
      }
    };

    $document.on("keydown", listenerFunction);

    return () => {
      $document.off("keydown", listenerFunction);
    };
  }, []);

  useEffect(() => {
    // on focus brighten the message box

    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    function brightenMessageBox() {
      $messageBoxInput.css("backgroundColor", "#8B0000");
    }

    $messageBoxInput.on("focus", brightenMessageBox);

    function dimMessageBox() {
      $messageBoxInput.css("backgroundColor", "black");
    }

    $messageBoxInput.on("blur", dimMessageBox);

    return () => {
      $messageBoxInput.off("focus", brightenMessageBox);
      $messageBoxInput.off("blur", dimMessageBox);
    };
  }, []);

  useEffect(() => {
    setState(["unreadMessages"], 0);
  }, []);

  // console.log(messages.length)

  return (
    <div
      style={{
        width: "100%",

        height: "25%",
        backgroundColor: "turquoise",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        position: "relative",
      }}
    >
      <input
        id={"message-box-input"}
        style={{
          width: "80%",
          height: "100%",

          border: "none",
          outline: "none",
          padding: 0,
          margin: 0,

          backgroundColor: "#d70000",

          color: COLOR_1,
        }}
        placeholder={"press t to type"}
        onFocus={() => {
          setState(["focus"], true);
        }}
        onBlur={() => {
          setState(["focus"], false);
        }}
        onChange={(e) => {
          let text = e.target.value;

          text = text.substring(0, MAX_MESSAGE_LENGTH);

          setState(["messageBoxInput"], text);
        }}
        value={getState("messageBoxInput")}
      />

      <button
        id={"send-message-button"}
        style={{
          width: "20%",
          height: "100%",

          border: "none",
          outline: "none",
          padding: 0,
          margin: 0,

          position: "relative",

          backgroundColor: loading ? "grey" : COLOR_0,

          cursor: loading ? "default" : "pointer",
        }}
        disabled={loading}
        onClick={() => {
          sendMessage();
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            backgroundColor: COLOR_2,
            color: COLOR_1,
          }}
        >
          {"Send"}
        </div>
      </button>
    </div>
  );
};

// const MOBILE_MODE_WIDTH_THRESHOLD = 800;

const NMessage = () => {
  // const loading = getState("loading");
  // const userData = getState("userData");
  const windowWidth = getState("windowWidth");
  const messages = getState("messages");

  // const mobileMode = windowWidth < MOBILE_MODE_WIDTH_THRESHOLD;
  // const mobileMode = getState("mobile");

  // const MESSAGE_BOX_HEIGHT = mobileMode
  //   ? BOTTOM_SECTION_HEIGHT_MOBILE
  //   : BOTTOM_SECTION_HEIGHT_DESKTOP;

  return (
    <div
      style={{
        // width: mobileMode ? MESSAGE_BOX_WIDTH : MESSAGE_BOX_WIDTH * 1.7,
        width: "50%",
        height: "100%",
        backgroundColor: COLOR_0,

        // position: "absolute",
        bottom: 0,
        left: (windowWidth - MESSAGE_BOX_WIDTH) / 2,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        zIndex: Z_INDICES.TOP_LAYER,
      }}
    >
      <CloseButton />
      <Spacer width={"100%"} height={4} backgroundColor={COLOR_0} />
      <MessagesSection messages={messages} />
      <InputSection messages={messages} />
    </div>
  );
};

export { NMessage };
