// import React, { useEffect, useState } from "react";
import {
  BOT6_MAX_TEXT_INPUT_LENGTH,
  EVENT_TYPES,
  // EVENT_TYPES
} from "../../../../../../../constants";
import {
  getState,
  //getState,
  setState,
} from "../../../../../../../reactStateManagement";
import { emitMTokenEvent } from "../../../../../../../utils/browserRealtime/emitMTokenEvent";
// import Bot6DatumDisplay from "./Bot6DatumDisplay";

export const interact = ({ tokenId, imageUrl, promptType = null }) => {
  const tokenIdToBot6InteractionInput = getState(
    "tokenIdToBot6InteractionInput"
  );

  const bot6InteractionInput = tokenIdToBot6InteractionInput[tokenId] || "";

  const data = {
    tokenId,
  };

  if (
    bot6InteractionInput &&
    typeof bot6InteractionInput === "string" &&
    bot6InteractionInput.length > 0
  ) {
    if (bot6InteractionInput.length > BOT6_MAX_TEXT_INPUT_LENGTH) {
      setState(["browserAlertData"], {
        message: "Boto interact failed, message too long",
      });

      return;
    } else {
      data.textInput = bot6InteractionInput;
    }
  }

  if (imageUrl) {
    if (typeof imageUrl === "string" && imageUrl.length > 0) {
      data.imageUrl = imageUrl;
    }
  }

  if (promptType) {
    data.promptType = promptType;
  }

  emitMTokenEvent({
    type: EVENT_TYPES.BOT6_INTERACT,
    data,
  });
};
