import {
  NWORLD_ROUND_TO_DECIMAL,
  NWORLD_X_PARTITION_PERCENT_DECIMAL,
  NWORLD_Y_PARTITION_PERCENT_DECIMAL,
} from "../../worldConstants";
import delay from "../delay";
import { roundToDecimal } from "../roundToDecimal";
import { cachePowerGetNWorldData } from "./cachePowerGetNWorldData";
const DEFAULT_DELAY = 250;

/**
 * Calculates the next partition decimal percent based on the provided parameters.
 * @param {Object} options - The options for calculating the next partition decimal percent.
 * @param {number} options.xPartitionDecimalPercentToUse - The current X partition decimal percent.
 * @param {number} options.yPartitionDecimalPercentToUse - The current Y partition decimal percent.
 * @param {Object} options.incrementToGet - The increment values for X and Y partition decimal percents.
 * @param {number} [options.multiplyDelay=1] - The delay multiplier.
 * @returns {Promise<void>} - A promise that resolves when the calculation is complete.
 */
const getNextPartitionDecimalPercent = async ({
  xPartitionDecimalPercentToUse,
  yPartitionDecimalPercentToUse,
  incrementToGet,
  multiplyDelay = 1,
}) => {
  const { xIncrementToGet, yIncrementToGet, customDelay } = incrementToGet;

  const nextXPartitionDecimalPercent = roundToDecimal(
    xPartitionDecimalPercentToUse +
      xIncrementToGet * NWORLD_X_PARTITION_PERCENT_DECIMAL,
    NWORLD_ROUND_TO_DECIMAL
  );

  const nextYPartitionDecimalPercent = roundToDecimal(
    yPartitionDecimalPercentToUse +
      yIncrementToGet * NWORLD_Y_PARTITION_PERCENT_DECIMAL,
    NWORLD_ROUND_TO_DECIMAL
  );

  if (
    nextXPartitionDecimalPercent >= 0 &&
    nextXPartitionDecimalPercent <= 1 &&
    nextYPartitionDecimalPercent >= 0 &&
    nextYPartitionDecimalPercent <= 1
  ) {
    cachePowerGetNWorldData({
      xPartitionDecimalPercent: nextXPartitionDecimalPercent,
      yPartitionDecimalPercent: nextYPartitionDecimalPercent,
    });

    const delayToUsePure = customDelay || DEFAULT_DELAY;

    const delayToUse = delayToUsePure * multiplyDelay;

    await delay(delayToUse);

    // debug log:

    // console.log(`

    //   MEGA LOG ${JSON.stringify(
    //     {
    //       incrementToGet: {
    //         xIncrementToGet: incrementToGet.increment[0],
    //         yIncrementToGet: incrementToGet.increment[1],
    //         customDelay: incrementToGet.increment[2],
    //         multiplyDelay,
    //       },
    //     },
    //     null,
    //     4
    //   )}
    //   )

    // `);
  }
};

export default getNextPartitionDecimalPercent;
