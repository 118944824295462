import React from "react";

import {
  // IMAGE_DISPLAY_CANVAS_ID,
  IMAGE_TO_PIXELS_SECTION_HEIGHT,
  //   MAX_SIZE,
} from "../imageToPixelsConstants";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // EVENT_TYPES
} from "../../../constants";
// import { getState, setState } from "../../../reactStateManagement";
import doImageToPixels from "./doImageToPixels";
import { getState, setState } from "../../../reactStateManagement";
import delay from "../../../utils/delay";

const ImageToPixelsSection = ({ image, mintDirect = false }) => {
  const loading = getState("loading");
  const text = mintDirect
    ? "Mint Directly to Pixel NFT"
    : "Convert Image to Pixels";

  return (
    <div
      style={{
        width: "100%",
        height: IMAGE_TO_PIXELS_SECTION_HEIGHT,
        backgroundColor: COLOR_0,
        color: COLOR_1,

        display: "block",
        overflow: "scroll",

        boxSizing: "border-box",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: COLOR_5,
      }}
    >
      <button
        style={{
          width: "100%",
          height: "100%",

          // backgroundColor: COLOR_0,
          background: loading ? COLOR_5 : COLOR_0,
          color: COLOR_1,

          fontSize: 16,
        }}
        disabled={loading}
        onClick={async () => {
          setState(["loading"], true);

          try {
            await doImageToPixels({ image, mintDirect });
          } catch (e) {
            console.log("error in ImageToPixelsSection:");
            console.error(e);
          }

          await delay(1500);

          setState(["loading"], false);
        }}
      >
        {text}
      </button>
    </div>
  );
};

export default ImageToPixelsSection;
