import React from "react";

export const DisplayText = ({
  text,
  top,
  left,
  bottom,
  right,

  mini = false,

  backgroundColor = "green",
  color = "black",
}) => {
  return (
    <div
      style={{
        position: "absolute",
        top,
        left,
        bottom,
        right,

        backgroundColor,

        width: mini ? "100%" : "auto",
        fontSize: mini ? 14 : "unset",

        overflow: mini ? "hidden" : "unset",

        color: mini ? "white" : color,
        wordBreak: mini ? "break-all" : "unset",
      }}
    >
      {text}
    </div>
  );
};
