import { BOT6_COMMANDS, ITEM_TYPES } from "../../constants";
import {
  getXDecimalPercentFromXCoord,
  getYDecimalPercentFromYCoord,
} from "../../coordsMapper";
import delay from "../../utils/delay";
import { putNObject } from "../NewZoomWorld/NBuildGrid/putNObject";

const sampleData = {
  lastUpdated: 1720643610974,
  partitionKey: "bot6InteractionXXXXXX104XXXXXX1720643610667",
  output: {
    commands: [
      {
        parameters: {
          x: 2,
          color: "#00FF00",
          y: 4,
        },
        command: "putPixel",
      },
    ],
    costData: {
      pricePerToken: 0.000005,
      totalTokensUsed: 1556,
    },
  },
  secondarySortKey: 1720643610667,
  trace: {
    id: "cc328af7-355d-4bdf-93a6-099cd2a76975",
    time: 1720589251428,
    searchKey:
      "bot2Action2_BOTOXXXXXXINTERACT_VIPOWERXXXXXX104_0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
    key: "bot2Action2_cc328af7-355d-4bdf-93a6-099cd2a76975_1720589251428",
  },
  input: {
    command: {
      type: "BOTO",
      subtype: "INTERACT_VIPOWER",
      value: {
        promptAIDataIndex: 1720589242647,
        gptSelection: "gpt-4o",
        contextAIDataIndex: 1720589242646,
        imageUrlAIDataIndex: 1720425041437,
        tokenId: "104",
      },
    },
    imageUrl:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/ai-images-staging/0x57d9be95b12a687d1cc6f327b57338cd85aeea8e/2024-07-08/1720425041202.png",
  },
  secondaryPartitionKey: "bot6InteractionXXXXXX104",
  type: "BOT6_INTERACTION",
};

typeof sampleData; // object

export const processingFunction = async (datum) => {
  const commands = datum?.output?.commands || [];

  console.log("running processingFunction with commands:", commands);

  for (const command of commands) {
    switch (command.command) {
      case BOT6_COMMANDS.PUT_PIXEL: {
        const { x, y, color } = command.parameters;

        console.log("running PUT_PIXEL with x:", x, "y:", y, "color:", color);

        const xDecimalPercent = getXDecimalPercentFromXCoord(x);
        const yDecimalPercent = getYDecimalPercentFromYCoord(y);

        const itemType = color ? ITEM_TYPES.TREE_0 : null;

        await putNObject({
          xDecimalPercent,
          yDecimalPercent,
          itemType,
          customBackgroundColor: color,
        });

        await delay(100);

        break;
      }
      default:
        console.log(
          `interaction ${datum?.partitionKey} - ` +
            `command ${command.command} does not need to be processed`
        );
    }
  }
};
