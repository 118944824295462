import React, { useEffect } from "react";
import { getState } from "../../reactStateManagement";
import { BOT6_COMMANDS, EVENT_TYPES } from "../../constants";
import getDoPromiseInQueue from "../../utils/getDoPromiseInQueue";
import delay from "../../utils/delay";
import { emitMTokenEvent } from "../../utils/browserRealtime/emitMTokenEvent";
import getEventRequestId from "../../utils/processPlus/getEventRequestId";
import { waitForRequestIdEventToBeCompleted as waitForRequestIdToBeCompleted } from "../../utils/browserRealtime/waitForRequestIdEventToBeCompleted";
import { processingFunction } from "./processingFunction";

const doPromiseInQueue = getDoPromiseInQueue();

const sampleData = {
  104: {
    interactions: [
      {
        lastUpdated: 1720643610974,
        partitionKey: "bot6InteractionXXXXXX104XXXXXX1720643610667",
        output: {
          commands: [
            {
              parameters: {
                x: 2,
                color: "#00FF00",
                y: 4,
              },
              command: "putPixel",
            },
          ],
          costData: {
            pricePerToken: 0.000005,
            totalTokensUsed: 1556,
          },
        },
        secondarySortKey: 1720643610667,
        trace: {
          id: "cc328af7-355d-4bdf-93a6-099cd2a76975",
          time: 1720589251428,
          searchKey:
            "bot2Action2_BOTOXXXXXXINTERACT_VIPOWERXXXXXX104_0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
          key: "bot2Action2_cc328af7-355d-4bdf-93a6-099cd2a76975_1720589251428",
        },
        input: {
          command: {
            type: "BOTO",
            subtype: "INTERACT_VIPOWER",
            value: {
              promptAIDataIndex: 1720589242647,
              gptSelection: "gpt-4o",
              contextAIDataIndex: 1720589242646,
              imageUrlAIDataIndex: 1720425041437,
              tokenId: "104",
            },
          },
          imageUrl:
            "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/ai-images-staging/0x57d9be95b12a687d1cc6f327b57338cd85aeea8e/2024-07-08/1720425041202.png",
        },
        secondaryPartitionKey: "bot6InteractionXXXXXX104",
        type: "BOT6_INTERACTION",
      },
      {
        lastUpdated: 1720594232574,
        partitionKey: "bot6InteractionXXXXXX104XXXXXX1720594232511",
        output: {
          commands: [
            {
              parameters: {
                message: "This pixel creation resembles a slice of pizza!",
              },
              command: "talk",
            },
          ],
          costData: {
            pricePerToken: 0.000005,
            totalTokensUsed: 1196,
          },
        },
        secondarySortKey: 1720594232511,
        input: {
          command: {
            type: "BOTO",
            subtype: "INTERACT_VIPOWER",
            value: {
              promptAIDataIndex: 1720594223785,
              gptSelection: "gpt-4o",
              contextAIDataIndex: 1720594223784,
              imageUrlAIDataIndex: 1720594223786,
              tokenId: "104",
            },
          },
          userInputText: "what type of food is this pixel creation?",
          imageUrl:
            "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/ai-images-staging/0x57d9be95b12a687d1cc6f327b57338cd85aeea8e/2024-07-10/1720594223387.png",
        },
        secondaryPartitionKey: "bot6InteractionXXXXXX104",
        type: "BOT6_INTERACTION",
      },
      {
        lastUpdated: 1720508326655,
        partitionKey: "bot6InteractionXXXXXX104XXXXXX1720508326586",
        output: {
          commands: [
            {
              parameters: {
                direction: "right",
              },
              command: "move",
            },
            {
              parameters: {
                message: "The blocks form the shape of the letter 'H'.",
              },
              command: "talk",
            },
          ],
          costData: {
            pricePerToken: 0.000005,
            totalTokensUsed: 1902,
          },
        },
        secondarySortKey: 1720508326586,
        input: {
          command: {
            type: "BOTO",
            subtype: "INTERACT_VIPOWER",
            value: {
              promptAIDataIndex: 1720508317835,
              gptSelection: "gpt-4o",
              contextAIDataIndex: 1720508317834,
              imageUrlAIDataIndex: 1720508317836,
              tokenId: "104",
            },
          },
          userInputText: "what letter does this look like?",
          imageUrl:
            "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/ai-images-staging/0x57d9be95b12a687d1cc6f327b57338cd85aeea8e/2024-07-09/1720508316639.png",
        },
        secondaryPartitionKey: "bot6InteractionXXXXXX104",
        type: "BOT6_INTERACTION",
      },
    ],
  },
};
typeof sampleData; // temporary line for linter compliance

const getIfDatumRequiresProcessing = (interactionDatum) => {
  // requires processing if is putPixel command

  const output = interactionDatum?.output;

  if (!output) {
    return false;
  }

  const commands = output?.commands;

  if (!commands) {
    return false;
  }

  const putPixelCommand = commands.find(
    (command) => command.command === BOT6_COMMANDS.PUT_PIXEL
  );

  if (putPixelCommand) {
    return true;
  }

  return false;
};

/*
    processing receipt schema:

    {
        partitionKey: `processingReceiptXXXXXX${datum.partitionKey}`,
        lastUpdated: Date.now(),
        type: BOT6_INTERACTION_PROCESSING_RECEIPT,
        secondaryPartitionKey: `processingReceiptXXXXXX${datum.secondaryPartitionKey}`,
        sortKey: datum.secondarySortKey,
    }

*/

const processingPlaceholderFunction = async () => {
  console.log("processingPlaceholderFunction");
  await delay(100);
  console.log("processingPlaceholderFunction done");
};

typeof processingPlaceholderFunction; // temporary line for linter compliance

const findProcessingReceipt = ({ datumPartitionKey }) => {
  const bot6InteractionProcessingReceiptData = getState(
    "bot6InteractionProcessingReceiptData"
  );

  const processingReceiptPartitionKey = `processingReceiptXXXXXX${datumPartitionKey}`;

  const processingReceipt = bot6InteractionProcessingReceiptData.find(
    (receipt) => receipt.partitionKey === processingReceiptPartitionKey
  );

  return processingReceipt;
};

const getProcessingReceiptFromDb = async ({
  datumPartitionKey,
  datumSecondaryPartitionKey,
  startingSecondarySortKey,
}) => {
  const requestId = getEventRequestId();

  emitMTokenEvent({
    type: EVENT_TYPES.LIST_BOT6_INTERACTION_PROCESSING_RECEIPTS,
    data: {
      requestId,
      datumSecondaryPartitionKey,
      startingSecondarySortKey,
    },
  });

  await waitForRequestIdToBeCompleted({
    requestId,
    name: "getProcessingReceiptFromDb",
  });

  return findProcessingReceipt({
    datumPartitionKey,
  });
};

const getProcessingReceipt = async ({
  datumPartitionKey,
  datumSecondaryPartitionKey,
  startingSecondarySortKey,
}) => {
  const processingReceipt = findProcessingReceipt({ datumPartitionKey });

  if (!processingReceipt) {
    const processingReceipt = await getProcessingReceiptFromDb({
      datumPartitionKey,
      datumSecondaryPartitionKey,
      startingSecondarySortKey,
      // datumSecondaryPartitionKey,
      // startingSecondarySortKey,
    });

    return processingReceipt;
  }

  return processingReceipt;
};

const putProcessingReceipt = async ({
  datumPartitionKey,
  datumSecondaryPartitionKey,
  datumSecondarySortKey,
}) => {
  const requestId = getEventRequestId();

  /*
  const userId = event?.data?.auth?.userId;
  const address = event?.data?.address;
  const mToken = event?.data?.mToken;
  const requestId = event?.data?.requestId;
  const datumPartitionKey = event?.data?.datumPartitionKey;
  const datumSecondaryPartitionKey = event?.data?.datumSecondaryPartitionKey;
  const datumSecondarySortKey = event?.data?.datumSecondarySortKey;
  */

  emitMTokenEvent({
    type: EVENT_TYPES.PUT_BOT6_INTERACTION_PROCESSING_RECEIPT,
    data: {
      requestId,
      datumPartitionKey,
      datumSecondaryPartitionKey,
      datumSecondarySortKey,
    },
  });

  await waitForRequestIdToBeCompleted({
    requestId,
    name: "putProcessingReceipt",
  });
};

const handleInteractionDatumProcessing = async () => {
  await doPromiseInQueue({
    operation: async () => {
      const tokenIdToBot6InteractionData = getState(
        "tokenIdToBot6InteractionData"
      );

      const tokenIds = Object.keys(tokenIdToBot6InteractionData);

      for (let i = 0; i < tokenIds.length; i++) {
        const tokenId = tokenIds[i];

        console.log(`i ${i} - tokenId ${tokenId}`);

        const bot6InteractionData = tokenIdToBot6InteractionData[tokenId];

        for (let j = 0; j < bot6InteractionData.interactions.length; j++) {
          // for (let j = 0; j <

          const bot6InteractionDatum = bot6InteractionData.interactions[j];
          // const bot6InteractionDatum = bot6InteractionData[j];

          const requiresProcessing =
            getIfDatumRequiresProcessing(bot6InteractionDatum);

          // console.log(
          //   `j ${j} - tokenId ${tokenId} - requiresProcessing ${requiresProcessing}`
          // );

          if (requiresProcessing) {
            // console.log(
            //   `requires processing: ${bot6InteractionDatum.partitionKey}`
            // );

            console.log(
              `j ${j} - tokenId ${tokenId} - requiresProcessing ${requiresProcessing} - ` +
                `key: ${bot6InteractionDatum.partitionKey}`
            );

            const processingReceipt = await getProcessingReceipt({
              datumPartitionKey: bot6InteractionDatum.partitionKey,
              datumSecondaryPartitionKey:
                bot6InteractionDatum.secondaryPartitionKey,
              startingSecondarySortKey: bot6InteractionDatum.secondarySortKey,
            });

            if (!processingReceipt) {
              console.log(`processing: ${bot6InteractionDatum.partitionKey}`);
              // await processingPlaceholderFunction(tokenId);

              await processingFunction(bot6InteractionDatum);

              await putProcessingReceipt({
                datumPartitionKey: bot6InteractionDatum.partitionKey,
                datumSecondaryPartitionKey:
                  bot6InteractionDatum.secondaryPartitionKey,
                datumSecondarySortKey: bot6InteractionDatum.secondarySortKey,
              });
            } else {
              console.log(
                `processing receipt already exists: ${bot6InteractionDatum.partitionKey}`
              );
            }
          }
        }
      }
    },
  });
};

// const searchProcessingReceipt = async ({
//   datumSecondaryPartitionKey,
//   startingSecondarySortKey,
// }) => {
//   // TODO: implement this function
//   typeof partitionKey; // temporary line for linter compliance
// };

// typeof searchProcessingReceipt; // temporary line for linter compliance

// non-visual component - used for background processing
export const AbstractBot6Processor = () => {
  const tokenIdToBot6InteractionData = getState("tokenIdToBot6InteractionData");

  useEffect(() => {
    handleInteractionDatumProcessing();
  }, [tokenIdToBot6InteractionData]);

  return (
    <div
      id="abstract-bot-6-processor"
      style={{
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        position: "absolute",
        top: 0,
        left: 0,
      }}
    ></div>
  );
};
