import React from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // MARKETPLACE_MODES,
} from "../../../../../constants";
// import { getState, setState } from "../../../../reactStateManagement";
import { RefreshButton } from "./RefreshButton";
import { ListingsScrollBox } from "./ListingsScrollBox";
import { Listing } from "./Listing";
import { getState, setState } from "../../../../../reactStateManagement";
import NFTList from "./NFTList";
import { RefreshSalesButton } from "./RefreshSalesButton";
import { RefreshActionablesButton } from "./RefreshActionablesButton";
import { CollapseExpandBar } from "./CollapseExpandBar";

// const SAMPLE_MARKETPLACE_NFT_LISTINGS = [
//   {
//     metadata: {
//       name: "Basic Item 222",
//       description: "Basic item",
//       image:
//         "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/sword_1.png",
//       external_url:
//         "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/sword_1.png",
//       attributes: [
//         {
//           value: "Basic Item",
//           trait_type: "Type",
//         },
//       ],
//     },
//     lastUpdated: 1717514563123,
//     tokenId: "222",
//     smartContractAddress: "0xc3844c3aea8458e21bdeee7ba6ed328637fef8cb",
//     symbol: "NFIUF",
//     secondarySortKey: 1717514518898,
//     blockNumber: "38865194",
//     purchaseAddress: "0xfCf42185fB42CfBad2fC3C1AC0d87eCEF37f4185",
//     url: "https://mathbitcoin.com/new-world/flamin/222.json",
//     name: "NFT Item UltraFlamin",
//     partitionKey:
//       "marketplaceNftV3XXXXXX0xc3844c3aea8458e21bdeee7ba6ed328637fef8cbXXXXXX222",
//     purchaseExpiry: 1718119363123,
//     expirationTime: 1720106518898,
//     possibleSpam: false,
//     secondaryPartitionKey:
//       "marketplaceNftV3XXXXXX0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
//     price: 0.05,
//     type: "MARKETPLACE_NFT_PURCHASED_V3",
//     ownerAddress: "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
//   },
// ];

const TextBox = ({
  text,
  refreshButton = (
    <div style={{ width: 1, height: 1, backgroundColor: "transparent" }} />
  ),
  marginBottom = 0,
  width = "100%",
}) => {
  return (
    <div
      style={{
        width,
        height: 40,

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        marginBottom,
      }}
    >
      {text}

      {refreshButton}
    </div>
  );
};

export const Listings = ({ fullMarketplaceExpandMode }) => {
  // const listings = [
  //   SAMPLE_MARKETPLACE_NFT_LISTINGS[0],
  //   SAMPLE_MARKETPLACE_NFT_LISTINGS[0],
  // ];

  const listings = getState("marketplaceNFTListings");
  const actionables = getState("marketplaceNFTActionables");
  const sales = getState("marketplaceNFTSales");
  const showNFTListings = getState("marketplaceShowNFTListings");
  const showNFTActionables = getState("marketplaceShowNFTActionables");
  const showNFTSales = getState("marketplaceShowNFTSales");

  // sales = []; // temp

  const listingElements = showNFTListings
    ? listings.map((listing, index) => {
        return <Listing key={index} listing={listing} />;
      })
    : [];

  const acitonablesElements = showNFTActionables
    ? actionables.map((listing, index) => {
        // "nftActionables": [
        //     {
        //         "metadata": {
        //             "name": "Tamachi #266",
        //             "image": "https://i.seadn.io/gcs/files/132515cf65c242798765980e1c53356c.jpg?w=500&auto=format",
        //             "description": "Tamachi Is A Collection Created From Fusion Between Egg And Chicken",
        //             "animation_url": null,
        //             "traits": [
        //                 {
        //                     "display_type": null,
        //                     "trait_count": 0,
        //                     "value": "Boto",
        //                     "trait_type": "Accesories",
        //                     "max_value": null,
        //                     "order": null
        //                 },
        //                 {
        //                     "display_type": null,
        //                     "trait_count": 0,
        //                     "value": "Chicken",
        //                     "trait_type": "Character",
        //                     "max_value": null,
        //                     "order": null
        //                 }
        //             ],
        //             "external_link": null
        //         },
        //         "lastUpdated": 1717699511607,
        //         "tokenId": "55240584583150782419737542431287223382763462269055877625221755060801161396225",
        //         "smartContractAddress": "0x2953399124f0cbb46d2cbacd8a89cf0599974963",
        //         "symbol": "OPENSTORE",
        //         "secondarySortKey": 1717688818792,
        //         "blockNumber": "48340406",
        //         "purchaseAddress": "0xfcf42185fb42cfbad2fc3c1ac0d87ecef37f4185",
        //         "url": "https://api.opensea.io/api/v2/metadata/matic/0x2953399124F0cBB46d2CbACD8A89cF0599974963/0x7a21101908aebcb5dd6e7e92e23041a52ff3fc51000000000001830000000001",
        //         "name": "OpenSea Collections",
        //         "nftType": "ERC1155",
        //         "partitionKey": "marketplaceNftV3XXXXXX0x2953399124f0cbb46d2cbacd8a89cf0599974963XXXXXX55240584583150782419737542431287223382763462269055877625221755060801161396225",
        //         "purchaseExpiry": 1718304311607,
        //         "buyerMemo": "",
        //         "purchaseTime": 1717699511607,
        //         "expirationTime": 1720280818792,
        //         "possibleSpam": true,
        //         "secondaryPartitionKey": "marketplaceNftV3XXXXXX0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
        //         "price": 2,
        //         "sellerMemo": "",
        //         "type": "MARKETPLACE_NFT_PURCHASED_V3",
        //         "ownerAddress": "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e"
        //     }
        // ]
        return <Listing key={index} listing={listing} actionable={true} />;
      })
    : [];

  const saleElements = showNFTSales
    ? sales.map((sale, index) => {
        return (
          <Listing
            key={index}
            listing={sale.nftListing}
            nftTransferToBuyer={sale.nftTransferToBuyer}
            sale={sale}
          />
        );
      })
    : [];

  const marketplaceTextBoxWidth = 1;

  const marketplaceTextBoxOffset = fullMarketplaceExpandMode ? 80 : 40;

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       actionables,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  return (
    <div
      style={{
        width: "100%",
        // height: 40,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",
      }}
    >
      {/* top row */}
      {/* <div
        style={{
          width: "100%",
          // height: 40,

          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>{"Listings"}</div>

        <RefreshButton />
      </div> */}

      <div
        style={{
          width: "100%",
          height: 40,
          // height: 40,

          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <TextBox
          text={"Marketplace"}
          marginBottom={0}
          width={`calc( ${
            marketplaceTextBoxWidth * 100
          }% - ${marketplaceTextBoxOffset}px)`}
        />
        <button
          style={{
            width: "40px",

            height: "100%",
            // height: 40,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            fontSize: 14,

            color: COLOR_1,
          }}
          onClick={() => {
            const marketplaceExpandMode = getState("marketplaceExpandMode");

            setState(["marketplaceExpandMode"], !marketplaceExpandMode);
          }}
        >
          {/*
            left and right arrow characters

            */}

          {/* {"<>"} */}

          {fullMarketplaceExpandMode ? "→" : "←"}
        </button>
      </div>

      <ListingsScrollBox fullMarketplaceExpandMode={fullMarketplaceExpandMode}>
        <NFTList key={"addListingSection"} />
        <TextBox text={"Listings"} refreshButton={<RefreshButton />} />
        <CollapseExpandBar
          show={showNFTListings}
          stateKey="marketplaceShowNFTListings"
          listings={listings}
          name={"Listings"}
        />
        {listingElements}
        <TextBox
          text={"List NFT"}
          refreshButton={
            <div
              style={{
                width: 1,
                height: 1,
                backgroundColor: "transparent",
              }}
            />
          }
        />
        <TextBox
          text={"Actionables"}
          refreshButton={<RefreshActionablesButton />}
        />
        <CollapseExpandBar
          show={showNFTActionables}
          stateKey="marketplaceShowNFTActionables"
          listings={actionables}
          name={"Actionables"}
        />
        {acitonablesElements}
        <TextBox text={"Sales"} refreshButton={<RefreshSalesButton />} />
        <CollapseExpandBar
          show={showNFTSales}
          stateKey="marketplaceShowNFTSales"
          listings={sales}
          name={"Sales"}
        />
        {saleElements}
      </ListingsScrollBox>
    </div>
  );
};
