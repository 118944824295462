export const DEFAULT_START_X_PERCENT_DECIMAL = 0.5;
export const DEFAULT_START_X_PERCENT_STRING = "50%";
export const DEFAULT_START_Y_PERCENT_DECIMAL = 0;
export const DEFAULT_START_Y_PERCENT_STRING = "0%";

// World
const GROUND = "GROUND";

export const UNIT_TYPES = {
  GROUND,
};

export const WORLD_NUMBER_TO_DATA = {
  1: {
    // w: 4,
    // h: 4,
    w: 4,
    h: 4,
    // w: 12,
    // h: 12,
    unitNumberToData: {
      0: {
        unitType: GROUND,
      },
      1: {
        unitType: GROUND,
      },
      2: {
        unitType: GROUND,
      },
      3: {
        unitType: GROUND,
      },
    },
  },
  2: {
    // w: 4,
    // h: 4,
    w: 10,
    h: 10,
    // w: 12,
    // h: 12,
    unitNumberToData: {
      0: {
        unitType: GROUND,
      },
      1: {
        unitType: GROUND,
      },
      2: {
        unitType: GROUND,
      },
      3: {
        unitType: GROUND,
      },
      4: {
        unitType: GROUND,
      },
      5: {
        unitType: GROUND,
      },
      6: {
        unitType: GROUND,
      },
      7: {
        unitType: GROUND,
      },
      8: {
        unitType: GROUND,
      },
      9: {
        unitType: GROUND,
      },
    },
  },
  0: {
    // w: 4,
    // h: 4,
    w: 1,
    h: 1,
    // w: 12,
    // h: 12,
    unitNumberToData: {
      0: {
        unitType: GROUND,
      },
      1: {
        unitType: GROUND,
      },
      // 2: {
      //   unitType: GROUND,
      // },
    },
  },
};

export const ITEM_TYPES = {
  TREE_0: "tree0",
  BIRCH_TREE_0: "birchTree0",
  STORE_0: "store0",
};

export const MOVEMENT_BLOCKING_ITEM_TYPES = [
  ITEM_TYPES.TREE_0,
  ITEM_TYPES.BIRCH_TREE_0,
];

// export const NWORLD_TOTAL_WIDTH = 2000000; // original 2 million
////////////////////////////////  9007199254740991 // MAX_SAFE_INTEGER

export const NWORLD_TOTAL_WIDTH = 2000000000000; // 2 trillion pixels
export const NWORLD_TOTAL_WIDTH_PIXELS = NWORLD_TOTAL_WIDTH; // alias
export const NWORLD_MIN_X = -NWORLD_TOTAL_WIDTH / 2;
export const NWORLD_MIN_X_PIXELS = NWORLD_MIN_X; // alias
export const NWORLD_MAX_X = NWORLD_TOTAL_WIDTH / 2;
export const NWORLD_MAX_X_PIXELS = NWORLD_MAX_X; // alias
export const NWORLD_TOTAL_HEIGHT = NWORLD_TOTAL_WIDTH;
export const NWORLD_TOTAL_HEIGHT_PIXELS = NWORLD_TOTAL_HEIGHT; // alias
export const NWORLD_MIN_Y = -NWORLD_TOTAL_HEIGHT / 2;
export const NWORLD_MIN_Y_PIXELS = NWORLD_MIN_Y; // alias
export const NWORLD_MAX_Y = NWORLD_TOTAL_HEIGHT / 2;
export const NWORLD_MAX_Y_PIXELS = NWORLD_MAX_Y; // alias
export const NWORLD_UNIT_WIDTH = 50;
export const NWORLD_UNIT_WIDTH_PIXELS = NWORLD_UNIT_WIDTH; // alias
export const NWORLD_UNIT_WIDTH_PERCENT_DECIMAL =
  NWORLD_UNIT_WIDTH_PIXELS / NWORLD_TOTAL_WIDTH;

export const NWORLD_STANDARD_USER_WIDTH_PERCENT_DECIMAL =
  NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;

export const NWORLD_STANDARD_USER_HEIGHT_PERCENT_DECIMAL =
  2 * NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;

const NWORLD_X_PARTITION_PIXELS = 500;
const NWORLD_Y_PARTITION_PIXELS = 500;

export const NWORLD_X_PARTITION_PERCENT_DECIMAL =
  NWORLD_X_PARTITION_PIXELS / NWORLD_TOTAL_WIDTH;
export const NWORLD_Y_PARTITION_PERCENT_DECIMAL =
  NWORLD_Y_PARTITION_PIXELS / NWORLD_TOTAL_HEIGHT;

export const NWORLD_ROUND_TO_DECIMAL = 15;

export const ITEM_TYPE_TO_DATA = {
  // tree
  tree0: {
    allowedOnUnitTypes: [GROUND],
    width: NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
    height: NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
  },

  // birch tree
  birchTree0: {
    allowedOnUnitTypes: [GROUND],
    width: NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
    height: NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
  },
};

// export const MAX_PUT_Y_INTEGER = 20;
export const MAX_PUT_Y_INTEGER = Math.floor(
  NWORLD_TOTAL_WIDTH / NWORLD_UNIT_WIDTH_PIXELS
);

export const NWORLD_MAX_PIXEL_ART_SIZE = 100;
