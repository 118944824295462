import React from "react";
// import { getState, setState } from "../../reactStateManagement";
import { ToggleButtonModeButtonSection } from "./ToggleButtonModeButtonSection";
import { BUTTON_HEIGHT_PERCENTAGE } from "./BUTTON_HEIGHT_PERCENTAGE";
import { emitEvent } from "../../utils/emitEvent";
import getAuth from "../../utils/getAuth";
import { EVENT_TYPES } from "../../constants";

export const EventTriggerButtonsSection4 = ({ buttonMode, setButtonMode }) => {
  // const settings = getState("settings");

  const [settingsTextAreaValue, setSettingsTextAreaValue] = React.useState(
    JSON.stringify(
      {
        tokenIdToLandData: {},
      },
      null,
      2
    )
  );

  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",

        // backgroundColor: "darkslateblue",
      }}
    >
      <ToggleButtonModeButtonSection
        {...{
          buttonMode,
          setButtonMode,
        }}
      />

      <textarea
        style={{
          width: "80%",
          height: `${5 * BUTTON_HEIGHT_PERCENTAGE}%`,
          // backgroundColor: "black",
          backgroundColor: "darkslategray",
          // color: "white",
          color: "white",
        }}
        onChange={(event) => {
          setSettingsTextAreaValue(event.target.value);
        }}
        value={settingsTextAreaValue}
      ></textarea>

      <button
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          // backgroundColor: "black",
          backgroundColor: "darkslategray",
          // color: "white",
          color: "white",
        }}
        onClick={() => {
          console.log("Power Button 4");

          const auth = getAuth();

          emitEvent({
            type: EVENT_TYPES.UPDATE_LAND_MAPPINGS,
            data: {
              auth,
              landMappings: JSON.parse(settingsTextAreaValue),
            },
          });
        }}
      >
        {"Update Land Mappings"}
      </button>
    </div>
  );
};
