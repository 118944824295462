import {
  EVENT_TYPES,
  // EVENT_TYPES
} from "../../../../../constants";
import { emitEvent } from "../../../../../utils/emitEvent";
import getAuth from "../../../../../utils/getAuth";
import { getMTokenData } from "../../../../../utils/mToken";
// import { getState } from "../../../../../reactStateManagement";

export const loadBot6s = () => {
  const auth = getAuth();

  if (!auth) {
    return;
  }

  const mTokenData = getMTokenData();

  if (!mTokenData) {
    return;
  }

  emitEvent({
    type: EVENT_TYPES.SEARCH_BOT6S,
    data: {
      auth,
      address: mTokenData.address,
      mToken: mTokenData.mToken,
    },
  });
};
