import { roundToDecimal } from "./utils/roundToDecimal";
import {
  NWORLD_TOTAL_WIDTH,
  NWORLD_TOTAL_HEIGHT,
  NWORLD_X_PARTITION_PERCENT_DECIMAL,
  NWORLD_Y_PARTITION_PERCENT_DECIMAL,
  NWORLD_ROUND_TO_DECIMAL,
  NWORLD_UNIT_WIDTH_PIXELS,
} from "./worldConstants";

export const quantize = (
  x,
  m,
  { floor = false, ceil = false } = {
    floor: false,
    ceil: false,
  }
) => {
  if (floor) {
    const k = Math.floor(x / m);
    return k * m;
  } else if (ceil) {
    const k = Math.ceil(x / m);
    return k * m;
  }
  const k = Math.round(x / m);
  return k * m;
};

// Deprecated
export const globalCoordToGridCoord = (worldData, unitNumber = 0) => {
  const NUMBER_OF_COLUMNS = worldData.w;
  const NUMBER_OF_ROWS = worldData.h;
  const TOTAL_NUMBER_OF_UNITS = NUMBER_OF_COLUMNS * NUMBER_OF_ROWS;
  const MAX_UNIT_NUMBER = TOTAL_NUMBER_OF_UNITS - 1;

  if (0 > unitNumber || unitNumber > MAX_UNIT_NUMBER) {
    return null;
    // throw new Error("Invalid unit number: " + unitNumber);
  }

  // const numberOfRows = TOTAL_NUMBER_OF_UNITS / UNITS_PER_ROW;

  // const numberOfColumns = TOTAL_NUMBER_OF_UNITS / numberOfRows;

  // TOTAL = NUMBER_OF_ROWS * NUMBER_OF_COLUMNS
  /*
        90 91 92 93 94 95 96 97 98 99
        80 81 82 83 84 85 86 87 88 89
        70 71 72 73 74 75 76 77 78 79
        60 61 62 63 64 65 66 67 68 69
        50 51 52 53 54 55 56 57 58 59
        40 41 42 43 44 45 46 47 48 49
        30 31 32 33 34 35 36 37 38 39
        20 21 22 23 24 25 26 27 28 29
        10 11 12 13 14 15 16 17 18 19
     0  0  1  2  3  4  5  6  7  8  9
        0
      */

  const x = unitNumber % NUMBER_OF_COLUMNS;

  const y = NUMBER_OF_COLUMNS - 1 - Math.floor(unitNumber / NUMBER_OF_COLUMNS);

  return {
    x,
    y,
  };
};

// must be within -NWORLD_TOTAL_WIDTH / 2 <= x <= NWORLD_TOTAL_WIDTH / 2
export const getXDecimalPercentFromXPixel = (pixel) => {
  if (pixel < -NWORLD_TOTAL_WIDTH / 2) {
    pixel = -NWORLD_TOTAL_WIDTH / 2;
  }

  if (pixel > NWORLD_TOTAL_WIDTH / 2) {
    pixel = NWORLD_TOTAL_WIDTH / 2;
  }

  const decimalPercent = roundToDecimal(
    (pixel + NWORLD_TOTAL_WIDTH / 2) / NWORLD_TOTAL_WIDTH,
    NWORLD_ROUND_TO_DECIMAL
  );

  return decimalPercent;
};

// must be within 0 <= x <= NWORLD_TOTAL_HEIGHT
export const getYDecimalPercentFromYPixel = (pixel) => {
  if (pixel < 0) {
    pixel = 0;
  }

  if (pixel > NWORLD_TOTAL_HEIGHT) {
    pixel = NWORLD_TOTAL_HEIGHT;
  }

  // const decimalPercent = pixel / NWORLD_TOTAL_HEIGHT;

  const decimalPercent = roundToDecimal(
    pixel / NWORLD_TOTAL_HEIGHT,
    NWORLD_ROUND_TO_DECIMAL
  );

  return decimalPercent;
};

export const getXPixelsFromXDecimalPercent = (decimalPercent) => {
  // need to consider offset for x

  let pixelX = decimalPercent * NWORLD_TOTAL_WIDTH - NWORLD_TOTAL_WIDTH / 2;

  if (pixelX < -NWORLD_TOTAL_WIDTH / 2) {
    pixelX = -NWORLD_TOTAL_WIDTH / 2;
  }

  if (pixelX > NWORLD_TOTAL_WIDTH / 2) {
    pixelX = NWORLD_TOTAL_WIDTH / 2;
  }

  return pixelX;
};

export const getXPixelsMagnitudeFromXDecimalPercent = (decimalPercent) => {
  let pixelX = decimalPercent * NWORLD_TOTAL_WIDTH;

  if (pixelX < 0) {
    // safeguard
    pixelX = 0;
  }
  if (pixelX > NWORLD_TOTAL_WIDTH) {
    // safeguard
    pixelX = NWORLD_TOTAL_WIDTH;
  }
  return pixelX;
};

export const getYPixelsFromYDecimalPercent = (decimalPercent) => {
  let pixelY = decimalPercent * NWORLD_TOTAL_HEIGHT;

  if (pixelY < 0) {
    pixelY = 0;
  }

  if (pixelY > NWORLD_TOTAL_HEIGHT) {
    pixelY = NWORLD_TOTAL_HEIGHT;
  }

  return pixelY;
};

export const getDecimalPercentFromPercentString = (percentString) => {
  const decimalPercent = roundToDecimal(
    parseFloat(percentString) / 100,
    NWORLD_ROUND_TO_DECIMAL
  );
  // parseFloat(percentString) / 100;

  return decimalPercent;
};

export const getXPixelsFromXPercentString = (xPercentString) => {
  const decimalPercent = getDecimalPercentFromPercentString(xPercentString);
  const pixelX = getXPixelsFromXDecimalPercent(decimalPercent);
  return pixelX;
};

export const getYPixelsFromYPercentString = (yPercentString) => {
  const decimalPercent = getDecimalPercentFromPercentString(yPercentString);
  const pixelY = getYPixelsFromYDecimalPercent(decimalPercent);
  return pixelY;
};

export const getPercentStringFromDecimalPercent = (decimalPercent) => {
  const percentString = `${decimalPercent * 100}%`;
  return percentString;
};

export const getXPercentStringFromXPixels = (xPixels) => {
  const decimalPercent = getXDecimalPercentFromXPixel(xPixels);
  const percentString = getPercentStringFromDecimalPercent(decimalPercent);
  return percentString;
};

export const getYPercentStringFromYPixels = (yPixels) => {
  const decimalPercent = getYDecimalPercentFromYPixel(yPixels);
  const percentString = getPercentStringFromDecimalPercent(decimalPercent);
  return percentString;
};

export const getAbsoluteXPixelsFromXDecimalPercent = (decimalPercent) => {
  return decimalPercent * NWORLD_TOTAL_WIDTH;
};

export const getAbsoluteYPixelsFromYDecimalPercent = (decimalPercent) => {
  return decimalPercent * NWORLD_TOTAL_HEIGHT;
};

export const getStringEncodedDecimalPercent = (decimalPercent) => {
  // replace . with D

  if (decimalPercent < 0 || decimalPercent > 1) {
    throw new Error("Invalid decimalPercent: " + decimalPercent);
  }

  let stringEncodedDecimalPercent = decimalPercent.toFixed(20);

  stringEncodedDecimalPercent = decimalPercent.toString().replace(".", "D");

  return stringEncodedDecimalPercent;
};

export const getDecimalPercentFromStringEncodedDecimalPercent = (
  stringEncodedDecimalPercent
) => {
  // replace D with .

  const decimalPercent = parseFloat(
    stringEncodedDecimalPercent.replace("D", ".")
  );

  return decimalPercent;
};

export const getXPartitionDecimalPercentFromXPartitionNumber = (
  xPartitionNumber
) => {
  const xPartitionDecimalPercentUnrounded =
    xPartitionNumber * NWORLD_X_PARTITION_PERCENT_DECIMAL;

  const xPartitionDecimalPercent = roundToDecimal(
    xPartitionDecimalPercentUnrounded,
    NWORLD_ROUND_TO_DECIMAL
  );

  return xPartitionDecimalPercent;
};

export const getYPartitionDecimalPercentFromYPartitionNumber = (
  yPartitionNumber
) => {
  const yPartitionDecimalPercentUnrounded =
    yPartitionNumber * NWORLD_Y_PARTITION_PERCENT_DECIMAL;

  const yPartitionDecimalPercent = roundToDecimal(
    yPartitionDecimalPercentUnrounded,
    NWORLD_ROUND_TO_DECIMAL
  );

  return yPartitionDecimalPercent;
};

const ROUNDING_ERROR_THRESHOLD = 0.00001;

export const getXPartitionNumberFromXDecimalPercent = (xDecimalPercent) => {
  // if there is a small rounding error
  // then should use round and not floor

  const division = xDecimalPercent / NWORLD_X_PARTITION_PERCENT_DECIMAL;

  const xFloor = Math.floor(division);
  const xCeiling = Math.ceil(division);

  const distanceToFloor = Math.abs(division - xFloor);
  const distanceToCeiling = Math.abs(division - xCeiling);

  let xPartitionNumber;

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       distanceToCeiling,
  //       xFloor,
  //       xCeiling,
  //       ROUNDING_ERROR_THRESHOLD,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  if (
    distanceToFloor < ROUNDING_ERROR_THRESHOLD ||
    distanceToCeiling < ROUNDING_ERROR_THRESHOLD
  ) {
    xPartitionNumber = Math.round(division);
  } else {
    xPartitionNumber = Math.floor(division);
  }

  return xPartitionNumber;
};

export const getYPartitionNumberFromYDecimalPercent = (yDecimalPercent) => {
  // const yPartitionNumber = Math.floor(
  //   yDecimalPercent / NWORLD_Y_PARTITION_PERCENT_DECIMAL
  // );
  // do same as x

  let division = roundToDecimal(
    yDecimalPercent / NWORLD_Y_PARTITION_PERCENT_DECIMAL,
    NWORLD_ROUND_TO_DECIMAL
  );

  const yFloor = Math.floor(division);

  // const distanceToCeiling = division - yFloor;
  const distanceToCeiling = 1 - Math.abs(division - yFloor);

  let yPartitionNumber;

  if (distanceToCeiling < ROUNDING_ERROR_THRESHOLD) {
    // should always be ceiling
    yPartitionNumber = Math.round(division);
  } else {
    yPartitionNumber = Math.floor(division);
  }

  return yPartitionNumber;
};

export const getYPStringFromYDecimalPercent = (
  yDecimalPercent,
  returnComplete = false
) => {
  // const yPartitionNumber = Math.floor(
  //   yDecimalPercent / NWORLD_Y_PARTITION_PERCENT_DECIMAL
  // );
  const yPartitionNumber =
    getYPartitionNumberFromYDecimalPercent(yDecimalPercent);

  // const yPartitionDecimalPercentUnrounded =
  //   yPartitionNumber * NWORLD_Y_PARTITION_PERCENT_DECIMAL;

  // const yPartitionDecimalPercent = roundToDecimal(
  //   yPartitionDecimalPercentUnrounded,
  //   NWORLD_ROUND_TO_DECIMAL
  // );

  const yPartitionDecimalPercent =
    getYPartitionDecimalPercentFromYPartitionNumber(yPartitionNumber);

  const yPartitionSEDecimalPercentString = getStringEncodedDecimalPercent(
    yPartitionDecimalPercent
  );

  if (returnComplete) {
    return {
      yPartitionNumber,
      yPartitionDecimalPercent,
      yPartitionSEDecimalPercentString,
    };
  }

  return yPartitionSEDecimalPercentString;
};

export const getNWorldPartitionDataFromXandYDecimalPercent = (
  worldNumber,
  levelNumber,
  xDecimalPercent,
  yDecimalPercent
) => {
  /*
    world goes from x: 0 to 1
    level goes from y: 0 to 1
 
    each partition in the x direction is NWORLD_X_PARTITION_PERCENT_DECIMAL percent of the world
    each partition in the y direction is NWORLD_Y_PARTITION_PERCENT_DECIMAL percent of the level
  */
  // const xPartitionNumber = Math.floor(
  //   xDecimalPercent / NWORLD_X_PARTITION_PERCENT_DECIMAL
  // );

  const xPartitionNumber =
    getXPartitionNumberFromXDecimalPercent(xDecimalPercent);

  // const yPartitionNumber = Math.floor(
  //   yDecimalPercent / NWORLD_Y_PARTITION_PERCENT_DECIMAL
  // );

  // map to a single digit
  /*
    5 6 7 8 9
    0 1 2 3 4
  */
  // const xPartitionDecimalPercentUnrounded =
  //   xPartitionNumber * NWORLD_X_PARTITION_PERCENT_DECIMAL;

  // const xPartitionDecimalPercent = roundToDecimal(
  //   xPartitionDecimalPercentUnrounded,
  //   NWORLD_ROUND_TO_DECIMAL
  // );

  const xPartitionDecimalPercent =
    getXPartitionDecimalPercentFromXPartitionNumber(xPartitionNumber);

  // const yPartitionDecimalPercent =
  //   yPartitionNumber * NWORLD_Y_PARTITION_PERCENT_DECIMAL;

  const xPartitionSEDecimalPercentString = getStringEncodedDecimalPercent(
    xPartitionDecimalPercent
  );
  // const yPartitionSEDecimalPercentString = getStringEncodedDecimalPercent(
  //   yPartitionDecimalPercent
  // );

  // const yPartitionSEDecimalPercentString = getYPartitionSEDecimalPercentString(
  //   worldNumber,
  //   levelNumber,
  //   yPartitionNumber
  // );

  // const yPartitionDecimalPercent =
  //   yPartitionNumber * NWORLD_Y_PARTITION_PERCENT_DECIMAL;

  // const yPartitionDecimalPercent =
  //   yPartitionNumber * NWORLD_Y_PARTITION_PERCENT_DECIMAL;

  // const yPartitionSEDecimalPercentString = getStringEncodedDecimalPercent(
  //   yPartitionDecimalPercent
  // );

  const {
    yPartitionNumber,
    yPartitionDecimalPercent,
    yPartitionSEDecimalPercentString,
  } = getYPStringFromYDecimalPercent(yDecimalPercent, true);

  const partitionId = `p_${worldNumber}_${levelNumber}_${xPartitionSEDecimalPercentString}_${yPartitionSEDecimalPercentString}`;

  const partitionData = {
    partitionId,
    //
    xPartitionNumber,
    xPartitionDecimalPercent,
    xPartitionSEDecimalPercentString,
    //
    yPartitionNumber,
    yPartitionDecimalPercent,
    yPartitionSEDecimalPercentString,
  };

  return partitionData;
};

export const getYAxisKey = (axis = "y", world, level) => {
  // assume axis is always "y"
  return `${axis}${world}x${level}`;
};

export const getXAxisKey = (world, level, yPartitionSEDecimalPercentString) => {
  // assume this is used for x axis
  return `id_index_key_${world}x${level}x${yPartitionSEDecimalPercentString}`;
};

export const getObjectKey = (world, level, x, y) => {
  if (x < 0) {
    const xRounded = roundToDecimal(x, NWORLD_ROUND_TO_DECIMAL);

    x = `N${Math.abs(xRounded).toFixed(NWORLD_ROUND_TO_DECIMAL)}`;
  }

  if (y < 0) {
    const yRounded = roundToDecimal(y, NWORLD_ROUND_TO_DECIMAL);

    y = `N${Math.abs(yRounded).toFixed(NWORLD_ROUND_TO_DECIMAL)}`;
  }

  if (typeof x === "number") {
    const xRounded = roundToDecimal(x, NWORLD_ROUND_TO_DECIMAL);

    x = xRounded.toFixed(NWORLD_ROUND_TO_DECIMAL);
  }

  if (typeof y === "number") {
    const yRounded = roundToDecimal(y, NWORLD_ROUND_TO_DECIMAL);

    y = yRounded.toFixed(NWORLD_ROUND_TO_DECIMAL);
  }

  // if (!Number.isInteger(world) || !Number.isInteger(level)) {
  //   throw new Error("world and level must be integers");
  // }

  // replace . with D

  x = x.replace(/\./g, "D");
  y = y.replace(/\./g, "D");

  // remove trailing zeros

  x = x.replace(/0+$/, "");
  y = y.replace(/0+$/, "");

  return `object${world}x${level}x${x}x${y}`;
};

export const getXCoordFromXDecimalPercent = (decimalPercent) => {
  const xPixels = getXPixelsFromXDecimalPercent(decimalPercent);
  //

  const xCoord = getXCoordFromXPixels(xPixels);

  return xCoord;
};

export const getXCoordFromXPixels = (
  xPixels,
  { floor = false } = {
    floor: false,
  }
) => {
  const xPixelInt = parseInt(xPixels);
  // const yPixelInt = parseInt(yPixels);

  const xCoord = Math.round(
    quantize(xPixelInt, NWORLD_UNIT_WIDTH_PIXELS, { floor }) /
      NWORLD_UNIT_WIDTH_PIXELS
  );

  return xCoord;
};

export const getXCoordFromXPercentString = (xPercentString) => {
  const xPixels = getXPixelsFromXPercentString(xPercentString);

  const xCoord = getXCoordFromXPixels(xPixels);

  return xCoord;
};

export const getXPixelsFromXCoord = (xCoord) => {
  const xPixels = Math.round(xCoord * NWORLD_UNIT_WIDTH_PIXELS);

  return xPixels;
};

export const getXDecimalPercentFromXCoord = (xCoord) => {
  const xPixels = getXPixelsFromXCoord(xCoord);

  const xDecimalPercent = getXDecimalPercentFromXPixel(xPixels);

  return xDecimalPercent;
};

export const getXPercentStringFromXCoord = (xCoord) => {
  const xPixels = getXPixelsFromXCoord(xCoord);

  const xDecimalPercent = getXDecimalPercentFromXPixel(xPixels);

  const xPercentString = getPercentStringFromDecimalPercent(xDecimalPercent);

  return xPercentString;
};

export const getYPixelsFromYCoord = (yCoord) => {
  const yPixels = Math.round(yCoord * NWORLD_UNIT_WIDTH_PIXELS);

  return yPixels;
};

export const getYCoordFromYDecimalPercent = (decimalPercent) => {
  const yPixels = getYPixelsFromYDecimalPercent(decimalPercent);

  const yCoord = getYCoordFromYPixels(yPixels);

  return yCoord;
};

export const getYDecimalPercentFromYCoord = (yCoord) => {
  const yPixels = getYPixelsFromYCoord(yCoord);

  const yDecimalPercent = getYDecimalPercentFromYPixel(yPixels);

  return yDecimalPercent;
};

export const getYPercentStringFromYCoord = (yCoord) => {
  const yPixels = getYPixelsFromYCoord(yCoord);

  const yDecimalPercent = getYDecimalPercentFromYPixel(yPixels);

  const yPercentString = getPercentStringFromDecimalPercent(yDecimalPercent);

  return yPercentString;
};

export const getYCoordFromYPixels = (
  yPixels,
  { floor = false } = {
    floor: false,
  }
) => {
  const yCoord = Math.round(
    quantize(yPixels, NWORLD_UNIT_WIDTH_PIXELS, {
      floor,
    }) / NWORLD_UNIT_WIDTH_PIXELS
  );

  return yCoord;
};

export const getYCoordFromYPercentString = (yPercentString) => {
  const yPixels = getYPixelsFromYPercentString(yPercentString);

  const yCoord = getYCoordFromYPixels(yPixels);

  return yCoord;
};
