import { getListings } from "./getListings";
import { getActionables } from "./getActionsables";
import { getSales } from "./getSales";
import delay from "../../../../utils/delay";

export const doTripleMarketplaceThreat = async () => {
  getListings();

  await delay(2000);

  getActionables();

  await delay(2000);

  getSales();
};
