import React from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // EVENT_TYPES
} from "../../../../constants";
// import { emitEvent } from "../../../../utils/emitEvent";
// import getAuth from "../../../../utils/getAuth";
// import { getMTokenData } from "../../../../utils/mToken";
import { getState } from "../../../../reactStateManagement";
import BotoDatumDisplay from "./BotoDatumDisplay";
import CreateBot2Button from "./CreateBot2Button";
import InteractWithBot2Button from "./InteractWithBot2Button";
import BotoInteractionsDisplay from "./BotoInteractionsDisplay";

const Bot222 = ({ zIndex = "unset" }) => {
  const userData = getState("userData");
  const addressToBot2Data = getState("bot2Data");

  const mobile = getState("mobile");

  if (!userData) {
    return;
  }

  const bot2Data = Object.keys(addressToBot2Data)
    .filter((address) => {
      return address.toLowerCase() === userData.address.toLowerCase();
    })
    .map((address) => {
      return {
        partitionKey: address,
        data: addressToBot2Data[address],
      };
    });

  const contents = [];

  const thereIsBot2Data = bot2Data && bot2Data.length > 0;

  if (thereIsBot2Data && Math.abs(1) === 1) {
    const bot2Datum = bot2Data[0];

    contents.push(
      <BotoDatumDisplay
        key={bot2Datum.partitionKey}
        datum={bot2Datum.data.data[0]}
      />
    );
  }

  return (
    <div
      style={{
        width: "100%",
        // height: 420,

        // maxHeight: 420,
        height: mobile ? 190 : 420,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        zIndex,
      }}
    >
      <div
        style={{
          width: "100%",

          // height: "40%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <CreateBot2Button />
        {contents}
        <InteractWithBot2Button />
      </div>
      <div
        style={{
          width: "100%",

          // height: "60%",
          height: "calc(100% - 80px)",

          display: "block",

          overflowY: "scroll",
        }}
      >
        <BotoInteractionsDisplay thereIsBot2Data={thereIsBot2Data} />
      </div>
    </div>
  );
};

export default Bot222;
