import React from "react";
import { getState, setState } from "../../reactStateManagement";
import { Z_INDICES } from "../../constants";

const BROWSER_ALERT_WIDTH = 320;
const BROWSER_ALERT_MAX_HEIGHT = 100;

// 2 lines
const BrowserAlertLine = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
        height: "25%",
        backgroundColor: "transparent",

        // temporary
        // backgroundColor: "pink",
      }}
    >
      {children}
    </div>
  );
};

const BrowserAlert = () => {
  const browserAlertData = getState(["browserAlertData"]);

  if (!browserAlertData) {
    return null;
  }

  const message = browserAlertData?.message || "";

  return (
    <div
      id={"browser-alert"}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,

        // opacity: 0.8,
        backgroundColor: "transparent",

        zIndex: Z_INDICES.DIALOG_LAYER + 2,
      }}
      onClick={(e) => {
        if (e.target.id === "browser-alert") {
          setState(["browserAlertData"], null);
        }
      }}
    >
      <div
        // alert box similar to native browser alert
        style={{
          width: BROWSER_ALERT_WIDTH,
          height: "25%",
          maxHeight: BROWSER_ALERT_MAX_HEIGHT,
          position: "absolute",
          top: "25%",
          //   left: "25%",
          //   left: "calc(50% - 160px)",
          left: `calc(50% - ${BROWSER_ALERT_WIDTH / 2}px)`,
          backgroundColor: "darkblue",
          color: "white",
          borderRadius: "5px",
          // padding: "20px",
          textAlign: "center",
          opacity: 1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <BrowserAlertLine>{message}</BrowserAlertLine>
        <BrowserAlertLine>
          <div
            style={{
              width: "20%",
              height: "100%",
              backgroundColor: "transparent",
            }}
          ></div>

          <button
            style={{
              width: "20%",
              height: "100%",
              backgroundColor: "teal",
              color: "beige",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() => {
              setState(["browserAlertData"], null);
            }}
          >
            {"Close"}
          </button>
        </BrowserAlertLine>
      </div>
    </div>
  );
};

export default BrowserAlert;
