import React, { useEffect, useState } from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  STANDARD_BUTTON_HEIGHT,
  // EVENT_TYPES
} from "../../../../../constants";
import GenericBot6Button from "../GenericBot6Button";
import { getState, setState } from "../../../../../reactStateManagement";
import Bot6DatumDisplay from "./Bot6DatumDisplay";
import Bot6InteractSection from "./Bot6InteractSection";
import Bot6InteractionsDisplay from "./Bot6InteractionsDisplay";
import { Bot6InteractionsDisplayHeader } from "./Bot6InteractionsDisplayHeader";
import Spacer from "../../../../../commonComponents/Spacer";

/*

DATA STRUCTURE:
{
        tokenId,
        bot6InteractionsResult: {
          interactions: [
            {
              partitionKey: "bot6InteractionXXXXXX104XXXXXX1719161154969",
              input: {
                command: {
                  subtype: "INTERACT_VIPOWER",
                  type: "BOTO",
                  value: {
                    contextAIDataIndex: -1,
                    gptSelection: "gpt-4o",
                    promptAIDataIndex: 0,
                    tokenId: "104",
                  },
                },
              },
              lastUpdated: 1719161154969,
              output: {
                commands: [
                  {
                    command: "move",
                    parameters: {
                      direction: "left",
                    },
                  },
                  {
                    command: "move",
                    parameters: {
                      direction: "up",
                    },
                  },
                ],
                costData: {
                  pricePerToken: 0.000059999999999999995,
                  totalTokensUsed: 133,
                },
              },
              secondaryPartitionKey: "bot6InteractionXXXXXX104",
              secondarySortKey: 1719161154969,
              type: "BOT6_INTERACTION",
            },
          ],
          paginationValue: null,
        },
      }
*/

const Bot6CommandCenter = ({
  // mobile,
  zIndex = "unset",
  executiveBoxHeight,
  // windowWidth,
  windowHeight,
  bot6PageExpandMode,
}) => {
  const [botoDatum, setBotoDatum] = useState(null);
  const [isModeSelect, setIsModeSelect] = useState(false);

  // const [bot6DatumDisplayHeight, setBot6DatumDisplayHeight] = useState(160); // 160
  const tokenIdToBot6InteractionData = getState("tokenIdToBot6InteractionData");
  const tokenIdToBot6InteractMode = getState("tokenIdToBot6InteractMode");
  const tokenId = getState("bot6PageModeTokenId");
  const bot6s = getState("bot6s");

  const bot6InteractionData =
    tokenIdToBot6InteractionData[tokenId]?.interactions;

  const thereIsBot6InteractionData =
    (bot6InteractionData && bot6InteractionData.length > 0) || false;

  const bot6InteractMode = tokenIdToBot6InteractMode[tokenId] || null;

  useEffect(() => {
    const bot6PageModeTokenId = getState("bot6PageModeTokenId");

    if (!bot6s) {
      console.log("bot6s not loaded");

      setState("browserAlertData", {
        message: "Botos not loaded",
      });

      setState("bot6PageMode", null);

      return;
    }

    const bot6Datum = bot6s.find((bot6) => {
      return String(bot6.secondarySortKey) === bot6PageModeTokenId;
    });

    if (!bot6Datum) {
      console.log("botDatum not found");

      setState("browserAlertData", {
        message: "Boto not found",
      });

      setState("bot6PageMode", null);

      return;
    }

    setBotoDatum(bot6Datum);
  }, [bot6s]);

  // useEffect(() => {
  //   const refreshBot6DatumDisplayHeight = () => {
  //     const $ = window.$;

  //     const bot6DatumDisplay = $("#bot6DatumDisplay");

  //     if (!bot6DatumDisplay || bot6DatumDisplay.length === 0) {
  //       return;
  //     }

  //     const bot6DatumDisplayHeight = bot6DatumDisplay.height();

  //     setBot6DatumDisplayHeight(bot6DatumDisplayHeight);
  //   };

  //   refreshBot6DatumDisplayHeight();

  //   window.addEventListener("resize", refreshBot6DatumDisplayHeight);

  //   return () => {
  //     window.removeEventListener("resize", refreshBot6DatumDisplayHeight);
  //   };
  // }, [windowHeight, windowWidth, botoDatum, bot6InteractionData]);

  const bot6DatumDisplayHeight = 120;
  const interactInputHeight = 30;
  const interactButtonHeight = 40;
  const bot6InteractionsDisplayHeaderHeight = 40;

  const totalTopPortionHeight =
    bot6DatumDisplayHeight +
    interactInputHeight +
    interactButtonHeight +
    bot6InteractionsDisplayHeaderHeight;

  return (
    <div
      style={{
        width: "100%",

        // maxHeight: 420,
        position: "relative",

        height: bot6PageExpandMode
          ? windowHeight
          : executiveBoxHeight - STANDARD_BUTTON_HEIGHT,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        zIndex,

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",

          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Spacer width={bot6PageExpandMode ? STANDARD_BUTTON_HEIGHT : 0} />

        <div
          id="bot6DatumDisplay"
          style={{
            width: bot6PageExpandMode
              ? `calc(100% - ${STANDARD_BUTTON_HEIGHT}px)`
              : "calc(100% - 0px)",
            // height: 160,

            height: totalTopPortionHeight,

            position: "absolute",
            top: 0,
            // left: 0,
            left: bot6PageExpandMode ? STANDARD_BUTTON_HEIGHT : 0,

            // display: "block",
            // overflowY: "scroll",

            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",

            overflow: "hidden",
          }}
        >
          <Bot6DatumDisplay
            datum={botoDatum}
            height={bot6DatumDisplayHeight}
            isModeSelect={isModeSelect}
            setIsModeSelect={setIsModeSelect}
          />
          {Math.abs(1) === 1 ? (
            <Bot6InteractSection
              interactInputHeight={interactInputHeight}
              interactButtonHeight={interactButtonHeight}
              isModeSelect={isModeSelect}
              bot6InteractMode={bot6InteractMode}
            />
          ) : null}
          {Math.abs(1) === 1 ? (
            <Bot6InteractionsDisplayHeader
              tokenId={tokenId}
              thereIsBot6InteractionData={thereIsBot6InteractionData}
              bot6InteractionData={bot6InteractionData}
              height={bot6InteractionsDisplayHeaderHeight}
            />
          ) : null}
        </div>
      </div>

      <div
        style={{
          width: "100%",
          // height: "calc(100% - 150px - 40px)",
          height: `calc(100% - ${totalTopPortionHeight}px - ${STANDARD_BUTTON_HEIGHT}px)`,

          position: "absolute",
          // top: 150,
          top: totalTopPortionHeight,
          left: 0,

          // display: "block",
          // overflowY: "scroll",

          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Bot6InteractionsDisplay
          tokenId={tokenId}
          bot6InteractionData={bot6InteractionData}
          thereIsBot6InteractionData={thereIsBot6InteractionData}
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: 40,
        }}
      >
        <GenericBot6Button
          text={"Main Boto Menu"}
          width={"100%"}
          height={"100%"}
          maxHeight={"100%"}
          onClick={() => {
            setState("bot6PageMode", null);
          }}
        />
      </div>
    </div>
  );
};

export default Bot6CommandCenter;
