import { PRODUCTION_MODE } from "./constants";

const StagingComponent = ({
  children,
  overrideStagingOff = false,
  productionElement = null,
}) => {
  if (overrideStagingOff) {
    if (productionElement) {
      return productionElement;
    }

    return null;
  }

  if (PRODUCTION_MODE) {
    if (productionElement) {
      return productionElement;
    }
    return null;
  }

  return children;
};

export default StagingComponent;
