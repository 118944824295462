// useEffect(() => {
//   const $ = window.$;

import { useEffect } from "react";

const usePreventSpacebarPress = ({ id }) => {
  const $ = window.$;

  useEffect(() => {
    const preventSpacebarClick = function (e) {
      var kc = e.keyCode;
      if (kc === 32) {
        e.preventDefault();
      }
    };
    $(`#${id}`).keydown(preventSpacebarClick);

    return () => {
      $(`#${id}`).off("keydown", preventSpacebarClick);
    };
  }, []);
};

export default usePreventSpacebarPress;
