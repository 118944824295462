// import React from "react";
import { EVENT_TYPES } from "../../../../../../constants";
// import { getState } from "../../../../../../reactStateManagement";
// import extractBotoGeneralizedCommandV1Data from "../../../../../../utils/aiBoto/extractBotoGeneralizedCommandV1Data";
import { emitMTokenEvent } from "../../../../../../utils/browserRealtime/emitMTokenEvent";
// import loadInteractions from "../../../../../../utils/aiBoto/loadBot2Interactions";

/*

DATA STRUCTURE:
{
        tokenId,
        bot6InteractionsResult: {
          interactions: [
            {
                    "partitionKey": "bot6InteractionXXXXXX104XXXXXX1719161154969",
                    "input": {
                        "command": {
                            "subtype": "INTERACT_VIPOWER",
                            "type": "BOTO",
                            "value": {
                                "contextAIDataIndex": -1,
                                "gptSelection": "gpt-4o",
                                "promptAIDataIndex": 0,
                                "tokenId": "104"
                            }
                        }
                    },
                    "lastUpdated": 1719161154969,
                    "output": {
                        "commands": [
                            {
                                "command": "move",
                                "parameters": {
                                    "direction": "left"
                                }
                            },
                            {
                                "command": "move",
                                "parameters": {
                                    "direction": "up"
                                }
                            }
                        ],
                        "costData": {
                            "pricePerToken": 0.000059999999999999995,
                            "totalTokensUsed": 133
                        }
                    },
                    "secondaryPartitionKey": "bot6InteractionXXXXXX104",
                    "secondarySortKey": 1719161154969,
                    "type": "BOT6_INTERACTION"
                },
          ],
          paginationValue: null,
        },
      }
*/

export const loadInteractions = async ({ tokenId }) => {
  console.log("loadInteractions called");
  emitMTokenEvent({
    type: EVENT_TYPES.LIST_BOT6_INTERACTIONS,
    data: {
      tokenId,
    },
  });
};
