import { getState, setState } from "../reactStateManagement";

export const deleteGuestTokenData = () => {
  setState(["userId"], null);
  setState(["password"], null);
};

export const setGuestTokenData = ({ userId, password }) => {
  setState(["userId"], userId);
  setState(["password"], password);
};

export const getGuestTokenData = () => {
  const userId = getState(["userId"]);
  const password = getState(["password"]);

  if (!userId || !password) {
    deleteGuestTokenData();
    return null;
  }

  return {
    userId,
    password,
  };
};
