import React from "react";
// import { DisplayText } from "./DisplayText";
import {
  //UNIT_WIDTH,
  Z_1,
} from "./nzwConstants";
import {
  NWORLD_STANDARD_USER_HEIGHT_PERCENT_DECIMAL,
  NWORLD_STANDARD_USER_WIDTH_PERCENT_DECIMAL,
  NWORLD_TOTAL_WIDTH,
  NWORLD_TOTAL_HEIGHT,
} from "../../worldConstants";
import { CSS_DEFAULT_ANIMATION_TRANSITION } from "../../constants";
import { setState } from "../../reactStateManagement";

export const Bot2 = ({ xPixels, xMin, yPixels, minY, tag }) => {
  const w = NWORLD_STANDARD_USER_WIDTH_PERCENT_DECIMAL * NWORLD_TOTAL_WIDTH;
  const h = NWORLD_STANDARD_USER_HEIGHT_PERCENT_DECIMAL * NWORLD_TOTAL_HEIGHT;

  let left;

  // if (typeof frenXPixels === "number" && typeof xMin === "number") {
  left = xPixels - xMin - w / 2;
  // } else {
  //   left = `calc(50% - ${w / 2}px)`;
  // }

  const bottom = yPixels - minY;

  return (
    <div
      id={`bot2-${tag}`}
      style={{
        position: "absolute",
        bottom,
        // left: "50%",
        left,
        // left: `calc($5 - ${UNIT_WIDTH / 2}px)`,
        width: w,
        height: h,

        // backgroundColor: "green",
        transition: CSS_DEFAULT_ANIMATION_TRANSITION,
        zIndex: Z_1,
      }}
      onClick={(e) => {
        e.stopPropagation();
        console.log("bot2 clicked");

        setState(["bot2Mode"], true);
      }}
    >
      <img
        alt="boto"
        src="https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/boto_cool_3.png"
        style={{
          height: "100%",
        }}
      />
    </div>
  );
};
