import React from "react";
import { getState, setState } from "../../../../reactStateManagement";
import { doAutoSignIn } from "../../../../utils/browserRealtime/doAutoSignIn";
// import doSignOut from "../../../../api/doSignOut";

const MIN_TIME_BETWEEN_AUTO_SIGN_INS = 3500;
/*
  About AutoSignInButton:

  -> a manual trigger to the autoSignIn function

  -> autoSignOut sends a message to the parent window
  -> the parent window will then return the address and powerKey
  -> the address and powerKey will be used to sign in
*/

export const AutoSignInButton = () => {
  const loading = getState("loading");
  const [lastPress, setLastPress] = React.useState(0);

  // const PERMA_DISABLED = true;
  const PERMA_DISABLED = false;

  const disabled =
    loading ||
    Date.now() - lastPress < MIN_TIME_BETWEEN_AUTO_SIGN_INS ||
    PERMA_DISABLED;

  return (
    <button
      style={{
        backgroundColor: loading ? "grey" : "white",

        // width: "20%",
        width: "80%",
        height: "100%",

        border: "none",
        outline: "none",
        padding: 0,
        margin: 0,
      }}
      disabled={disabled}
      onClick={async () => {
        setState("loading", true);

        try {
          setLastPress(Date.now());
          await doAutoSignIn();
        } catch (err) {
          console.log("error in auto signing in:");
          console.log(err);

          // const errorMessage =
          //   err?.response?.data?.message || err.message || "error";

          // setState(["snackMessage"], errorMessage);
          // setState(["snackTime"], 6);
        }

        setState("loading", false);
      }}
    >
      {"Sign In"}
    </button>
  );
};
