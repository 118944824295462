export const TEST_LOG_MEMORY = false;
export const FREQUENCY = 10;
const EVENT_LOOP_ITERATIONS_PER_SECOND = 1 / FREQUENCY; // 0.25 s

export const MAX_EVENT_LOOP_ITERATION_DURATION =
  EVENT_LOOP_ITERATIONS_PER_SECOND * 1000; // 250 ms

export const EVENT_LOOP_RUNNER_ERROR_DELAY = 15000; // 15 seconds

export const GLOBAL_CONTROL_SCALAR = 0.0000000009;

export const MAX_Y_MOMENTUM = 12 * GLOBAL_CONTROL_SCALAR; //
export const JUMP_INCREMENT = 44 * GLOBAL_CONTROL_SCALAR;
export const MAX_MOMENTUM = 9 * GLOBAL_CONTROL_SCALAR;
export const INCREMENT_CONSTANT = 6 * GLOBAL_CONTROL_SCALAR;
export const INCREMENT_BOOST_THRESHOLD = 12 * GLOBAL_CONTROL_SCALAR;
export const INCREMENT_BOOST = 2 * GLOBAL_CONTROL_SCALAR;
