import React, { useEffect } from "react";
import { getState, setState } from "../../reactStateManagement";
import { NChar } from "./NChar";
import NBuildGrid from "./NBuildGrid";
import Bots from "./Bots";
import {
  getXPixelsFromXPercentString,
  getYPixelsFromYPercentString,
} from "../../coordsMapper";
import { NCharFren } from "./NCharFren";
import useGameComponent from "../../utils/effects/useGameComponent";
import {
  DEFAULT_START_X_PERCENT_STRING,
  DEFAULT_START_Y_PERCENT_STRING,
  // NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
  PRE_RENDER_WIDTH,
} from "../../constants";
import { SelectRect } from "./SelectRect";
import { SelectSquare } from "./SelectSquare";
import { getHandleNZWClick } from "./handleNZWClick";
import { Items } from "./Items";
import useSelectedItems from "./useSelectedItems";
import { getArtificialItems } from "./getArtificialItems";
import { Bot2 } from "./Bot2";
import { Bot6 } from "./Bot6";

export const NZW = ({
  windowHeight,
  windowWidth,
  minX,
  nzwW,
  nzwH,
  setNzwW,
  items,
  frenCharacterData,
  xPixels,
  yPixels,
  minY,
  scale,
  botoScreenCaptureMode,
}) => {
  const mobile = getState("mobile");
  const selectedItems = getState("selectedItems");
  const bCharDatum = getState("bCharDatum");
  const pressedDownOnBuildPlane = getState("pressedDownOnBuildPlane");

  // update dims with scale
  typeof scale;

  // nzwW = nzwW ;

  useEffect(() => {
    const internalSetNzwW = () => {
      const $ = window.$;

      const $nzw = $("#new-zoom-world");
      const newWidth = $nzw.width();

      const scaledWidth = newWidth;

      setNzwW(scaledWidth);
    };

    internalSetNzwW();
  }, [setNzwW, windowHeight, windowWidth, scale]);

  useGameComponent({
    id: "new-zoom-world",
  });

  useSelectedItems({
    selectedItems,
  });

  const ncharFrens = [];

  const prerenderMinX = minX - PRE_RENDER_WIDTH;
  const prerenderMaxX = minX + nzwW + PRE_RENDER_WIDTH;

  // frens
  for (let i = 0; i < frenCharacterData.length; i++) {
    const frenCharacterDatum = frenCharacterData[i];

    const frenXPixels = getXPixelsFromXPercentString(frenCharacterDatum.x);

    const frenIsOnScreen =
      prerenderMaxX >= frenXPixels && frenXPixels >= prerenderMinX;

    if (frenIsOnScreen) {
      const frenYPixels = getYPixelsFromYPercentString(frenCharacterDatum.y);

      const fren = (
        <NCharFren
          xMin={minX}
          yMin={minY}
          xPixels={frenXPixels}
          yPixels={frenYPixels}
          characterDatum={frenCharacterDatum}
          key={`nchar-fren-${i}-${frenCharacterDatum.userId}`}
        />
      );

      ncharFrens.push(fren);
    }
  }

  const addressToBot2Data = getState("bot2Data");

  const bot2Data = Object.keys(addressToBot2Data).map((address) => {
    return {
      address,
      data: addressToBot2Data[address],
    };
  });

  const bot2s = [];

  // bot2
  for (let i = 0; i < bot2Data.length; i++) {
    const bot2MegaDatum = bot2Data[i];

    const bot2DatumData = bot2MegaDatum?.data;

    // console.log(`

    //   MEGA LOG ${JSON.stringify(
    //     {
    //       t: typeof Bot2,
    //       bot2s,
    //       bot2DatumData,
    //     },
    //     null,
    //     4
    //   )}
    //   )

    // `);

    if (bot2DatumData) {
      const address = bot2MegaDatum.address;
      const bot2DatumDataData = bot2MegaDatum?.data?.data;

      if (bot2DatumDataData?.length > 0) {
        for (let j = 0; j < bot2DatumDataData.length; j++) {
          const bot2Datum = bot2DatumDataData[j];
          const bot2XPixels = getXPixelsFromXPercentString(bot2Datum.x);
          const bot2YPixels = getYPixelsFromYPercentString(bot2Datum.y);

          const tag = `${address}-${j}`;

          const bot2 = (
            <Bot2
              key={bot2Datum.partitionKey}
              xPixels={bot2XPixels}
              xMin={minX}
              yPixels={bot2YPixels}
              minY={minY}
              tag={tag}
            />
          );

          bot2s.push(bot2);
        }
      }
    }
  }

  const tokenIdToBot6Datum = getState("bot6WorldData");

  const bot6s = [];

  const bot6TokenIds = Object.keys(tokenIdToBot6Datum);

  // bot6
  for (let i = 0; i < bot6TokenIds.length; i++) {
    const tokenId = bot6TokenIds[i];
    const bot6Datum = tokenIdToBot6Datum[tokenId];

    const xPixels = getXPixelsFromXPercentString(
      bot6Datum.x || DEFAULT_START_X_PERCENT_STRING
    );
    const yPixels = getYPixelsFromYPercentString(
      bot6Datum.y || DEFAULT_START_Y_PERCENT_STRING
    );

    const bot6 = (
      <Bot6
        key={tokenId}
        xPixels={xPixels}
        yPixels={yPixels}
        xMin={minX}
        minY={minY}
        tokenId={tokenId}
        botoScreenCaptureMode={botoScreenCaptureMode}
      />
    );

    bot6s.push(bot6);
  }

  let artificialItems = getArtificialItems({ minX, nzwW });

  // artificialItems = [];

  const allItems = [].concat(artificialItems, items);

  const ITEMS_DISABLED = false;

  const handleNZWClick = getHandleNZWClick({
    minX,
    minY,
    scale,
  });

  return (
    <div
      id={"new-zoom-world"}
      style={{
        position: "relative",
        width: "100%",
        // maxWidth: 700,
        height: "100%",

        // backgroundColor: "blue",
        overflow: "hidden",
        // overflowX: "scroll",
      }}
      onClick={mobile ? null : handleNZWClick}
      // onTouchEnd={mobile ? handleNZWClick : null}
      onTouchStart={mobile ? handleNZWClick : null}
      onMouseLeave={() => {
        setState(["pressedDownOnBuildPlane"], false);
      }}
    >
      <SelectRect minX={minX} minY={minY} nzwH={nzwH} scale={scale} />
      <SelectSquare
        minX={minX}
        minY={minY}
        nzwH={nzwH}
        selectedItems={selectedItems}
      />
      {/* <NChar yPixels={yPixels} characterDatum={characterDatum} /> */}
      <NChar
        yPixels={yPixels}
        characterDatum={bCharDatum}
        minY={minY}
        botoScreenCaptureMode={botoScreenCaptureMode}
      />
      {ncharFrens}
      {!ITEMS_DISABLED ? (
        <Items
          items={allItems}
          minX={minX}
          minY={minY}
          nzwW={nzwW}
          nzwH={nzwH}
          selectedItems={selectedItems}
          pressedDownOnBuildPlane={pressedDownOnBuildPlane}
          mobile={mobile}
          scale={scale}
          windowHeight={windowHeight}
        />
      ) : null}
      <NBuildGrid
        userX={xPixels}
        userY={yPixels}
        minX={minX}
        nzwW={nzwW}
        nzwH={nzwH}
        minY={minY}
        pressedDownOnBuildPlane={pressedDownOnBuildPlane}
        windowHeight={windowHeight}
        scale={scale}
      />
      <Bots minX={minX} nzwW={nzwW} />
      {bot2s}
      {bot6s}
    </div>
  );
};
