import { EVENT_TYPES, PRODUCTION_MODE } from "../../constants";
import { getNWorldPartitionDataFromXandYDecimalPercent } from "../../coordsMapper";
import {
  getState,
  //setState
} from "../../reactStateManagement";
import { emitEvent } from "../emitEvent";
import getAuth from "../getAuth";

const DEBUG_LOG_ON = false;
// const DEBUG_LOG_ON = true;

const cachePowerGetNWorldDataCache = [];

// const MAX_CACHE_SIZE = 5000;
const MAX_CACHE_SIZE = 1500;
const MIN_TIME_BETWEEN_SAME_REQUESTS = 1000;

const addRequestToCache = ({
  xPartitionDecimalPercent,
  yPartitionDecimalPercent,
}) => {
  const newRequest = {
    xPartitionDecimalPercent,
    yPartitionDecimalPercent,
    timestamp: Date.now(),
  };

  cachePowerGetNWorldDataCache.push(newRequest);
};

const getIfRequestIsCached = ({
  xPartitionDecimalPercent,
  yPartitionDecimalPercent,
}) => {
  const requestIsCached = cachePowerGetNWorldDataCache.some((cachedRequest) => {
    const xIsEqual =
      cachedRequest.xPartitionDecimalPercent === xPartitionDecimalPercent;

    if (!xIsEqual) {
      return false;
    }

    const yIsEqual =
      cachedRequest.yPartitionDecimalPercent === yPartitionDecimalPercent;

    if (!yIsEqual) {
      return false;
    }

    const timeSinceRequest = Date.now() - cachedRequest.timestamp;

    const requestIsCached = timeSinceRequest < MIN_TIME_BETWEEN_SAME_REQUESTS;

    return requestIsCached;
  });

  return requestIsCached;
};

export const cachePowerGetNWorldData = ({
  providedAuth = null,
  xPartitionDecimalPercent,
  yPartitionDecimalPercent,
  skipCache = false,
}) => {
  // Current to add: cache requests to ensure
  // we don't make the same request twice in short succession

  const requestIsCached = getIfRequestIsCached({
    xPartitionDecimalPercent,
    yPartitionDecimalPercent,
  });

  if (requestIsCached) {
    // console.log(`

    //   MONKEY LOCAL CACHE HIT, YES YES YES!

    //   )

    // `);
    return;
  }

  addRequestToCache({
    xPartitionDecimalPercent,
    yPartitionDecimalPercent,
  });

  if (cachePowerGetNWorldDataCache.length > MAX_CACHE_SIZE) {
    cachePowerGetNWorldDataCache.shift();
  }

  // const auth = getAuth();
  let auth;

  try {
    auth = providedAuth || getAuth();
  } catch (error) {
    //
  }

  if (!auth) {
    return;
  }

  // const shouldTryToGetCachedData = !skipCache && false;
  let shouldTryToGetCachedData = !skipCache;

  // TEMPORARY NO CACHE FOR TESTING
  // const TEST_NO_CACHE = true && !PRODUCTION_MODE;
  const TEST_NO_CACHE = false;

  if (TEST_NO_CACHE) {
    console.log("forcing no cache for testing");
    shouldTryToGetCachedData = false;
  }

  if (shouldTryToGetCachedData) {
    const world = 0;
    const level = 0;

    const partitionIdToNWorldData = getState("partitionIdToNWorldData");

    const { partitionId } = getNWorldPartitionDataFromXandYDecimalPercent(
      world,
      level,
      xPartitionDecimalPercent,
      yPartitionDecimalPercent
    );

    const cachedData = partitionIdToNWorldData[partitionId];

    // console.log(`

    //   MEGA LOG ${JSON.stringify(
    //     {
    //       cachedData: cachedData?.length,
    //     },
    //     null,
    //     4
    //   )}
    //   )

    // `);

    const TEST_ALWAYS_SKIP_CACHE = true;
    // const TEST_ALWAYS_SKIP_CACHE = false;

    if (cachedData && !TEST_ALWAYS_SKIP_CACHE) {
      // temp log

      /*
          Potential Idea that is probably not worth implementing:

            if cache has data, but it is old, refresh it
              --
            analysis:
              this is not necessary because the data is
               automatically refreshed on expiration and re-entry


      */

      // const SKIP_LOGGING_CACHE_GET = false;
      const SKIP_LOGGING_CACHE_GET = true;

      if (DEBUG_LOG_ON && !PRODUCTION_MODE && !SKIP_LOGGING_CACHE_GET) {
        console.log(`

        "GETTING CACHED DATA: ${cachedData.nWorldData.length} items" +
          P: ${partitionId}
          xPartitionDecimalPercent: ${xPartitionDecimalPercent}
          yPartitionDecimalPercent: ${yPartitionDecimalPercent}

      `);
      }

      // console.log(`

      //   MEGA LOG ${JSON.stringify(
      //     {
      //       cachedData,
      //     },
      //     null,
      //     4
      //   )}
      //   )

      // `);
      return;
    }
  }

  // console.log("GETTING NON_CACHE DATA");

  if (DEBUG_LOG_ON && !PRODUCTION_MODE) {
    console.log(`

        "GETTING NON-CACHED DATA:
          xPartitionDecimalPercent: ${xPartitionDecimalPercent}
          yPartitionDecimalPercent: ${yPartitionDecimalPercent}

      `);
  }

  const getData = () => {
    emitEvent({
      type: EVENT_TYPES.POWER_GET_NWORLD_DATA,
      data: {
        auth,

        xPartitionDecimalPercent,
        yPartitionDecimalPercent,
      },
    });
  };

  setTimeout(getData, 0);
};
