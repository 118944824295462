import React, { useState } from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // MARKETPLACE_MODES,
} from "../../../../../constants";
import { getState } from "../../../../../reactStateManagement";
// import { getState, setState } from "../../../../reactStateManagement";

// const HEIGHT = 150;
const HEIGHT = 500;
// const HEIGHT_FULL_MARKETPLACE_EXPAND_MODE = `calc(100% - 222px)`;

const HEIGHT_MOBILE = 250;

export const ListingsScrollBox = ({ children, fullMarketplaceExpandMode }) => {
  // const listingElements = [
  //   <XBasedListing
  //     key={"listing0"}
  //     listing={SAMPLE_MARKETPLACE_NFT_LISTINGS[0]}
  //   />,
  //   <MetaBasedListing
  //     key={"listing1"}
  //     data={SAMPLE_MARKETPLACE_NFT_LISTINGS[0]}
  //   />,
  // ];
  const [fullMarketplaceExpandModeHeight, setFullMarketplaceExpandModeHeight] =
    useState(600);

  useState(() => {
    const windowHeight = getState("windowHeight");

    const newFullMarketplaceExpandModeHeight = windowHeight - 82;

    setFullMarketplaceExpandModeHeight(newFullMarketplaceExpandModeHeight);
  }, [fullMarketplaceExpandMode]);

  const mobile = getState("mobile");

  // const height = mobile ? HEIGHT_MOBILE : HEIGHT;

  let height;

  if (fullMarketplaceExpandMode) {
    height = fullMarketplaceExpandModeHeight;
  } else if (mobile) {
    height = HEIGHT_MOBILE;
  } else {
    height = HEIGHT;
  }

  return (
    <div
      style={{
        width: "100%",
        height,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        overflowY: "scroll",
      }}
    >
      {children}
    </div>
  );
};
