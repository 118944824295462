import React, { useEffect } from "react";
// import {
//   NWORLD_X_PARTITION_PERCENT_DECIMAL,
//   NWORLD_Y_PARTITION_PERCENT_DECIMAL,
// } from "../../worldConstants";
import {
  getXDecimalPercentFromXPixel,
  getXPixelsFromXDecimalPercent,
  getYDecimalPercentFromYPixel,
  getYPixelsFromYDecimalPercent,
} from "../../coordsMapper";
import {
  CSS_DEFAULT_ANIMATION_TRANSITION,
  ITEM_TYPE_TO_DATA,
  NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
  Z_INDICES,
} from "../../constants";

import { getState, setState } from "../../reactStateManagement";
import { isAnyPointInside } from "../../utils/world/isAnyPointInside";

const COLOR = "red";
// const TIMEOUT = 300222;
const TIMEOUT = 300;

const TheSelectRect = ({
  minX,
  minY,
  nzwH,
  worldXTopLeftPixelsNzw,
  worldYTopLeftPixelsNzw,
  worldYBottomRight,
  width,
  scale,
}) => {
  const id = "select-rect";

  useEffect(() => {
    // const worldNumber = 0;
    // const levelNumber = 0;

    const leftMostX = worldXTopLeftPixelsNzw + minX;
    const rightMostX = leftMostX + width;

    const topMostY = worldYTopLeftPixelsNzw;
    const bottomMostY = worldYBottomRight;

    const leftMostXDecimalPercent = getXDecimalPercentFromXPixel(leftMostX);
    const rightMostXDecimalPercent = getXDecimalPercentFromXPixel(rightMostX);

    const topMostYDecimalPercent = getYDecimalPercentFromYPixel(topMostY);
    const bottomMostYDecimalPercent = getYDecimalPercentFromYPixel(bottomMostY);

    // const worldNumberToLevelNumberToNWorldData = getState(
    //   "worldNumberToLevelNumberToNWorldData"
    // );

    // const nWorldData =
    //   worldNumberToLevelNumberToNWorldData[worldNumber][levelNumber];
    const nWorldData = getState("nWorldData");

    const itemsInsideSelectRect = [];

    for (let i = 0; i < nWorldData.length; i++) {
      const item = nWorldData[i];
      const itemLeftMostXDecimalPercent = item.xDecimalPercent;

      const itemData = ITEM_TYPE_TO_DATA[item.itemType];

      const itemWidth = itemData.width || NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;
      const itemRightMostXDecimalPercent =
        itemLeftMostXDecimalPercent + itemWidth;

      const itemBottomMostYDecimalPercent = item.yDecimalPercent;

      const itemHeight = itemData.height || NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;
      const itemTopMostYDecimalPercent =
        itemBottomMostYDecimalPercent + itemHeight;

      const xa1 = leftMostXDecimalPercent;
      const xa2 = rightMostXDecimalPercent;
      const ya1 = bottomMostYDecimalPercent;
      const ya2 = topMostYDecimalPercent;

      const xb1 = itemLeftMostXDecimalPercent;
      const xb2 = itemRightMostXDecimalPercent;
      const yb1 = itemBottomMostYDecimalPercent;
      const yb2 = itemTopMostYDecimalPercent;

      const inside = isAnyPointInside(
        [xa1, ya1, xa2, ya2],
        [xb1, yb1, xb2, yb2]
      );

      if (inside) {
        itemsInsideSelectRect.push(item);
      }
    }

    // console.log(`

    //     selectedItems LOG: ${JSON.stringify(
    //       {
    //         itemsInsideSelectRect: itemsInsideSelectRect.length,
    //       },
    //       null,
    //       4
    //     )}

    // `);

    setState(["selectedItems"], itemsInsideSelectRect);

    setTimeout(() => {
      setState(["selectRectTopLeftX"], null);
      setState(["selectRectTopLeftY"], null);
      setState(["selectRectBottomRightX"], null);
      setState(["selectRectBottomRightY"], null);
    }, TIMEOUT);
  }, []);

  const topUnscaled = nzwH - worldYTopLeftPixelsNzw + minY;
  const top = topUnscaled * 1;

  const leftUnscaled = worldXTopLeftPixelsNzw;
  const left = leftUnscaled * 1;

  typeof scale;

  return (
    <div
      id={id}
      style={{
        // backgroundColor: "blue",

        position: "absolute",

        top,
        left,

        width,
        height: Math.abs(worldYTopLeftPixelsNzw - worldYBottomRight),

        transition: CSS_DEFAULT_ANIMATION_TRANSITION,

        border: `1px solid ${COLOR}`,

        zIndex: Z_INDICES.TOP_LAYER,
      }}
    ></div>
  );
};

/**
 * SelectRect
 *
 * When you click once it will create the first point of the rectangle (top left corner)
 * When you click again it will create the second point of the rectangle (bottom right corner)
 *
 */
export const SelectRect = ({
  minX,
  minY,
  nzwH,
  scale,
  //nzwW
}) => {
  // minY = 0;
  //   const selectRectTopLeftX = 0.5 + NWORLD_X_PARTITION_PERCENT_DECIMAL * 0.05;
  //   const selectRectTopLeftY = NWORLD_Y_PARTITION_PERCENT_DECIMAL * 0.5;
  //   const selectRectBottomRightX = 0.5 + NWORLD_X_PARTITION_PERCENT_DECIMAL * 0.1;
  //   const selectRectBottomRightY = NWORLD_Y_PARTITION_PERCENT_DECIMAL * 0.6;

  const selectRectTopLeftX = getState("selectRectTopLeftX");
  const selectRectTopLeftY = getState("selectRectTopLeftY");
  const selectRectBottomRightX = getState("selectRectBottomRightX");
  const selectRectBottomRightY = getState("selectRectBottomRightY");

  const onlyTopLeftHasBeenSet =
    typeof selectRectTopLeftX === "number" &&
    typeof selectRectTopLeftY === "number" &&
    (typeof selectRectBottomRightX !== "number" ||
      typeof selectRectBottomRightY !== "number");

  if (onlyTopLeftHasBeenSet) {
    const selectRectTopLeftXPixels =
      getXPixelsFromXDecimalPercent(selectRectTopLeftX);
    const selectRectTopLeftYPixels =
      getYPixelsFromYDecimalPercent(selectRectTopLeftY);

    const worldXTopLeftPixelsNzw = selectRectTopLeftXPixels - minX;
    const worldYTopLeftPixelsNzw = selectRectTopLeftYPixels;

    return (
      <div
        style={{
          position: "absolute",
          backgroundColor: COLOR,
          width: 20,
          height: 20,

          top: nzwH - worldYTopLeftPixelsNzw + minY,
          left: worldXTopLeftPixelsNzw,
          transition: CSS_DEFAULT_ANIMATION_TRANSITION,
        }}
      ></div>
    );
  }

  if (
    typeof selectRectTopLeftX !== "number" ||
    typeof selectRectTopLeftY !== "number" ||
    typeof selectRectBottomRightX !== "number" ||
    typeof selectRectBottomRightY !== "number"
  ) {
    return null;
  }

  const selectRectTopLeftXPixels =
    getXPixelsFromXDecimalPercent(selectRectTopLeftX);
  const selectRectTopLeftYPixels =
    getYPixelsFromYDecimalPercent(selectRectTopLeftY);

  const selectRectBottomRightXPixels = getXPixelsFromXDecimalPercent(
    selectRectBottomRightX
  );

  const selectRectBottomRightYPixels = getYPixelsFromYDecimalPercent(
    selectRectBottomRightY
  );

  const worldXTopLeftPixelsNzw = selectRectTopLeftXPixels - minX;
  const worldYTopLeftPixelsNzw = selectRectTopLeftYPixels;

  const worldXBottomRight = selectRectBottomRightXPixels - minX;
  const worldYBottomRight = selectRectBottomRightYPixels;

  const width = Math.abs(worldXTopLeftPixelsNzw - worldXBottomRight);

  // console.log(`

  //         MEGA LOG ${JSON.stringify(
  //           {
  //             selectRectTopLeftX,
  //             selectRectTopLeftY,
  //             selectRectBottomRightX,
  //             selectRectBottomRightY,
  //             selectRectTopLeftXPixels,
  //             worldXTopLeftPixelsNzw,
  //             worldYTopLeftPixelsNzw,
  //             selectRectBottomRightXPixels,
  //             worldXBottomRight,
  //             worldYBottomRight,
  //             width,
  //           },
  //           null,
  //           4
  //         )}
  //         )

  //     `);

  return (
    <TheSelectRect
      {...{
        minX,
        minY,
        nzwH,
        worldXTopLeftPixelsNzw,
        worldYTopLeftPixelsNzw,
        worldYBottomRight,
        width,
        scale,
      }}
    />
  );
};
