import React, { useState, useRef, useEffect } from "react";
import {
  moveWCharLeftOff,
  moveWCharRightOff,
} from "../../../../utils/wCharacter/moveWChar";
import { JoySquare } from "./JoySquare";
import useMobileSwitchDetector from "../../../../utils/hooks/useMobileSwitchDetector";
// import { COLOR_2 } from "../../../../constants";

const CIRCLE_CONTAINER_RATIO = 3;
const CIRCLE_CONTAINER_RATIO_WIDTH = CIRCLE_CONTAINER_RATIO;
const CIRCLE_CONTAINER_RATIO_HEIGHT = CIRCLE_CONTAINER_RATIO;

export function SquareContainer() {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  // const [yDeviation, setYDeviation] = useState(0);
  // const [mouseDown, setMouseDown] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  const circleContainerWidth = width / CIRCLE_CONTAINER_RATIO_WIDTH;
  const circleContainerHeight = height / CIRCLE_CONTAINER_RATIO_HEIGHT;

  // const midPointX = width / 2 - width / 6;
  const midPointY = height / 2 - circleContainerHeight / 2;
  // const midPointY = height / 2 - height / 6;
  const midPointX = width / 2 - circleContainerWidth / 2;

  const xDeviation = position.x - midPointX;
  const yDeviation = position.y - midPointY;

  const handleMouseDown = (event) => {
    // setMouseDown(true);
    const containerRect = containerRef.current.getBoundingClientRect();
    // const x = event.clientX - containerRect.left - containerRect.width / 6;
    const x =
      event.clientX -
      containerRect.left -
      containerRect.width / CIRCLE_CONTAINER_RATIO_WIDTH / 2;
    const y =
      event.clientY -
      containerRect.top -
      containerRect.height / CIRCLE_CONTAINER_RATIO_HEIGHT / 2;

    setPosition({ x, y });
  };

  const handleTouchDown = (event) => {
    // setMouseDown(true
    const containerRect = containerRef.current.getBoundingClientRect();

    // let x =
    //   event.touches[0].clientX - containerRect.left - containerRect.width / 6;

    let x =
      event.touches[0].clientX -
      containerRect.left -
      containerRect.width / CIRCLE_CONTAINER_RATIO_WIDTH / 2;

    // let y =
    //   event.touches[0].clientY - containerRect.top - containerRect.height / 6;

    let y =
      event.touches[0].clientY -
      containerRect.top -
      containerRect.height / CIRCLE_CONTAINER_RATIO_HEIGHT / 2;

    setPosition({ x, y });
  };

  const handleMouseUp = () => {
    setPosition({ x: midPointX, y: midPointY });
  };

  const handleMouseMove = (event) => {
    const mouseIsDown = event.buttons === 1;

    if (!mouseIsDown) {
      return;
    }

    const containerRect = containerRef.current.getBoundingClientRect();
    // const x = event.clientX - containerRect.left - containerRect.width / 6;
    // const y = event.clientY - containerRect.top - containerRect.height / 6;

    const x =
      event.clientX -
      containerRect.left -
      containerRect.width / CIRCLE_CONTAINER_RATIO_WIDTH / 2;
    const y =
      event.clientY -
      containerRect.top -
      containerRect.height / CIRCLE_CONTAINER_RATIO_HEIGHT / 2;

    setPosition({ x, y });
  };

  const handleTouchMove = (event) => {
    const containerRect = containerRef.current.getBoundingClientRect();

    // let x =
    //   event.touches[0].clientX - containerRect.left - containerRect.width / 6;

    // let y =
    //   event.touches[0].clientY - containerRect.top - containerRect.height / 6;

    let x =
      event.touches[0].clientX -
      containerRect.left -
      containerRect.width / CIRCLE_CONTAINER_RATIO_WIDTH / 2;

    let y =
      event.touches[0].clientY -
      containerRect.top -
      containerRect.height / CIRCLE_CONTAINER_RATIO_HEIGHT / 2;

    setPosition({ x, y });
  };

  const handleMouseLeave = () => {
    setPosition({ x: midPointX, y: midPointY });

    // console.log("R/L OFF - MOUSE LEAVE");
    moveWCharRightOff();
    moveWCharLeftOff();
  };
  typeof handleMouseLeave;

  useEffect(() => {
    const handleResize = () => {
      setWidth(containerRef.current.clientWidth);
      setHeight(containerRef.current.clientHeight);

      // m
      setPosition({
        // x:
        //   containerRef.current.clientWidth / 2 -
        //   containerRef.current.clientWidth / 6,
        // y:
        //   containerRef.current.clientHeight / 2 -
        //   containerRef.current.clientHeight / 6,

        x:
          containerRef.current.clientWidth / 2 -
          containerRef.current.clientWidth / CIRCLE_CONTAINER_RATIO_WIDTH / 2,

        y:
          containerRef.current.clientHeight / 2 -
          containerRef.current.clientHeight / CIRCLE_CONTAINER_RATIO_HEIGHT / 2,
      });
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const id = "square-joypad";

  useMobileSwitchDetector({
    id,
  });

  // const MOVE_X_THRESHOLD
  // let moveXThreshold = width / 2 / 8; // JUL 6 2024
  let moveXThreshold = width / 2 / 5;

  // let MAX_MOVE_X_THRESHOLD = 50; // JUL 6 2024
  let MAX_MOVE_X_THRESHOLD = 150;

  if (moveXThreshold > MAX_MOVE_X_THRESHOLD) {
    moveXThreshold = MAX_MOVE_X_THRESHOLD;
  }

  const jumpYThreshold = -height / 4;

  return (
    <div
      id={id}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchDown}
      // onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onTouchMove={handleTouchMove}
      // onTouchCancel={handleMouseLeave}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        // backgroundColor: COLOR_2,

        // border: `2px solid ${COLOR_2}`,
        // boxSizing: "border-box",

        // borderRadius: 10,
      }}
    >
      {/* visualizer - x move threshold */}

      <div
        style={{
          position: "absolute",

          top: 0,
          height: "100%",
          width: "100%",

          // left: width / 2 - moveXThreshold,

          // width: moveXThreshold * 2,

          // left: width / 2 - moveXThreshold  circleContainerWidth,
          // width: moveXThreshold * 2,
          left: 0,
        }}
      >
        <div
          style={{
            position: "absolute",

            top: 0,
            height: "100%",
            left: width / 2 - moveXThreshold - circleContainerWidth / 2 / 3,
            width: moveXThreshold * 2 + circleContainerWidth / 3,

            backgroundColor: "rgba(255, 0, 0, 0.25)",
            // backgroundColor: "rgba(0, 0, 255, 0.5)",
          }}
        ></div>
      </div>

      {/* visualizer - y jump threshold */}
      <div
        style={{
          position: "absolute",

          left: 0,
          width: "100%",

          top: -circleContainerHeight / 2,
          height: -jumpYThreshold,

          // backgroundColor: "rgba(0, 0, 255, 0.5)",
          backgroundColor: "rgba(255, 0, 0, 0.25)",
        }}
      ></div>

      <JoySquare
        width={width}
        height={height}
        xDeviation={xDeviation}
        yDeviation={yDeviation}
        position={position}
        moveXThreshold={moveXThreshold}
        jumpYThreshold={jumpYThreshold}
        circleContainerHeight={circleContainerHeight}
        circleContainerWidth={circleContainerWidth}
      />
    </div>
  );
}
