import {
  NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
  NWORLD_TOTAL_WIDTH,
} from "../../worldConstants";

import * as constants from "../../constants";

export const UNIT_WIDTH =
  NWORLD_UNIT_WIDTH_PERCENT_DECIMAL * NWORLD_TOTAL_WIDTH;

export const Z_0 = 20;
export const Z_1 = 21;

export const BELOW_CHARACTER_VIEW_OFFSET =
  3 * NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;

// export const TOP_FACTOR = 0.8;
// export const BOTTOM_FACTOR = 1 - TOP_FACTOR;

export const TOP_FACTOR = constants.TOP_FACTOR;
export const BOTTOM_FACTOR = constants.BOTTOM_FACTOR;
