const getDoPromiseInQueue = () => {
  let promise = Promise.resolve();

  const doPromiseInQueue = async ({ operation = async () => {} }) => {
    promise = promise
      .then(async () => {
        await operation();
      })
      .catch(async (err) => {
        console.log("doPromiseInQueue error:", err);
      });
  };

  return doPromiseInQueue;
};

export default getDoPromiseInQueue;
