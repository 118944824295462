import React from "react";
import { BUTTON_HEIGHT_PERCENTAGE } from "./BUTTON_HEIGHT_PERCENTAGE";

export const ToggleButtonModeButtonSection = ({
  buttonMode,
  setButtonMode,
}) => {
  return (
    <div
      style={{
        width: "80%",
        height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
        backgroundColor: "black",
        // backgroundColor: "darkcyan",
        // color: "white",
        color: "white",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        style={{
          width: "100%",
          height: "100%",
          // backgroundColor: "darkred",
          backgroundColor: "darkcyan",
          // color: "white",
          color: "white",
        }}
        onClick={async () => {
          // if (!buttonMode || buttonMode === 0) {
          //   setButtonMode(1);

          //   return;
          // }

          // setButtonMode(0);

          const newButtonMode = (buttonMode + 1) % 4;

          setButtonMode(newButtonMode);
        }}
      >
        {"Toggle Button Mode"}
      </button>
    </div>
  );
};
