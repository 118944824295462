import React from "react";
import { COLOR_0, COLOR_1, COLOR_5 } from "../../../../constants";

const Wearables = () => {
  return (
    <div
      style={{
        width: "100%",
        // height: 40,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",
      }}
    ></div>
  );
};

export default Wearables;
