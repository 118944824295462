import React from "react";
import {
  BOT6_PAGE_MODES,
  COLOR_0,
  COLOR_1,
  COLOR_5,
  STANDARD_BUTTON_HEIGHT,
  // EVENT_TYPES
} from "../../../../constants";
import { getState } from "../../../../reactStateManagement";
import MintSection from "./MintSection";
import BotoList from "./BotoList";
import Bot6CommandCenter from "./Bot6CommandCenter";
import { Bot6ExpandButton } from "./Bot6ExpandButton";

const TitleSection = () => {
  return (
    <div
      style={{
        width: "100%",

        height: 30,

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: "calc( 100% - 30px )",
          height: "100%",

          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {"Boto"}
      </div>

      <Bot6ExpandButton />
    </div>
  );
};

const Bot6Page = ({
  zIndex = "unset",

  executiveBoxHeight,

  windowWidth,
  windowHeight,
}) => {
  const userData = getState("userData");
  const mobile = getState("mobile");
  const bot6PageMode = getState("bot6PageMode");
  const bot6PageExpandMode = getState("bot6PageExpandMode");

  if (!userData) {
    return;
  }

  if (bot6PageMode === BOT6_PAGE_MODES.COMMAND_CENTER) {
    return (
      <Bot6CommandCenter
        mobile={mobile}
        zIndex={zIndex}
        executiveBoxHeight={executiveBoxHeight}
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        bot6PageExpandMode={bot6PageExpandMode}
      />
    );
  }

  const mintSectionHeightRatio = 0.3;

  const mintSectionHeight = `${mintSectionHeightRatio * 100}%`;
  const botoListHeight = `${(1 - mintSectionHeightRatio) * 100}%`;

  return (
    <div
      style={{
        width: "100%",

        // maxHeight: 420,
        height: bot6PageExpandMode
          ? windowHeight
          : executiveBoxHeight - STANDARD_BUTTON_HEIGHT,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        zIndex,

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <TitleSection />
      <MintSection height={mintSectionHeight} />
      <BotoList height={botoListHeight} />
    </div>
  );
};

export default Bot6Page;
