import { getState, setState } from "../../reactStateManagement";
import delay from "../delay";
// import doSignOut from "../../../../api/doSignOut";

const AUTO_SIGN_IN_MESSAGE = "nftitemland-autoSignIn";
const AUTO_SIGN_IN_MESSAGE_SUBTLE = "nftitemland-autoSignIn-subtle";

// const LISTENER_RETRY_COUNT = 3;
const LISTENER_RETRY_COUNT = 1;

const getLastPowerCredentialsStatus = ({
  initialLastPowerCredentialsPostMessageTime,
}) => {
  const shouldSilentCancelPowerCredentialsRequest = getState(
    "shouldSilentCancelPowerCredentialsRequest"
  );

  if (shouldSilentCancelPowerCredentialsRequest) {
    return {
      updated: false,
      requestedSilentCancel: true,
    };
  }

  const lastPowerCredentialsPostMessageTime = getState(
    "lastPowerCredentialsPostMessageTime"
  );

  if (
    lastPowerCredentialsPostMessageTime !==
    initialLastPowerCredentialsPostMessageTime
  ) {
    return {
      updated: true,
      requestedSilentCancel: false,
    };
  }

  return {
    updated: false,
    requestedSilentCancel: false,
  };
};

const recursivelyCheckForPowerCredentialsTimeUpdate = async (
  initialLastPowerCredentialsPostMessageTime
) => {
  // let lastPowerCredentialsPostMessageTime =
  //   initialLastPowerCredentialsPostMessageTime;

  for (let i = 0; i < LISTENER_RETRY_COUNT; i++) {
    await delay(1000);

    // lastPowerCredentialsPostMessageTime = getState(
    //   "lastPowerCredentialsPostMessageTime"
    // );

    // if (
    //   lastPowerCredentialsPostMessageTime !==
    //   initialLastPowerCredentialsPostMessageTime
    // ) {
    //   return {
    //     updated: true,
    //   };
    // }

    const checkResults = getLastPowerCredentialsStatus({
      initialLastPowerCredentialsPostMessageTime,
    });

    if (checkResults.requestedSilentCancel) {
      return {
        updated: false,
        requestedSilentCancel: true,
      };
    }

    if (checkResults.updated) {
      return {
        updated: true,
        requestedSilentCancel: false,
      };
    }
  }

  const powerCredentialsWaitTimeBoostCount =
    getState("powerCredentialsWaitTimeBoostCount") || 0;

  for (let i = 0; i < powerCredentialsWaitTimeBoostCount; i++) {
    await delay(500);

    // console.log("THIS IS AN ADDITIONAL LISTEN: " + i);

    // lastPowerCredentialsPostMessageTime = getState(
    //   "lastPowerCredentialsPostMessageTime"
    // );

    // if (
    //   lastPowerCredentialsPostMessageTime !==
    //   initialLastPowerCredentialsPostMessageTime
    // ) {
    //   return {
    //     updated: true,
    //   };
    // }

    const checkResults = getLastPowerCredentialsStatus({
      initialLastPowerCredentialsPostMessageTime,
    });

    if (checkResults.requestedSilentCancel) {
      return {
        updated: false,
        requestedSilentCancel: true,
      };
    }

    if (checkResults.updated) {
      return {
        updated: true,
        requestedSilentCancel: false,
      };
    }
  }

  return {
    updated: false,
    requestedSilentCancel: false,
  };
};

export const doAutoSignIn = async (
  { silentFail = false, subtle = false } = { silentFail: false, subtle: false }
) => {
  // postmessage to parent window

  setState(["powerCredentialsWaitTimeBoostCount"], 0);
  setState(["shouldSilentCancelPowerCredentialsRequest"], false);
  // pre message adds boost

  const messageToSend = subtle
    ? AUTO_SIGN_IN_MESSAGE_SUBTLE
    : AUTO_SIGN_IN_MESSAGE;

  window.parent.postMessage(messageToSend, "*");

  // wait for response

  /*
  wait 10 seconds to see if the state value:
      lastPowerCredentialsPostMessageTime
  has been updated
  */

  const lastPowerCredentialsPostMessageTime = getState(
    "lastPowerCredentialsPostMessageTime"
  );

  const { updated, requestedSilentCancel } =
    await recursivelyCheckForPowerCredentialsTimeUpdate(
      lastPowerCredentialsPostMessageTime
    );

  if (!updated) {
    if (silentFail || requestedSilentCancel) {
      // alert(
      //   "TEMPORARY MESSAGE - NORMALLY WOULD BE SILENT" +
      //     `, requestedSilentCancel: ${requestedSilentCancel}` +
      //     `, silentFail: ${silentFail}`
      // );
    } else {
      setState(["browserAlertData"], {
        message:
          "Must sign in on nftitem.io first with MetaMask, Coinbase Wallet, or Trust Wallet.",
      });
    }
  }
};
