import { useEffect, useRef } from "react";
import {
  //getState,
  setState,
} from "../../reactStateManagement";
import { getUserData } from "../../api/getUserData";
import { getMTokenData } from "../mToken";
import { handleIncomingMessage } from "../browserRealtime/handleIncomingMessage";
// import setUserToRespawn from "../bChar/setUserToRespawn";
import setBCharDatumToMetaverseState from "../bChar/setBCharDatumToMetaverseState";

const useExecutiveState = {
  hasLoaded: false,
};

export const useExecutive = () => {
  // const executiveBoxLoaded = useRef(false);
  const executiveBoxLoaded = useRef(useExecutiveState);

  useEffect(() => {
    if (executiveBoxLoaded.current.hasLoaded) {
      return;
    }
    executiveBoxLoaded.current.hasLoaded = true;

    console.log("useExecutive XXXXXX");

    const mTokenData = getMTokenData();

    if (mTokenData) {
      getUserData({
        addToState: true,
      }).then(async (userData) => {
        // setUserToRespawn({ userData });

        setBCharDatumToMetaverseState({ userData });
        // const x = userData?.arbitraryData?.metaverse_respawnData?.bCharDatum?.x;
        // const y = userData?.arbitraryData?.metaverse_respawnData?.bCharDatum?.y;

        // if (x && y) {
        //   const bCharDatum = getState("bCharDatum");

        //   const newBCharDatum = {
        //     ...bCharDatum,
        //     x,
        //     y,
        //   };

        //   console.log(
        //     "setting bCharDatum using userData: " + `x: ${x}, y: ${y}`
        //   );

        //   setState(["bCharDatum"], newBCharDatum);
        // }

        setState(["loading"], false);
      });
    } else {
      // login as guest
      setState(["loading"], false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleIncomingMessage);

    return () => {
      window.removeEventListener("message", handleIncomingMessage);
    };
  }, []);
};
