export * from "./worldConstants";

const HOSTNAME =
  process.env.REACT_APP_CUSTOM_HOSTNAME ||
  (window && window.location && window.location.hostname);

if (!HOSTNAME) {
  // safeguard - should not happen in normal operation
  throw new Error(`HOSTNAME is undefined`);
}

export const PRODUCTION_MODE = HOSTNAME !== "localhost";

export const DOGE_API_BASE_URL = process.env.DOGE_API_BASE_URL
  ? process.env.DOGE_API_BASE_URL
  : "https://x5k6eeugtb.execute-api.us-east-1.amazonaws.com";

// Local Storage Keys
export const LOCAL_STORAGE_KEYS = {
  RESPAWN_DATA: "RESPAWN_DATA",
};

export const MAIN_S3_BUCKET_URL =
  "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com";

// const isProduction = ![
//   "localhost",
//   "127.0.0.1",
//   "host.docker.internal",
//   "172.17.0.0",
//   "172.17.0.1",
//   "172.17.0.2",
// ].includes(HOSTNAME.toLowerCase());

const protocol = process.env.REACT_APP_HTTP_MODE === "true" ? "http" : "https";

// export const SOCKET_SERVER_URL = `https://${HOSTNAME}:8001`;
export const SOCKET_SERVER_URL = `${protocol}://${HOSTNAME}:8001`;

export const DATA_CENTER_ID =
  process.env.REACT_APP_DATA_CENTER_ID || "MISSING-DATA_CENTER_ID";

export const REACT_APP_API_DEBUG_GRID_VIEW_ON =
  process.env.REACT_APP_API_DEBUG_GRID_VIEW_ON;

export const TYPE_POWER_CREDENTIALS = "PowerCredentials";
export const TYPE_PRE_POWER_CREDENTIALS = "PrePowerCredentials";
export const TYPE_POWER_CREDENTIALS_REQUEST_CANCEL =
  "PowerCredentialsRequestCancel";

export const POST_MESSAGE_PREFIX = "nftitem-";

export const FONT_0 = '"Fredoka", sans-serif';

export const COLOR_0 = "black";
export const COLOR_1 = "white";
export const COLOR_2 = "#380000";
export const COLOR_3 = "#21ec9f";
export const COLOR_4 = "#8b0000"; /*darkred in hex*/
export const COLOR_5 = "#ff0000"; /*red in hex*/
// darker COLOR_2
export const COLOR_6 = "#2d0000";
// loading grey
export const COLOR_7 = "#808080";

export const EVENT = "event";

export const BROWSER_ONLY_EVENT_TYPES = {
  E_PRESS: "E_PRESS",
  POWER_MODE_TOGGLE: "POWER_MODE_TOGGLE",
};

export const EVENT_TYPES = {
  GUEST_JOIN: "GUEST_JOIN",
  GUEST_JOINED: "GUEST_JOINED",

  GUEST_LEAVE: "GUEST_LEAVE",

  CHARACTER_UPDATE: "CHARACTER_UPDATE",

  MOVE: "MOVE",
  W_MOVE_RIGHT_ON: "W_MOVE_RIGHT_ON",
  W_MOVE_RIGHT_OFF: "W_MOVE_RIGHT_OFF",
  W_MOVE_LEFT_ON: "W_MOVE_LEFT_ON",
  W_MOVE_LEFT_OFF: "W_MOVE_LEFT_OFF",
  W_MOVE_JUMP_ON: "W_MOVE_JUMP_ON",
  W_MOVE_JUMP_OFF: "W_MOVE_JUMP_OFF",
  W_MOVE_RIGHT_UP_ON: "W_MOVE_RIGHT_UP_ON",
  W_MOVE_RIGHT_UP_OFF: "W_MOVE_RIGHT_UP_OFF",
  W_MOVE_LEFT_UP_ON: "W_MOVE_LEFT_UP_ON",
  W_MOVE_LEFT_UP_OFF: "W_MOVE_LEFT_UP_OFF",

  W_CHARACTER_DATA: "W_CHARACTER_DATA",
  HELLO_WORLD: "HELLO_WORLD", // special event type for testing

  UPDATE_WORLD_NUMBER: "UPDATE_WORLD_NUMBER",
  UPDATE_WORLD_NUMBER_CLIENT: "UPDATE_WORLD_NUMBER_CLIENT",

  API_REQUEST: "API_REQUEST",
  API_RESPONSE: "API_RESPONSE",

  CREATE_BOT: "CREATE_BOT",
  DELETE_BOT: "DELETE_BOT",
  BOT_DATA: "BOT_DATA",
  GET_BOT_DATA: "GET_BOT_DATA",
  BOT_COMMAND: "BOT_COMMAND",

  CREATE_BOT2: "CREATE_BOT2",
  DELETE_BOT2: "DELETE_BOT2",
  BOT2_DATA: "BOT2_DATA",
  BOT2_INTERACT: "BOT2_INTERACT",
  LIST_BOT2_INTERACTIONS: "LIST_BOT2_INTERACTIONS",
  BOT2_INTERACTIONS: "BOT2_INTERACTIONS",

  SEARCH_BOT6S: "SEARCH_BOT6S",
  BOT6_DATA: "BOT6_DATA",
  BOT6_INTERACT: "BOT6_INTERACT",
  LIST_BOT6_INTERACTIONS: "LIST_BOT6_INTERACTIONS",
  BOT6_INTERACTIONS: "BOT6_INTERACTIONS",
  BOT6_WORLD_DATA: "BOT6_WORLD_DATA",
  LIST_BOT6_INTERACTION_PROCESSING_RECEIPTS:
    "LIST_BOT6_INTERACTION_PROCESSING_RECEIPTS",
  BOT6_INTERACTION_PROCESSING_RECEIPTS: "BOT6_INTERACTION_PROCESSING_RECEIPTS",

  PUT_BOT6_INTERACTION_PROCESSING_RECEIPT:
    "PUT_BOT6_INTERACTION_PROCESSING_RECEIPT",

  REQUEST_ID_EVENT_COMPLETED: "REQUEST_ID_EVENT_COMPLETED",

  CHARACTER_DATA: "CHARACTER_DATA",

  BCHAR_BROADCAST: "BCHAR_BROADCAST",
  BCHAR_DATA: "BCHAR_DATA",

  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  UPDATE_LAND_MAPPINGS: "UPDATE_LAND_MAPPINGS",
  GET_LAND_MAPPINGS: "GET_LAND_MAPPINGS",
  LAND_MAPPINGS: "LAND_MAPPINGS",

  PUT_WORLD_OBJECT: "PUT_WORLD_OBJECT",
  PUT_NWORLD_OBJECT: "PUT_NWORLD_OBJECT",
  PUT_PIXELS: "PUT_PIXELS",
  MINT_NFT: "MINT_NFT",
  MINT_NFT_RESPONSE: "MINT_NFT_RESPONSE",

  GET_WORLD_DATA: "GET_WORLD_DATA",
  WORLD_DATA: "WORLD_DATA",
  GET_NWORLD_DATA: "GET_NWORLD_DATA",
  GET_NWORLD_DATA_MULTIPLE: "GET_NWORLD_DATA_MULTIPLE",
  GET_NWORLD_DATA_MULTIPLE_V2: "GET_NWORLD_DATA_MULTIPLE_V2",
  POWER_GET_NWORLD_DATA: "POWER_GET_NWORLD_DATA",
  POWER_TRIGGER_DELETE_NWORLD_DATA: "POWER_TRIGGER_DELETE_NWORLD_DATA",
  POWER_NWORLD_DATA: "POWER_NWORLD_DATA",
  POWER_NWORLD_DATA_ATOMIC: "POWER_NWORLD_DATA_ATOMIC",
  GET_USER_LAND_DATA: "GET_USER_LAND_DATA",

  NWORLD_DATA: "NWORLD_DATA",

  GET_NFT_LISTINGS: "GET_NFT_LISTINGS",
  NFT_LISTINGS: "NFT_LISTINGS",

  GET_NFT_ACTIONABLES: "GET_NFT_ACTIONABLES",
  NFT_ACTIONABLES: "NFT_ACTIONABLES",

  GET_NFT_SALES: "GET_NFT_SALES",
  NFT_SALES: "NFT_SALES",

  LIST_NFT: "LIST_NFT",

  PURCHASE_NFT: "PURCHASE_NFT",

  CREATE_IMAGE: "CREATE_IMAGE",
  CREATE_IMAGE_RESPONSE: "CREATE_IMAGE_RESPONSE",

  MESSAGE: "MESSAGE",
  MESSAGES: "MESSAGES",

  RELOAD: "RELOAD",

  TRIGGER_BROWSER_ALERT: "TRIGGER_BROWSER_ALERT",
  BROWSER_ALERT: "BROWSER_ALERT",
};

export const Z_INDICES = {
  // dialog layer
  // used for dialog boxes
  DIALOG_LAYER: 4000000,

  NEW_ZOOM_WORLD_LAYER: 2323232,

  // top layer
  // used for menu items
  TOP_LAYER: 1000,

  // middle layer
  // used for world and characters
  MIDDLE_LAYER: 500,

  MIDDLE_LAYER_A: 501,
  MIDDLE_LAYER_B: 502,
  MIDDLE_LAYER_C: 503,

  // bottom layer
  BOTTOM_LAYER: 0,
};

export const TOP_FACTOR = 0.8;
export const BOTTOM_FACTOR = 1 - TOP_FACTOR;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "https://mathbitcoin.com";

// Dims
export const MOBILE_WIDTH_THRESHOLD = 700;

export const BOTTOM_SECTION_HEIGHT_MOBILE = 75;
export const BOTTOM_SECTION_HEIGHT_DESKTOP = 150;
export const MESSAGE_BOX_WIDTH = 175;
export const COORDS_HEIGHT = 40;
export const SLIDE_MENU_WIDTH = 290;

export const STANDARD_BUTTON_HEIGHT = 40;

// Dialog

export const DIALOG_TYPES = {
  EVENT_SENDER: "EVENT_SENDER",
};

// Browser Event (brovent)
export const BROVENT_TYPES = {
  TOTAL_MAP_REFRESH: "TOTAL_MAP_REFRESH",
};

export const BROVENT_KEYS = {
  rightDown: "rightDown",
  // rightUp: "rightUp",
  leftDown: "leftDown",
  // leftUp: "leftUp",
  jumpDown: "jumpDown",
  // jumpUp: "jumpUp",
};

// World

// const GROUND = "GROUND";

// export const UNIT_TYPES = {
//   GROUND,
// };

// export const WORLD_NUMBER_TO_DATA = {
//   0: {
//     w: 4,
//     h: 4,
//     unitNumberToData: {
//       0: {
//         unitType: GROUND,
//       },
//       1: {
//         unitType: GROUND,
//       },
//       2: {
//         unitType: GROUND,
//       },
//       3: {
//         unitType: GROUND,
//       },
//       4: {
//         unitType: GROUND,
//       },
//       5: {
//         unitType: GROUND,
//       },
//       6: {
//         unitType: GROUND,
//       },
//       7: {
//         unitType: GROUND,
//       },
//       8: {
//         unitType: GROUND,
//       },
//       9: {
//         unitType: GROUND,
//       },
//     },
//   },
// };

// export const ITEM_TYPES = {
//   TREE_0: "tree0",
//   BIRCH_TREE_0: "birchTree0",
// };

// export const ITEM_TYPE_TO_DATA = {
//   // tree
//   tree0: {
//     allowedOnUnitTypes: [GROUND],
//   },

//   // birch tree
//   birchTree0: {
//     allowedOnUnitTypes: [GROUND],
//   },
// };

// WORLD DISPLAY ONLY
export const CSS_DEFAULT_ANIMATION_TRANSITION = "all 0.09s ease";
export const PRE_RENDER_WIDTH = 300;
// number of pixels to render before and after the screen

export const ERASER = "ERASER";

export const POWER_MODES = {
  MOON: "MOON",
  X: "X",
  POWER: "POWER",
};

export const POWER_MODES_LIST = [
  POWER_MODES.X,
  POWER_MODES.POWER,
  POWER_MODES.MOON,
];

export const EXECUTIVE_BOX_MODES = {
  IMAGE_TO_PIXELS: "IMAGE_TO_PIXELS",
  ADV_BUILD: "ADV_BUILD",
  MARKETPLACE: "MARKETPLACE",
  WEARABLES: "WEARABLES",
  BOT222: "BOT222",
  BOT6: "BOT6",
  MINT: "MINT",
  CHARACTER_SELECT: "CHARACTER_SELECT",
};

export const MARKETPLACE_MODES = {
  SELL: "SELL",
};

// Bot6
export const BOT6_PAGE_MODES = {
  COMMAND_CENTER: "COMMAND_CENTER",
};

export const BOT6_MAX_TEXT_INPUT_LENGTH = 140;

export const BOT6_INTERACT_MODES = {
  EXPLORE: "EXPLORE",
  BUILD: "BUILD",
};
// botoScreenCaptureModeType
export const BOT6_SCREEN_CAPTURE_MODE_TYPES = {
  COORDINATE_GRID: "COORDINATE_GRID",
};

export const BOT6_PROMPT_TYPES = {
  BUILD: "BUILD",
};

export const BOT6_COMMANDS = {
  MOVE: "move",
  TALK: "talk",
  PUT_PIXEL: "putPixel",
};

// Characters

export const CHARACTERS = {
  DOGE: "DOGE",
  ELON: "ELON",
  ZUCC: "ZUCC",
  BEARA: "BEARA",
};

export const CHARACTERS_ARRAY = Array.from(new Set(Object.values(CHARACTERS)));

const ZUCC_IMAGE_0 =
  "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_0.png";
const ZUCC_IMAGE_0_R =
  "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_0_r.png";

const BEARA_IMAGE_0 =
  "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/beara_0.png";

const BEARA_IMAGE_0_R =
  "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/beara_0_r.png";

export const characterToData = {
  [CHARACTERS.DOGE]: {
    imageUrlA:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8_a.png",
    imageUrlMove:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8a_a.png",
  },
  [CHARACTERS.ELON]: {
    imageUrlA:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/elon_0.png",
    imageUrlMove:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/elon_1.png",
    imageUrlARight:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/elon_0_r.png",
    imageUrlMoveRight:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/elon_1_r.png",
  },
  [CHARACTERS.ZUCC]: {
    // imageUrlA:
    //   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_0.png",
    // imageUrlMove:
    //   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_1.png",
    // imageUrlARight:
    //   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_0_r.png",
    // imageUrlMoveRight:
    //   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_1_r.png",
    imageUrlA: ZUCC_IMAGE_0,
    imageUrlMove: ZUCC_IMAGE_0,
    imageUrlARight: ZUCC_IMAGE_0_R,
    imageUrlMoveRight: ZUCC_IMAGE_0_R,
  },

  [CHARACTERS.BEARA]: {
    imageUrlA: BEARA_IMAGE_0_R,
    imageUrlMove: BEARA_IMAGE_0_R,
    imageUrlARight: BEARA_IMAGE_0,
    imageUrlMoveRight: BEARA_IMAGE_0,
  },
};
