import { BROVENT_TYPES } from "../../constants";
import { resetReactStateManagement } from "../../reactStateManagement";

const totalMapRefresh = () => {
  console.log("TOTAL MAP REFRESH");

  resetReactStateManagement({
    listOfKeysToInclude: [
      ["nWorldData"],
      ["partitionIdToNWorldData"],
      ["localUpdateQueuePutsBrowser"],
      ["localUpdateQueueRemovesBrowser"],
      ["localUpdateQueueCombinedBrowser"],
      ["selectedItems"],
    ],
  });
};

export const broventMessageListener = (event, data) => {
  // event is a custom jquery event

  try {
    const broventType = data?.broventType;

    switch (broventType) {
      case BROVENT_TYPES.TOTAL_MAP_REFRESH:
        totalMapRefresh(event, data);
        break;
      default:
        console.log("Unknown brovent type:", broventType);
        break;
    }
  } catch (error) {
    console.log("Error in brovent message listener:");
    console.log(error);
  }
};
