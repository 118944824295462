import { UNIT_WIDTH } from "../nzwConstants";

export const getUserYToUse = ({ quantizeMode, userY, minY }) => {
  if (quantizeMode) {
    const belowUserUnitCount = Math.floor(userY / UNIT_WIDTH);

    const userYToUse = belowUserUnitCount * UNIT_WIDTH - minY;

    return userYToUse;
  }

  return userY - minY;
};
