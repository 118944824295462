import { ERASER, EVENT_TYPES } from "../../../constants";
import { getState } from "../../../reactStateManagement";
import { emitEvent } from "../../../utils/emitEvent";
import getAuth from "../../../utils/getAuth";

export const putNObject = async ({
  xDecimalPercent,
  yDecimalPercent,
  itemType,
  customBackgroundColor,
  // backgroundColor,
}) => {
  const auth = getAuth();

  // console.log(`

  //   putNObject LOG ${JSON.stringify(
  //     {
  //       xDecimalPercent,
  //       yDecimalPercent,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  const data = {
    auth,
    x: xDecimalPercent,
    y: yDecimalPercent,
    itemType,
  };

  if (customBackgroundColor || customBackgroundColor === null) {
    data.backgroundColor = customBackgroundColor;

    if (customBackgroundColor === null) {
      data.itemType = null;
    }
  } else {
    const nObjectPutBackgroundColor = getState("nObjectPutBackgroundColor");

    if (nObjectPutBackgroundColor === ERASER) {
      data.itemType = null;
    }

    if (nObjectPutBackgroundColor) {
      data.backgroundColor = nObjectPutBackgroundColor;
    }
  }

  emitEvent({
    data,
    type: EVENT_TYPES.PUT_NWORLD_OBJECT,
  });
};
