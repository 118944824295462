import axios from "axios";
import {
  getMTokenData,
  //deleteMTokenData
} from "../utils/mToken";
import { API_BASE_URL } from "../constants";

const POWER_QUERY_V2 = "POWER_QUERY_V2";

const doMagnaDocker = async ({ message }) => {
  try {
    const mTokenData = getMTokenData();

    if (!mTokenData) {
      throw new Error("user not authorized -");
    }

    const { address, mToken } = mTokenData;

    const data = {
      info: {
        command: POWER_QUERY_V2,
        message,
      },
    };

    const response = await axios({
      method: "post",
      url: `${API_BASE_URL}/expansive-world/magna`,
      headers: {
        "Content-Type": "application/json",
        "nftitem-address": address,
        "nftitem-ntoken": mToken,
      },
      data,
    });

    return response.data;
  } catch (err) {
    console.error("error in getting magna:");
    console.error(err);

    return null;
  }
};

export default doMagnaDocker;
