import React from "react";
import { BROVENT_TYPES, EVENT_TYPES, ITEM_TYPE_TO_DATA } from "../../constants";
import { getState, setState } from "../../reactStateManagement";
import { helloWorld } from "../../utils/character/helloWorld";
import {
  moveDown,
  moveLeft,
  moveRight,
  moveUp,
} from "../../utils/character/move";
import { emitEvent } from "../../utils/emitEvent";
import getAuth from "../../utils/getAuth";
import {
  DEFAULT_START_X_PERCENT_STRING,
  DEFAULT_START_Y_PERCENT_STRING,
  NWORLD_X_PARTITION_PERCENT_DECIMAL,
} from "../../worldConstants";
import { BUTTON_HEIGHT_PERCENTAGE } from "./BUTTON_HEIGHT_PERCENTAGE";
import { ToggleButtonModeButtonSection } from "./ToggleButtonModeButtonSection";
import { cachePowerGetNWorldData } from "../../utils/world/cachePowerGetNWorldData";
import { getMTokenData } from "../../utils/mToken";

export const EventTriggerButtonsSection = ({ buttonMode, setButtonMode }) => {
  const eventSenderEventsShowCharacterData = getState(
    "eventSenderEventsShowCharacterData"
  );
  const eventSenderEventsShowWCharacterData = getState(
    "eventSenderEventsShowWCharacterData"
  );
  const eventSenderEventsShowBotData = getState("eventSenderEventsShowBotData");
  const eventSenderEventsShowBCharData = getState(
    "eventSenderEventsShowBCharData"
  );

  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",

        backgroundColor: "darkslateblue",
      }}
    >
      <ToggleButtonModeButtonSection
        {...{
          buttonMode,
          setButtonMode,
        }}
      />
      <button
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          // backgroundColor: "black",
          backgroundColor: eventSenderEventsShowCharacterData
            ? "green"
            : "darkslategray",
          // color: "white",
          color: eventSenderEventsShowCharacterData ? "white" : "grey",
        }}
        onClick={() => {
          setState(
            ["eventSenderEventsShowCharacterData"],
            !eventSenderEventsShowCharacterData
          );
        }}
      >
        {"Toggle Show Character Data"}
      </button>

      <button
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          // backgroundColor: "black",
          backgroundColor: eventSenderEventsShowWCharacterData
            ? "green"
            : "darkslategray",
          // color: "white",
          color: eventSenderEventsShowWCharacterData ? "white" : "grey",
        }}
        onClick={() => {
          setState(
            ["eventSenderEventsShowWCharacterData"],
            !eventSenderEventsShowWCharacterData
          );
        }}
      >
        {"Toggle Show WCharacter Data"}
      </button>

      <button
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          // backgroundColor: "black",
          backgroundColor: eventSenderEventsShowBotData
            ? "green"
            : "darkslategray",
          // color: "white",
          color: eventSenderEventsShowBotData ? "white" : "grey",
        }}
        onClick={() => {
          setState(
            ["eventSenderEventsShowBotData"],
            !eventSenderEventsShowBotData
          );
        }}
      >
        {"Toggle Show Bot Data"}
      </button>

      <button
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          // backgroundColor: "black",
          backgroundColor: eventSenderEventsShowBCharData
            ? "green"
            : "darkslategray",
          // color: "white",
          color: eventSenderEventsShowBCharData ? "white" : "grey",
        }}
        onClick={() => {
          setState(
            ["eventSenderEventsShowBCharData"],
            !eventSenderEventsShowBCharData
          );
        }}
      >
        {"Toggle Show BChar Data"}
      </button>

      <button
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          // backgroundColor: "black",
          backgroundColor: "darkcyan",
          // color: "white",
          color: "white",
        }}
        onClick={async () => {
          helloWorld();
        }}
      >
        {"Send Hello World Event"}
      </button>
      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          // backgroundColor: "black",
          backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <select
          id="event-sender-move-select"
          style={{
            width: "40%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "black",
            // color: "white",
            color: "white",
          }}
        >
          <option value="left">{"Left"}</option>
          <option value="right">{"Right"}</option>
          <option value="up">{"Up"}</option>
          <option value="down">{"Down"}</option>
        </select>
        <button
          style={{
            width: "60%",
            height: "100%",
            backgroundColor: "black",
            // backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            // alert("event.target.value: " + event.target.value);
            const select = document.getElementById("event-sender-move-select");

            if (!select) {
              return;
            }

            switch (select.value) {
              case "left":
                moveLeft();
                break;
              case "right":
                moveRight();
                break;
              case "up":
                moveUp();
                break;
              case "down":
                moveDown();
                break;
              default:
                break;
            }
          }}
        >
          {"Move"}
        </button>
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <select
          id="event-sender-world-number-select"
          style={{
            width: "40%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "black",
            // color: "white",
            color: "white",
          }}
        >
          <option value="0">{"0"}</option>
          {/* <option value="1">{"1"}</option> */}
        </select>
        <button
          style={{
            width: "60%",
            height: "100%",
            backgroundColor: "black",
            // backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            const auth = getAuth();

            emitEvent({
              data: {
                auth,
              },
              type: EVENT_TYPES.GET_WORLD_DATA,
            });
          }}
        >
          {"Get World Data"}
        </button>
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "60%",
            height: "100%",
            // backgroundColor: "darkred",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            const auth = getAuth();

            const worldNumberInput = document.getElementById(
              "event-sender-put-world-object-world-number-input"
            );
            const unitNumberInput = document.getElementById(
              "event-sender-put-world-object-unit-number-input"
            );

            const itemTypeSelect = document.getElementById(
              "event-sender-put-world-object-item-type-select"
            );

            if (
              !worldNumberInput ||
              !unitNumberInput ||
              !itemTypeSelect ||
              !itemTypeSelect.value
            ) {
              alert("Invalid input");
              return;
            }

            const worldNumber = Number(worldNumberInput.value);
            const unitNumber = Number(unitNumberInput.value);
            const itemType = itemTypeSelect.value;

            if (
              isNaN(worldNumber) ||
              isNaN(unitNumber) ||
              !Number.isInteger(worldNumber) ||
              !Number.isInteger(unitNumber)
            ) {
              alert("Invalid worldNumber or unitNumber");
              return;
            }

            if (!ITEM_TYPE_TO_DATA[itemType]) {
              alert("Invalid itemType");
              return;
            }

            emitEvent({
              data: {
                auth,
                worldNumber,
                unitNumber,
                itemType,
              },
              type: EVENT_TYPES.PUT_WORLD_OBJECT,
            });
          }}
        >
          {"Put World Object"}
        </button>
        <div
          style={{
            width: "40%",
            height: "100%",
            backgroundColor: "black",
            // backgroundColor: "darkcyan",
            // color: "white",
            color: "white",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            id="event-sender-put-world-object-world-number-input"
            style={{
              width: "100%",
              height: "calc( 100% / 3 )",
              // backgroundColor: "black",
              backgroundColor: "black",
              // color: "white",
              color: "white",
            }}
            placeholder="World Number"
            defaultValue={"0"}
          />
          <input
            id="event-sender-put-world-object-unit-number-input"
            style={{
              width: "100%",
              height: "calc( 100% / 3 )",
              // backgroundColor: "black",
              backgroundColor: "black",
              // color: "white",
              color: "white",
            }}
            placeholder="Unit Number"
          />
          <select
            id="event-sender-put-world-object-item-type-select"
            style={{
              width: "100%",
              height: "calc( 100% / 3 )",
              // backgroundColor: "black",
              backgroundColor: "black",
              // color: "white",
              color: "white",
            }}
            placeholder="Item Type"
          >
            <option value="tree0">{"tree0"}</option>
          </select>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "100%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            const auth = getAuth();

            emitEvent({
              data: {
                auth,
                // worldNumber,
                // unitNumber,
                // itemType,
              },
              type: EVENT_TYPES.CREATE_BOT,
            });
          }}
        >
          {"Add Bot"}
        </button>
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "100%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            const auth = getAuth();

            emitEvent({
              data: {
                auth,
                // worldNumber,
                // unitNumber,
                // itemType,
              },
              type: EVENT_TYPES.GET_BOT_DATA,
            });
          }}
        >
          {"Get Bot Data"}
        </button>
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            const auth = getAuth();

            const botIdInput = document.getElementById(
              "event-sender-remove-bot-bot-id-input"
            );

            if (!botIdInput) {
              return;
            }

            const botId = botIdInput?.value;

            const botIdIsUUID = botId.length === 36;

            if (!botIdIsUUID) {
              return;
            }

            emitEvent({
              data: {
                auth,
                botId,
              },
              type: EVENT_TYPES.DELETE_BOT,
            });
          }}
        >
          {"Remove Bot"}
        </button>
        <input
          id="event-sender-remove-bot-bot-id-input"
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "black",
            // color: "white",
            color: "white",
          }}
          placeholder="Bot ID"
        />
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            const auth = getAuth();

            // const botIdInput = document.getElementById(
            //   "event-sender-remove-bot-bot-id-input"
            // );
            // if (!botIdInput) {
            //   return;
            // }
            // const botId = botIdInput?.value;
            // const botIdIsUUID = botId.length === 36;
            // if (!botIdIsUUID) {
            //   return;
            // }
            emitEvent({
              data: {
                auth,
                // botId,
              },
              type: EVENT_TYPES.BOT_COMMAND,
            });
          }}
        >
          {"Do Bot Command"}
        </button>
        <input
          id="event-sender-bot-command-input"
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "black",
            // color: "white",
            color: "white",
          }}
          placeholder="Bot Command"
        />
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            const worldNumberInput = document.getElementById(
              "event-sender-update-world-number-input"
            );

            const worldNumber = Number(worldNumberInput?.value);

            if (![0, 1].includes(worldNumber)) {
              return;
            }

            const auth = getAuth();
            emitEvent({
              data: {
                auth,
                // botId,
                worldNumber,
              },
              type: EVENT_TYPES.UPDATE_WORLD_NUMBER,
            });
          }}
        >
          {"Update World Number"}
        </button>
        <input
          id="event-sender-update-world-number-input"
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "black",
            // color: "white",
            color: "white",
          }}
          placeholder="World Number"
        />
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            const auth = getAuth();

            emitEvent({
              data: {
                auth,
                userXPercentString: DEFAULT_START_X_PERCENT_STRING,
                userYPercentString: DEFAULT_START_Y_PERCENT_STRING,
              },
              type: EVENT_TYPES.GET_NWORLD_DATA,
            });
          }}
        >
          {"Get NWorld Data"}
        </button>
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            const auth = getAuth();

            emitEvent({
              data: {
                auth,
                // userXPercentString: DEFAULT_START_X_PERCENT_STRING,
                // userYPercentString: DEFAULT_START_Y_PERCENT_STRING,
                xPartitionDecimalPercent: 0.5,
                yPartitionDecimalPercent: 0,
              },
              type: EVENT_TYPES.GET_NWORLD_DATA_MULTIPLE_V2,
            });
          }}
        >
          {"Get NWorld Data Multi"}
        </button>
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            const $ = window.$;

            // send custom "brovent" event (browser event)

            const data = {
              broventType: BROVENT_TYPES.TOTAL_MAP_REFRESH,
            };

            $(window).trigger("brovent", data);
          }}
        >
          {"Trigger Total Map Refresh"}
        </button>
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            // eventSenderEvents;
            // eventSenderEventsOutput;
            setState(["eventSenderEvents"], []);
            setState(["eventSenderEventsOutput"], []);
          }}
        >
          {"Clear Events"}
        </button>
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            const auth = getAuth();

            // const botIdInput = document.getElementById(
            //   "event-sender-remove-bot-bot-id-input"
            // );
            // if (!botIdInput) {
            //   return;
            // }
            // const botId = botIdInput?.value;
            // const botIdIsUUID = botId.length === 36;
            // if (!botIdIsUUID) {
            //   return;
            // }
            const input = document.getElementById(
              "event-sender-browser-alert-input"
            );

            if (!input) {
              return;
            }

            if (!input.value) {
              return;
            }

            emitEvent({
              data: {
                auth,
                message: input.value,
              },
              type: EVENT_TYPES.TRIGGER_BROWSER_ALERT,
            });
          }}
        >
          {"Trigger Alert"}
        </button>
        <input
          id="event-sender-browser-alert-input"
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "black",
            // color: "white",
            color: "white",
          }}
          placeholder="Test Alert"
        />
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            let xPartitionDecimalPercentInput = document.getElementById(
              "event-sender-power-get-nworld-data-input"
            );

            if (
              !xPartitionDecimalPercentInput ||
              !xPartitionDecimalPercentInput.value
            ) {
              xPartitionDecimalPercentInput = {
                value: "0",
              };
            }

            const xNumber = Number(xPartitionDecimalPercentInput.value);

            if (!Number.isInteger(xNumber)) {
              return;
            }

            const xPartitionDecimalPercentOffset =
              xNumber * NWORLD_X_PARTITION_PERCENT_DECIMAL;

            const xPartitionDecimalPercent =
              0.5 + xPartitionDecimalPercentOffset;

            cachePowerGetNWorldData({
              xPartitionDecimalPercent,
              yPartitionDecimalPercent: 0,
              skipCache: true,
            });
          }}
        >
          {"Power Get NWorld Data"}
        </button>
        <input
          id="event-sender-power-get-nworld-data-input"
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "black",
            // color: "white",
            color: "white",
          }}
          placeholder="0"
        />
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            let xPartitionDecimalPercentInput = document.getElementById(
              "event-sender-cache-power-get-nworld-data-input"
            );

            if (
              !xPartitionDecimalPercentInput ||
              !xPartitionDecimalPercentInput.value
            ) {
              xPartitionDecimalPercentInput = {
                value: "0",
              };
            }

            const xNumber = Number(xPartitionDecimalPercentInput.value);

            if (!Number.isInteger(xNumber)) {
              return;
            }

            const xPartitionDecimalPercentOffset =
              xNumber * NWORLD_X_PARTITION_PERCENT_DECIMAL;

            const xPartitionDecimalPercent =
              0.5 + xPartitionDecimalPercentOffset;

            cachePowerGetNWorldData({
              xPartitionDecimalPercent,
              yPartitionDecimalPercent: 0,
              skipCache: false,
            });
          }}
        >
          {"Cache Power Get NWorld Data"}
        </button>
        <input
          id="event-sender-cache-power-get-nworld-data-input"
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "black",
            // color: "white",
            color: "white",
          }}
          placeholder="0.5"
        />
      </div>

      <div
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          backgroundColor: "black",
          // backgroundColor: "darkcyan",
          // color: "white",
          color: "white",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "darkcyan",
            // color: "white",
            color: "white",
          }}
          onClick={async () => {
            // ignore input for now

            const auth = getAuth();

            if (!auth) {
              return;
            }

            const mTokenData = getMTokenData();

            if (!mTokenData) {
              alert("No mTokenData - must be signed in");
              return;
            }

            emitEvent({
              data: {
                auth,
                mToken: mTokenData.mToken,
                address: mTokenData.address,
              },
              type: EVENT_TYPES.CREATE_BOT2,
            });
          }}
        >
          {"Create Bot2"}
        </button>
        <input
          id="event-sender-create-bot2-tag-input"
          style={{
            width: "50%",
            height: "100%",
            // backgroundColor: "black",
            backgroundColor: "black",
            // color: "white",
            color: "white",
          }}
          placeholder="Bot2 tag"
        />
      </div>
    </div>
  );
};
