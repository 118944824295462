import { useEffect } from "react";

const useSelectableTextComponent = ({ id }) => {
  useEffect(() => {
    // ensure user select is always enabled for the joypad

    const $ = window.$;

    const $element = $(`#${id}`);

    if ($element.length === 0) {
      return;
    }

    const handle = function () {
      $element.css("user-select", "text");

      return true;
    };

    // $element.css("user-select", "text");
    $element.bind("contextmenu", handle);
    $element.bind("dblclick", handle);
    $element.bind("touchend", handle);
    $element.bind("touchstart", handle);
    $element.bind("click", handle);

    return () => {
      $element.unbind("contextmenu", handle);
      $element.unbind("touchend", handle);
      $element.unbind("dblclick", handle);
      $element.unbind("touchstart", handle);
      $element.unbind("click", handle);
    };
  }, []);
};

export default useSelectableTextComponent;
