import { getState } from "../reactStateManagement";

const getAuth = (safeGet = false) => {
  const userId = getState("userId");
  const password = getState("password");

  if (!userId || !password) {
    if (safeGet) {
      return null;
    }

    throw new Error("getAuth: missing userId or password");
  }

  const auth = {
    userId,
    password,
  };

  return auth;
};

export default getAuth;
