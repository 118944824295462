import React from "react";
import { getState, setState } from "../../reactStateManagement";
import { ToggleButtonModeButtonSection } from "./ToggleButtonModeButtonSection";
import { BUTTON_HEIGHT_PERCENTAGE } from "./BUTTON_HEIGHT_PERCENTAGE";

const scale = (settings, key) =>
  Math.round(settings[key] / settings.globalControlScalar);

const unscale = (settings, key) => settings[key] * settings.globalControlScalar;

export const EventTriggerButtonsSection2 = ({ buttonMode, setButtonMode }) => {
  const settings = getState("settings");

  const settingsToDisplay = {
    maxNumberOfPartitionsInMemory: settings.maxNumberOfPartitionsInMemory,
    frequency: settings.frequency,
    bFrequency: settings.bFrequency,
    globalControlScalar: settings.globalControlScalar,
    maxYMomentum: scale(settings, "maxYMomentum"),
    gravity: scale(settings, "gravity"),
    jumpIncrement: scale(settings, "jumpIncrement"),
    maxMomentum: scale(settings, "maxMomentum"),
    incrementConstant: scale(settings, "incrementConstant"),
    incrementBoostThreshold: scale(settings, "incrementBoostThreshold"),
    incrementBoost: scale(settings, "incrementBoost"),
  };

  const [settingsTextAreaValue, setSettingsTextAreaValue] = React.useState(
    JSON.stringify(settingsToDisplay, null, 2)
  );

  //  settings: v({
  //       frequency: 10,
  //       bFrequency: 1,
  //       globalControlScalar: GLOBAL_CONTROL_SCALAR,
  //       maxYMomentum: 12 * GLOBAL_CONTROL_SCALAR,
  //       jumpIncrement: 44 * GLOBAL_CONTROL_SCALAR,
  //       maxMomentum: 9 * GLOBAL_CONTROL_SCALAR,
  //       incrementConstant: 6 * GLOBAL_CONTROL_SCALAR,
  //       incrementBoostThreshold: 12 * GLOBAL_CONTROL_SCALAR,
  //       incrementBoost: 2 * GLOBAL_CONTROL_SCALAR,
  //     }),

  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",

        // backgroundColor: "darkslateblue",
      }}
    >
      <ToggleButtonModeButtonSection
        {...{
          buttonMode,
          setButtonMode,
        }}
      />

      <textarea
        style={{
          width: "80%",
          height: `${5 * BUTTON_HEIGHT_PERCENTAGE}%`,
          // backgroundColor: "black",
          backgroundColor: "darkslategray",
          // color: "white",
          color: "white",
        }}
        onChange={(event) => {
          setSettingsTextAreaValue(event.target.value);
        }}
        value={settingsTextAreaValue}
      ></textarea>

      <button
        style={{
          width: "80%",
          height: `${BUTTON_HEIGHT_PERCENTAGE}%`,
          // backgroundColor: "black",
          backgroundColor: "darkslategray",
          // color: "white",
          color: "white",
        }}
        onClick={() => {
          const newSettings = JSON.parse(settingsTextAreaValue);
          console.log(`
            
            
              MEGA LOG ${JSON.stringify(
                {
                  newSettings,
                },
                null,
                4
              )}
              )
            
            
            `);

          newSettings.maxYMomentum = unscale(newSettings, "maxYMomentum");
          newSettings.gravity = unscale(newSettings, "gravity");
          newSettings.jumpIncrement = unscale(newSettings, "jumpIncrement");
          newSettings.maxMomentum = unscale(newSettings, "maxMomentum");
          newSettings.incrementConstant = unscale(
            newSettings,
            "incrementConstant"
          );
          newSettings.incrementBoostThreshold = unscale(
            newSettings,
            "incrementBoostThreshold"
          );
          newSettings.incrementBoost = unscale(newSettings, "incrementBoost");

          console.log(`
            
            
              MEGA LOG 2${JSON.stringify(
                {
                  newSettings,
                },
                null,
                4
              )}
              )
            
            
            `);

          setState(["settings"], newSettings);
        }}
      >
        {"Power Button"}
      </button>
    </div>
  );
};
