import React, { useEffect, useState } from "react";
import { getState, setState } from "../../../../../../reactStateManagement";
import {
  BOT6_INTERACT_MODES,
  BOT6_SCREEN_CAPTURE_MODE_TYPES,
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // PRODUCTION_MODE,
} from "../../../../../../constants";
import Bot6InteractButton from "./Bot6InteractButton";
import Bot6InteractInput from "./Bot6InteractInput";
import { getDisplayNameFromBot6InteractMode } from "../../../../../../utils/aiBoto/bot6Tools";
// import StagingComponent from "../../../../../../StagingComponent";

const Bot6InteractSection = ({
  interactInputHeight,
  interactButtonHeight,
  isModeSelect,
  bot6InteractMode,
}) => {
  const [botoDatum, setBotoDatum] = useState(null);

  useEffect(() => {
    const bot6PageModeTokenId = getState("bot6PageModeTokenId");

    const bot6s = getState("bot6s");

    if (!bot6s) {
      // safeguard, should not happen in normal operation
      console.log("bot6s not loaded");

      setState("browserAlertData", {
        message: "Botos not loaded",
      });

      setState("bot6PageMode", null);

      return;
    }

    const bot6Datum = bot6s.find(
      (bot6) => String(bot6.secondarySortKey) === bot6PageModeTokenId
    );

    if (!bot6Datum) {
      console.log("botDatum not found");

      setState("browserAlertData", {
        message: "Boto not foundd",
      });

      setState("bot6PageMode", null);

      return;
    }

    setBotoDatum(bot6Datum);
  }, []);

  const tokenId = botoDatum && String(botoDatum.secondarySortKey);

  // const powerButtonWidth = 65;

  const disabled = isModeSelect;

  const buttonText = getDisplayNameFromBot6InteractMode(bot6InteractMode);

  let power;
  let botoScreenCaptureModeType;

  if (bot6InteractMode === BOT6_INTERACT_MODES.BUILD) {
    power = true;
    botoScreenCaptureModeType = BOT6_SCREEN_CAPTURE_MODE_TYPES.COORDINATE_GRID;
  } else if (bot6InteractMode === BOT6_INTERACT_MODES.EXPLORE) {
    power = true;
    botoScreenCaptureModeType = null;
  } else {
    power = false;
  }

  return (
    <div
      style={{
        // width: "100%",

        // maxHeight: 420,
        position: "relative",
        // height: mobile ? 190 : 420,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",
        boxSizing: "border-box",

        //   position: "absolute",
        //   bottom: 0,
        //   left: 0,
        width: "100%",

        height: interactInputHeight + interactButtonHeight,
        // height: 60,
      }}
    >
      <Bot6InteractInput
        tokenId={tokenId}
        height={interactInputHeight}
        disabled={disabled}
      />
      <div
        style={{
          width: "100%",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          height: interactButtonHeight,
        }}
      >
        <Bot6InteractButton
          tokenId={tokenId}
          text={buttonText}
          disabled={disabled}
          // width={`calc( 100% - ${powerButtonWidth}px )`}
          width={"100%"}
          power={power}
          botoScreenCaptureModeType={botoScreenCaptureModeType}
          bot6InteractMode={bot6InteractMode}
        />
        {/* <StagingComponent overrideStagingOff={POWER_OVERRIDE_STAGING_OFF}> */}
        {/* <Bot6InteractButton
          tokenId={tokenId}
          text={"Power($$)"}
          width={powerButtonWidth}
          disabled={disabled}
          power={true}
        /> */}
        {/* </StagingComponent> */}
      </div>
    </div>
  );
};

export default Bot6InteractSection;
