import { EVENT_TYPES } from "../../../constants";
import { getDecimalPercentFromPercentString } from "../../../coordsMapper";
import { getState } from "../../../reactStateManagement";
import { emitEvent } from "../../../utils/emitEvent";
import getAuth from "../../../utils/getAuth";
import getDoPromiseInQueue from "../../../utils/getDoPromiseInQueue";
import { getMTokenData } from "../../../utils/mToken";

const doPromiseInQueue = getDoPromiseInQueue();

const CMD_MESSAGE_IDENTIFIER_ALIAS = "bot ";
const CMD_MESSAGE_IDENTIFIER = "boto ";

const handleCmdMessageCore = async (message) => {
  // if (!message.startsWith(CMD_MESSAGE_IDENTIFIER)) {
  //   return;
  // }

  let command;

  // if (message.startsWith(CMD_MESSAGE_IDENTIFIER_ALIAS)) {
  // command = message.substring(CMD_MESSAGE_IDENTIFIER_ALIAS.length).trim();

  // message = CMD_MESSAGE_IDENTIFIER + command;
  // }

  let trueBotoCommand = false;

  const lowerCaseMessage = message.toLowerCase();

  if (lowerCaseMessage.startsWith(CMD_MESSAGE_IDENTIFIER)) {
    command = message.substring(CMD_MESSAGE_IDENTIFIER.length).trim();

    trueBotoCommand = true;
  } else if (lowerCaseMessage.startsWith(CMD_MESSAGE_IDENTIFIER_ALIAS)) {
    command = message.substring(CMD_MESSAGE_IDENTIFIER_ALIAS.length).trim();

    trueBotoCommand = true;
  } else {
    command = message;
  }

  // const command = message.substring(CMD_MESSAGE_IDENTIFIER.length).trim();

  if (!command) {
    return;
  }

  const auth = getAuth();
  const mTokenData = getMTokenData() || {};

  const bCharDatum = getState("bCharDatum");

  const xDecimalPercent = getDecimalPercentFromPercentString(bCharDatum.x);
  const yDecimalPercent = getDecimalPercentFromPercentString(bCharDatum.y);

  if (
    typeof xDecimalPercent !== "number" ||
    typeof yDecimalPercent !== "number"
  ) {
    console.log("handleCmdMessageCore: invalid x or y");
    return;
  }

  emitEvent({
    type: EVENT_TYPES.BOT_COMMAND,
    data: {
      auth,
      address: mTokenData?.address || null,
      mToken: mTokenData?.mToken || null,
      command,
      trueBotoCommand,
      xDecimalPercent,
      yDecimalPercent,
    },
  });
};

const handleCmdMessage = async (message) => {
  try {
    await doPromiseInQueue({
      operation: async () => {
        await handleCmdMessageCore(message);
      },
    });
  } catch (error) {
    console.error("Error handling command message", error);
  }
};

export default handleCmdMessage;
