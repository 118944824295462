import React from "react";
// import React, { useEffect } from "react";
// import { EVENT_TYPES } from "../../../constants";
// import { getState } from "../../../reactStateManagement";
// import { helloWorld } from "../../../utils/character/helloWorld";
// import getAuth from "../../../utils/getAuth";
// import { emitEvent } from "../../../utils/emitEvent";
import {
  CSS_DEFAULT_ANIMATION_TRANSITION,
  NWORLD_TOTAL_HEIGHT,
  NWORLD_TOTAL_WIDTH,
  NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
  //EVENT_TYPES,
  Z_INDICES,
} from "../../../constants";
import {
  getDecimalPercentFromPercentString,
  getXPixelsFromXDecimalPercent,
  getYPixelsFromYPercentString,
} from "../../../coordsMapper";

const DEFAULT_BOT_NUMBER = 0;

const BOT_NUMBER_TO_DATA = {
  0: {
    name: "BOTO",
    image0:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/boto_cool_3.png",
    image0a:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/boto_cool_2.png",
  },
};

export const Bot = ({
  // botId,
  x = 0,
  y = 0,
  minX,
  // metadata attributes
  // metaStateA = false,
  botNumber = DEFAULT_BOT_NUMBER,
  tauntMode = null,
  tauntModeEnd = null,

  // worldNumber,
  // worldData,
}) => {
  // listen for wasd key presses

  // const selfUserId = getState("userId");

  // const isSelf = selfUserId === userId;

  const [isTauntMode, setIsTauntMode] = React.useState(false);

  React.useEffect(() => {
    const i = setInterval(() => {
      if (tauntModeEnd && Date.now() > tauntModeEnd) {
        setIsTauntMode(false);

        return;
      }

      if (Number.isInteger(tauntMode)) {
        setIsTauntMode(true);
      }

      // if (isTauntMode) {
      //   console.log("tauntMode");
      // }
    }, 420);

    return () => {
      clearInterval(i);
    };
  }, [tauntMode, tauntModeEnd]);

  const bot =
    BOT_NUMBER_TO_DATA[botNumber] || BOT_NUMBER_TO_DATA[DEFAULT_BOT_NUMBER];

  // const image = bot.image0;

  const image = isTauntMode ? bot.image0a : bot.image0;

  // console.log(image);
  const unitWidth = NWORLD_UNIT_WIDTH_PERCENT_DECIMAL * NWORLD_TOTAL_WIDTH;
  const unitHeight = NWORLD_UNIT_WIDTH_PERCENT_DECIMAL * NWORLD_TOTAL_HEIGHT;

  const botHeight = 3 * unitHeight;
  const botWidth = 5 * unitWidth;

  const xDecimalPercent = getDecimalPercentFromPercentString(x);

  const xPixels = getXPixelsFromXDecimalPercent(xDecimalPercent);

  const botX = xPixels - minX;

  const yPixels = getYPixelsFromYPercentString(y);

  // console.log(`

  //     MEGA LOG: ${JSON.stringify(
  //       {
  //         x,
  //         xDecimalPercent,
  //         xPixels,
  //         minX,
  //         botX,
  //         y,
  //         yPixels,
  //       },
  //       null,
  //       4
  //     )}

  // `);

  return (
    <div
      style={{
        // width: "20%",
        // height: "50%",
        // width: "100%",
        height: botWidth,
        width: botHeight,

        // backgroundColor: "black",

        position: "absolute",

        left: botX,
        bottom: yPixels,

        zIndex: Z_INDICES.MIDDLE_LAYER_C,

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        transition: CSS_DEFAULT_ANIMATION_TRANSITION,
        alignItems: "center",
      }}
      onClick={() => {
        // if (!isSelf) {
        //   return;
        // }
        // helloWorld();
      }}
    >
      <img
        alt={"bot"}
        className={"no-drag"}
        style={{
          transition: CSS_DEFAULT_ANIMATION_TRANSITION,
          // height: "100%",
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        src={image}
      />
    </div>
  );
};
