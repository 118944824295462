import React /*, { useEffect }*/ from "react";
import { Z_INDICES } from "../../../constants";
import useGameComponent from "../../../utils/effects/useGameComponent";
import { JoyContainer2 } from "./JoyContainer2";
import { getState } from "../../../reactStateManagement";
import { SquareContainer } from "./SquareContainer";
// import { SquareContainer } from "./SquareContainer";

const JoyPad = () => {
  useGameComponent({
    id: "joypad",
  });

  const mobile = getState("mobile");

  // const TEST_MODE = true;

  // if (TEST_MODE) {
  //   return (
  //     <div
  //       id="joypad"
  //       style={{
  //         // width,
  //         // height: MESSAGE_BOX_HEIGHT,

  //         width: "50%",
  //         height: "100%",
  //         // backgroundColor: ",

  //         // position: "absolute",
  //         // bottom: 0,
  //         // left: (windowWidth - MESSAGE_BOX_WIDTH) / 2,
  //         // left: 0,

  //         display: "flex",
  //         flexDirection: "column",
  //         justifyContent: "center",
  //         alignItems: "center",

  //         zIndex: Z_INDICES.TOP_LAYER,

  //         overflow: "hidden",

  //         color: "white",

  //         userSelect: "all",
  //       }}
  //     >
  //       <p id={"joypad-p"}>{"TEST TEXT"}</p>
  //     </div>
  //   );
  // }

  return (
    <div
      id="joypad"
      style={{
        // width,
        // height: MESSAGE_BOX_HEIGHT,

        width: "50%",
        height: "100%",
        // backgroundColor: ",

        // position: "absolute",
        // bottom: 0,
        // left: (windowWidth - MESSAGE_BOX_WIDTH) / 2,
        // left: 0,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        zIndex: Z_INDICES.TOP_LAYER,

        overflow: "hidden",
      }}
    >
      {/* <JoyContainer2 /> */}

      {mobile ? <SquareContainer /> : <JoyContainer2 />}
    </div>
  );
};

export { JoyPad };
