import { EVENT_TYPES } from "../constants";
import { getState } from "../reactStateManagement";
import { addEventToEventSenderEvents } from "./browserRealtime/addEventToEventSenderEvents";
import { getEvent } from "./getEvent";

export const emitEvent = ({ data, type }) => {
  const event = getEvent({
    data,
    type,
  });
  const socket = getState("socket");
  if (!socket) {
    return;
  }
  socket.emit("event", event);
  addEventToEventSenderEvents(event, {
    stateKey: "eventSenderEventsOutput",
    ignoreEventTypes: [EVENT_TYPES.BCHAR_BROADCAST],
  });
};
