import React, { useEffect } from "react";

// { useEffect }
// import {
//   NWORLD_X_PARTITION_PERCENT_DECIMAL,
//   NWORLD_Y_PARTITION_PERCENT_DECIMAL,
// } from "../../worldConstants";
import {
  getAbsoluteXPixelsFromXDecimalPercent,
  getAbsoluteYPixelsFromYDecimalPercent,
  // getXDecimalPercentFromXPixel,
  getXPixelsFromXDecimalPercent,
  // getYPixelsFromYDecimalPercent,
  // getYDecimalPercentFromYPixel,
  // getYPixelsFromYDecimalPercent,
} from "../../coordsMapper";
import {
  CSS_DEFAULT_ANIMATION_TRANSITION,
  NWORLD_ROUND_TO_DECIMAL,
  NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
  // NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
  NWORLD_UNIT_WIDTH_PIXELS,
  // ITEM_TYPE_TO_DATA,
  // NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
} from "../../constants";

import {
  getState,
  setState,
  //setState
} from "../../reactStateManagement";
import colorToHex from "../../utils/visual/colorToHex";
import { roundToDecimal } from "../../utils/roundToDecimal";
// import { isAnyPointInside } from "../../utils/world/isAnyPointInside";

const COLOR = "teal";

const getIfMatrixIsEmpty = (matrix) => {
  // i.e. all black

  for (let i = 0; i < matrix.length; i++) {
    const row = matrix[i];

    for (let j = 0; j < row.length; j++) {
      const datum = row[j];

      if (datum.backgroundColor !== "#000000") {
        return false;
      }
    }
  }

  return true;
};

const TheSquareSelect = ({
  minX,
  minY,
  // nzwH,
  selectSquareTopLeftX,
  selectSquareTopLeftY,
  selectSquareBottomRightX,
  selectSquareBottomRightY,
  selectedItems,
  // width,
}) => {
  // minY = 0;
  const id = "select-square";

  const height = Math.floor(
    getAbsoluteYPixelsFromYDecimalPercent(
      Math.abs(selectSquareBottomRightY - selectSquareTopLeftY)
    )
  );

  const left = getXPixelsFromXDecimalPercent(selectSquareTopLeftX) - minX;

  const width = Math.floor(
    getAbsoluteXPixelsFromXDecimalPercent(
      Math.abs(selectSquareBottomRightX - selectSquareTopLeftX)
    )
  );

  const bottomPixels =
    getAbsoluteYPixelsFromYDecimalPercent(selectSquareTopLeftY);

  useEffect(() => {
    /*  

        next steps:

          1. get dims of select square
          
          e.g. 3x3

          0 1 2
          3 4 5
          6 7 8

          2. order selected items by y and then x

          get data or format:

          [
            [{
              backgroundColor: "red",
            },
            {
              backgroundColor: "red",
            },
            {
              backgroundColor: "red",
            },
          ],
          ...
          ]
          
          (i.e. a 3x3 matrix)

      */

    // typeof size;

    // sortPoints(selectedItemsInsideSelectSquare);

    // const matrix =

    const size = Math.round(height / NWORLD_UNIT_WIDTH_PIXELS);

    // let currentX = selectSquareTopLeftX;
    // let currentY = selectSquareTopLeftY;

    let currentX = roundToDecimal(
      selectSquareTopLeftX,
      NWORLD_ROUND_TO_DECIMAL
    );

    let currentY = roundToDecimal(
      selectSquareTopLeftY,
      NWORLD_ROUND_TO_DECIMAL
    );

    const matrix = [];

    for (let i = 0; i < size; i++) {
      const row = [];

      for (let j = 0; j < size; j++) {
        // get selected item at currentX and currentY (if any)

        const selectedItem = selectedItems.find((selectedItem) => {
          const x = roundToDecimal(
            selectedItem.xDecimalPercent,
            NWORLD_ROUND_TO_DECIMAL
          );

          const y = roundToDecimal(
            selectedItem.yDecimalPercent,
            NWORLD_ROUND_TO_DECIMAL
          );

          if (x === currentX && y === currentY) {
            return true;
          }

          return false;
        });

        if (selectedItem) {
          const datum = {
            backgroundColor: selectedItem.backgroundColor
              ? colorToHex(selectedItem.backgroundColor)
              : "#8B0000",
          };

          row.push(datum);
        } else {
          const datum = {
            backgroundColor: "#000000",
          };

          row.push(datum);
        }

        // currentX += NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;
        currentX = roundToDecimal(
          currentX + NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
          NWORLD_ROUND_TO_DECIMAL
        );
      }

      matrix.push(row);

      currentX = selectSquareTopLeftX;
      // currentY += NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;
      currentY = roundToDecimal(
        currentY + NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
        NWORLD_ROUND_TO_DECIMAL
      );
    }

    // invert y

    const matrixInvertedY = matrix.reverse();

    const matrixIsEmptyResult = getIfMatrixIsEmpty(matrixInvertedY);

    if (matrixIsEmptyResult) {
      setState(["selectSquareTopLeftX"], null);
      setState(["selectSquareTopLeftY"], null);
      setState(["selectSquareBottomRightX"], null);
      setState(["selectSquareBottomRightY"], null);

      setState("pixelSpec", null);
      return;
    }

    setState("pixelSpec", matrixInvertedY);
  }, [
    selectedItems,
    selectSquareTopLeftX,
    selectSquareTopLeftY,
    selectSquareBottomRightX,
    selectSquareBottomRightY,
  ]);

  return (
    <div
      id={id}
      style={{
        // backgroundColor: "blue",

        position: "absolute",

        left,
        // top: nzwH * 0,

        bottom: bottomPixels - minY,

        width,
        height,

        transition: CSS_DEFAULT_ANIMATION_TRANSITION,

        border: `5px solid ${COLOR}`,
        boxSizing: "border-box",

        zIndex: 9999999,
      }}
    ></div>
  );
};

/**
 * SelectRect
 *
 * When you click once it will create the first point of the rectangle (top left corner)
 * When you click again it will create the second point of the rectangle (bottom right corner)
 *
 */
export const SelectSquare = ({
  minX,
  minY,
  nzwH,
  selectedItems,
  //nzwW
}) => {
  // use
  // setState("selectSquareTopLeftX", biggestSquare.topLeftX);
  // setState("selectSquareTopLeftY", biggestSquare.topLeftY);

  const selectSquareTopLeftX = getState("selectSquareTopLeftX");
  const selectSquareTopLeftY = getState("selectSquareTopLeftY");
  const selectSquareBottomRightX = getState("selectSquareBottomRightX");
  const selectSquareBottomRightY = getState("selectSquareBottomRightY");

  if (
    selectSquareTopLeftX === null ||
    selectSquareTopLeftY === null ||
    selectSquareBottomRightX === null ||
    selectSquareBottomRightY === null
  ) {
    return null;
  }

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       selectSquareTopLeftX,
  //       selectSquareBottomRightX,
  //       selectSquareTopLeftY,
  //       selectSquareBottomRightY,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  return (
    <TheSquareSelect
      {...{
        minX,
        minY,
        nzwH,
        selectSquareTopLeftX,
        selectSquareTopLeftY,
        selectSquareBottomRightX,
        selectSquareBottomRightY,
        selectedItems,
      }}
    />
  );
};
