import React from "react";
import { getState, setState } from "../../reactStateManagement";
import {
  COLOR_2,
  COORDS_HEIGHT,
  SLIDE_MENU_WIDTH,
  Z_INDICES,
} from "../../constants";

const WIDTH = SLIDE_MENU_WIDTH;
const HEIGHT = "80%";

const ExitButton = () => {
  const showCoords = getState("showCoords");
  const windowWidth = getState("windowWidth");

  const smoll = windowWidth < 500;

  return (
    <button
      style={{
        position: "absolute",
        top: smoll && showCoords ? COORDS_HEIGHT : 0,
        right: 0,

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: 40,
        height: 40,
        backgroundColor: "black",
        color: "white",

        boxSizing: "border-box",
        border: `2px solid ${COLOR_2}`,

        fontSize: 20,
      }}
      onClick={() => {
        setState(["slideMenuData"], null);
      }}
    >
      <div
        style={{
          fontSize: 24,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          paddingBottom: 4,

          userSelect: "none",
        }}
      >
        {"x"}
      </div>
    </button>
  );
};

const CoordinatesDisplay = ({ x, y, w, h }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "90%",
        height: 25,
        backgroundColor: "darkslateblue",
        color: "white",
      }}
    >
      {`x: ${x} y: ${y} w: ${w} h: ${h}`}
    </div>
  );
};

const LinkBox = ({ text, logoUrl, link }) => {
  let contents;

  if (logoUrl) {
    contents = (
      <img
        src={logoUrl}
        style={{
          width: 25,
          height: 25,
          borderRadius: 10,
          // marginLeft: 10,
        }}
      />
    );
  } else {
    contents = text;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "90%",
        height: 25,
      }}
    >
      <a href={link} target="_blank" rel="noreferrer">
        {contents}
      </a>
    </div>
  );
};

const SlideMenuItem = ({ item }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        // height: "25%",
        height: 100,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          // height: "25%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 25,
            backgroundColor: "black",
          }}
        >
          {item.title}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 75,
            backgroundColor: "black",
          }}
        >
          <img
            src={item.image}
            style={{
              width: 64,
              height: 64,
              borderRadius: 10,
              // marginLeft: 10,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              width: "calc(100% - 64px)",
              height: "100%",
              backgroundColor: "black",
            }}
          >
            {/* <CoordinatesDisplay x={-200} y={0} w={30} h={10} /> */}
            <CoordinatesDisplay x={item.x} y={item.y} w={item.w} h={item.h} />

            <LinkBox
              text={"OpenSea"}
              logoUrl={
                "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/opensea_logo.png"
              }
              link={item.openSeaLink}
            />

            {/* <LinkBox text={"Magic Eden"} link={"#"} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const SlideMenu = () => {
  const slideMenuData = getState(["slideMenuData"]);

  if (!slideMenuData) {
    return null;
  }

  const items = slideMenuData?.items || [];
  // const items = slideMenuData?.items || [
  //   // temporary sample default items
  //   {
  //     title: "Land 177",
  //     image:
  //       "https://s3.amazonaws.com/coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk/pixel-nfts/211.png",
  //     openSeaLink:
  //       "https://opensea.io/assets/matic/0xd70292d3df1c7ee16d395469a3f0e7ba3824d355/177",
  //   },
  // ];

  let slideMenuItems = items.map((item, index) => {
    return <SlideMenuItem key={index} item={item} />;
  });

  return (
    <div
      id={"slide-menu"}
      style={{
        width: "100%",
        height: HEIGHT,
        position: "absolute",
        top: 0,
        left: 0,

        // opacity: 0.8,
        backgroundColor: "transparent",

        zIndex: Z_INDICES.DIALOG_LAYER + 1,
      }}
      onClick={(e) => {
        if (e.target.id === "slide-menu") {
          setState(["slideMenuData"], null);
        }
      }}
    >
      <div
        // alert box similar to native browser alert
        style={{
          width: WIDTH,
          // height: "80%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: COLOR_2,
          color: "white",
          // borderRadius: "5px",
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
          // padding: "20px",
          textAlign: "center",
          opacity: 1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ExitButton />

        <div
          style={{
            height: "90%",
            width: "100%",

            // backgroundColor: "red",

            display: "block",

            overflowY: "scroll",
          }}
        >
          {slideMenuItems}
        </div>
      </div>
    </div>
  );
};

export default SlideMenu;
