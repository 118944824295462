import React from "react";
// import React, { useEffect, useState } from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // EVENT_TYPES
} from "../../../../../../../constants";
import GenericBot6Button from "../../../GenericBot6Button";
import {
  //getState,
  setState,
} from "../../../../../../../reactStateManagement";
import { interact } from "./interact";
import { powerInteract } from "./powerInteract";
// import Bot6DatumDisplay from "./Bot6DatumDisplay";

const Bot6InteractButton = ({
  tokenId,

  width = "50%",
  text = "Interact",
  power = false,
  botoScreenCaptureModeType = null,
  bot6InteractMode,
  disabled = false,
}) => {
  return (
    <div
      style={{
        // width: "100%",

        // maxHeight: 420,
        position: "relative",
        // height: mobile ? 190 : 420,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",
        boxSizing: "border-box",

        //   position: "absolute",
        //   bottom: 0,
        //   left: 0,
        width,
        // height: 30,
        height: "100%",
      }}
    >
      <GenericBot6Button
        text={text}
        width={"100%"}
        height={"100%"}
        maxHeight={"100%"}
        onClick={async () => {
          if (!tokenId) {
            // safeguard, should not happen in normal operation

            console.error("Failed bot2 interact, missing tokenId");

            setState("browserAlertData", {
              message:
                "Boto interact failed, invalid Boto selection, please try again",
            });

            return;
          }

          if (!power) {
            interact({ tokenId });
          } else {
            await powerInteract({
              tokenId,
              botoScreenCaptureModeType,
              bot6InteractMode,
            });
          }
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default Bot6InteractButton;
