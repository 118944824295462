import axios from "axios";
import { setMTokenData } from "../utils/mToken";
import { API_BASE_URL } from "../constants";

// const NFTITEM_BASE_URL = "http://localhost:6969";

const doSignIn = async ({ username, password }) => {
  console.log("signing in with username:", username);
  console.log("signing in with password:", password.length);

  const response = await axios({
    method: "post",
    url: `${API_BASE_URL}/expansive-world/login-m`,
    headers: {
      "Content-Type": "application/json",
      "nftitem-power-key": password,
      "nftitem-address": username,
    },
  });

  // setMTokenData(response.data);

  setMTokenData({
    address: username,
    mToken: response.data.mToken,
    expiry: response.data.expiry,
  });

  return {
    mToken: response.data.mToken,
    expiry: response.data.expiry,
  };
};

export default doSignIn;
