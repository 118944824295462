import { API_BASE_URL } from "../constants";
import axios from "axios";
import { setState } from "../reactStateManagement";
import { getMTokenData } from "../utils/mToken";

export const getUserData = async ({ addToState = false }) => {
  try {
    const mTokenData = getMTokenData();

    if (!mTokenData) {
      return null;
    }

    const { address, mToken } = mTokenData;

    const response = await axios({
      method: "get",
      url: `${API_BASE_URL}/expansive-world/user-data`,
      headers: {
        "Content-Type": "application/json",
        "nftitem-address": address,
        "nftitem-ntoken": mToken,
      },
    });

    console.log(`
        INITIAL USER DATA LOG: ${JSON.stringify(
          {
            responseData: Object.keys(response.data).length,
          },
          null,
          4
        )}
    `);

    if (addToState) {
      setState(["userData"], response.data);
    }

    return response.data;
  } catch (err) {
    console.log("error in getting user data:");
    console.log(err);

    return null;
  }
};
