import { setState } from "../../reactStateManagement";
import { emitEvent } from "../emitEvent";
import getAuth from "../getAuth";
import { getMTokenData } from "../mToken";

const displayMustBeLoggedIn = () => {
  setState("browserAlertData", {
    message: "You must be logged in to perform this action",
  });
};

export const emitMTokenEvent = ({ data, type }) => {
  const auth = getAuth();

  if (!auth) {
    displayMustBeLoggedIn();

    return;
  }

  const mTokenData = getMTokenData();

  if (!mTokenData) {
    displayMustBeLoggedIn();

    return;
  }

  emitEvent({
    type,
    data: {
      auth,
      address: mTokenData.address,
      mToken: mTokenData.mToken,
      ...data,
    },
  });
};
