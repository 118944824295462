import React from "react";
import { COLOR_0, COLOR_1, COLOR_5 } from "../../../../constants";
import { getState, setState } from "../../../../reactStateManagement";

export const Bot6ExpandButton = ({ height = "100%" }) => {
  const bot6PageExpandMode = getState("bot6PageExpandMode");

  return (
    <button
      style={{
        height,
        // backgroundColor: "black",
        width: 30,

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        borderRadius: 10,
      }}
      onClick={() => {
        setState(["bot6PageExpandMode"], !bot6PageExpandMode);
      }}
    >
      {bot6PageExpandMode ? ">" : "<"}
    </button>
  );
};
