// import {
//   getState,
//   //getState,
//   setState,
// } from "../../../../reactStateManagement";
import { getState, setState } from "../../../../reactStateManagement";
import getDoPromiseInQueue from "../../../getDoPromiseInQueue";
import { addRequestIdToCompletedRequestIds } from "../../addRequestIdToCompletedRequestIds";

const doPromiseInQueue = getDoPromiseInQueue();

const addBot6InteractionProcessingReceiptDataToState = ({ data }) => {
  const existingBot6InteractionProcessingReceiptData = getState(
    "bot6InteractionProcessingReceiptData"
  );

  console.log(
    "addBot6InteractionProcessingReceiptDataToState existingBot6InteractionProcessingReceiptData: " +
      JSON.stringify(
        existingBot6InteractionProcessingReceiptData?.length,
        null,
        4
      )
  );

  const partitionKeyToDatum = {};

  for (const datum of existingBot6InteractionProcessingReceiptData) {
    partitionKeyToDatum[datum.partitionKey] = datum;
  }

  const newBot6InteractionProcessingReceiptData =
    data?.result?.bot6InteractionProcessingReceipts?.ultimateResults;

  if (!newBot6InteractionProcessingReceiptData) {
    console.log(
      `error in addBot6InteractionProcessingReceiptDataToState: newBot6InteractionProcessingReceiptData is not defined.`
    );

    throw new Error(
      `error in addBot6InteractionProcessingReceiptDataToState: newBot6InteractionProcessingReceiptData is not defined.`
    );
  }

  console.log(
    "addBot6InteractionProcessingReceiptDataToState newBot6InteractionProcessingReceiptData: " +
      JSON.stringify(newBot6InteractionProcessingReceiptData?.length, null, 4)
  );

  for (const datum of newBot6InteractionProcessingReceiptData) {
    partitionKeyToDatum[datum.partitionKey] = datum;
  }

  const newBot6InteractionProcessingReceiptDataArray =
    Object.values(partitionKeyToDatum);

  const newBot6InteractionProcessingReceiptDataArraySorted =
    newBot6InteractionProcessingReceiptDataArray.sort((a, b) => {
      return b.secondarySortKey - a.secondarySortKey;
    });

  console.log(
    `newBot6InteractionProcessingReceiptDataArraySorted:`,
    newBot6InteractionProcessingReceiptDataArraySorted?.length
  );

  setState(
    ["bot6InteractionProcessingReceiptData"],
    newBot6InteractionProcessingReceiptDataArraySorted
  );
};

const _handleBot6InteractionProcessingReceipts = async ({ data }) => {
  addBot6InteractionProcessingReceiptDataToState({ data });

  if (data && data.requestId) {
    addRequestIdToCompletedRequestIds({
      requestId: data.requestId,
    });
  }
};

const handleBot6InteractionProcessingReceipts = async ({ data }) => {
  await doPromiseInQueue({
    operation: async () => {
      await _handleBot6InteractionProcessingReceipts({ data });
    },
  });
};

export { handleBot6InteractionProcessingReceipts };
