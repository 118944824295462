const getEvent = ({ data, type }) => {
  const time = Date.now();
  const id = `event-${time}`;

  return {
    data,
    datacontenttype: "application/json; charset=utf-8",
    id,
    // source: window.location.href,
    // specversion: "1.0",
    type,
    time: time,
    // dataschema: null,
    // methodName: null,
    // resourceName: null,
    // serviceName: null,
    // subject: null,
  };
};

export { getEvent };
