import { useEffect, useRef } from "react";
// import { getState, setState } from "../../../../reactStateManagement";
import { doTripleMarketplaceThreat } from "./doTripleMarketplaceThreat";

const initialLoadStartMutex = {
  hasFirstStarted: false,
};

export const marketplaceInitialStart = async () => {
  const hasFirstStartedRef = useRef(initialLoadStartMutex);

  useEffect(() => {
    if (hasFirstStartedRef.current.hasFirstStarted) {
      return;
    }
    hasFirstStartedRef.current.hasFirstStarted = true;

    console.log("INITIAL POWER LOAD STARTED");

    Promise.resolve().then(async () => {
      await doTripleMarketplaceThreat();
    });
  }, []);
};
