import {
  POST_MESSAGE_PREFIX,
  TYPE_POWER_CREDENTIALS,
  TYPE_PRE_POWER_CREDENTIALS,
  TYPE_POWER_CREDENTIALS_REQUEST_CANCEL,
} from "../../constants";
import { setState } from "../../reactStateManagement";
import { safeSignIn } from "./safeSignIn";

export const handleIncomingMessage = async (event) => {
  if (
    !event.data ||
    typeof event.data !== "string" ||
    event.data.indexOf(POST_MESSAGE_PREFIX) !== 0
  ) {
    return;
  }

  const messageData = JSON.parse(event.data.slice(POST_MESSAGE_PREFIX.length));

  console.log("Offspring received message:", messageData);

  if (messageData.$type === TYPE_POWER_CREDENTIALS_REQUEST_CANCEL) {
    console.log(`
    
    
      MEGA LOG ${JSON.stringify(
        {
          "CANCELING POWER CREDENTIALS REQUEST": {
            messageData: messageData,
            "Date.now()": Date.now(),
            "JSON.stringify(messageData)": JSON.stringify(messageData),
            null: null,
            4: 4,
          },
        },
        null,
        4
      )}
      )
    
    
    `);

    setState(["shouldSilentCancelPowerCredentialsRequest"], true);
  } else if (messageData.$type === TYPE_PRE_POWER_CREDENTIALS) {
    console.log(`
    
    
      MEGA LOG ${JSON.stringify(
        {
          "REFRESHING POWER CREDENTIALS BOOST WITH PRE": {
            messageData: messageData,
            "Date.now()": Date.now(),
            "JSON.stringify(messageData)": JSON.stringify(messageData),
            null: null,
            4: 4,
          },
        },
        null,
        4
      )}
      )
    
    
    `);

    setState(["powerCredentialsWaitTimeBoostCount"], 18);
  } else if (messageData.$type === TYPE_POWER_CREDENTIALS) {
    setState(["lastPowerCredentialsPostMessageTime"], Date.now());

    await safeSignIn(messageData);
  } else if (messageData.$type === "nftitemland-autoSignIn") {
    console.log("AUTO SIGN IN MESSAGE RECEIVED");
  } else {
    console.log(
      "Offspring received message with unknown type: " +
        JSON.stringify(messageData, null, 2)
    );
  }
};
