import {
  getState,
  //getState,
  setState,
} from "../../../../reactStateManagement";
import getDoPromiseInQueue from "../../../getDoPromiseInQueue";

const doPromiseInQueue = getDoPromiseInQueue();

/*
{
        tokenId,
        bot6InteractionsResult: {
          interactions: [
            {
              partitionKey: "bot6InteractionXXXXXX104XXXXXX1719161154969",
              input: {
                command: {
                  subtype: "INTERACT_VIPOWER",
                  type: "BOTO",
                  value: {
                    contextAIDataIndex: -1,
                    gptSelection: "gpt-4o",
                    promptAIDataIndex: 0,
                    tokenId: "104",
                  },
                },
              },
              lastUpdated: 1719161154969,
              output: {
                commands: [
                  {
                    command: "move",
                    parameters: {
                      direction: "left",
                    },
                  },
                  {
                    command: "move",
                    parameters: {
                      direction: "up",
                    },
                  },
                ],
                costData: {
                  pricePerToken: 0.000059999999999999995,
                  totalTokensUsed: 133,
                },
              },
              secondaryPartitionKey: "bot6InteractionXXXXXX104",
              secondarySortKey: 1719161154969,
              type: "BOT6_INTERACTION",
            },
          ],
          paginationValue: null,
        },
      }
*/

const _handleBot6Interactions = (data) => {
  /*

    new format
    "bot2InteractionsResult": {
        "botNumber": 0,
        "bot2InteractionsResult": {

    new state key:

      botNumberToBot2InteractionData: v({}),
  
  */
  // setState("bot2Interactions", bot2InteractionsResult.action2);
  // setState(
  //   "bot2InteractionsThereAreMoreResults",
  //   bot2InteractionsResult.thereAreMoreResults
  // );

  // console.log(`

  //   BOT6 DATA LOG ${JSON.stringify(
  //     {
  //       data,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  /*

{
  sample data:
    "data": {
        "bot6InteractionsResult": {
            "interactions": [
                {
                    "partitionKey": "bot6InteractionXXXXXX104XXXXXX1719161154969",
                    "input": {
                        "command": {
                            "subtype": "INTERACT_VIPOWER",
                            "type": "BOTO",
                            "value": {
                                "contextAIDataIndex": -1,
                                "gptSelection": "gpt-4o",
                                "promptAIDataIndex": 0,
                                "tokenId": "104"
                            }
                        }
                    },
                    "lastUpdated": 1719161154969,
                    "output": {
                        "commands": [
                            {
                                "command": "move",
                                "parameters": {
                                    "direction": "left"
                                }
                            },
                            {
                                "command": "move",
                                "parameters": {
                                    "direction": "up"
                                }
                            }
                        ],
                        "costData": {
                            "pricePerToken": 0.000059999999999999995,
                            "totalTokensUsed": 133
                        }
                    },
                    "secondaryPartitionKey": "bot6InteractionXXXXXX104",
                    "secondarySortKey": 1719161154969,
                    "type": "BOT6_INTERACTION"
                }
            ],
            "paginationValue": null
        }
    }
}
  */

  // typeof getState;
  // typeof setState;

  const tokenIdToBot6InteractionData = getState("tokenIdToBot6InteractionData");

  const tokenId = data.tokenId;

  const newTokenIdToBot6InteractionData = {
    ...tokenIdToBot6InteractionData,
    // [tokenId]: {
    //   interactions: data.bot6InteractionsResult.interactions,
    //   paginationValue: data.bot6InteractionsResult.paginationValue,
    // },
    [tokenId]: data.bot6InteractionsResult,
  };
  setState("tokenIdToBot6InteractionData", newTokenIdToBot6InteractionData);
};

const handleBot6Interactions = async ({ bot6InteractionsResult }) => {
  await doPromiseInQueue({
    operation: async () => {
      _handleBot6Interactions(bot6InteractionsResult);
    },
  });
};

export { handleBot6Interactions };
