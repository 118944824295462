import { BOT6_INTERACT_MODES } from "../../constants";

export const getDisplayNameFromBot6InteractMode = (bot6InteractMode) => {
  switch (bot6InteractMode) {
    case BOT6_INTERACT_MODES.BUILD:
      return "Build($$)";
    case BOT6_INTERACT_MODES.EXPLORE:
      return "Explore($$)";
    default:
      break;
  }

  return "Interact($)";
};
