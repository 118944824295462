const messagesScrollDown = () => {
  const $ = window.$;

  const $messagesSection = $("#messages-section");

  const scrollDown = () => {
    $messagesSection.scrollTop(2222222);
  };

  setTimeout(scrollDown, 25);
  setTimeout(scrollDown, 50);
  setTimeout(scrollDown, 100);
  setTimeout(scrollDown, 200);
  setTimeout(scrollDown, 300);
  setTimeout(scrollDown, 400);
  setTimeout(scrollDown, 500);
};

export { messagesScrollDown };
