import React, {
  useEffect,

  //useRef,
  useState,
} from "react";
import { connectSocket } from "../../utils/browserRealtime/connectSocket/connectSocket.js";
import { getState } from "../../reactStateManagement.js";
// import { emitEvent } from "../../../../realtimeServer/app/utils/emitEvent.mjs";

// data,
// datacontenttype: "application/json; charset=utf-8",
// id,
// // source: window.location.href,
// // specversion: "1.0",
// type,
// time: time,

const SocketIOComponent = () => {
  // const socketIOComponentLoaded = useRef(false);
  const [hasSecondLoaded, setHasSecondLoaded] = useState(false);
  const [secondLoadingConnectSocket, setSecondLoadingConnectSocket] =
    useState(false);
  const socket = getState("socket");

  useEffect(() => {
    // if (!socketIOComponentLoaded.current) {
    if (!socket) {
      // socketIOComponentLoaded.current = true;
      connectSocket();
      setHasSecondLoaded(true);

      return () => {
        const socket2 = getState("socket");

        if (socket2) {
          socket2.disconnect();
        }
      };
    }
  }, []);

  useEffect(() => {
    if (!hasSecondLoaded) {
      return;
    }

    if (!socket && !secondLoadingConnectSocket) {
      setSecondLoadingConnectSocket(true);
      connectSocket();
      setSecondLoadingConnectSocket(false);
    }
  }, [hasSecondLoaded, socket, secondLoadingConnectSocket]);

  return (
    <div>
      {/* <h1>Socket.IO Component</h1> */}
      {/* <form onSubmit={handleSendMessage}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here..."
        />
        <button type="submit">Send Message</button> */}
      {/* </form> */}
    </div>
  );
};

export default SocketIOComponent;
