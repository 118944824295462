// import { MOBILE_WIDTH_THRESHOLD } from "../../constants";
import { getState, setState } from "../../reactStateManagement";

const chronWindowDims = () => {
  const $ = window.$;

  // add event listener to window

  $(window).resize(() => {
    const width = $(window).width();

    setState(["windowWidth"], width);
    setState(["windowHeight"], $(window).height());

    const mobileWidthThreshold = getState("mobileWidthThreshold");

    if (width < mobileWidthThreshold) {
      setState(["mobile"], true);
    } else {
      setState(["mobile"], false);
    }
  });
};

export { chronWindowDims };
