import {
  DEFAULT_START_X_PERCENT_DECIMAL,
  NWORLD_ROUND_TO_DECIMAL,
  // NWORLD_ROUND_TO_DECIMAL,
  NWORLD_STANDARD_USER_HEIGHT_PERCENT_DECIMAL,
  NWORLD_STANDARD_USER_WIDTH_PERCENT_DECIMAL,
  NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
  // NWORLD_X_PARTITION_PERCENT_DECIMAL,
  // NWORLD_Y_PARTITION_PERCENT_DECIMAL,
  // NWORLD_X_PARTITION_PERCENT_DECIMAL,
  // NWORLD_X_PARTITION_PERCENT_DECIMAL,
} from "../../../worldConstants";
import { getIsColliding } from "./getIsColliding";

// import {
//   // GLOBAL_CONTROL_SCALAR,
//   MAX_Y_MOMENTUM,
//   JUMP_INCREMENT,
//   MAX_MOMENTUM,
//   INCREMENT_CONSTANT,
//   INCREMENT_BOOST_THRESHOLD,
//   INCREMENT_BOOST,
// } from "../bEventLoopConstants";
import { getState } from "../../../reactStateManagement";
// import {
//   getXPartitionDecimalPercentFromXPartitionNumber,
//   getXPartitionNumberFromXDecimalPercent,
// } from "../../world/getNWorldPartitionDataFromXandYDecimalPercent";

// import { emitEvent } from "../../emitEvent";
// import { EVENT_TYPES } from "../../../constants";
// import getAuth from "../../getAuth";

import {
  getPercentStringFromDecimalPercent,
  getXPartitionDecimalPercentFromXPartitionNumber,
  getXPartitionNumberFromXDecimalPercent,
  getYPartitionDecimalPercentFromYPartitionNumber,
  getYPartitionNumberFromYDecimalPercent,
  quantize,
  // getYPixelsFromYDecimalPercent,
} from "../../../coordsMapper";
// import { cachePowerGetNWorldData } from "../../world/cachePowerGetNWorldData";
// import { roundToDecimal } from "../../roundToDecimal";
import { getDataIfMovingBetweenPartitions } from "./getDataIfMovingBetweenPartitions";
import { roundToDecimal } from "../../roundToDecimal";
import { POWER_MODES } from "../../../constants";

const getMomentumIncrementAmount = (
  currentMomentum,
  frequency,
  right,
  INCREMENT_CONSTANT,
  INCREMENT_BOOST_THRESHOLD,
  INCREMENT_BOOST
) => {
  // if near 0, then add a boost for faster acceleration

  /*
  
    MAX_Y_MOMENTUM,
  JUMP_INCREMENT,
  MAX_MOMENTUM,
  INCREMENT_CONSTANT,
  INCREMENT_BOOST_THRESHOLD,
  INCREMENT_BOOST,*/

  const currentMomentumIsNearZero =
    Math.abs(currentMomentum) < INCREMENT_BOOST_THRESHOLD;

  let newMomentumIsContestingExistingMomentum;

  if (right) {
    newMomentumIsContestingExistingMomentum = currentMomentum < 0;
  } else {
    newMomentumIsContestingExistingMomentum = currentMomentum > 0;
  }

  let totalBoost = 0;

  if (currentMomentumIsNearZero) {
    // totalBoost += INCREMENT_BOOST;

    const boostAmount = 4 * INCREMENT_BOOST;

    totalBoost += boostAmount;

    // console.log("BOOST");
  }

  if (newMomentumIsContestingExistingMomentum) {
    // totalBoost += INCREMENT_BOOST * 2;

    const boostAmount = 3 * INCREMENT_BOOST;

    totalBoost += boostAmount;

    // console.log("CONTESTING");
  }

  const modifiedIncrementConstant = INCREMENT_CONSTANT + totalBoost;

  const increment = modifiedIncrementConstant / frequency;

  return increment;
};

const handleBChar = (bChar, { frequency, settings }) => {
  // const worldNumber = 0;
  // const levelNumber = 0;
  //   const newWCharacterData = Object.assign([], wCharacterData);

  // const characterWidthPercentDecimal = NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;
  // const characterHeightPercentDecimal = 2 * NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;
  const powerMode = getState("powerMode");
  const characterWidthPercentDecimal =
    NWORLD_STANDARD_USER_WIDTH_PERCENT_DECIMAL;
  const characterHeightPercentDecimal =
    NWORLD_STANDARD_USER_HEIGHT_PERCENT_DECIMAL;

  // const gravityBoostScalar = powerMode ? 0.5 : 1;
  // const maxMomentumBoostScalar = powerMode ? 2 : 0.8;
  // const jumpIncrementBoostScalar = powerMode ? 5 : 1;

  let gravityBoostScalar;
  let maxMomentumBoostScalar;
  let jumpIncrementBoostScalar;
  let yMomentumBoostScalar;

  switch (powerMode) {
    case POWER_MODES.MOON:
      // gravityBoostScalar = 1;
      // maxMomentumBoostScalar = 0.8;
      // jumpIncrementBoostScalar = 1;

      // ought to be astronomical
      // this means LOW gravity and HIGH jump
      // and SLOW movement

      gravityBoostScalar = 0.1;
      maxMomentumBoostScalar = 0.1;
      jumpIncrementBoostScalar = 1.4;
      yMomentumBoostScalar = 0.1;

      break;
    case POWER_MODES.POWER:
      gravityBoostScalar = 0.5;
      maxMomentumBoostScalar = 2;
      jumpIncrementBoostScalar = 5;
      yMomentumBoostScalar = 1;
      break;
    default:
      gravityBoostScalar = 1;
      maxMomentumBoostScalar = 0.8;
      jumpIncrementBoostScalar = 1;
      yMomentumBoostScalar = 1;
      break;
  }

  const MAX_Y_MOMENTUM = settings.maxYMomentum;
  const GRAVITY = settings.gravity * 0.01 * gravityBoostScalar;
  const JUMP_INCREMENT = settings.jumpIncrement * jumpIncrementBoostScalar;
  const MAX_MOMENTUM = settings.maxMomentum * maxMomentumBoostScalar;
  const INCREMENT_CONSTANT = settings.incrementConstant;
  const INCREMENT_BOOST_THRESHOLD = settings.incrementBoostThreshold;
  const INCREMENT_BOOST = settings.incrementBoost;

  // const partitionIdToKeyToData = await getValue("partitionIdToKeyToData");
  // const worldNumberToLevelNumberToNWorldData = getState(
  //   "worldNumberToLevelNumberToNWorldData"
  // );

  // const nWorldData =
  //   worldNumberToLevelNumberToNWorldData[worldNumber][levelNumber];

  const nWorldData = getState("nWorldData");
  // get all world data

  const wCharacterDatum = Object.assign({}, bChar);

  let rightDownOccurred = false;
  let leftDownOccurred = false;
  let currentXPartitionDecimalPercent = null;
  let xSwitchedPartitionsTo = null; // decimal percent
  let currentYPartitionDecimalPercent = null;
  let ySwitchedPartitionsTo = null; // decimal percent

  const x = wCharacterDatum.x;
  const xNumber = parseFloat(x);
  const xMomentum = wCharacterDatum.xMomentum || 0;
  const xDecimalPercent = xNumber / 100;
  const xPartitionNumber =
    getXPartitionNumberFromXDecimalPercent(xDecimalPercent);
  currentXPartitionDecimalPercent =
    getXPartitionDecimalPercentFromXPartitionNumber(xPartitionNumber);
  const y = wCharacterDatum.y;
  const yNumber = parseFloat(y);
  const yMomentum = wCharacterDatum.yMomentum || 0;
  const yDecimalPercent = yNumber / 100;
  const yPartitionNumber =
    getYPartitionNumberFromYDecimalPercent(yDecimalPercent);
  currentYPartitionDecimalPercent =
    getYPartitionDecimalPercentFromYPartitionNumber(yPartitionNumber);

  /*  
    Before applying action:

    -> check if current user position is colliding with any object
  */

  const isStartingColliding = getIsColliding({
    proposedXNumber: xNumber,
    proposedYNumber: yNumber,
    nWorldData,
    characterWidthPercentDecimal,
    characterHeightPercentDecimal,
  });

  // let ignoreGravity = false;

  if (isStartingColliding) {
    /*
      Ideal Algo:
        search one unit width to the right
        then search one unit width to the left
        then search two unit widths to the right
        then search two unit widths to the left
        ...etc
        (until no collision is found)

        if left is less than 0, then it should never be selected
        if right is greater than 100, then it should never be selected
        
      First Algo:
        move to the right one unit width

      Second Algo:
        move up one unit height
    */

    // const newXDecimalPercent =
    //   xNumber / 100 + NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;

    // wCharacterDatum.x = getPercentStringFromDecimalPercent(newXDecimalPercent);

    // const newYDecimalPercent =
    // yNumber / 100 + NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;

    // wCharacterDatum.y = getPercentStringFromDecimalPercent(newYDecimalPercent);

    // ignoreGravity = true;

    const getNewWCharacterDatumX = () => {
      let right = true;
      let incrementDistance = NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;

      for (let i = 0; i < 1000; i++) {
        let proposedXDecimalPercent;

        if (right) {
          proposedXDecimalPercent = xDecimalPercent + incrementDistance;

          if (proposedXDecimalPercent > 1) {
            proposedXDecimalPercent = 1;
          }
        } else {
          proposedXDecimalPercent = xDecimalPercent - incrementDistance;

          if (proposedXDecimalPercent < 0) {
            proposedXDecimalPercent = 0;
          }
        }

        const isColliding = getIsColliding({
          proposedXNumber: proposedXDecimalPercent * 100,
          proposedYNumber: yNumber,
          nWorldData,
          characterWidthPercentDecimal,
          characterHeightPercentDecimal,
        });

        if (!isColliding) {
          const wCharacterDatumX = getPercentStringFromDecimalPercent(
            proposedXDecimalPercent
          );

          if (i > 500) {
            console.log(
              `[RARE] COLLISION RESOLUTION AT N = ${i} (RIGHT = ${right}) ` +
                `(INCREMENT DISTANCE = ${incrementDistance})`
            );
          }

          return wCharacterDatumX;
        }

        if (right) {
          right = false;
        } else {
          right = true;
          incrementDistance += NWORLD_UNIT_WIDTH_PERCENT_DECIMAL;
        }
      }

      // if no solution found
      console.log("NO X START COLLISION SOLUTION FOUND");
      return x;
    };

    wCharacterDatum.x = getNewWCharacterDatumX();
  }

  if (wCharacterDatum.rightDown) {
    // console.log("EVENT RD");

    const increment = getMomentumIncrementAmount(
      xMomentum,
      frequency,
      true,
      INCREMENT_CONSTANT,
      INCREMENT_BOOST_THRESHOLD,
      INCREMENT_BOOST
    );
    //   const newXMomentum = currentXMomentum + increment;
    let proposedXMomentum = Math.min(xMomentum + increment, MAX_MOMENTUM);
    let proposedX = xNumber + proposedXMomentum;

    if (proposedX < 0) {
      proposedX = 0;
    } else if (proposedX > 100) {
      proposedX = 100;
    }

    const proposedXDecimalPercent = proposedX / 100;

    const proposedXPartitionNumber = getXPartitionNumberFromXDecimalPercent(
      proposedXDecimalPercent
    );

    const switchingPartitions = xPartitionNumber !== proposedXPartitionNumber;

    if (switchingPartitions) {
      const proposedXPartitionDecimalPercent =
        getXPartitionDecimalPercentFromXPartitionNumber(
          proposedXPartitionNumber
        );

      xSwitchedPartitionsTo = proposedXPartitionDecimalPercent;
    }

    let isColliding = getIsColliding({
      proposedXNumber: proposedX,
      proposedYNumber: parseFloat(wCharacterDatum.y),
      nWorldData,
      characterWidthPercentDecimal,
      characterHeightPercentDecimal,
    });

    if (isColliding) {
      proposedX = `${proposedX}%`;
      wCharacterDatum.xMomentum = 0;
    } else {
      wCharacterDatum.x = `${proposedX}%`;
      wCharacterDatum.xMomentum = proposedXMomentum;

      // console.log(`

      //   rightDown LOG ${JSON.stringify(
      //     {
      //       proposedX,
      //       proposedXMomentum: proposedXMomentum * Math.pow(10, 9),
      //     },
      //     null,
      //     4
      //   )}
      //   )

      // `);
    }

    wCharacterDatum.lastDirection = "right";

    rightDownOccurred = true;
  } else if (wCharacterDatum.leftDown) {
    // console.log("EVENT LD");

    const increment = getMomentumIncrementAmount(
      xMomentum,
      frequency,
      false,
      INCREMENT_CONSTANT,
      INCREMENT_BOOST_THRESHOLD,
      INCREMENT_BOOST
    );
    //   const newXMomentum = currentXMomentum - increment;
    const proposedXMomentum = Math.max(xMomentum - increment, -MAX_MOMENTUM);
    let proposedX = xNumber + proposedXMomentum;

    if (proposedX < 0) {
      proposedX = 0;
    } else if (proposedX > 100) {
      proposedX = 100;
    }

    const proposedXDecimalPercent = proposedX / 100;

    const proposedXPartitionNumber = getXPartitionNumberFromXDecimalPercent(
      proposedXDecimalPercent
    );

    const switchingPartitions = xPartitionNumber !== proposedXPartitionNumber;

    if (switchingPartitions) {
      const proposedXPartitionDecimalPercent =
        getXPartitionDecimalPercentFromXPartitionNumber(
          proposedXPartitionNumber
        );

      xSwitchedPartitionsTo = proposedXPartitionDecimalPercent;
    }

    let isColliding = getIsColliding({
      proposedXNumber: proposedX,
      proposedYNumber: parseFloat(wCharacterDatum.y),
      nWorldData,
      characterWidthPercentDecimal,
      characterHeightPercentDecimal,
    });

    // isColliding = false;

    if (isColliding) {
      proposedX = `${proposedX}%`;
      wCharacterDatum.xMomentum = 0;
    } else {
      wCharacterDatum.x = `${proposedX}%`;
      wCharacterDatum.xMomentum = proposedXMomentum;

      // console.log(`

      //   leftDown LOG ${JSON.stringify(
      //     {
      //       proposedX,
      //       proposedXMomentum: proposedXMomentum * Math.pow(10, 9),
      //     },
      //     null,
      //     4
      //   )}
      //   )

      // `);
    }

    wCharacterDatum.lastDirection = "left";

    leftDownOccurred = true;
  }

  // apply friction

  if (!rightDownOccurred && !leftDownOccurred) {
    // console.log("EVENT FRICTION");

    const currentXMomentum = wCharacterDatum.xMomentum || 0;
    const newXMomentum = currentXMomentum * 0.2;
    wCharacterDatum.xMomentum = newXMomentum;
    const x = wCharacterDatum.x;
    const xNumber = parseFloat(x);

    // let newX = xNumber + newXMomentum;

    // friction must contest the momentum

    const sign = currentXMomentum > 0 ? -1 : 1;

    let newX = xNumber + sign * newXMomentum;
    if (newX < 0) {
      newX = 0;
    } else if (newX > 100) {
      newX = 100;
    }

    wCharacterDatum.x = `${newX}%`;
  }

  // i.e. jump button pressed down (jumping up)
  // if (wCharacterDatum.jumpDown) {
  //   // console.log("EVENT JD");

  //   const currentYMomentum = wCharacterDatum.yMomentum || 0;
  //   const increment = JUMP_INCREMENT / frequency;

  //   const proposedYMomentum = Math.max(
  //     currentYMomentum + increment,
  //     MAX_Y_MOMENTUM
  //   );
  //   const proposedY = yNumber + proposedYMomentum;
  //   // wCharacterDatum.y = `${proposedY}%`;
  //   // wCharacterDatum.yMomentum = proposedYMomentum;
  //   const proposedYPartitionNumber = getYPartitionNumberFromYDecimalPercent(
  //     proposedY / 100
  //   );

  //   // check for collisions with object above
  //   const isColliding = getIsColliding({
  //     proposedXNumber: parseFloat(wCharacterDatum.x),
  //     proposedYNumber: proposedY,
  //     nWorldData,
  //     characterWidthPercentDecimal,
  //     characterHeightPercentDecimal,
  //   });

  //   if (isColliding) {
  //     wCharacterDatum.y = `${yNumber}%`;
  //     wCharacterDatum.yMomentum = 0;
  //   } else {
  //     wCharacterDatum.y = `${proposedY}%`;
  //     wCharacterDatum.yMomentum = proposedYMomentum;

  //     const switchingPartitions = yPartitionNumber !== proposedYPartitionNumber;
  //     if (switchingPartitions) {
  //       const newYPartitionDecimalPercent =
  //         getYPartitionDecimalPercentFromYPartitionNumber(
  //           proposedYPartitionNumber
  //         );

  //       ySwitchedPartitionsTo = newYPartitionDecimalPercent;
  //     }
  //   }
  // }

  const X_MOMENTUM_NEAR_ZERO_THRESHOLD = 10e-17;

  if (
    Math.abs(wCharacterDatum.xMomentum) < X_MOMENTUM_NEAR_ZERO_THRESHOLD &&
    wCharacterDatum.xMomentum !== 0
  ) {
    wCharacterDatum.xMomentum = 0;
  }

  // const Y_MOMENTUM_NEAR_ZERO_THRESHOLD = 10e-17;
  // 2.2500000000000003e-9;

  const Y_MOMENTUM_NEAR_ZERO_THRESHOLD = 2.2500000000000003e-9;

  // apply y momentum correction
  if (
    Math.abs(wCharacterDatum.yMomentum) <= Y_MOMENTUM_NEAR_ZERO_THRESHOLD &&
    wCharacterDatum.yMomentum !== 0
  ) {
    // wCharacterDatum.yMomentum = 0;

    // if (wCharacterDatum.yMomentumIsNearZero) {

    if (wCharacterDatum.lastY === wCharacterDatum.y) {
      // console.log("CORRECTING Y MqwOMENTUM");

      wCharacterDatum.yMomentum = 0;
      delete wCharacterDatum.lastY;

      // wCharacterDatum.yMomentumIsNearZero = false;
    } else {
      // wCharacterDatum.yMomentumIsNearZero = true;
      wCharacterDatum.lastY = wCharacterDatum.y;
    }

    // check on second iteration
  }

  typeof MAX_Y_MOMENTUM;

  const mutateWCharacterDatumWithJump = () => {
    const proposedYMomentum =
      ((JUMP_INCREMENT * 8) / frequency) * yMomentumBoostScalar;

    wCharacterDatum.yMomentum = proposedYMomentum;
    let proposedY = roundToDecimal(
      yNumber + proposedYMomentum,
      NWORLD_ROUND_TO_DECIMAL
    );

    // wCharacterDatum.y = `${proposedY}%`;

    const proposedYPartitionNumber = getYPartitionNumberFromYDecimalPercent(
      proposedY / 100
    );

    // console.log("PYPN: ", proposedYPartitionNumber);

    // check for collisions with object above
    const isColliding = getIsColliding({
      proposedXNumber: parseFloat(wCharacterDatum.x),
      proposedYNumber: proposedY,
      nWorldData,
      characterWidthPercentDecimal,
      characterHeightPercentDecimal,
    });

    if (isColliding) {
      wCharacterDatum.y = `${yNumber}%`;
      wCharacterDatum.yMomentum = 0;
    } else {
      if (proposedY < 0) {
        proposedY = 0;
      } else if (proposedY > 100) {
        proposedY = 100;
      }

      wCharacterDatum.y = `${proposedY}%`;

      const switchingPartitions = yPartitionNumber !== proposedYPartitionNumber;
      if (switchingPartitions) {
        const newYPartitionDecimalPercent =
          getYPartitionDecimalPercentFromYPartitionNumber(
            proposedYPartitionNumber
          );

        ySwitchedPartitionsTo = newYPartitionDecimalPercent;
      }
    }
  };

  // const MAX_JUMP_ITERATIONS = 10;
  const MAX_JUMP_ITERATIONS = powerMode === POWER_MODES.MOON ? 100 : 10;

  // // v2 with limited jump
  // if (wCharacterDatum.jumpDown) {
  //   /*
  //     Concept:
  //       1. if y momentum is ~0, then apply jump momentum
  //   */

  //   if (
  //     (!wCharacterDatum.yMomentum || wCharacterDatum.yMomentum === 0) &&
  //     (!wCharacterDatum.jumpCounter || wCharacterDatum.jumpCounter === 0)
  //   ) {
  //     mutateWCharacterDatumWithJump();
  //     wCharacterDatum.jumpCounter = 1;
  //   } else {
  //     if (wCharacterDatum.jumpCounter < MAX_JUMP_ITERATIONS) {
  //       if (wCharacterDatum.yMomentum < 0) {
  //         wCharacterDatum.yMomentum = MAX_JUMP_ITERATIONS;
  //       } else {
  //         const yMomentumIsNotInvalid = !(
  //           !wCharacterDatum.yMomentum ||
  //           wCharacterDatum.yMomentum === 0 ||
  //           wCharacterDatum.yMomentum <= 0
  //         );

  //         if (yMomentumIsNotInvalid) {
  //           mutateWCharacterDatumWithJump();
  //           wCharacterDatum.jumpCounter += 1;
  //         } else {
  //           wCharacterDatum.jumpCounter = 0;
  //         }
  //       }
  //     }
  //   }
  // } else {
  //   if (wCharacterDatum.jumpCounter && wCharacterDatum.yMomentum === 0) {
  //     // delete wCharacterDatum.jumpCounter;
  //     wCharacterDatum.jumpCounter = 0;
  //   }
  // }

  // v3 with limited jump - simplified
  if (wCharacterDatum.jumpDown) {
    /*
      Simplified Strategy:

      - introducing the concept of jumpReady
      - if jumpReady, then apply jump momentum
      - jumpReadyIsSetToTrue, if yMomentum is 0 and lastYMomentum is 0
      - also introducing the concept of jumpedLastIteration
      - if jumpedLastIteration, then apply jump momentum
      - if !jumpedLastIteration, then jump has ended
    */

    const jumpReady =
      !wCharacterDatum.yMomentum && !wCharacterDatum.lastYMomentum;

    // if (jumpReady) {
    //   console.log(`

    //   MEGA LOG ${JSON.stringify(
    //     {
    //       yMomentum: wCharacterDatum.yMomentum,
    //       lastYMomentum: wCharacterDatum.lastYMomentum,
    //     },
    //     null,
    //     4
    //   )}
    //   )

    // `);
    // }

    if (
      jumpReady &&
      !wCharacterDatum.jumpedLastIteration &&
      (!wCharacterDatum.yMomentum || wCharacterDatum.yMomentum === 0) &&
      (!wCharacterDatum.lastYMomentum || wCharacterDatum.lastYMomentum === 0) &&
      wCharacterDatum.lastY === wCharacterDatum.y
    ) {
      mutateWCharacterDatumWithJump();
      wCharacterDatum.jumpedLastIteration = 1;
    } else if (wCharacterDatum.jumpedLastIteration) {
      if (wCharacterDatum.jumpedLastIteration < MAX_JUMP_ITERATIONS) {
        if (wCharacterDatum.yMomentum < 0) {
          wCharacterDatum.yMomentum = MAX_JUMP_ITERATIONS;
        } else {
          const yMomentumIsNotInvalid = !(
            !wCharacterDatum.yMomentum ||
            wCharacterDatum.yMomentum === 0 ||
            wCharacterDatum.yMomentum <= 0
          );

          if (yMomentumIsNotInvalid) {
            mutateWCharacterDatumWithJump();
            wCharacterDatum.jumpedLastIteration += 1;
          }
        }
      }
    }
  } else {
    if (wCharacterDatum.jumpedLastIteration) {
      wCharacterDatum.jumpedLastIteration = MAX_JUMP_ITERATIONS;
    }
  }

  const shouldApplyGravity =
    yNumber > 0 &&
    !(
      wCharacterDatum.jumpDown &&
      wCharacterDatum.jumpedLastIteration < MAX_JUMP_ITERATIONS
    );

  // apply gravity
  if (shouldApplyGravity) {
    // console.log("EVENT GRAVITY");
    // const proposedYMomentum = Math.max(
    //   (yMomentum * 0.8) / frequency - 3,
    //   -MAX_Y_MOMENTUM
    // );

    // typeof yMomentum;
    const INITIAL_AIR_MOMENTUM = GRAVITY;

    let yMomentumToUse = -INITIAL_AIR_MOMENTUM;

    yMomentumToUse = yMomentumToUse * frequency * 1;

    // const proposedYMomentum = Math.min(yMomentum / frequency, -MAX_Y_MOMENTUM);

    const proposedYMomentum = yMomentumToUse;

    // console.log("PYM: ", proposedYMomentum);

    let proposedY = yNumber + proposedYMomentum;
    if (proposedY < 0) {
      proposedY = 0;
    } else if (proposedY > 100) {
      proposedY = 100;
    }

    const proposedYDecimalPercent = proposedY / 100;
    const proposedYPartitionNumber = getYPartitionNumberFromYDecimalPercent(
      proposedYDecimalPercent
    );

    const switchingPartitions = yPartitionNumber !== proposedYPartitionNumber;

    if (switchingPartitions) {
      const proposedYPartitionDecimalPercent =
        getYPartitionDecimalPercentFromYPartitionNumber(
          proposedYPartitionNumber
        );

      ySwitchedPartitionsTo = proposedYPartitionDecimalPercent;
    }

    let isColliding = getIsColliding({
      proposedXNumber: parseFloat(wCharacterDatum.x),
      proposedYNumber: proposedY,
      nWorldData,
      characterWidthPercentDecimal,
      characterHeightPercentDecimal,
    });

    if (isColliding) {
      // proposedY = `${proposedY}%`;

      typeof yMomentum;

      let newYDecimalPercent = quantize(
        proposedY / 100,
        NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
        {
          ceil: true,
        }
      );

      if (newYDecimalPercent < 0) {
        newYDecimalPercent = 0;
      }

      // const newYPercentNumber = newYDecimalPercent * 100;

      wCharacterDatum.y =
        getPercentStringFromDecimalPercent(newYDecimalPercent);
      wCharacterDatum.yMomentum = 0;
    } else {
      wCharacterDatum.y = `${proposedY}%`;
      wCharacterDatum.yMomentum = proposedYMomentum;
    }
  }

  // reset jumpLastIteration if yMomentum is 0
  if (
    wCharacterDatum.yMomentum === 0 &&
    wCharacterDatum.lastYMomentum === 0 &&
    wCharacterDatum.y === wCharacterDatum.lastY &&
    wCharacterDatum.jumpedLastIteration
  ) {
    // console.log("RESET JUMP");
    wCharacterDatum.jumpedLastIteration = 0;
  }

  wCharacterDatum.lastX = x;
  wCharacterDatum.lastY = y;
  wCharacterDatum.lastXMomentum = xMomentum;
  wCharacterDatum.lastYMomentum = yMomentum;

  // const DATA_GET_TEMPORARILY_DISABLED = true;
  const DATA_GET_TEMPORARILY_DISABLED = false;

  if (!DATA_GET_TEMPORARILY_DISABLED) {
    const xMonitorIsANumber = typeof xSwitchedPartitionsTo === "number";
    const yMonitorIsANumber = typeof ySwitchedPartitionsTo === "number";

    if (xMonitorIsANumber || yMonitorIsANumber) {
      let xPartitionDecimalPercentToUse = xMonitorIsANumber
        ? xSwitchedPartitionsTo
        : currentXPartitionDecimalPercent;
      let yPartitionDecimalPercentToUse = yMonitorIsANumber
        ? ySwitchedPartitionsTo
        : currentYPartitionDecimalPercent;

      if (typeof xPartitionDecimalPercentToUse !== "number") {
        xPartitionDecimalPercentToUse = DEFAULT_START_X_PERCENT_DECIMAL;
      }

      getDataIfMovingBetweenPartitions({
        xPartitionDecimalPercentToUse,
        yPartitionDecimalPercentToUse,
        currentXPartitionDecimalPercent,
        currentYPartitionDecimalPercent,
      });

      // console.log(`
      //     MEGA LOG ${JSON.stringify(
      //       {
      //         xMonitorIsANumber,
      //         yMonitorIsANumber,
      //         xSwitchedPartitionsTo,
      //         ySwitchedPartitionsTo,
      //         currentXPartitionDecimalPercent,
      //         currentYPartitionDecimalPercent,
      //         xPartitionDecimalPercentToUse,
      //         yPartitionDecimalPercentToUse,
      //       },
      //       null,
      //       4
      //     )}
      //     )
      //   `);

      // yPartitionDecimalPercentToUse = 0; //temporarily

      // cachePowerxGetNWorldData({
      //   xPartitionDecimalPercent: xPartitionDecimalPercentToUse,
      //   yPartitionDecimalPercent: yPartitionDecimalPercentToUse,
      // });

      // /*
      //   get next,

      //   if positive x increment, then get right partition
      //   ..etc

      // */

      // const xIncrement =
      //   xPartitionDecimalPercentToUse - currentXPartitionDecimalPercent;
      // const thereIsAnXIncrement = xIncrement !== 0;

      // if (thereIsAnXIncrement) {
      //   const xIncrementIsPositive = xIncrement > 0;

      //   if (xIncrementIsPositive) {
      //     // get right partition
      //     // console.log("get right partition");

      //     const nextXPartitionDecimalPercent = roundToDecimal(
      //       xPartitionDecimalPercentToUse + NWORLD_X_PARTITION_PERCENT_DECIMAL,
      //       NWORLD_ROUND_TO_DECIMAL
      //     );

      //     if (nextXPartitionDecimalPercent <= 1) {
      //       cachePxowerGetNWorldData({
      //         xPartitionDecimalPercent: nextXPartitionDecimalPercent,
      //         yPartitionDecimalPercent: yPartitionDecimalPercentToUse,
      //       });
      //     }
      //   } else {
      //     // get left partition
      //     // console.log("get left partition");

      //     const previousXPartitionDecimalPercent = roundToDecimal(
      //       xPartitionDecimalPercentToUse - NWORLD_X_PARTITION_PERCENT_DECIMAL,
      //       NWORLD_ROUND_TO_DECIMAL
      //     );

      //     if (previousXPartitionDecimalPercent >= 0) {
      //       cachePowxerGetNWorldData({
      //         xPartitionDecimalPercent: previousXPartitionDecimalPercent,
      //         yPartitionDecimalPercent: yPartitionDecimalPercentToUse,
      //       });
      //     }
      //   }
      // }

      // const yIncrement =
      //   yPartitionDecimalPercentToUse - currentYPartitionDecimalPercent;

      // const thereIsAYIncrement = yIncrement !== 0;

      // if (thereIsAYIncrement) {
      //   const yIncrementIsPositive = yIncrement > 0;

      //   if (yIncrementIsPositive) {
      //     // get bottom partition
      //     // console.log("get bottom partition");

      //     const nextYPartitionDecimalPercent = roundToDecimal(
      //       yPartitionDecimalPercentToUse + NWORLD_Y_PARTITION_PERCENT_DECIMAL,
      //       NWORLD_ROUND_TO_DECIMAL
      //     );

      //     if (nextYPartitionDecimalPercent <= 1) {
      //       cachePxowerGetNWorldData({
      //         xPartitionDecimalPercent: xPartitionDecimalPercentToUse,
      //         yPartitionDecimalPercent: nextYPartitionDecimalPercent,
      //       });
      //     }
      //   } else {
      //     // get top partition
      //     // console.log("get top partition");

      //     const previousYPartitionDecimalPercent = roundToDecimal(
      //       yPartitionDecimalPercentToUse - NWORLD_Y_PARTITION_PERCENT_DECIMAL,
      //       NWORLD_ROUND_TO_DECIMAL
      //     );

      //     if (previousYPartitionDecimalPercent >= 0) {
      //       cachePxowerGetNWorldData({
      //         xPartitionDecimalPercent: xPartitionDecimalPercentToUse,
      //         yPartitionDecimalPercent: previousYPartitionDecimalPercent,
      //       });
      //     }
      //   }
      // }
    }
  }

  return wCharacterDatum;
};

export default handleBChar;
