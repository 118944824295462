import { browserCreateImage } from "./browserCreateImage.js";

export const uploadImageAIData = async ({ canvasRef, dataUrl }) => {
  let browserCreateImageResult;

  try {
    browserCreateImageResult = await browserCreateImage({ canvasRef, dataUrl });

    console.log("imageKey:");
    console.log(browserCreateImageResult.imageKey);
  } catch (error) {
    console.log("error in uploadImageAIData:");
    console.log(error);

    throw new Error("error in uploadImageAIData: " + error.message);
  }

  return {
    imageKey: browserCreateImageResult.imageKey,
  };
};
