import {
  DIALOG_TYPES,
  // EVENT_TYPES
} from "../../constants.js";
import { getState, setState } from "../../reactStateManagement.js";

const MAX_EVENT_SENDER_EVENTS = 10;

const DEFAULT_STATE_KEY = "eventSenderEvents";
const DEFAULT_IGNORE_EVENT_TYPES = [
  // EVENT_TYPES.CHARACTER_DATA,
  // EVENT_TYPES.BOT_DATA,
  // //
];

export const addEventToEventSenderEvents = (
  event,
  {
    stateKey = DEFAULT_STATE_KEY,
    ignoreEventTypes = DEFAULT_IGNORE_EVENT_TYPES,
  } = {
    stateKey: DEFAULT_STATE_KEY,
    ignoreEventTypes: DEFAULT_IGNORE_EVENT_TYPES,
  }
) => {
  if (ignoreEventTypes.includes(event.type)) {
    return;
  }

  const dialog = getState("dialog");

  if (dialog && dialog.type === DIALOG_TYPES.EVENT_SENDER) {
    const eventSenderEvents = getState(stateKey);

    const newEventSenderEvents = [];

    const startIndex = eventSenderEvents.length - 1;
    // want offset by 1
    const endIndex = Math.max(0, startIndex - MAX_EVENT_SENDER_EVENTS + 1);

    for (let i = startIndex; i >= endIndex; i--) {
      newEventSenderEvents.push(eventSenderEvents[i]);
    }

    newEventSenderEvents.push(event);

    setState([stateKey], newEventSenderEvents);
  }
};
