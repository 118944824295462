import React from "react";
// import { DisplayText } from "./DisplayText";
import {
  //UNIT_WIDTH,
  Z_1,
} from "./nzwConstants";
import {
  NWORLD_STANDARD_USER_HEIGHT_PERCENT_DECIMAL,
  NWORLD_STANDARD_USER_WIDTH_PERCENT_DECIMAL,
  NWORLD_TOTAL_WIDTH,
  NWORLD_TOTAL_HEIGHT,
} from "../../worldConstants";
import {
  CHARACTERS,
  characterToData,
  CSS_DEFAULT_ANIMATION_TRANSITION,
} from "../../constants";

const playerNumberToImageData = {
  0: {
    stationary:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8_a.png",
    motion:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8a_a.png",
  },
  1: {
    stationary:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8_b.png",
    motion:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8a_b.png",
  },
};

const getPlayerImageData = (index, character) => {
  if (character) {
    // if (character === CHARACTERS.ELON) {
    if (
      [CHARACTERS.ELON, CHARACTERS.ZUCC, CHARACTERS.BEARA].includes(character)
    ) {
      return {
        // stationary: characterToData[CHARACTERS.ELON].imageUrlA,
        // motion: characterToData[CHARACTERS.ELON].imageUrlMove,
        stationary: characterToData[character].imageUrlA,
        motion: characterToData[character].imageUrlMove,
      };
    } else if (character === CHARACTERS.DOGE) {
      return {
        stationary: playerNumberToImageData[index].stationary,
        motion: playerNumberToImageData[index].motion,
      };
    }
  }

  const playerImageData = playerNumberToImageData[index];

  if (!playerImageData) {
    return playerNumberToImageData[1];
  }

  return playerImageData;
};

export const NCharBase = ({
  frenXPixels,
  xMin,
  yPixels,
  index = 0,
  rightDown,
  leftDown,
  lastDirection,
  minY,
  botoScreenCaptureMode,
  character,
}) => {
  // if (!character) {
  //   character = CHARACTERS.ZUCC;
  // }

  const userId = 0;

  const playerImageData = getPlayerImageData(index, character);

  const leftOrRightDown = rightDown || leftDown;

  const imageUrl = leftOrRightDown
    ? playerImageData.motion
    : playerImageData.stationary;

  const w = NWORLD_STANDARD_USER_WIDTH_PERCENT_DECIMAL * NWORLD_TOTAL_WIDTH;
  const h = NWORLD_STANDARD_USER_HEIGHT_PERCENT_DECIMAL * NWORLD_TOTAL_HEIGHT;

  let left;

  if (typeof frenXPixels === "number" && typeof xMin === "number") {
    left = frenXPixels - xMin - w / 2;
  } else {
    left = `calc(50% - ${w / 2}px)`;
  }

  const bottom = yPixels - minY;

  // const isCharSpecialCase = character === CHARACTERS.ELON;

  const isCharSpecialCase = [
    CHARACTERS.ELON,
    CHARACTERS.ZUCC,
    CHARACTERS.BEARA,
  ].includes(character);

  const wasRightLastDirection = lastDirection === "right";

  let newImageUrl;

  if (isCharSpecialCase) {
    // TODO:
    if (rightDown) {
      // newImageUrl = wasRightLastDirection
      //   ? characterToData[character].imageUrlMoveRight
      //   : characterToData[character].imageUrlMoveRight;

      newImageUrl = characterToData[character].imageUrlMoveRight;
    } else {
      // newImageUrl = wasRightLastDirection
      //   ? characterToData[character].imageUrlA
      //   : characterToData[character].imageUrlA;

      if (leftDown) {
        newImageUrl = characterToData[character].imageUrlMove;
      } else {
        newImageUrl = wasRightLastDirection
          ? characterToData[character].imageUrlARight
          : characterToData[character].imageUrlA;
      }
    }
  } else {
    newImageUrl = imageUrl;
  }

  // const doubleSize = character === CHARACTERS.ZUCC;
  const doubleSize = [
    CHARACTERS.ELON,
    CHARACTERS.ZUCC,
    CHARACTERS.BEARA,
  ].includes(character);

  let transform;

  if (isCharSpecialCase) {
    if (doubleSize) {
      // transform = "scale(2, 2)";
      // transform = "scale(1.5, 1.5) translateY(-17.5%)";
      // transform = "scale(1.75, 1.75) translateY(-21.25%)";

      let SCALAR; // = 1.85;
      let TRANSLATION;

      switch (character) {
        case CHARACTERS.ELON:
          SCALAR = 1.25;
          // TRANSLATION = (-SCALAR * 100) / 12.5;
          TRANSLATION = -SCALAR * 100 * 0.08;
          break;
        default:
          SCALAR = 1.8;
          TRANSLATION = (-SCALAR * 100) / 8;
          break;
      }

      // const TRANSLATION = (-SCALAR * 100) / 8;
      // transform = `scale(1.95, 1.95) translateY(${TRANSLATION}%)`;
      transform = `scale(${SCALAR}, ${SCALAR}) translateY(${TRANSLATION}%)`;
      // transform = "scale(2, 2) translateY(-25%)";
    } else {
      // transform = "scaleX(1)";
      transform = "scaleX(1)";
    }
  } else {
    transform = wasRightLastDirection ? "scaleX(-1)" : "scaleX(1)";
  }

  return (
    <div
      id={`wchar-${userId}`}
      style={{
        position: "absolute",
        bottom,
        // left: "50%",

        backgroundColor: botoScreenCaptureMode ? "green" : "transparent",

        border: botoScreenCaptureMode ? "5px solid red" : "none",

        boxSizing: "border-box",

        left,
        // left: `calc($5 - ${UNIT_WIDTH / 2}px)`,
        width: w,
        height: h,

        // backgroundColor: "green",
        transition: CSS_DEFAULT_ANIMATION_TRANSITION,
        zIndex: Z_1 + 1,
      }}
    >
      {botoScreenCaptureMode ? (
        <div
          style={{
            color: "white",
            textAlign: "center",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {"U"}
          <br />
          {"S"}
          <br />
          {"E"}
          <br />
          {"R"}
        </div>
      ) : (
        <img
          src={newImageUrl}
          alt="nftitem_user"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            // apply double size if character is zucc
            // width: doubleSize ? "200%" : "100%",
            // height: doubleSize ? "200%" : "100%",

            // marginBottom: doubleSize ? "-50%" : "0",

            // flip if lastDirection is right
            // transform:
            //   !isCharSpecialCase && wasRightLastDirection
            //     ? "scaleX(-1)"
            //     : "scaleX(1)",

            transform,
          }}
        />
      )}
    </div>
  );
};
