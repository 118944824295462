import React from "react";
import { CardboardBox } from "./items/CardboardBox";
import {
  // ITEM_TYPES,
  ITEM_TYPE_TO_DATA,
} from "../../worldConstants";
import {
  getXPixelsFromXDecimalPercent,
  getYPixelsFromYDecimalPercent,
} from "../../coordsMapper";
import { PRE_RENDER_WIDTH as RAW_PRE_RENDER_WIDTH } from "../../constants";
import { getState } from "../../reactStateManagement";
import { TOP_FACTOR } from "./nzwConstants";

export const Items = ({
  items,
  minX,
  minY,
  nzwW,
  nzwH,
  pressedDownOnBuildPlane,
  mobile,
  scale,
  windowHeight,
}) => {
  const selectedItems = getState("selectedItems");

  const PRE_RENDER_WIDTH = RAW_PRE_RENDER_WIDTH;

  const itemsToConsider = items.filter((item) => {
    const itemXPixels = getXPixelsFromXDecimalPercent(item.xDecimalPercent);

    if (itemXPixels < minX - PRE_RENDER_WIDTH) {
      return false;
    }

    if (itemXPixels > minX + nzwW + PRE_RENDER_WIDTH) {
      return false;
    }

    const itemYPixels = getYPixelsFromYDecimalPercent(item.yDecimalPercent);

    const yScaledRangeOffset = (TOP_FACTOR * windowHeight) / scale;

    if (itemYPixels < minY - PRE_RENDER_WIDTH) {
      //  < minY - PRE_RENDER_WIDTH) {
      return false;
    }

    if (itemYPixels > minY + nzwH + PRE_RENDER_WIDTH + yScaledRangeOffset) {
      // > minY + nzwH + PRE_RENDER_WIDTH) {
      return false;
    }

    return true;
  });

  // console.log(`
  //     MEGA LOG: ${JSON.stringify(
  //       {
  //         "Item Count": items.length,
  //         "Items To Consider Count": itemsToConsider.length,
  //       },
  //       null,
  //       4
  //     )}
  // `);

  const itemComponents = itemsToConsider.map((item) => {
    // if (item.type === ITEM_TYPES.CARDBOARD_BOX) {
    if (ITEM_TYPE_TO_DATA[item?.itemType]) {
      const itemIsSelected = !!selectedItems.find(
        (selectedItem) => selectedItem.key === item.key
      );

      return (
        <CardboardBox
          // key={`${item.xDecimalPercent}-${item.yDecimalPercent}-${item.itemType}-${index}`}
          key={`${item.key}`}
          x={item.xDecimalPercent}
          minX={minX}
          y={item.yDecimalPercent}
          minY={minY}
          backgroundColor={item.backgroundColor}
          isSelected={itemIsSelected}
          pressedDownOnBuildPlane={pressedDownOnBuildPlane}
          mobile={mobile}
        />
      );
    }

    return null;
  });

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    >
      {itemComponents}
    </div>
  );
};
