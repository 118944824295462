import React from "react";
import {
  BOT6_PAGE_MODES,
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // EVENT_TYPES,
  // EVENT_TYPES
} from "../../../../../constants";
import { getState, setState } from "../../../../../reactStateManagement";
// import { emitEvent } from "../../../../../utils/emitEvent";
// import getAuth from "../../../../../utils/getAuth";
// import { getMTokenData } from "../../../../../utils/mToken";
// import { getState } from "../../../../../reactStateManagement";

/*
  {
    "data": {
        "bot6Data": {
            "bot6s": [
                {
                    "lastUpdated": 1718966226429,
                    "partitionKey": "bot6XXXXXX0x84040E364001f367E10a068Ae137893d7Dd1C289XXXXXX104",
                    "secondaryPartitionKey": "bot6XXXXXX0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
                    "secondarySortKey": 104,
                    "type": "BOT6"
                }
            ],
            "paginationValue": null
        }
    }

  */

const Bot6Chooser = ({ botDatum, zIndex = "unset" }) => {
  return (
    <div
      style={{
        width: "100%",
        maxHeight: 420,
        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",
        boxSizing: "border-box",
        zIndex,
        // padding: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",

          height: 35,
        }}
      >
        <div
          style={{
            backgroundColor: "#440000",

            height: "100%",

            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >{`Bot ${botDatum.secondarySortKey} (${botDatum.sex})`}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: 45,
        }}
      >
        <button
          style={{
            backgroundColor: "#880000",
            color: "#FFFFFF",

            height: "55%",
            width: "55%",

            boxSizing: "border-box",

            borderRadius: 5,
          }}
          onClick={() => {
            // Add your selection logic here
            // console.log(
            // //   `Selected Bot6 with tokenId ${botDatum.secondarySortKey}`
            // // );

            const tokenId = String(botDatum.secondarySortKey);

            setState(["bot6PageModeTokenId"], tokenId);
            setState(["bot6PageMode"], BOT6_PAGE_MODES.COMMAND_CENTER);
          }}
        >
          {"Select"}
        </button>
      </div>
    </div>
  );
};
export const Bot6DisplayList = ({ zIndex = "unset" }) => {
  // const userData = getState("userData");

  // // const mobile = getState("mobile");

  // if (!userData) {
  //   return;
  // }

  const bot6s = getState("bot6s");

  const bot6sCopy = [
    ...bot6s,
    // {
    //   lastUpdated: 1718966226429,
    //   partitionKey:
    //     "bot6XXXXXX0x84040E364001f367E10a068Ae137893d7Dd1C289XXXXXX104",
    //   secondaryPartitionKey:
    //     "bot6XXXXXX0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
    //   secondarySortKey: 104,
    //   type: "BOT6",
    // },
    // {
    //   lastUpdated: 1718966226429,
    //   partitionKey:
    //     "bot6XXXXXX0x84040E364001f367E10a068Ae137893d7Dd1C289XXXXXX104",
    //   secondaryPartitionKey:
    //     "bot6XXXXXX0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
    //   secondarySortKey: 69,
    //   type: "BOT6",
    // },
  ];

  const bot6Choosers = bot6sCopy.map((botDatum) => {
    return <Bot6Chooser key={botDatum.secondarySortKey} botDatum={botDatum} />;
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        // maxHeight: 420,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        zIndex,

        display: "block",

        overflowY: "scroll",
      }}
    >
      {bot6Choosers}
    </div>
  );
};
