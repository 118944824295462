import React, { useEffect } from "react";
import {
  COLOR_4 as COLOR,
  COLOR_0,
  COLOR_1,
  COLOR_2,
  COLOR_5,
  // EVENT_TYPES,
} from "../../constants";
// import {
//   // getState,
//   setState,
// } from "../../reactStateManagement";
// import { getDecimalPercentFromPercentString } from "../../coordsMapper";
// import { emitEvent } from "../../utils/emitEvent";
// import getAuth from "../../utils/getAuth";
// import handleCmdMessage from "../NewControls/NMessage/handleCmdMessage";

// const IMAGE_TO_PIXELS_HEIGHT = 260;
// const TITLE_HEIGHT = IMAGE_TO_PIXELS_HEIGHT / 2 / 3;
// const IMAGE_UPLOADER_HEIGHT = IMAGE_TO_PIXELS_HEIGHT / 2 / 3;
// const IMAGE_DISPLAY_CANVAS_HEIGHT = IMAGE_TO_PIXELS_HEIGHT / 2;
// const IMAGE_TO_PIXELS_SECTION_HEIGHT = IMAGE_TO_PIXELS_HEIGHT / 2 / 3;

// const IMAGE_DISPLAY_CANVAS_ID = "image-display-canvas";
// const IMAGE_UPLOADER_ID = "image-uploader";

// const MAX_SIZE = 100;

import {
  // IMAGE_TO_PIXELS_HEIGHT,
  TITLE_HEIGHT,
  IMAGE_UPLOADER_HEIGHT,
  IMAGE_DISPLAY_CANVAS_HEIGHT,
  // IMAGE_TO_PIXELS_SECTION_HEIGHT,
  IMAGE_DISPLAY_CANVAS_ID,
  // MAX_SIZE,
  IMAGE_UPLOADER_ID,
} from "./imageToPixelsConstants";
import ImageToPixelsSection from "./ImageToPixelSection";
import { getState, setState } from "../../reactStateManagement";

const Title = () => {
  return (
    <div
      style={{
        width: "100%",
        height: TITLE_HEIGHT,
        backgroundColor: COLOR_0,
        color: COLOR_1,

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        boxSizing: "border-box",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: COLOR_5,
      }}
    >
      {/* <RemoveImageButton setImage={() => {}} /> */}
      {"Image to Pixels"}
    </div>
  );
};

const ImageUploader = ({ image, setImage }) => {
  useEffect(() => {
    const TEST_SKIP = false;
    // const TEST_SKIP = true;

    if (TEST_SKIP) {
      return;
    }

    if (!image) {
      // clear canvas

      const canvas = document.getElementById(IMAGE_DISPLAY_CANVAS_ID);

      const context = canvas.getContext("2d");

      context.clearRect(0, 0, canvas.width, canvas.height);

      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.getElementById(IMAGE_DISPLAY_CANVAS_ID);

        const context = canvas.getContext("2d");

        if (canvas.width !== img.width) {
          canvas.width = img.width;
        }

        if (canvas.height !== img.height) {
          canvas.height = img.height;
        }

        // if (img.width > MAX_SIZE || img.height > MAX_SIZE) {
        //   const scale = Math.min(MAX_SIZE / img.width, MAX_SIZE / img.height);

        //   context.drawImage(img, 0, 0, img.width * scale, img.height * scale);

        //   return;
        // }

        context.drawImage(img, 0, 0);
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(image);
  }, [image]);

  useEffect(() => {
    // if image in state, put it in image uploader

    if (image) {
      try {
        const imageUploader = document.getElementById(IMAGE_UPLOADER_ID);

        /*
      account for this:
      Failed to set the 'files' property on 'HTMLInputElement': Failed to convert value to 'FileList'.
TypeError: Failed to set the 'files' property on 'HTMLInputElement': Failed to convert value to 'FileList'.
      */

        const file = new File([image], image.name, {
          type: image.type,
        });

        const fileList = new DataTransfer();

        fileList.items.add(file);

        imageUploader.files = fileList.files;
      } catch (error) {
        console.log("[ignorable] error in setting image in image uploader:");
        console.log(error);
      }
    }
  }, [image]);

  return (
    <div
      style={{
        width: "100%",
        height: IMAGE_UPLOADER_HEIGHT,
        backgroundColor: COLOR_0,
        color: COLOR_1,

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        boxSizing: "border-box",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: COLOR_5,
      }}
    >
      <input
        id={IMAGE_UPLOADER_ID}
        // type="file"
        // image only
        type="file"
        accept="image/*"
        onChange={(e) => {
          const file = e.target.files[0];

          setImage(file);
        }}
      />
    </div>
  );
};

const RemoveImageButton = ({ image, setImage }) => {
  // const showCoords = getState("showCoords");
  // const windowWidth = getState("windowWidth");

  // const smoll = windowWidth < 500;

  if (!image) {
    return null;
  }

  return (
    <button
      style={{
        position: "absolute",
        top: 2,
        right: 2,

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: 40,
        height: 40,
        backgroundColor: COLOR_0,
        color: COLOR_1,

        boxSizing: "border-box",
        border: `2px solid ${COLOR_2}`,

        fontSize: 20,
        zIndex: 2,
      }}
      onClick={() => {
        setImage(null);

        // clear canvas

        const canvas = document.getElementById(IMAGE_DISPLAY_CANVAS_ID);

        const context = canvas.getContext("2d");

        context.clearRect(0, 0, canvas.width, canvas.height);

        // remove image from image uploader

        const imageUploader = document.getElementById(IMAGE_UPLOADER_ID);

        imageUploader.value = null;
      }}
    >
      <div
        style={{
          fontSize: 24,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          paddgBottom: 4,

          userSelect: "none",
        }}
      >
        {"x"}
      </div>
    </button>
  );
};

const ToggleBar = ({ text, onClick, image, active }) => {
  if (!image) {
    return null;
  }

  return (
    <button
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,

        width: "100%",
        height: 40,
        backgroundColor: active ? COLOR_2 : COLOR_0,
        color: COLOR_1,

        boxSizing: "border-box",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: COLOR_5,

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        zIndex: 2,
      }}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

const ImageDisplayCanvas = ({ image, setImage }) => {
  const imageToPixelsPutBlackPixels = getState("imageToPixelsPutBlackPixels");

  return (
    <div
      style={{
        width: "100%",
        height: IMAGE_DISPLAY_CANVAS_HEIGHT,
        backgroundColor: COLOR_0,
        color: COLOR_1,

        position: "relative",
        display: "block",
        overflow: "scroll",

        boxSizing: "border-box",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: COLOR_5,
      }}
    >
      <RemoveImageButton image={image} setImage={setImage} />
      <ToggleBar
        text={"Put Black Pixels"}
        onClick={() => {
          setState(
            ["imageToPixelsPutBlackPixels"],
            !imageToPixelsPutBlackPixels
          );
        }}
        image={image}
        active={imageToPixelsPutBlackPixels}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: COLOR_0,
          color: COLOR_1,

          position: "relative",
          display: "block",
          overflow: "scroll",

          boxSizing: "border-box",
          borderStyle: "solid",
          borderWidth: 2,
          borderColor: COLOR_5,

          zIndex: 1,
        }}
      >
        <canvas id={IMAGE_DISPLAY_CANVAS_ID} />
      </div>
    </div>
  );
};

export const ImageToPixels = () => {
  // const [image, setImage] = useState(null);
  const image = getState("imageToPixelsImage");

  const setImage = (image) => {
    setState(["imageToPixelsImage"], image);
  };

  return (
    <div
      style={{
        width: "100%",
        height: 300,

        backgroundColor: COLOR,
      }}
    >
      <Title />

      <ImageUploader image={image} setImage={setImage} />

      <ImageDisplayCanvas image={image} setImage={setImage} />

      <ImageToPixelsSection image={image} />

      <ImageToPixelsSection image={image} mintDirect={true} />
    </div>
  );
};
