import React from "react";
import { getState } from "../../../reactStateManagement";
// import { emitEvent } from "../../../utils/emitEvent";
// import getAuth from "../../../utils/getAuth";

export const usePress = (key, type, down = false) => {
  React.useEffect(() => {
    const $ = window.$;

    const handle = (event) => {
      const focus = getState("focus");

      // console.log(`

      //   MEGA LOG ${JSON.stringify(
      //     {
      //       "HANDLING KEY PRESS": "CORE",
      //     },
      //     null,
      //     4
      //   )}
      //   )

      // `);

      if (focus) {
        return;
      }
      if (event.key === key) {
        // console.log(`PRESS ${key} ${type}`);
        // const auth = getAuth(true);

        // if (!auth) {
        //   return;
        // }

        // emitEvent({
        //   data: {
        //     auth,
        //   },
        //   type,
        // });

        // Emit bEvent
        const $ = window.$;

        const $body = $("body");

        if (!$body.length) {
          return;
        }

        $body.trigger(type);
      }
    };

    const eventName = down ? "keydown" : "keyup";

    // $(document).on(eventName, handle);
    // return () => {
    //   $(document).off(eventName, handle);
    // };
    // get core to use instead of document
    const core = $("body");

    if (!core.length) {
      return;
    }

    core.on(eventName, handle);

    // window.onload = () => {
    //   core.on(eventName, handle);
    // };
    return () => {
      core.off(eventName, handle);
    };
  }, []);
};
