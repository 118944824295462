import { getUserData } from "../../api/getUserData";
import { EVENT_TYPES } from "../../constants";
import { setState } from "../../reactStateManagement";
import { NWORLD_MAX_PIXEL_ART_SIZE } from "../../worldConstants";
import delay from "../delay";
import { emitEvent } from "../emitEvent";
import getAuth from "../getAuth";
import getDoPromiseInQueue from "../getDoPromiseInQueue";
import { getMTokenData } from "../mToken";

const doPromiseInQueue = getDoPromiseInQueue();

const refreshUserDataForSomeTimeCore = async () => {
  // exponential refresh

  console.log("load 0");
  await getUserData({ addToState: true });

  await delay(1000);

  console.log("load 1");
  await getUserData({ addToState: true });

  await delay(10000);

  console.log("load 2");
  await getUserData({ addToState: true });

  await delay(4000);

  console.log("load 3");
  await getUserData({ addToState: true });

  await delay(8000);

  console.log("load 4");
  await getUserData({ addToState: true });

  await delay(16000);

  console.log("load 5");
  await getUserData({ addToState: true });

  await delay(16000);

  console.log("load 6");
  await getUserData({ addToState: true });

  await delay(16000);

  console.log("load 7");
  await getUserData({ addToState: true });

  await delay(16000);

  console.log("load 8");
  await getUserData({ addToState: true });

  await delay(16000);

  console.log("load 9");
  await getUserData({ addToState: true });

  await delay(16000);

  console.log("load 10");
  await getUserData({ addToState: true });

  await delay(16000);

  console.log("load 11");
  await getUserData({ addToState: true });
};

const refreshUserDataForSomeTime = async () => {
  await doPromiseInQueue({
    operation: async () => {
      try {
        await refreshUserDataForSomeTimeCore();
      } catch (error) {
        console.log("error in refreshUserDataForSomeTime");
        console.log(error);
      }
    },
  });
};

export const doBrowserMint = async ({ pixelSpecData }) => {
  //   console.log(`

  //     MEGA LOG ${JSON.stringify(
  //       {
  //         pixelSpecData,
  //       },
  //       null,
  //       4
  //     )}
  //     )

  // `);

  const mTokenData = getMTokenData();

  if (!mTokenData) {
    // alert("Please login first");

    setState(["browserAlertData"], {
      message: "Please login first",
    });

    return;
  }

  const { address, mToken } = mTokenData;

  const auth = getAuth();

  const size = pixelSpecData.length;

  const MAX_SIZE = NWORLD_MAX_PIXEL_ART_SIZE;

  if (size > MAX_SIZE) {
    alert(
      `Max size is currently ${MAX_SIZE}x${MAX_SIZE} - ` +
        `selected size is ${size}x${size}`
    );

    return;
  }

  const pixelSpecComplete = {
    width: size,
    height: size,
    data: pixelSpecData,
  };

  emitEvent({
    type: EVENT_TYPES.MINT_NFT,
    data: {
      auth,
      address,
      mToken,
      pixelSpec: pixelSpecComplete,
    },
  });

  refreshUserDataForSomeTime();
};
