const BOTO_GENERALIZED_COMMAND_V1_PREFIX_STRING =
  "BOTOCOMMAND_GENERALIZED_COMMAND_V1_|";
const BOTO_GENERALIZED_COMMAND_V1_SUFFIX_STRING = "|";

const extractBotoGeneralizedCommandV1Data = (input) => {
  const prefixValid = input.startsWith(
    BOTO_GENERALIZED_COMMAND_V1_PREFIX_STRING
  );

  if (!prefixValid) {
    return null;
  }

  const suffixValid = input.endsWith(BOTO_GENERALIZED_COMMAND_V1_SUFFIX_STRING);

  if (!suffixValid) {
    return null;
  }

  const data = input.slice(
    BOTO_GENERALIZED_COMMAND_V1_PREFIX_STRING.length,
    input.length - BOTO_GENERALIZED_COMMAND_V1_SUFFIX_STRING.length
  );

  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

export default extractBotoGeneralizedCommandV1Data;
