import React from "react";
import {
  COLOR_2,
  COLOR_4,
  DEFAULT_START_X_PERCENT_DECIMAL,
  EVENT_TYPES,
  NWORLD_ROUND_TO_DECIMAL,
  NWORLD_X_PARTITION_PERCENT_DECIMAL,
  Z_INDICES,
} from "../../../constants";
import { getState, setState } from "../../../reactStateManagement";
import { emitEvent } from "../../../utils/emitEvent";
import getAuth from "../../../utils/getAuth";
import delay from "../../../utils/delay";
import {
  getXCoordFromXDecimalPercent,
  getYCoordFromYDecimalPercent,
} from "../../../coordsMapper";
import { roundToDecimal } from "../../../utils/roundToDecimal";

// getXCoordFromXDecimalPercent
// getYCoordFromYDecimalPercent
/*
  FORM A:

        "tokenIdToLandData": {
            "177": {
                "partitions": [
                    {
                        "xPartitionDecimalPercent": 0.49999999425,
                        "yPartitionDecimalPercent": 0
                    },
                    {
                        "xPartitionDecimalPercent": 0.4999999945,
                        "yPartitionDecimalPercent": 0
                    },
                    {
                        "xPartitionDecimalPercent": 0.49999999475,
                        "yPartitionDecimalPercent": 0
                    }
                ]

  FORM B:

      "items": [
        {
          "title": "Land 177",
          "image": "https://s3.amazonaws.com/coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk/pixel-nfts/211.png",
          "openSeaLink": "https://opensea.io/assets/matic/0xd70292d3df1c7ee16d395469a3f0e7ba3824d355/177",
          "x": -200,
          "y": 0,
          "w": 30,
          "h": 10
        }
      ]
*/

const getFormBFromFormA = (formALandMappings) => {
  const items = Object.keys(formALandMappings)
    .map((tokenId) => {
      try {
        const partitions = formALandMappings[tokenId].partitions;

        // console.log(`

        //   MEGA LOG ${JSON.stringify(
        //     {
        //       tokenId,
        //       data: formALandMappings[tokenId],
        //     },
        //     null,
        //     4
        //   )}
        //   )

        // `);

        let minXDecimalPercent;
        let maxXDecimalPercent;
        let minYDecimalPercent;
        let maxYDecimalPercent;

        for (let i = 0; i < partitions.length; i++) {
          const partition = partitions[i];

          const xPartitionDecimalPercent = partition.xPartitionDecimalPercent;
          const yPartitionDecimalPercent = partition.yPartitionDecimalPercent;

          if (i === 0) {
            minXDecimalPercent = xPartitionDecimalPercent;
            maxXDecimalPercent = xPartitionDecimalPercent;
            minYDecimalPercent = yPartitionDecimalPercent;
            maxYDecimalPercent = yPartitionDecimalPercent;
          } else {
            if (xPartitionDecimalPercent < minXDecimalPercent) {
              minXDecimalPercent = xPartitionDecimalPercent;
            } else if (xPartitionDecimalPercent > maxXDecimalPercent) {
              maxXDecimalPercent = xPartitionDecimalPercent;
            }

            if (yPartitionDecimalPercent < minYDecimalPercent) {
              minYDecimalPercent = yPartitionDecimalPercent;
            } else if (yPartitionDecimalPercent > maxYDecimalPercent) {
              maxYDecimalPercent = yPartitionDecimalPercent;
            }
          }
        }

        const x = getXCoordFromXDecimalPercent(minXDecimalPercent);
        const y = getYCoordFromYDecimalPercent(minYDecimalPercent);

        const partitionCoordsMagnitude = getXCoordFromXDecimalPercent(
          DEFAULT_START_X_PERCENT_DECIMAL + NWORLD_X_PARTITION_PERCENT_DECIMAL
        );

        const w = roundToDecimal(
          getXCoordFromXDecimalPercent(maxXDecimalPercent) -
            x +
            partitionCoordsMagnitude,

          NWORLD_ROUND_TO_DECIMAL
        );
        const h = roundToDecimal(
          getYCoordFromYDecimalPercent(maxYDecimalPercent) -
            y +
            partitionCoordsMagnitude,
          NWORLD_ROUND_TO_DECIMAL
        );

        return {
          title: `Land ${tokenId}`,
          image:
            "https://s3.amazonaws.com/coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk/pixel-nfts/211.png",
          openSeaLink: `https://opensea.io/assets/matic/0xd70292d3df1c7ee16d395469a3f0e7ba3824d355/${tokenId}`,
          x,
          y,
          w,
          h,
        };
      } catch (error) {
        console.error("Error in getFormBFromFormA:", error);

        return null;
      }
    })
    .filter((item) => item);

  return {
    items,
  };
};

const waitForLandMappings = async () => {
  for (let i = 0; i < 10; i++) {
    const landMappings = getState("landMappings");

    if (landMappings) {
      return landMappings;
    }

    await delay(500);
  }

  return false;
};

const getLandMappingsComplete = async () => {
  const auth = getAuth(true);

  if (!auth) {
    return false;
  }

  emitEvent({
    type: EVENT_TYPES.GET_LAND_MAPPINGS,
    data: {
      auth,
    },
  });

  const landMappings = await waitForLandMappings();

  return landMappings;
};

const ShowLandsToggle = () => {
  const slideMenuData = getState("slideMenuData");
  const mobile = getState("mobile");

  const fontSize = mobile ? 12 : "unset";

  return (
    <div
      style={{
        width: "25%",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        // backgroundColor: "purple",

        zIndex: Z_INDICES.TOP_LAYER,
      }}
    >
      <button
        style={{
          width: "100%",
          height: "100%",
          // backgroundColor: "blue",

          backgroundColor: slideMenuData ? COLOR_4 : COLOR_2,
          color: "white",
          fontSize,
        }}
        onClick={async () => {
          if (slideMenuData) {
            setState(["slideMenuData"], null);
            return;
          }

          const landMappings = await getLandMappingsComplete();

          if (landMappings && landMappings.tokenIdToLandData) {
            // temporarily hardcoding the land data
            // setState(["slideMenuData"], {
            //   items: [
            //     {
            //       title: "Land 177",
            //       image:
            //         "https://s3.amazonaws.com/coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk/pixel-nfts/211.png",
            //       openSeaLink:
            //         "https://opensea.io/assets/matic/0xd70292d3df1c7ee16d395469a3f0e7ba3824d355/177",
            //       x: -200,
            //       y: 0,
            //       w: 30,
            //       h: 10,
            //     },
            //   ],
            // });

            const formB = getFormBFromFormA(landMappings.tokenIdToLandData);

            setState(["slideMenuData"], formB);
          }
        }}
      >
        {"Land"}
      </button>
    </div>
  );
};

export { ShowLandsToggle };
