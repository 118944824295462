import React from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // MARKETPLACE_MODES,
} from "../../../../../constants";
import { getActionables } from "../getActionsables";
// import { getState, setState } from "../../../../reactStateManagement";

export const RefreshActionablesButton = () => {
  return (
    <button
      style={{
        width: 60,
        height: 40,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",
      }}
      onClick={getActionables}
    >
      {"Refresh"}
    </button>
  );
};
