import React, { useEffect } from "react";

import { SignInBox } from "./SignInBox";
// import { getMTokenData } from "../../src/compute/coreMinter/lib/CoreMinterStack/dockernftitem/app/src/utils/mToken";
// import { getUserData } from "../../src/compute/coreMinter/lib/CoreMinterStack/dockernftitem/app/src/api/getUserData";
import { SignedInBox } from "./SignedInBox";
import {
  COLOR_0,
  COLOR_1,
  COLOR_2,
  COLOR_5,
  COORDS_HEIGHT,
  EXECUTIVE_BOX_MODES,
  SLIDE_MENU_WIDTH,
  STANDARD_BUTTON_HEIGHT,
  // COORDS_HEIGHT,
  TOP_FACTOR,
  Z_INDICES,
} from "../../../constants";
import { getState, setState } from "../../../reactStateManagement";
import { ShowCoordsToggle } from "./ShowCoordsToggle";
import { SetRespawnButton } from "./SetRespawnButton";
import { ShowLandsToggle } from "./ShowLandsToggle";
import { ImageToPixels } from "../../ImageToPixels";
import AdvBuild from "./AdvBuild";
import Marketplace from "./Marketplace";
import StagingComponent from "../../../StagingComponent";
import Wearables from "./Wearables";
import Bot222 from "./Bot222";
import Bot6Page from "./Bot6Page";
import MintPage from "./MintPage";
import CharacterSelect from "./CharacterSelect";
// import StagingComponent from "../../../StagingComponent";

const ExecutiveBoxModeSelectButton = ({ text, mode, zIndex = "unset" }) => {
  return (
    <button
      style={{
        width: "100%",
        height: STANDARD_BUTTON_HEIGHT,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        zIndex,
      }}
      onClick={() => {
        setState(["executiveBoxMode"], mode);
      }}
    >
      {text}
    </button>
  );
};

const MOBILE_MODE_WIDTH_THRESHOLD = 700;
const ExecutiveBox = () => {
  const windowWidth = getState("windowWidth");
  const windowHeight = getState("windowHeight");
  const loading = getState("loading");
  const userData = getState("userData");
  const showCoords = getState("showCoords");
  const executiveBoxMode = getState("executiveBoxMode");
  const marketplaceExpandMode = getState("marketplaceExpandMode");
  const bot6PageExpandMode = getState("bot6PageExpandMode");

  const [executiveBoxHeight, setExecutiveBoxHeight] = React.useState(200);

  const mobileMode = windowWidth < MOBILE_MODE_WIDTH_THRESHOLD;
  const fullMarketplaceExpandMode =
    marketplaceExpandMode &&
    executiveBoxMode === EXECUTIVE_BOX_MODES.MARKETPLACE;
  const fullBot6ExpandMode =
    bot6PageExpandMode && executiveBoxMode === EXECUTIVE_BOX_MODES.BOT6;

  useEffect(() => {
    const handleResize = () => {
      const $ = window.$;

      const executiveBox = $("#executive-box");

      if (executiveBox.length === 0) {
        return;
      }

      const executiveBoxHeight = executiveBox.height();

      setExecutiveBoxHeight(executiveBoxHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const contents = [];
  const bottomContents = [];

  switch (executiveBoxMode) {
    case EXECUTIVE_BOX_MODES.IMAGE_TO_PIXELS:
      contents.push(<ImageToPixels key="image-to-pixels" />);

      bottomContents.push(
        <ExecutiveBoxModeSelectButton
          key="back-button"
          text="← Back"
          mode={null}
        />
      );
      break;
    case EXECUTIVE_BOX_MODES.ADV_BUILD:
      contents.push(<AdvBuild key="advanced-build" />);

      bottomContents.push(
        <ExecutiveBoxModeSelectButton
          key="back-button"
          text="← Back"
          mode={null}
        />
      );
      break;
    case EXECUTIVE_BOX_MODES.CHARACTER_SELECT:
      contents.push(
        <CharacterSelect
          key="character-select"
          executiveBoxHeight={executiveBoxHeight}
        />
      );

      bottomContents.push(
        <ExecutiveBoxModeSelectButton
          key="back-button"
          text="← Back"
          mode={null}
        />
      );
      break;
    case EXECUTIVE_BOX_MODES.MARKETPLACE:
      contents.push(
        <Marketplace
          key="marketplace"
          fullMarketplaceExpandMode={fullMarketplaceExpandMode}
        />
      );

      bottomContents.push(
        <ExecutiveBoxModeSelectButton
          key="back-button"
          text="← Back"
          mode={null}
        />
      );
      break;

    case EXECUTIVE_BOX_MODES.BOT222:
      contents.push(<Bot222 key="bot222" zIndex={1} />);

      bottomContents.push(
        <ExecutiveBoxModeSelectButton
          key="back-button"
          text="← Back"
          mode={null}
          zIndex={2}
        />
      );

      break;

    case EXECUTIVE_BOX_MODES.BOT6:
      contents.push(
        <Bot6Page
          key="bot6-page"
          zIndex={1}
          executiveBoxHeight={executiveBoxHeight}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
        />
      );

      bottomContents.push(
        <ExecutiveBoxModeSelectButton
          key="back-button"
          text="← Back"
          mode={null}
          zIndex={2}
        />
      );

      break;

    case EXECUTIVE_BOX_MODES.MINT:
      contents.push(
        <MintPage
          key="mint-page"
          zIndex={2}
          executiveBoxHeight={executiveBoxHeight}
          // windowWidth={windowWidth}
          // windowHeight={windowHeight}
        />
      );

      bottomContents.push(
        <ExecutiveBoxModeSelectButton
          key="back-button"
          text="← Back"
          mode={null}
          zIndex={2}
        />
      );

      break;

    case EXECUTIVE_BOX_MODES.WEARABLES:
      contents.push(<Wearables key="wearables" />);

      bottomContents.push(
        <ExecutiveBoxModeSelectButton
          key="back-button"
          text="← Back"
          mode={null}
        />
      );
      break;

    default:
      contents.push(
        <div
          key="world-buttons"
          style={{
            width: "100%",
            height: COORDS_HEIGHT,
            overflow: "hidden",

            backgroundColor: COLOR_0,
            color: COLOR_1,

            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginLeft: 7,

              backgroundColor: "transparent",
              // should be transparent, updated for testing
              // backgroundColor: "#003300",
              // backgroundColor: "#440000",
              // backgroundColor: "#440044",
            }}
          >
            {mobileMode && showCoords ? "" : "Menu"}
          </div>
        </div>,

        <div
          key="account-box"
          style={{
            width: "100%",
            height: 60,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {userData ? (
            <SignedInBox loading={loading} />
          ) : (
            <SignInBox loading={loading} />
          )}
        </div>,

        userData?.address ? (
          <ExecutiveBoxModeSelectButton
            key="bot6-button"
            text="Boto"
            mode={EXECUTIVE_BOX_MODES.BOT6}
          />
        ) : null,

        <ExecutiveBoxModeSelectButton
          key="marketplace-button"
          text="Marketplace"
          mode={EXECUTIVE_BOX_MODES.MARKETPLACE}
        />,

        // userData?.address ? (
        //   <ExecutiveBoxModeSelectButton
        //     key="Bot222-button"
        //     text="Boto"
        //     mode={EXECUTIVE_BOX_MODES.BOT222}
        //   />
        // ) : null,

        // <StagingComponent
        //   key={"bot6-button-container"}
        //   productionElement={<></>}
        //   overrideStagingOff={false}
        // >

        <ExecutiveBoxModeSelectButton
          key="image-to-pixels-button"
          text="Image to Pixels"
          mode={EXECUTIVE_BOX_MODES.IMAGE_TO_PIXELS}
        />,

        userData?.address ? (
          <ExecutiveBoxModeSelectButton
            key="mint-button"
            text="Mint"
            mode={EXECUTIVE_BOX_MODES.MINT}
          />
        ) : null,
        // </StagingComponent>,
        <StagingComponent
          key={"advanced-build-button-container"}
          productionElement={<></>}
          overrideStagingOff={true}
        >
          <ExecutiveBoxModeSelectButton
            key="advanced-build-button"
            text="Advanced Build"
            mode={EXECUTIVE_BOX_MODES.ADV_BUILD}
          />
        </StagingComponent>,

        userData?.address ? (
          <ExecutiveBoxModeSelectButton
            key="character-select-menu-button"
            text="Character Select"
            mode={EXECUTIVE_BOX_MODES.CHARACTER_SELECT}
          />
        ) : null,

        <StagingComponent
          key={"wearables-button-container"}
          productionElement={<></>}
          overrideStagingOff={true}
        >
          <ExecutiveBoxModeSelectButton
            key="wearables-button"
            text="Wearables"
            mode={EXECUTIVE_BOX_MODES.WEARABLES}
          />
        </StagingComponent>
      );

      bottomContents.push(
        <div
          key="menu-buttons"
          style={{
            width: "100%",
            // height: "40%",

            height: 75,

            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",

            // backgroundColor: "blue",
          }}
        >
          <SetRespawnButton />
          <ShowCoordsToggle />
          <ShowLandsToggle />
        </div>
      );
  }

  let width = fullBot6ExpandMode || fullMarketplaceExpandMode ? "100%" : "30%";
  let maxWidth =
    fullMarketplaceExpandMode || fullBot6ExpandMode ? "100%" : SLIDE_MENU_WIDTH;

  const altSmallContainerWidthMode =
    executiveBoxMode === EXECUTIVE_BOX_MODES.MINT;

  return (
    <div
      id="executive-box-container"
      style={{
        // left: 0,
        left: altSmallContainerWidthMode ? "unset" : 0,
        right: altSmallContainerWidthMode ? 0 : "unset",
        top: 0,
        // width: "100%",

        // width,
        maxWidth: altSmallContainerWidthMode ? maxWidth : "unset",
        minWidth: altSmallContainerWidthMode ? 140 : "unset",

        width: altSmallContainerWidthMode ? width : "100%",

        // minWidth: 140,
        height: `${TOP_FACTOR * 100}%`,
        // height: 222,

        position: "absolute",

        // visibility: botoScreenCaptureMode ? "hidden" : "unset",
        // top: 0,

        // right: 0,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        backgroundColor: "transparent",
        // backgroundColor: "rgba(0, 0, 0, 0.5)",

        zIndex: Z_INDICES.DIALOG_LAYER,
      }}
      onClick={(e) => {
        const target = e?.target;

        if (target?.id === "executive-box-container") {
          // console.log("clicked on executive-box-container")
          setState(["loginMode"], null);
        }
      }}
    >
      <div
        id="executive-box"
        style={{
          right: 0,
          top: 0,
          // width,
          width: altSmallContainerWidthMode ? "100%" : width,
          maxWidth: altSmallContainerWidthMode ? "unset" : maxWidth,
          minWidth: 140,
          height: "100%",
          // height: 222,

          position: "absolute",
          // top: 0,

          // right: 0,

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",

          backgroundColor: COLOR_2,

          // zIndex: Z_INDICES.DIALOG_LAYER,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",

            // backgroundColor: "red",
          }}
        >
          {contents}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            // backgroundColor: "green",
          }}
        >
          {bottomContents}
        </div>
      </div>
    </div>
  );
};

export { ExecutiveBox };
