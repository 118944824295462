import { useEffect } from "react";
import { getState, setState } from "../../reactStateManagement";
import { NWORLD_UNIT_WIDTH_PERCENT_DECIMAL } from "../../worldConstants";
// import { isAnyPointInside } from "../../utils/world/isAnyPointInside";
// import { NWORLD_UNIT_WIDTH_PERCENT_DECIMAL } from "../../worldConstants";

/*
    this is an abstract point square

    size 0 means 1x1 (a single point/square/pixel)
*/
function getBiggestSquare(leftMostX, topMostY, rightMostX, bottomMostY) {
  // Calculate the width and height of the rectangle
  // const width = Math.abs(rightMostX - leftMostX);

  let tempX;

  if (leftMostX > rightMostX) {
    tempX = leftMostX;
    leftMostX = rightMostX;
    rightMostX = tempX;
  }

  let tempY;

  if (topMostY > bottomMostY) {
    tempY = topMostY;
    topMostY = bottomMostY;
    bottomMostY = tempY;
  }

  const width = Math.abs(rightMostX - leftMostX);
  const height = Math.abs(bottomMostY - topMostY);

  // get smallest side
  const smallerSide = Math.min(width, height);

  const squareLeftMostX = leftMostX;
  const squareTopMostY = topMostY;

  const squareRightMostX = squareLeftMostX + smallerSide;
  const squareBottomMostY = squareTopMostY + smallerSide;

  return {
    topLeftX: squareLeftMostX,
    topLeftY: squareTopMostY,
    bottomRightX: squareRightMostX,
    bottomRightY: squareBottomMostY,
  };
}
// // Example usage:
// const rectangle = {
//   leftMostX: 5,
//   topMostY: 10,
//   rightMostX: 25,
//   bottomMostY: 30
// };

// const square = getBiggestSquare(
//   rectangle.leftMostX,
//   rectangle.topMostY,
//   rectangle.rightMostX,
//   rectangle.bottomMostY
// );

// console.log(square);
// Output: { topLeftX: 15, topLeftY: 20, side: 10 }

const useSelectedItems = ({ selectedItems }) => {
  // selectRectTopLeftX: v(null),
  // selectRectTopLeftY: v(null),
  // selectRectBottomRightX: v(null),
  // selectRectBottomRightY: v(null),
  const selectRectTopLeftX = getState("selectRectTopLeftX");
  const selectRectTopLeftY = getState("selectRectTopLeftY");
  const selectRectBottomRightX = getState("selectRectBottomRightX");
  const selectRectBottomRightY = getState("selectRectBottomRightY");

  useEffect(() => {
    if (selectedItems.length === 0) {
      return;
    }

    let leftMostX = null;
    let topMostY = null;
    let rightMostX = null;
    let bottomMostY = null;

    for (let i = 0; i < selectedItems.length; i++) {
      const selectedItem = selectedItems[i];

      const { xDecimalPercent: x, yDecimalPercent: y } = selectedItem;

      if (leftMostX === null || x < leftMostX) {
        leftMostX = x;
      }

      if (topMostY === null || y < topMostY) {
        topMostY = y;
      }

      if (rightMostX === null || x > rightMostX) {
        rightMostX = x;
      }

      if (bottomMostY === null || y > bottomMostY) {
        bottomMostY = y;
      }
    }

    const biggestSquare = getBiggestSquare(
      leftMostX,
      topMostY,
      rightMostX,
      bottomMostY
    );

    // Select Square
    setState("selectSquareTopLeftX", biggestSquare.topLeftX);
    setState("selectSquareTopLeftY", biggestSquare.topLeftY);
    setState(
      "selectSquareBottomRightX",
      biggestSquare.bottomRightX + NWORLD_UNIT_WIDTH_PERCENT_DECIMAL
    );
    setState(
      "selectSquareBottomRightY",
      biggestSquare.bottomRightY + NWORLD_UNIT_WIDTH_PERCENT_DECIMAL
    );
  }, [
    selectedItems,
    selectRectTopLeftX,
    selectRectTopLeftY,
    selectRectBottomRightX,
    selectRectBottomRightY,
  ]);
};

export default useSelectedItems;
