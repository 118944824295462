import React from "react";
import { getMTokenData } from "../../../../../../utils/mToken";
import { getState, setState } from "../../../../../../reactStateManagement";
import { emitEvent } from "../../../../../../utils/emitEvent";
import { EVENT_TYPES } from "../../../../../../constants";
import getAuth from "../../../../../../utils/getAuth";
import delay from "../../../../../../utils/delay";
import useSelectableTextComponent from "../../../../../../utils/effects/useSelectableTextComponent";
import ValidImage from "./ValidImage";

const TIMEOUT = 4200;

const commonPStyles = {
  wordWrap: "break-word",
  userSelect: "text !important",
  width: "96%",
};

const Actionable = ({ listing }) => {
  const id = `actionable-${listing.partitionKey}`;

  useSelectableTextComponent({
    id,
  });

  return (
    <div
      id={id}
      className="special-selectable-text"
      // dark red font
      style={{
        color: "#bb0000",

        width: "100%",
        overflowWrap: "break-word",
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        userSelect: "text",
      }}
    >
      <span
        className="special-selectable-text"
        style={{ color: "#ff0000", fontSize: "18px" }}
      >
        {
          "Action required to complete sale and for seller to receive Prime Points: "
        }
      </span>

      <br />
      {`NFT owner `}
      <span
        className="special-selectable-text"
        style={{ color: "#fff", userSelect: "contain" }}
      >
        {listing.ownerAddress}
      </span>

      {` must send ` + `NFT to buyer `}
      <span
        className="special-selectable-text"
        style={{ color: "#fff", ...commonPStyles }}
      >
        {listing.purchaseAddress}
      </span>
      {` within the next ${Math.floor(
        (listing.purchaseExpiry - Date.now()) / 1000 / 60 / 60 / 24
      )} days. ` +
        `If the purchased NFT is not sent to the buyer within this time, the buyer will be refunded.`}
      {/* <br />
          <br />
          <p style={{ fontSize: "16px", color: "#666" }}>
            {"NFT can be sent to buyer using OpenSea or PolygonScan."}
          </p> */}
    </div>
  );
};

export const Listing = ({
  listing,

  nftTransferToBuyer = null,
  sale = null,
  actionable = false,
}) => {
  const metadata = listing?.metadata || {};

  const loading = getState("loading");
  const marketplaceListNFTPartitionKeyToBuyerMemoInput = getState(
    "marketplaceListNFTPartitionKeyToBuyerMemoInput"
  );

  const buyerMemoInput =
    marketplaceListNFTPartitionKeyToBuyerMemoInput[listing.partitionKey] || "";

  const id = `listing-${listing.partitionKey}`;

  useSelectableTextComponent({
    id,
  });

  let name;

  if (metadata.name) {
    name = metadata.name;
  } else if (listing.name) {
    name = listing.name;
  }

  return (
    <div
      id={id}
      className="special-selectable-text"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderBottom: "1px solid #880000",
        boxSizing: "border-box",

        // should be able to select in both mobile and desktop

        userSelect: "text !important",

        wordWrap: "break-word",
      }}
    >
      {/* <img
        src={metadata.image}
        alt={name}
        style={{
          //   width: "100%",
          maxWidth: "100%",
          height: "125px",
          objectFit: "cover",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      /> */}
      <ValidImage name={name} listing={listing} />
      <h2
        className="special-selectable-text"
        style={{ fontSize: "18px", fontWeight: "bold", ...commonPStyles }}
      >
        {name}
      </h2>

      {metadata.description ? (
        <p
          className="special-selectable-text"
          style={{ fontSize: "16px", color: "#666", ...commonPStyles }}
        >
          {metadata.description}
        </p>
      ) : null}
      <p
        className="special-selectable-text"
        style={{ fontSize: "16px", color: "#666", ...commonPStyles }}
      >
        {`Price: ${listing.price} PP`}
      </p>
      <p
        className="special-selectable-text"
        style={{ fontSize: "16px", color: "#666", ...commonPStyles }}
      >
        {`Seller: `}
        <span
          className="special-selectable-text"
          style={{ color: "#fff", fontSize: "14px", ...commonPStyles }}
        >
          {listing.ownerAddress}
        </span>
      </p>
      {actionable || sale ? (
        <p
          className="special-selectable-text"
          style={{ fontSize: "16px", color: "#666", ...commonPStyles }}
        >
          {`Buyer: `}
          <span
            className="special-selectable-text"
            style={{ color: "#fff", fontSize: "14px", ...commonPStyles }}
          >
            {listing.purchaseAddress}
          </span>
        </p>
      ) : null}

      {listing.sellerMemo ? (
        <p
          className="special-selectable-text"
          style={{
            fontSize: "16px",
            color: "#666",

            userSelect: "text",
            ...commonPStyles,
          }}
        >
          {`Seller Memo: ${listing.sellerMemo.substring(0, 280)}`}
        </p>
      ) : null}
      {listing.buyerMemo ? (
        <p
          className="special-selectable-text"
          style={{ fontSize: "16px", color: "#666", ...commonPStyles }}
        >
          {`Buyer Memo: ${listing.buyerMemo.substring(0, 280)}`}
        </p>
      ) : null}

      {/* TODO: actionables message */}

      {actionable ? <Actionable listing={listing} /> : null}

      {actionable || sale ? null : (
        <input
          style={{
            width: "90%",
            height: "30px",
            borderRadius: "5px",
            backgroundColor: "#444",
            color: "white",
            marginBottom: "20px",
          }}
          onFocus={() => {
            setState(["focus"], true);
          }}
          onBlur={() => {
            setState(["focus"], false);
          }}
          placeholder={"Buyer Memo (optional)"}
          value={buyerMemoInput}
          onChange={(e) => {
            // setState(["marketplaceListNFTBuyerMemoInput"], e.target.value);

            const newMarketplaceListNFTPartitionKeyToBuyerMemoInput = {
              ...marketplaceListNFTPartitionKeyToBuyerMemoInput,
              [listing.partitionKey]: e.target.value,
            };

            setState(
              ["marketplaceListNFTPartitionKeyToBuyerMemoInput"],
              newMarketplaceListNFTPartitionKeyToBuyerMemoInput
            );
          }}
        />
      )}
      {actionable || sale ? null : (
        <button
          style={{
            //   backgroundColor: "#333",
            // backgroundColor: "#880000",
            backgroundColor: loading ? "#ccc" : "#880000",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
          disabled={loading}
          onClick={async () => {
            setState(["loading"], true);

            try {
              const mTokenData = getMTokenData();

              if (!mTokenData) {
                // alert("Please login first");

                setState(["browserAlertData"], {
                  message: "Please login first",
                });

                return;
              }

              const auth = getAuth();

              if (!auth) {
                setState(["browserAlertData"], {
                  message: "Please login first",
                });

                return;
              }

              // const TEST_SKIP_EMIT_EVENT = true;
              const TEST_SKIP_EMIT_EVENT = false;

              if (!TEST_SKIP_EMIT_EVENT) {
                emitEvent({
                  data: {
                    auth,
                    address: mTokenData.address,
                    mToken: mTokenData.mToken,
                    smartContractAddress: listing.smartContractAddress,
                    tokenId: listing.tokenId,
                    buyerMemo: buyerMemoInput,
                  },
                  type: EVENT_TYPES.PURCHASE_NFT,
                });
              }
            } catch (err) {
              console.log("error in sending purchase NFT event:");
              console.log(err);
            } finally {
              await delay(TIMEOUT);
            }

            setState(["loading"], false);
          }}
        >
          {"Buy Now"}
        </button>
      )}
      {sale && nftTransferToBuyer?.transactionHash ? (
        <p
          className="special-selectable-text"
          style={{ fontSize: "12px", color: "#666", ...commonPStyles }}
        >
          {`Transaction Hash: `}
          <span
            className="special-selectable-text"
            style={{ color: "#fff", fontSize: "14px", ...commonPStyles }}
          >
            {nftTransferToBuyer.transactionHash}
          </span>
        </p>
      ) : null}
    </div>
  );
};

/*
  
  
    MEGA LOG {
    "actionables": [
        {
            "metadata": {
                "name": "Basic Item 147",
                "description": "Basic item",
                "image": "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/sword_1.png",
                "external_url": "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/sword_1.png",
                "attributes": [
                    {
                        "value": "Basic Item",
                        "trait_type": "Type"
                    }
                ]
            },
            "lastUpdated": 1718011629715,
            "tokenId": "147",
            "smartContractAddress": "0xc3844c3aea8458e21bdeee7ba6ed328637fef8cb",
            "symbol": "NFIUF",
            "secondarySortKey": 1718011563391,
            "blockNumber": "57892655",
            "purchaseAddress": "0xfcf42185fb42cfbad2fc3c1ac0d87ecef37f4185",
            "url": "https://mathbitcoin.com/new-world/flamin/147.json",
            "name": "NFT Item UltraFlamin",
            "nftType": "ERC721",
            "partitionKey": "marketplaceNftV3XXXXXX0xc3844c3aea8458e21bdeee7ba6ed328637fef8cbXXXXXX147",
            "purchaseExpiry": 1718616429715,
            "buyerMemo": "",
            "purchaseTime": 1718011629715,
            "expirationTime": 1720603563391,
            "possibleSpam": false,
            "secondaryPartitionKey": "marketplaceNftV3XXXXXX0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
            "price": 0.69,
            "sellerMemo": "",
            "type": "MARKETPLACE_NFT_PURCHASED_V3",
            "ownerAddress": "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e"
        }
    ]
}
    )
  
  
 
    MEGA LOG {
    "sales": [
        {
            "lastUpdated": 1717723580146,
            "partitionKey": "marketplaceNFTSaleReceiptV3XXXXXXmarketplaceNftV3XXXXXX0x2953399124f0cbb46d2cbacd8a89cf0599974963XXXXXX83337528321688202077871746597530122964344226551259186510292158874044814327809XXXXXX1717723580146",
            "nftTransferToBuyer": {
                "blockHash": "0x45b616225aa000f5aa0448d7b29160a2039d56c28870fd0a3378c5e7345d7a21",
                "amount": "1",
                "logIndex": 346,
                "verifiedCollection": false,
                "tokenId": "83337528321688202077871746597530122964344226551259186510292158874044814327809",
                "contractType": "ERC1155",
                "possibleSpam": true,
                "verified": 0,
                "transactionIndex": 98,
                "toAddress": "0xfcf42185fb42cfbad2fc3c1ac0d87ecef37f4185",
                "transactionHash": "0xcecf26ad4e86d6abf06bda12f4dfd30d5cd856ca8e850ff0ceba2f71e311b9c0",
                "operator": "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
                "transactionType": "Single",
                "tokenAddress": "0x2953399124f0cbb46d2cbacd8a89cf0599974963",
                "fromAddressLabel": null,
                "blockNumber": "57855769",
                "blockTimestamp": "2024-06-07T01:25:58.000Z",
                "fromAddress": "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
                "value": "0",
                "toAddressLabel": null
            },
            "secondarySortKey": 1717723580146,
            "nftListing": {
                "symbol": "OPENSTORE",
                "metadata": null,
                "tokenId": "83337528321688202077871746597530122964344226551259186510292158874044814327809",
                "possibleSpam": true,
                "secondaryPartitionKey": "marketplaceNftV3XXXXXX0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
                "type": "MARKETPLACE_NFT_PURCHASED_V3",
                "url": "https://api.opensea.io/api/v2/metadata/matic/0x2953399124F0cBB46d2CbACD8A89cF0599974963/0xb83f5e9656c427564177699fe2e0da2bacea2ef4000000000000780000000001",
                "nftType": "ERC1155",
                "lastUpdated": 1717723529364,
                "purchaseExpiry": 1718328329364,
                "secondarySortKey": 1717723495301,
                "partitionKey": "marketplaceNftV3XXXXXX0x2953399124f0cbb46d2cbacd8a89cf0599974963XXXXXX83337528321688202077871746597530122964344226551259186510292158874044814327809",
                "expirationTime": 1720315495301,
                "price": 1,
                "blockNumber": "46406387",
                "name": "OpenSea Collections",
                "smartContractAddress": "0x2953399124f0cbb46d2cbacd8a89cf0599974963",
                "purchaseTime": 1717723529364,
                "purchaseAddress": "0xfcf42185fb42cfbad2fc3c1ac0d87ecef37f4185",
                "sellerMemo": "",
                "ownerAddress": "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
                "buyerMemo": ""
            },
            "purchaseAddress": "0xfcf42185fb42cfbad2fc3c1ac0d87ecef37f4185",
            "saleAddress": "0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
            "secondaryPartitionKey": "marketplaceNFTSaleReceiptV3XXXXXX0x57d9be95b12a687d1cc6f327b57338cd85aeea8e",
            "type": "MARKETPLACE_NFT_SALE_RECEIPT_V3"
        }
    ]
}
    )

 */
