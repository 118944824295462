import React, { useEffect } from "react";
import useGameComponent from "../../../../utils/effects/useGameComponent";
import {
  getState,
  setState,
  //setState
} from "../../../../reactStateManagement";
import {
  COLOR_2,
  EVENT_TYPES,
  // MOBILE_WIDTH_THRESHOLD,
} from "../../../../constants";
import useMobileSwitchDetector from "../../../../utils/hooks/useMobileSwitchDetector";

// const LARGE_PROPORTION = '50%'
const SMALL_WIDTH = 38;
const LARGE_FULL_WIDTH = "50%";
const LARGE_WIDTH = `calc(${LARGE_FULL_WIDTH} - ${SMALL_WIDTH / 2}px)`;

const arrowButtonDirectionToText = {
  up: "↑",
  left: "←",
  leftUp: "↖",
  right: "→",
  rightUp: "↗",
};

const directionToData = {
  right: {
    onType: EVENT_TYPES.W_MOVE_RIGHT_ON,
    offType: EVENT_TYPES.W_MOVE_RIGHT_OFF,
  },
  left: {
    onType: EVENT_TYPES.W_MOVE_LEFT_ON,
    offType: EVENT_TYPES.W_MOVE_LEFT_OFF,
  },
  up: {
    onType: EVENT_TYPES.W_MOVE_JUMP_ON,
    offType: EVENT_TYPES.W_MOVE_JUMP_OFF,
    onType3: EVENT_TYPES.W_MOVE_RIGHT_OFF,
    onType4: EVENT_TYPES.W_MOVE_LEFT_OFF,
    onType5: EVENT_TYPES.W_MOVE_JUMP_ON,
  },

  rightUp: {
    onType: EVENT_TYPES.W_MOVE_RIGHT_ON,
    offType: EVENT_TYPES.W_MOVE_RIGHT_OFF,
    onType2: EVENT_TYPES.W_MOVE_JUMP_ON,
    offType2: EVENT_TYPES.W_MOVE_JUMP_OFF,
  },

  leftUp: {
    onType: EVENT_TYPES.W_MOVE_LEFT_ON,
    onType2: EVENT_TYPES.W_MOVE_JUMP_ON,
    offType: EVENT_TYPES.W_MOVE_LEFT_OFF,
    offType2: EVENT_TYPES.W_MOVE_JUMP_OFF,
  },
};

const setMoveState = ({ type, type2, delay = 0 }) => {
  const $ = window.$;

  // const eventName = down ? "keydown" : "keyup";

  // $(document).on(eventName, handle);
  // return () => {
  //   $(document).off(eventName, handle);
  // };
  // get core to use instead of document
  const core = $("body");

  if (!core.length) {
    return;
  }

  const $body = $("body");

  if (!$body.length) {
    return;
  }

  setTimeout(() => {
    $body.trigger(type);

    if (type2) {
      $body.trigger(type2);
    }
  }, delay);
};

const unpressAllButtons = ({
  setButtonIsPressedRight,
  setButtonIsPressedLeft,
  setButtonIsPressedUp,
  setButtonIsPressedRightUp,
  setButtonIsPressedLeftUp,
  setMoveState,
}) => {
  setButtonIsPressedRight(false);
  setButtonIsPressedLeft(false);
  setButtonIsPressedUp(false);
  setButtonIsPressedRightUp(false);
  setButtonIsPressedLeftUp(false);

  setMoveState({
    type: EVENT_TYPES.W_MOVE_RIGHT_OFF,
  });

  setMoveState({
    type: EVENT_TYPES.W_MOVE_LEFT_OFF,
  });

  setMoveState({
    type: EVENT_TYPES.W_MOVE_JUMP_OFF,
  });

  // trigger mouse up event

  const $ = window.$;

  const $body = $("body");

  if (!$body.length) {
    return;
  }

  $body.trigger(EVENT_TYPES.W_MOVE_RIGHT_OFF);
  $body.trigger(EVENT_TYPES.W_MOVE_LEFT_OFF);
  $body.trigger(EVENT_TYPES.W_MOVE_JUMP_OFF);
};

const ArrowButton = ({
  direction,
  mobile,
  buttonIsPressed,
  setButtonIsPressed,
  atLeastOneButtonIsPressed,

  setStatesToSetToFalseOnEnter = [],
  eventsToTriggerOnEnter = [],
  large,
}) => {
  const text = arrowButtonDirectionToText[direction] || "";

  const id = `arrow-button-${direction}`;

  useGameComponent({
    id,
  });

  useMobileSwitchDetector({
    id,
  });

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       mobileWidthThreshold: getState("mobileWidthThreshold"),
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  let backgroundColor;

  if (buttonIsPressed) {
    backgroundColor = "#8B0000";
  } else {
    backgroundColor = COLOR_2;
  }
  const data = directionToData[direction];

  if (!data) {
    return null;
  }

  const handleDownAction = () => {
    setMoveState({
      type: data.onType,
    });

    if (data.onType5) {
      setTimeout(() => {
        setMoveState({
          type: data.onType5,
        });
      }, 20);
    }

    if (data.onType2) {
      setTimeout(() => {
        setMoveState({
          type: data.onType2,
        });
      }, 1);

      setTimeout(() => {
        setMoveState({
          type: data.onType2,
        });
      }, 10);
    }

    if (data.onType3) {
      setTimeout(() => {
        setMoveState({
          type: data.onType3,
        });
      }, 1);
    }

    if (data.onType4) {
      setTimeout(() => {
        setMoveState({
          type: data.onType4,
        });
      }, 2);
    }

    setButtonIsPressed(true);
  };

  const handleDownActionPressedOnly = () => {
    if (!atLeastOneButtonIsPressed) {
      return;
    }

    setStatesToSetToFalseOnEnter.forEach((setStateFunction) => {
      setStateFunction(false);
    });

    eventsToTriggerOnEnter.forEach((event) => {
      setMoveState(event);
    });

    handleDownAction();
  };

  const handleUpAction = () => {
    // console.log("HANDLE UP ACTIOn");

    setMoveState({
      type: data.offType,
    });

    if (data.offType2) {
      // console.log("OFF OF TYPE 2: " + data.offType2);

      setTimeout(() => {
        setMoveState({
          type: data.offType2,
        });
      }, 1);
    }

    setButtonIsPressed(false);
  };

  const width = large ? LARGE_WIDTH : SMALL_WIDTH;

  return (
    <button
      id={id}
      className="no-drag"
      style={{
        userSelect: "none",
        width,
        // width: "calc(100% / 3)",
        height: "100%",

        backgroundColor,
        color: "white",
        borderRadius: 0,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onMouseDown={mobile ? null : handleDownAction}
      onMouseEnter={mobile ? null : handleDownActionPressedOnly}
      onMouseUp={mobile ? null : handleUpAction}
      onMouseLeave={mobile ? null : handleUpAction}
      onTouchStart={mobile ? handleDownAction : null}
      onTouchEnd={mobile ? handleUpAction : null}
    >
      {text}
    </button>
  );
};

const ArrowButtonRow = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "calc( 100%/ 2)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};

const getSetStateFunction = (key) => {
  return (value) => {
    setState([key], value);
  };
};

export function JoyContainer2() {
  const mobile = getState("mobile");

  const buttonIsPressedRight = getState("buttonIsPressedRight");
  const buttonIsPressedLeft = getState("buttonIsPressedLeft");
  const buttonIsPressedUp = getState("buttonIsPressedUp");
  const buttonIsPressedRightUp = getState("buttonIsPressedRightUp");
  const buttonIsPressedLeftUp = getState("buttonIsPressedLeftUp");
  const centerDivIsPressed = getState("centerDivIsPressed");

  const setButtonIsPressedRight = getSetStateFunction("buttonIsPressedRight");
  const setButtonIsPressedLeft = getSetStateFunction("buttonIsPressedLeft");
  const setButtonIsPressedUp = getSetStateFunction("buttonIsPressedUp");
  const setButtonIsPressedRightUp = getSetStateFunction(
    "buttonIsPressedRightUp"
  );
  const setButtonIsPressedLeftUp = getSetStateFunction("buttonIsPressedLeftUp");
  const setCenterDivIsPressed = getSetStateFunction("centerDivIsPressed");

  useEffect(() => {
    const $ = window.$;

    // add event listener for mouse up
    const $window = $(window);

    if ($window.length === 0) {
      return;
    }

    const handleMouseUp = () => {
      unpressAllButtons({
        setButtonIsPressedRight,
        setButtonIsPressedLeft,
        setButtonIsPressedUp,
        setButtonIsPressedRightUp,
        setButtonIsPressedLeftUp,
        setMoveState,
      });
    };

    $window.on("mouseup", handleMouseUp);

    return () => {
      $window.off("mouseup", handleMouseUp);
    };
  }, []);

  /*
      if a button is pressed
        then periodically trigger the event for that button
        to ensure that the event is triggered
        in case there is a jumble
  */
  useEffect(() => {
    const f = () => {
      if (buttonIsPressedRight) {
        setMoveState({
          type: EVENT_TYPES.W_MOVE_RIGHT_ON,
        });
      } else if (buttonIsPressedLeft) {
        setMoveState({
          type: EVENT_TYPES.W_MOVE_LEFT_ON,
        });
      } else if (buttonIsPressedUp) {
        setMoveState({
          type: EVENT_TYPES.W_MOVE_JUMP_ON,
        });
      } else if (buttonIsPressedRightUp) {
        setMoveState({
          type: EVENT_TYPES.W_MOVE_RIGHT_ON,
          type2: EVENT_TYPES.W_MOVE_JUMP_ON,
          delay: 0,
        });

        // setMoveState({
        //   type: EVENT_TYPES.W_MOVE_JUMP_ON,
        //   delay: 0,
        // });
      } else if (buttonIsPressedLeftUp) {
        setMoveState({
          type: EVENT_TYPES.W_MOVE_LEFT_ON,
          type2: EVENT_TYPES.W_MOVE_JUMP_ON,
          delay: 0,
        });

        // setMoveState({
        //   type: EVENT_TYPES.W_MOVE_JUMP_ON,
        //   delay: 55,
        // });
      }
    };

    const intervalConstant = setInterval(f, 200);

    return () => {
      clearInterval(intervalConstant);
    };
  }, [
    buttonIsPressedRight,
    buttonIsPressedLeft,
    buttonIsPressedUp,
    buttonIsPressedRightUp,
    buttonIsPressedLeftUp,
    centerDivIsPressed,
  ]);

  const atLeastOneButtonIsPressed =
    buttonIsPressedRight ||
    buttonIsPressedLeft ||
    buttonIsPressedUp ||
    buttonIsPressedRightUp ||
    buttonIsPressedLeftUp ||
    centerDivIsPressed; // also valid here

  return (
    <div
      id="square-joypad"
      style={{
        position: "relative",
        width: "100%",
        // maxWidth: 180,
        height: "100%",
        // maxHeight: 120,
      }}
      onMouseLeave={() => {
        unpressAllButtons({
          setButtonIsPressedRight,
          setButtonIsPressedLeft,
          setButtonIsPressedUp,
          setButtonIsPressedRightUp,
          setButtonIsPressedLeftUp,
          setMoveState,
        });
      }}
      onTouchMove={(event) => {
        // get id of element that is being touched

        const element = document.elementFromPoint(
          event.touches[0].clientX,
          event.touches[0].clientY
        );

        if (element) {
          // console.log("ELEMENT TOUCHED: " + element.id);

          if (element.id === "arrow-button-left") {
            setButtonIsPressedRight(false);
            setButtonIsPressedLeft(true);
            setButtonIsPressedUp(false);
            setButtonIsPressedRightUp(false);
            setButtonIsPressedLeftUp(false);

            setMoveState({
              type: EVENT_TYPES.W_MOVE_LEFT_ON,
            });

            // setMoveState({
            //   type: EVENT_TYPES.W_MOVE_RIGHT_OFF,
            //   delay: 1,
            // });
          } else if (element.id === "center-div") {
            setButtonIsPressedRight(false);
            setButtonIsPressedLeft(false);
            setButtonIsPressedUp(false);
            setButtonIsPressedRightUp(false);
            setButtonIsPressedLeftUp(false);
            setMoveState({
              type: EVENT_TYPES.W_MOVE_LEFT_ON,
              type2: EVENT_TYPES.W_MOVE_RIGHT_ON,
            });
            // setMoveState({
            //   type: EVENT_TYPES.W_MOVE_RIGHT_OFF,
            //   delay: 1,
            // });
          } else if (element.id === "arrow-button-right") {
            setButtonIsPressedRight(true);
            setButtonIsPressedLeft(false);
            setButtonIsPressedUp(false);
            setButtonIsPressedRightUp(false);
            setButtonIsPressedLeftUp(false);

            setMoveState({
              type: EVENT_TYPES.W_MOVE_RIGHT_ON,
            });

            // setMoveState({
            //   type: EVENT_TYPES.W_MOVE_LEFT_OFF,
            // });
          } else if (element.id === "arrow-button-up") {
            setButtonIsPressedRight(false);
            setButtonIsPressedLeft(false);
            setButtonIsPressedUp(true);
            setButtonIsPressedRightUp(false);
            setButtonIsPressedLeftUp(false);

            setMoveState({
              type: EVENT_TYPES.W_MOVE_JUMP_ON,
              type2: EVENT_TYPES.W_MOVE_RIGHT_OFF,
            });

            setMoveState({
              type: EVENT_TYPES.W_MOVE_JUMP_ON,
              type2: EVENT_TYPES.W_MOVE_LEFT_OFF,
              delay: 0,
            });

            // setMoveState({
            //   type: EVENT_TYPES.W_MOVE_RIGHT_OFF,
            //   delay: 0,
            // });

            // setMoveState({
            //   type: EVENT_TYPES.W_MOVE_LEFT_OFF,
            //   delay: 0,
            // });
          } else if (element.id === "arrow-button-rightUp") {
            setButtonIsPressedRightUp(true);
            setButtonIsPressedLeft(false);
            setButtonIsPressedUp(false);
            setButtonIsPressedRight(false);
            setButtonIsPressedLeftUp(false);

            setMoveState({
              type: EVENT_TYPES.W_MOVE_RIGHT_ON,
              type2: EVENT_TYPES.W_MOVE_JUMP_ON,
            });
            // setMoveState({

            //   type: EVENT_TYPES.W_MOVE_JUMP_ON,
            //   delay: 1,
            // });
          } else if (element.id === "arrow-button-leftUp") {
            setButtonIsPressedLeftUp(true);
            setButtonIsPressedLeft(false);
            setButtonIsPressedUp(false);
            setButtonIsPressedRight(false);
            setButtonIsPressedRightUp(false);

            setMoveState({
              type: EVENT_TYPES.W_MOVE_LEFT_ON,
              type2: EVENT_TYPES.W_MOVE_JUMP_ON,
            });
            // setMoveState({
            //   type: EVENT_TYPES.W_MOVE_JUMP_ON,
            //   delay: 1,
            // });
          }
        }
      }}
    >
      <ArrowButtonRow>
        <ArrowButton
          direction="leftUp"
          large={true}
          mobile={mobile}
          buttonIsPressed={buttonIsPressedLeftUp}
          setButtonIsPressed={setButtonIsPressedLeftUp}
          atLeastOneButtonIsPressed={atLeastOneButtonIsPressed}
          setStatesToSetToFalseOnEnter={[
            setButtonIsPressedRight,
            setButtonIsPressedRightUp,
          ]}
          eventsToTriggerOnEnter={[
            {
              type: EVENT_TYPES.W_MOVE_RIGHT_OFF,
            },
            {
              type: EVENT_TYPES.W_MOVE_LEFT_ON,
            },
          ]}
        />
        <ArrowButton
          direction="up"
          mobile={mobile}
          buttonIsPressed={buttonIsPressedUp}
          setButtonIsPressed={setButtonIsPressedUp}
          atLeastOneButtonIsPressed={atLeastOneButtonIsPressed}
        />
        <ArrowButton
          direction="rightUp"
          large={true}
          mobile={mobile}
          buttonIsPressed={buttonIsPressedRightUp}
          setButtonIsPressed={setButtonIsPressedRightUp}
          atLeastOneButtonIsPressed={atLeastOneButtonIsPressed}
          setStatesToSetToFalseOnEnter={[
            setButtonIsPressedLeft,
            setButtonIsPressedLeftUp,
          ]}
          eventsToTriggerOnEnter={[
            {
              type: EVENT_TYPES.W_MOVE_LEFT_OFF,
            },
            {
              type: EVENT_TYPES.W_MOVE_RIGHT_ON,
            },
          ]}
        />
      </ArrowButtonRow>
      <ArrowButtonRow>
        <ArrowButton
          direction="left"
          mobile={mobile}
          buttonIsPressed={buttonIsPressedLeft}
          setButtonIsPressed={setButtonIsPressedLeft}
          atLeastOneButtonIsPressed={atLeastOneButtonIsPressed}
          large={true}
        />
        <div
          id="center-div"
          style={{
            // width: "calc(100% / 3)",
            width: SMALL_WIDTH,
            height: "100%",

            // backgroundColor: COLOR_2,
            backgroundColor: centerDivIsPressed ? "#8B0000" : COLOR_2,
          }}
          onMouseDown={() => {
            setCenterDivIsPressed(true);
          }}
          onMouseEnter={() => {
            if (atLeastOneButtonIsPressed) {
              setCenterDivIsPressed(true);
            }
          }}
          onMouseUp={() => {
            setCenterDivIsPressed(false);
          }}
          onMouseLeave={() => {
            setCenterDivIsPressed(false);
          }}
        />
        <ArrowButton
          direction="right"
          mobile={mobile}
          buttonIsPressed={buttonIsPressedRight}
          setButtonIsPressed={setButtonIsPressedRight}
          atLeastOneButtonIsPressed={atLeastOneButtonIsPressed}
          large={true}
        />
      </ArrowButtonRow>
    </div>
  );
}
