import {
  // COLOR_5,
  EVENT_TYPES,
} from "../../constants";
import { emitEvent } from "../emitEvent";
import { getMTokenData } from "../mToken";
import getAuth from "../getAuth";
// import { getState } from "../../../../../reactStateManagement";
// import extractBotoGeneralizedCommandV1Data from "../../../../../utils/aiBoto/extractBotoGeneralizedCommandV1Data";
// import AiInteractionRecord from "./AiInteractionRecord";

const loadInteractions = async () => {
  const auth = getAuth();

  if (!auth) {
    return;
  }

  const mTokenData = getMTokenData();

  if (!mTokenData) {
    return;
  }

  emitEvent({
    type: EVENT_TYPES.LIST_BOT2_INTERACTIONS,
    data: {
      auth,
      address: mTokenData.address,
      mToken: mTokenData.mToken,
    },
  });
};

export default loadInteractions;
