import { ITEM_TYPES } from "../../worldConstants";
import {
  getXDecimalPercentFromXPixel,
  getXPixelsMagnitudeFromXDecimalPercent,
} from "../../coordsMapper";
import { PRODUCTION_MODE } from "../../constants";
import {
  NWORLD_X_PARTITION_PERCENT_DECIMAL,
  //ITEM_TYPE_TO_DATA
  // NWORLD_TOTAL_WIDTH,
} from "../../worldConstants";

const NWORLD_X_PARTITION_PIXELS = getXPixelsMagnitudeFromXDecimalPercent(
  NWORLD_X_PARTITION_PERCENT_DECIMAL
);
// function check_multiple_in_range(x, a, b):
//     lower_bound = (a // x + (a % x != 0)) * x
//     upper_bound = b // x * x
//     if lower_bound <= upper_bound:
//         return True
//     else:
//         return False
// In the pseudocode, // represents integer division (floor division), and % represents the modulo operator.
const checkMultipleInRange = (x, a, b) => {
  const lowerBound = Math.ceil(a / x) * x;
  const upperBound = Math.floor(b / x) * x;

  if (lowerBound <= upperBound) {
    return true;
  }

  return false;
};
// function find_first_multiple(a, x):
//     q = a // x
//     r = a % x
//     if r == 0:
//         return a
//     else:
//         return (q + 1) * x
// In the pseudocode, // represents integer division (floor division), and % represents the modulo operator.
const findFirstMultiple = (a, x) => {
  const q = Math.floor(a / x); // use Math.floor() to get the integer part of the quotient

  const r = a % x;

  if (r === 0) {
    return a;
  }

  return (q + 1) * x;
};
const getArtificialItemBackgroundColor = (x) => {
  return x === 0 ? "teal" : "pink";
};
export const getArtificialItems = ({ minX, nzwW }) => {
  if (PRODUCTION_MODE) {
    return [];
  }

  const thereIsAnXInThisRange = checkMultipleInRange(
    NWORLD_X_PARTITION_PIXELS,
    minX,
    minX + nzwW
  );

  const artificialItems = [];

  if (thereIsAnXInThisRange) {
    const firstXMultiple = findFirstMultiple(minX, NWORLD_X_PARTITION_PIXELS);

    const xDecimalPercent = getXDecimalPercentFromXPixel(firstXMultiple);

    artificialItems.push({
      key: `artificial-item-${firstXMultiple}`,
      xDecimalPercent,
      yDecimalPercent: 0,
      itemType: ITEM_TYPES.TREE_0,
      backgroundColor: getArtificialItemBackgroundColor(firstXMultiple),
    });

    const secondXMultiple = firstXMultiple + NWORLD_X_PARTITION_PIXELS;

    const secondXDecimalPercent = getXDecimalPercentFromXPixel(secondXMultiple);

    artificialItems.push({
      key: `artificial-item-${secondXMultiple}`,
      xDecimalPercent: secondXDecimalPercent,
      yDecimalPercent: 0,
      itemType: ITEM_TYPES.TREE_0,
      backgroundColor: getArtificialItemBackgroundColor(secondXMultiple),
    });

    const zeroXMultiple = firstXMultiple - NWORLD_X_PARTITION_PIXELS;

    const zeroXDecimalPercent = getXDecimalPercentFromXPixel(zeroXMultiple);

    artificialItems.push({
      key: `artificial-item-${zeroXMultiple}`,
      xDecimalPercent: zeroXDecimalPercent,
      yDecimalPercent: 0,
      itemType: ITEM_TYPES.TREE_0,
      backgroundColor: getArtificialItemBackgroundColor(zeroXMultiple),
    });
  }

  return artificialItems;
};
