import doMagnaDocker from "../../api/doMagnaDocker";
import createExecutionQueue from "../createExecutionQueue";
import delay from "../delay";

const executionQueue = createExecutionQueue(10);

const getMetaverseStateKey = (key) => {
  return `metaverse_${key}`;
};

const updateMetaverseUserState = async ({ key, value }) => {
  await executionQueue(async () => {
    await doMagnaDocker({
      message: `BOTOCOMMAND_GENERALIZED_COMMAND_V1_|${JSON.stringify({
        type: "ARBITRARY_DATA",
        subtype: "UPDATE_ONE_KEY_PAIR",
        value: {
          key: getMetaverseStateKey(key),
          value,
        },
      })}|`,
    });

    await delay(4000);
  });
};

export default updateMetaverseUserState;
