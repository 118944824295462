// import {
//   NWORLD_ROUND_TO_DECIMAL,
//   NWORLD_X_PARTITION_PERCENT_DECIMAL,
//   NWORLD_Y_PARTITION_PERCENT_DECIMAL,
// } from "../../../worldConstants";
import { cachePowerGetNWorldData } from "../../world/cachePowerGetNWorldData";
// import { roundToDecimal } from "../../roundToDecimal";
// import delay from "../../delay";
import getDoPromiseInQueue from "../../getDoPromiseInQueue";
import getNextPartitionDecimalPercent from "../../world/getNextPartitionDecimalPercent";

const doPromiseInQueue = getDoPromiseInQueue();

// const getNextXPartitionDecimalPercent = async ({
//   xPartitionDecimalPercentToUse,
//   yPartitionDecimalPercentToUse,
//   incrementToGet,
// }) => {
//   const { xIncrementToGet, yIncrementToGet, customDelay } = incrementToGet;

//   const nextXPartitionDecimalPercent = roundToDecimal(
//     xPartitionDecimalPercentToUse +
//       xIncrementToGet * NWORLD_X_PARTITION_PERCENT_DECIMAL,
//     NWORLD_ROUND_TO_DECIMAL
//   );

//   const nextYPartitionDecimalPercent = roundToDecimal(
//     yPartitionDecimalPercentToUse +
//       yIncrementToGet * NWORLD_Y_PARTITION_PERCENT_DECIMAL,
//     NWORLD_ROUND_TO_DECIMAL
//   );

//   if (
//     nextXPartitionDecimalPercent >= 0 &&
//     nextXPartitionDecimalPercent <= 1 &&
//     nextYPartitionDecimalPercent >= 0 &&
//     nextYPartitionDecimalPercent <= 1
//   ) {
//     cachePowerGxetNWorldData({
//       xPartitionDecimalPercent: nextXPartitionDecimalPercent,
//       yPartitionDecimalPercent: nextYPartitionDecimalPercent,
//     });

//     const delayToUse = customDelay || DEFAULT_DELAY;

//     await delay(delayToUse);
//   }
// };

// const getNextYPartitionDecimalPercent = async ({
//   xPartitionDecimalPercentToUse,
//   yPartitionDecimalPercentToUse,
//   incrementToGet,
// }) => {
//   const { xIncrementToGet, yIncrementToGet, customDelay } = incrementToGet;

//   const nextXPartitionDecimalPercent = roundToDecimal(
//     xPartitionDecimalPercentToUse +
//       xIncrementToGet * NWORLD_X_PARTITION_PERCENT_DECIMAL,
//     NWORLD_ROUND_TO_DECIMAL
//   );

//   const nextYPartitionDecimalPercent = roundToDecimal(
//     yPartitionDecimalPercentToUse +
//       yIncrementToGet * NWORLD_Y_PARTITION_PERCENT_DECIMAL,
//     NWORLD_ROUND_TO_DECIMAL
//   );

//   if (
//     nextXPartitionDecimalPercent >= 0 &&
//     nextXPartitionDecimalPercent <= 1 &&
//     nextYPartitionDecimalPercent >= 0 &&
//     nextYPartitionDecimalPercent <= 1
//   ) {
//     cachePowerGetxNWorldData({
//       xPartitionDecimalPercent: nextXPartitionDecimalPercent,
//       yPartitionDecimalPercent: nextYPartitionDecimalPercent,
//     });

//     const delayToUse = customDelay || DEFAULT_DELAY;

//     await delay(delayToUse);
//   }
// };

const getDataIfMovingBetweenPartitions = async ({
  xPartitionDecimalPercentToUse,
  yPartitionDecimalPercentToUse,
  currentXPartitionDecimalPercent,
  currentYPartitionDecimalPercent,
}) => {
  cachePowerGetNWorldData({
    xPartitionDecimalPercent: xPartitionDecimalPercentToUse,
    yPartitionDecimalPercent: yPartitionDecimalPercentToUse,
  });

  /*
        get next,
 
        if positive x increment, then get right partition
        ..etc
 
      */
  const xIncrement =
    xPartitionDecimalPercentToUse - currentXPartitionDecimalPercent;

  const thereIsAnXIncrement = xIncrement !== 0;

  if (thereIsAnXIncrement) {
    const xIncrementIsPositive = xIncrement > 0;

    const incrementsToGet = [
      [0, 0, 25, true],
      [0, 1, 75],
      [0, -1, 75],
      [1, 0, 25, true],
      [1, 1, 100],
      [1, 2, 100],
      [1, -1, 100],
      [1, -2, 100],
      [2, 0],
      [2, 1],
      [2, -1],
      [3, 0],
      [3, 1, 1000],
      [3, -1, 1000],
      [4, 0],
      // [4, 1, 1000],
      // [4, -1, 1000],
      // [5, 0, 1000],
      // [5, 1, 1000],
      // [5, -1, 1000],
      // [6, 0, 2000],
      // [6, 1, 2000],
      // [6, -1, 2000],
      // [7, 0, 2000],
      // [7, 1, 2000],
      // [7, -1, 2000],
      // [8, 0, 2000],
      // [8, 1, 2000],
      // [9, 0, 2000],
      // [9, 1, 2000],
    ].map((increment, index) => {
      // if not xIncrementIsPositive, then reverse the increments

      const xIncrementToGet = xIncrementIsPositive
        ? increment[0]
        : -increment[0];
      const yIncrementToGet = increment[1];

      // return [xIncrementToGet, yIncrementToGet, increment[2], increment[3]];

      return {
        xIncrementToGet,
        yIncrementToGet,
        customDelay: increment[2],
        shouldDoGetInQueue: !increment[3],
        index,
        increment, // provide original for debugging
      };
    });

    for (let i = 0; i < incrementsToGet.length; i++) {
      const incrementToGet = incrementsToGet[i];

      // const shouldDoGetInQueue = incrementToGet[3];
      const { shouldDoGetInQueue } = incrementToGet;

      if (!shouldDoGetInQueue) {
        await getNextPartitionDecimalPercent({
          xPartitionDecimalPercentToUse,
          yPartitionDecimalPercentToUse,
          incrementToGet,
        });
      } else {
        doPromiseInQueue({
          operation: async () => {
            await getNextPartitionDecimalPercent({
              xPartitionDecimalPercentToUse,
              yPartitionDecimalPercentToUse,
              incrementToGet,
              multiplyDelay: 1.5,
            });

            // const xIncrementToGet = incrementToGet[0];
            // const yIncrementToGet = incrementToGet[1];
            // const customDelay = incrementToGet[2];

            // const nextXPartitionDecimalPercent = roundToDecimal(
            //   xPartitionDecimalPercentToUse +
            //     xIncrementToGet * NWORLD_X_PARTITION_PERCENT_DECIMAL,
            //   NWORLD_ROUND_TO_DECIMAL
            // );

            // const nextYPartitionDecimalPercent = roundToDecimal(
            //   yPartitionDecimalPercentToUse +
            //     yIncrementToGet * NWORLD_Y_PARTITION_PERCENT_DECIMAL,
            //   NWORLD_ROUND_TO_DECIMAL
            // );

            // if (
            //   nextXPartitionDecimalPercent >= 0 &&
            //   nextXPartitionDecimalPercent <= 1 &&
            //   nextYPartitionDecimalPercent >= 0 &&
            //   nextYPartitionDecimalPercent <= 1
            // ) {
            //   cachePowerxGetNWorldData({
            //     xPartitionDecimalPercent: nextXPartitionDecimalPercent,
            //     yPartitionDecimalPercent: nextYPartitionDecimalPercent,
            //   });

            //   // await delay(75);

            //   //

            //   await delay(customDelay || DEFAULT_DELAY);
            // }
          },
        });
      }
    }
  }

  const yIncrement =
    yPartitionDecimalPercentToUse - currentYPartitionDecimalPercent;

  const thereIsAYIncrement = yIncrement !== 0;

  if (thereIsAYIncrement) {
    const yIncrementIsPositive = yIncrement > 0;

    const incrementsToGet = [
      [0, 0, 25, true],
      [1, 0, 75],
      [-1, 0, 75],
      [0, 1, 25, true],
      [1, 1, 100],
      [-1, 1, 100],
      [0, 2, 100],
      [1, 2],
      [-1, 2],
      [0, 3],
      [1, 3, 1000],
      [-1, 3, 1000],
      [0, 4],
      // [1, 4, 1000],
      // [-1, 4, 1000],
      // [0, 5, 400],
      // [1, 5, 400],
      // [-1, 5, 400],
      // [0, 6, 400],
      // [1, 6, 1000],
      // [-1, 6, 1000],
      // [0, 7, 1000],
      // [1, 7, 1000],
      // [-1, 7, 1000],
      // [0, 8, 1000],
      // [1, 8, 1000],
      // [-1, 8, 1000],
      // [0, 9, 1000],
      // [1, 9, 1000],
      // [-1, 9, 1000],
    ].map((increment, index) => {
      const xIncrementToGet = increment[0];
      const yIncrementToGet = yIncrementIsPositive
        ? increment[1]
        : -increment[1];

      // return [xIncrementToGet, yIncrementToGet];

      return {
        xIncrementToGet,
        yIncrementToGet,
        customDelay: increment[2],
        shouldDoGetInQueue: !increment[3],
        index,
        increment, // provide original for debugging
      };
    });

    for (let i = 0; i < incrementsToGet.length; i++) {
      const incrementToGet = incrementsToGet[i];

      const { shouldDoGetInQueue } = incrementToGet;

      if (!shouldDoGetInQueue) {
        await getNextPartitionDecimalPercent({
          xPartitionDecimalPercentToUse,
          yPartitionDecimalPercentToUse,
          incrementToGet,
        });
      } else {
        doPromiseInQueue({
          operation: async () => {
            await getNextPartitionDecimalPercent({
              xPartitionDecimalPercentToUse,
              yPartitionDecimalPercentToUse,
              incrementToGet,
              multiplyDelay: 1.5,
            });
          },
        });
      }
    }
  }
};

export { getDataIfMovingBetweenPartitions };
