import React, { useEffect, useState } from "react";
import {
  // FONT_0,
  COLOR_0,
  //   COLOR_1,
  // Z_INDICES
} from "../../constants";
import { getState } from "../../reactStateManagement";
import { JoyPad } from "./JoyPad";
import { NeoControls } from "./NeoControls";
import { NMessage } from "./NMessage";
import { NPaint } from "./NPaint";
// import { ExecutiveBox } from "./ExecutiveBox";
import { Bot2Mode } from "./Bot2Mode";
// import useGameComponent from "../../utils/effects/useGameComponent";

const NewControls = () => {
  const windowWidth = getState("windowWidth");
  const windowHeight = getState("windowHeight");
  const messageMode = getState("messageMode");
  const buildMode = getState("buildMode");
  // const loginMode = getState("loginMode");
  const bot2Mode = getState("bot2Mode");
  // const nPaintMode = getState("nPaintMode");

  const [height, setHeight] = useState("20%");

  useEffect(() => {
    const $ = window.$;

    // const core-background
    const coreBackground = $("#core-background");

    if (coreBackground.length === 0) {
      return;
    }

    const coreBackgroundHeight = coreBackground.height();

    const newHeight = (windowHeight - coreBackgroundHeight) / 2;
    // const newHeight = windowHeight - coreBackgroundHeight;

    setHeight(newHeight);
  }, [windowWidth, windowHeight]);

  //   console.log("height:", height);

  // useGameComponent({
  //   id: "new-controls-container",
  // });

  let leftSideElement;

  // if (loginMode) {
  //   leftSideElement = <ExecutiveBox />;
  // } else {
  leftSideElement = <JoyPad />;
  // }

  let rightSideElement;

  if (messageMode) {
    rightSideElement = <NMessage />;
  } else if (buildMode) {
    rightSideElement = <NPaint />;
  } else if (bot2Mode) {
    rightSideElement = <Bot2Mode />;
  } else {
    rightSideElement = <NeoControls />;
  }

  return (
    <div
      id={"new-controls-container"}
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: height,
        backgroundColor: COLOR_0,
        // backgroundColor: COLOR_1,

        zIndex: 2323232,

        // color: COLOR_1,

        // fontFamily: FONT_0,

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        // zIndex: Z_INDICES.MIDDLE_LAYER,
      }}
    >
      {leftSideElement}
      {rightSideElement}
      {/* {messageMode ? <NMessage /> : <NeoControls />} */}
    </div>
  );
};

export { NewControls };
