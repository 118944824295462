import React from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // EVENT_TYPES,
} from "../../../../../../constants";
// import { getState } from "../../../../../../reactStateManagement";
// import extractBotoGeneralizedCommandV1Data from "../../../../../../utils/aiBoto/extractBotoGeneralizedCommandV1Data";
import AiInteractionRecord from "./AiInteractionRecord";
// import { emitMTokenEvent } from "../../../../../../utils/browserRealtime/emitMTokenEvent";
// import loadInteractions from "../../../../../../utils/aiBoto/loadBot2Interactions";

import { loadInteractions } from "./loadInteractions";
// import { Header } from "./Bot6InteractionsDisplayHeader";
import Spacer from "../../../../../../commonComponents/Spacer";

/*
  DATA STRUCTURE:
"bot6InteractionData2": {
        "lastUpdated": 1719404440820,
        "partitionKey": "bot6InteractionXXXXXX104XXXXXX1719404440820",
        "output": {
            "commands": [
                {
                    "parameters": {
                        "direction": "left"
                    },
                    "command": "move"
                },
                {
                    "parameters": {
                        "direction": "up"
                    },
                    "command": "move"
                },
                {
                    "parameters": {
                        "message": "Hi User A!"
                    },
                    "command": "talk"
                }
            ],
            "costData": {
                "pricePerToken": 0.000059999999999999995,
                "totalTokensUsed": 133
            }
        },
        "secondarySortKey": 1719404440820,
        "input": {
            "command": {
                "type": "BOTO",
                "subtype": "INTERACT_VIPOWER",
                "value": {
                    "promptAIDataIndex": 0,
                    "gptSelection": "gpt-4o",
                    "contextAIDataIndex": -1,
                    "tokenId": "104"
                }
            }
        },
        "secondaryPartitionKey": "bot6InteractionXXXXXX104",
        "type": "BOT6_INTERACTION"
    }
}
*/

// let hasFirstLoadedVar = true;
// let hasFirstLoadedVar = false;

const baseLevelStateObject = {
  hasInitialLoaded: false,
};

const Bot6InteractionsDisplay = ({
  tokenId,
  bot6InteractionData,
  thereIsBot6InteractionData,
  height = "100%",
}) => {
  const baseLevelStateObjectRef = React.useRef(baseLevelStateObject);

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       tokenId,
  //       bot6InteractionData,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  React.useEffect(() => {
    if (
      thereIsBot6InteractionData ||
      !tokenId ||
      baseLevelStateObjectRef.current.hasInitialLoaded
    ) {
      // console.log(
      //   "no initial load of bot6InteractionData: " +
      //     `thereIsBot6InteractionData: ${thereIsBot6InteractionData}, ` +
      //     `tokenId: ${tokenId}, ` +
      //     `baseLevelStateObjectRef.current.hasInitialLoaded: ${baseLevelStateObjectRef.current.hasInitialLoaded}`
      // );

      return;
    }
    baseLevelStateObjectRef.current.hasInitialLoaded = true;

    console.log("initial load of bot6InteractionData");

    loadInteractions({
      tokenId,
    });
  }, [tokenId, thereIsBot6InteractionData]);

  // const TEMPORARY_TEST_MODE = true;
  const TEMPORARY_TEST_MODE = false;

  if (
    !bot6InteractionData ||
    bot6InteractionData.length === 0 ||
    !thereIsBot6InteractionData ||
    !tokenId ||
    TEMPORARY_TEST_MODE
  ) {
    return null;
  }

  const interactions = [];

  if (bot6InteractionData && bot6InteractionData.length > 0) {
    for (let i = 0; i < bot6InteractionData.length; i++) {
      const bot6Interaction = bot6InteractionData[i];

      let actionType;

      try {
        // const commandData = extractBotoGeneralizedCommandV1Data(
        //   bot6Interaction.input
        // );
        // already extract in the backend

        const commandData = bot6Interaction.input.command;

        if (
          commandData &&
          commandData.type === "BOTO" &&
          commandData.subtype === "INTERACT_VIPOWER"
        ) {
          actionType = `Interact`;
        }
      } catch (e) {
        console.error("error in extractBotoGeneralizedCommandV1Data:", e);

        actionType = "Interact - General";
      }

      /*
{
 "partitionKey": "bot6InteractionXXXXXX104XXXXXX1720070378845",
 "input": {
  "command": {
   "subtype": "INTERACT_VIPOWER",
   "type": "BOTO",
   "value": {
    "contextAIDataIndex": 1720070370204,
    "gptSelection": "gpt-4o",
    "promptAIDataIndex": 1720070370205,
    "tokenId": "104"
   }
  },
  "userInputText": "what is a monkey?"
 },
 "lastUpdated": 1720070378885,
 "output": {
  "commands": [
   {
    "command": "talk",
    "parameters": {
     "message": "A monkey is a type of primate that typically has a long tail, lives in trees, and is known for its intelligence and agility."
    }
   }
  ],
  "costData": {
   "pricePerToken": 0.000005,
   "totalTokensUsed": 446
  }
 },
 "secondaryPartitionKey": "bot6InteractionXXXXXX104",
 "secondarySortKey": 1720070378845,
 "type": "BOT6_INTERACTION"
}

      */

      let userInput;

      if (
        bot6Interaction &&
        bot6Interaction.input &&
        bot6Interaction.input.userInputText
      ) {
        userInput = bot6Interaction.input.userInputText;
      } else {
        userInput = null;
      }

      let imageUrl;

      if (
        bot6Interaction &&
        bot6Interaction.input &&
        bot6Interaction.input.imageUrl
      ) {
        imageUrl = bot6Interaction.input.imageUrl;
      } else {
        imageUrl = null;
      }

      const interactionParams = {
        time: bot6Interaction.lastUpdated,
        actionType,
        userInput,
        imageUrl,
        result: bot6Interaction.output,
      };

      interactions.push(<AiInteractionRecord key={i} {...interactionParams} />);
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height,
        // height: "100%",

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {/* <Header
        tokenId={tokenId}
        thereIsBot6InteractionData={thereIsBot6InteractionData}
      /> */}

      <div
        style={{
          width: "100%",
          height: "100%",
          // height: "calc(100% - 40px)",

          // display: "block",
          // overflowY: "scroll",
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "flex-start",
          // alignItems: "center",

          display: "block",
          overflowY: "scroll",
        }}
      >
        {interactions}
      </div>

      {/* footer */}
      <Spacer height={0} />
    </div>
  );
};

export default Bot6InteractionsDisplay;
