export const deleteMTokenData = () => {
  localStorage.removeItem("address");
  localStorage.removeItem("mToken");
  localStorage.removeItem("expiry");
};

export const setMTokenData = ({ address, mToken, expiry }) => {
  localStorage.setItem("address", address);
  localStorage.setItem("mToken", mToken);
  localStorage.setItem("expiry", String(expiry));
};

export const getMTokenData = () => {
  const address = localStorage.getItem("address");
  const mToken = localStorage.getItem("mToken");
  const expiryString = localStorage.getItem("expiry");

  if (!address || !mToken || !expiryString) {
    deleteMTokenData();
    return null;
  }

  const expiry = Number(expiryString);

  if (expiry < Date.now()) {
    deleteMTokenData();
    return null;
  }

  return {
    address,
    mToken,
    expiry,
  };
};
