// import {
//   //getState,
//   setState,
// } from "../../../../reactStateManagement";
import {
  // getState,
  setState,
} from "../../../../reactStateManagement";
import getDoPromiseInQueue from "../../../getDoPromiseInQueue";

const doPromiseInQueue = getDoPromiseInQueue();

const _handleBot6WorldData = (data) => {
  setState("bot6WorldData", data.tokenIdToBot6Data);
};

const handleBot6WorldData = async (data) => {
  await doPromiseInQueue({
    operation: async () => {
      _handleBot6WorldData(data);
    },
  });
};

export { handleBot6WorldData };
