const componentToHex = (c) => {
  const hex = c.toString(16);

  return hex.length === 1 ? "0" + hex : hex;
};

const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const getPixels = ({ width, height, imageDataData }) => {
  const pixels = new Array(height).fill(null).map(() => {
    return new Array(width).fill(null);
  });

  for (let i = 0; i < height; i++) {
    for (let j = 0; j < width; j++) {
      const index = 4 * (i * width + j);

      const r = imageDataData[index];
      const g = imageDataData[index + 1];
      const b = imageDataData[index + 2];

      const hex = rgbToHex(r, g, b);

      pixels[i][j] = {
        backgroundColor: hex,
      };
    }
  }

  return pixels;
};
