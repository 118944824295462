import { getState } from "../../reactStateManagement";
import delay from "../delay";

export const waitForRequestIdEventToBeCompleted = async ({
  requestId,
  name = "f",
}) => {
  const MAX_WAIT_TIME = 10000;

  const WAIT_INTERVAL = 1000;

  const MAX_ITERATIONS = Math.round(MAX_WAIT_TIME / WAIT_INTERVAL);

  for (let i = 0; i < MAX_ITERATIONS; i++) {
    console.log(
      `waitForRequestIdToBeCompleted: wi ${i} - name ${name} - requestId ${requestId}`
    );

    const completedRequestIds = getState("completedRequestIds");

    const isCompleted = completedRequestIds.includes(requestId);

    if (isCompleted) {
      return;
    }

    await delay(WAIT_INTERVAL);
  }

  throw new Error(
    `waitForRequestIdToBeCompleted: requestId ${requestId} not completed`
  );
};
