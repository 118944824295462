import React from "react";
import { COLOR_0, COLOR_1, COLOR_5 } from "../../../../constants";

const GenericBot222Button = ({ text, onClick }) => {
  return (
    <button
      style={{
        width: "100%",
        height: 40,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",
      }}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default GenericBot222Button;
