import React from "react";
import { getState, setState } from "../../../../reactStateManagement";
import doSignOut from "../../../../api/doSignOut";

const AUTO_SIGN_OUT_MESSAGE = "nftitemland-autoSignOut";

export const SignOutButton = () => {
  const loading = getState("loading");
  const mobile = getState("mobile");

  const disabled = loading;

  return (
    <button
      style={{
        backgroundColor: loading ? "grey" : "white",

        width: "80%",
        // height: 35,
        height: mobile ? 20 : 35,

        border: "none",
        outline: "none",
        padding: 0,
        margin: 0,
      }}
      disabled={disabled}
      onClick={async () => {
        setState("loading", true);

        try {
          await doSignOut();

          // send AUTO_SIGN_OUT_MESSAGE to parent window
          window.parent.postMessage(AUTO_SIGN_OUT_MESSAGE, "*");

          setState(["snackMessage"], "logged out");
          setState(["snackTime"], 6);
        } catch (err) {
          console.log("error in logging out:");
          console.log(err);

          const errorMessage =
            err?.response?.data?.message || err.message || "error";

          setState(["snackMessage"], errorMessage);
          setState(["snackTime"], 6);
        }

        setState("loading", false);
      }}
    >
      {loading ? "loading" : "sign out"}
    </button>
  );
};
