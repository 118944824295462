// import React, { useEffect, useState } from "react";
import {
  BOT6_INTERACT_MODES,
  BOT6_MAX_TEXT_INPUT_LENGTH,
  BOT6_PROMPT_TYPES,
  MAIN_S3_BUCKET_URL,

  // EVENT_TYPES
} from "../../../../../../../constants";
import {
  getState,
  //getState,
  setState,
} from "../../../../../../../reactStateManagement";
import { uploadImageAIData } from "../../../../../../../utils/aiBoto/uploadImageAIData";
import delay from "../../../../../../../utils/delay";
// import delay from "../../../../../../../utils/delay";
import { interact } from "./interact";
// import Bot6DatumDisplay from "./Bot6DatumDisplay";

// const MAX_WAIT_TIME = 4200;
// const ITERATION_DELAY = 10;
// const MAX_ITERATIONS = MAX_WAIT_TIME / ITERATION_DELAY;

// const getCanvasLocal = async ({ canvasId }) => {
//   for (let i = 0; i < MAX_ITERATIONS; i++) {
//     const canvas = document.getElementById(canvasId);

//     if (canvas) {
//       return canvas;
//     }

//     await delay(ITERATION_DELAY);
//   }

//   return null;
// };

export const powerInteract = async ({
  tokenId,
  botoScreenCaptureModeType = null,
  bot6InteractMode,
}) => {
  const tokenIdToBot6InteractionInput = getState(
    "tokenIdToBot6InteractionInput"
  );

  const bot6InteractionInput = tokenIdToBot6InteractionInput[tokenId] || "";

  const data = {
    tokenId,
  };

  if (
    bot6InteractionInput &&
    typeof bot6InteractionInput === "string" &&
    bot6InteractionInput.length > 0
  ) {
    if (bot6InteractionInput.length > BOT6_MAX_TEXT_INPUT_LENGTH) {
      setState(["browserAlertData"], {
        message: "Boto interact failed, message too long",
      });

      return;
    } else {
      data.textInput = bot6InteractionInput;
    }
  }
  const node = document.getElementById("new-zoom-world");

  if (!node) {
    console.log("no node found");
    return;
  }

  setState(["botoScreenCaptureModeType"], botoScreenCaptureModeType);
  setState(["botoScreenCaptureMode"], true);

  await delay(100);

  // const canvas = await window.html2canvas(document.body);

  const canvas = await window.html2canvas(node);

  const result = await uploadImageAIData({
    canvasRef: {
      current: canvas,
    },
    tokenId,
  });

  console.log("the url:");
  console.log(`${MAIN_S3_BUCKET_URL}/${result.imageKey}`);

  // await delay(50);

  setState(["botoScreenCaptureMode"], false);
  setState(["botoScreenCaptureModeType"], null);

  const imageUrl = `${MAIN_S3_BUCKET_URL}/${result.imageKey}`;

  let promptType;

  if (bot6InteractMode === BOT6_INTERACT_MODES.BUILD) {
    promptType = BOT6_PROMPT_TYPES.BUILD;
  } else {
    promptType = null;
  }

  interact({
    tokenId,
    imageUrl,
    promptType,
  });
};
