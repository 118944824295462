import React from "react";
import { UNIT_WIDTH, Z_0 } from "../nzwConstants";
import { getState, setState } from "../../../reactStateManagement";
import { getUserYToUse } from "./getUserYToUse";
import { getLowestX } from "./getLowestX";
import { BuildUnit } from "./BuildUnit";
import { BOT6_SCREEN_CAPTURE_MODE_TYPES } from "../../../constants";

/*
  In progress: Quantize mode

    Quantize mode will allow the user to place objects in a grid-like fashion. This will be useful for placing objects in a more precise manner.
*/
const NBuildGrid = ({
  userX,
  userY,
  minX,
  minY,
  nzwW,
  nzwH,
  pressedDownOnBuildPlane,
  windowHeight,
  scale,
}) => {
  const buildMode = getState("buildMode");
  const putObjectMode = getState("putObjectMode");
  const quantizeMode = getState("quantizeMode");
  const mobile = getState("mobile");
  const botoScreenCaptureMode = getState("botoScreenCaptureMode");
  const botoScreenCaptureModeType = getState("botoScreenCaptureModeType");

  if (!buildMode && !botoScreenCaptureMode) {
    return null;
  }

  if (botoScreenCaptureMode && !botoScreenCaptureModeType) {
    return null;
  }

  const isScreenCaptureCoordinateGridMode =
    botoScreenCaptureModeType ===
    BOT6_SCREEN_CAPTURE_MODE_TYPES.COORDINATE_GRID;

  const halfWidth = nzwW / 2;

  const lowestX = getLowestX({
    quantizeMode,
    minX,

    halfWidth,
  });

  const UNIT_COUNT_OFFSET = 0;

  const totalNumberOfXUnits =
    Math.floor(halfWidth / UNIT_WIDTH) * 2 + 4 + UNIT_COUNT_OFFSET;
  // const totalNumberOfXUnits = 22; // temporarily hard-coded

  // let totalNumberOfYUnits =
  //   (Math.floor(nzwH / UNIT_WIDTH) + 1 + UNIT_COUNT_OFFSET) * 2;

  const Y_UNIT_SCALED_OFFSET_COUNT = windowHeight / scale / UNIT_WIDTH;

  let totalNumberOfYUnits =
    (Math.floor(nzwH / UNIT_WIDTH) + 1 + UNIT_COUNT_OFFSET) * 1.7 +
    Y_UNIT_SCALED_OFFSET_COUNT -
    0;

  // user y - 3 units
  // const lowestY = userY - 3 * UNIT_WIDTH;

  // totalNumberOfYUnits = 4; // temporarily hard-coded

  const userYToUse = getUserYToUse({
    quantizeMode,
    userY,
    minY,
  });

  const yOffset = 5 * UNIT_WIDTH;

  const unitElements = [];

  for (let i = 0; i < totalNumberOfXUnits; i++) {
    const x = lowestX + i * UNIT_WIDTH;

    const displayX = minX + x;

    for (let j = 0; j < totalNumberOfYUnits; j++) {
      // const y = userY + j * UNIT_WIDTH;
      const y = userYToUse + j * UNIT_WIDTH - yOffset;
      const displayY = minY + y;

      unitElements.push(
        <BuildUnit
          key={`${i}-${j}`}
          userX={userX}
          minX={minX}
          minY={minY}
          x={x}
          y={y}
          displayX={displayX}
          displayY={displayY}
          putObjectMode={putObjectMode}
          quantizeMode={quantizeMode || botoScreenCaptureMode}
          botoScreenCaptureMode={botoScreenCaptureMode}
          isScreenCaptureCoordinateGridMode={isScreenCaptureCoordinateGridMode}
          mobile={mobile}
          mouseIsDown={pressedDownOnBuildPlane}
        />
      );
    }
  }

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",

        // backgroundColor: "darkblue",
        backgroundColor: "transparent",

        zIndex: Z_0,
      }}
      onMouseDown={() => {
        setState(["pressedDownOnBuildPlane"], true);
      }}
      onMouseUp={() => {
        setState(["pressedDownOnBuildPlane"], false);
      }}
      // onMouseLeave={() => {
      //   setMouseIsDown(false);
      // }}
    >
      {unitElements}
    </div>
  );
};

export default NBuildGrid;
