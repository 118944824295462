import React from "react";

const useGameComponent = ({ id }) => {
  // segment into three parts

  // part 1 - context menu

  React.useEffect(() => {
    const $ = window.$;
    const $element = $(`#${id}`);
    const handleContextMenu = function (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      return false;
    };
    $element.bind("contextmenu", handleContextMenu);
    return () => {
      $element.unbind("contextmenu", handleContextMenu);
    };
  }, []);

  // part 2 - double click
  React.useEffect(() => {
    function clearSelection() {
      if (document.selection && document.selection.empty) {
        document.selection.empty();
      } else if (window.getSelection) {
        var sel = window.getSelection();
        sel.removeAllRanges();
      }
    }
    const $ = window.$;
    const $element = $(`#${id}`);
    const handleDoubleClick = function (event) {
      event.preventDefault();
      clearSelection();
    };
    $element.bind("dblclick", handleDoubleClick);
    return () => {
      $element.unbind("dblclick", handleDoubleClick);
    };
  }, []);

  // part 3 - touch end

  React.useEffect(() => {
    const $ = window.$;

    const $element = $(`#${id}`);

    const handleTouchEnd = function (event) {
      event.preventDefault();
      $element.click();
    };

    $element.bind("touchend", handleTouchEnd);

    return () => {
      $element.unbind("touchend", handleTouchEnd);
    };
  }, []);
};

export default useGameComponent;
