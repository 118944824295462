import React from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // EVENT_TYPES
} from "../../../../constants";
import GenericBot6Button from "./GenericBot6Button";
import Spacer from "../../../../commonComponents/Spacer";
// import { emitEvent } from "../../../../utils/emitEvent";
import getAuth from "../../../../utils/getAuth";
import { doBrowserMint } from "../../../../utils/pixels/doBrowserMint";

const BOTOS_SPECIAL_HEX_M = "#6b0709"; // "6 Boto 9"
const BOTOS_SPECIAL_HEX_F = "#4b0702"; // "4 Boto 2"

const doMint = async ({ female = false }) => {
  const auth = getAuth();

  if (!auth) {
    return;
  }

  const backgroundColor = female ? BOTOS_SPECIAL_HEX_F : BOTOS_SPECIAL_HEX_M;

  const pixelSpecData = [
    [
      {
        backgroundColor,
      },
    ],
  ];

  // const pixelSpec=  {
  // width: 1,
  // height: 1,
  // pixels: pixelSpecData,
  // };

  await doBrowserMint({
    pixelSpecData,
  });
};

const MintSection = ({ height }) => {
  return (
    <div
      style={{
        width: "100%",
        height,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Spacer height={"5%"} />

      <div
        style={{
          height: "20%",
          width: "100%",

          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <GenericBot6Button
          text={"Mint Boto F"}
          width={"40%"}
          height={"100%"}
          onClick={async () => {
            doMint({
              female: true,
            });
          }}
        />

        <GenericBot6Button
          text={"Mint Boto M"}
          width={"40%"}
          height={"100%"}
          onClick={async () => {
            doMint({});
          }}
        />
      </div>
      <Spacer height={"5%"} />

      <div
        style={{
          width: "100%",
          height: "70%",

          display: "block",
          overflowY: "scroll",
        }}
      >
        <Spacer height={10} />

        {"Minting a new Boto costs 0.1 Prime Points. " +
          "Soon after your Boto is minted, it will appear in your crypto wallet and " +
          "here in the Boto list."}
      </div>
    </div>
  );
};

export default MintSection;
