import axios from "axios";
import { getMTokenData, deleteMTokenData } from "../utils/mToken";
import { API_BASE_URL } from "../constants";
import { setState } from "../reactStateManagement";

// const NFTITEM_BASE_URL = "http://localhost:6969";

const doSignOut = async () => {
  const mTokenData = getMTokenData();

  if (!mTokenData) {
    return;
  }

  const { address, mToken } = mTokenData;

  await axios({
    method: "post",
    url: `${API_BASE_URL}/expansive-world/logout-m`,
    headers: {
      "Content-Type": "application/json",
      "nftitem-mtoken": mToken,
      "nftitem-address": address,
    },
  });

  // setMTokenData(response.data);

  deleteMTokenData();

  setState(["userData"], null);
};

export default doSignOut;
