import React, { useEffect } from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_5,
  // EVENT_TYPES,
  // EVENT_TYPES
} from "../../../../../constants";
// import { emitEvent } from "../../../../../utils/emitEvent";
// import getAuth from "../../../../../utils/getAuth";
// import { getMTokenData } from "../../../../../utils/mToken";
// import { getState } from "../../../../../reactStateManagement";
import { Title } from "./Title";
import { Bot6DisplayList } from "./Bot6DisplayList";
import { loadBot6s } from "./loadBot6s";

/*
  {
    "data": {
        "bot6Data": {
            "bot6s": [
                {
                    "lastUpdated": 1718966226429,
                    "partitionKey": "bot6XXXXXX0x84040E364001f367E10a068Ae137893d7Dd1C289XXXXXX104",
                    "secondaryPartitionKey": "bot6XXXXXX0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
                    "secondarySortKey": 104,
                    "type": "BOT6"
                }
            ],
            "paginationValue": null
        }
    }

  */

const baseLevelStateObject = {
  hasInitialLoadStarted: false,
};

const BotoList = ({ height, zIndex = "unset" }) => {
  // const userData = getState("userData");

  const baseLevelStateObjectRef = React.useRef(baseLevelStateObject);

  // // const mobile = getState("mobile");

  // if (!userData) {
  //   return;
  // }
  useEffect(() => {
    if (baseLevelStateObjectRef.current.hasInitialLoadStarted) {
      return;
    }
    baseLevelStateObjectRef.current.hasInitialLoadStarted = true;

    console.log("loading bot6s");
    loadBot6s();
  }, []);

  return (
    <div
      style={{
        width: "100%",

        // maxHeight: 420,
        height,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        zIndex,

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Title />
      <Bot6DisplayList />
    </div>
  );
};

export default BotoList;
