import React from "react";

const Spacer = ({
  maxWidth = "unset",
  maxHeight = "unset",
  width = 1,
  height = 1,
  borderRadius = 0,
  backgroundColor = "transparent",
}) => {
  return (
    <div
      style={{
        width,
        height,
        backgroundColor,
        maxWidth,
        maxHeight,
        borderRadius,
      }}
    />
  );
};

export default Spacer;
