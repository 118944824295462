function isPointInside(point, rect) {
  const [x, y] = point;
  const [x1, y1, x2, y2] = rect;
  return x >= x1 && x <= x2 && y >= y1 && y <= y2;
}
export function isAnyPointInside(rect1, rect2) {
  const [xb1, yb1, xb2, yb2] = rect2;

  const inside = [
    [xb1, yb1],
    [xb2, yb1],
    [xb1, yb2],
    [xb2, yb2],
  ].some((point) => isPointInside(point, rect1));

  return inside;
}
