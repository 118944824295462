// Helper function to average colors
function averageColor(colors) {
  let r = 0;
  let g = 0;
  let b = 0;
  let total = 0;
  for (const color of colors) {
    const rgb = parseInt(color.backgroundColor.substring(1), 16);
    r += (rgb >> 16) & 0xff;
    g += (rgb >> 8) & 0xff;
    b += rgb & 0xff;
    total++;
  }
  const averageR = Math.floor(r / total);
  const averageG = Math.floor(g / total);
  const averageB = Math.floor(b / total);
  // Convert the RGB values to hex and pad with zeros if necessary
  const hexR = averageR.toString(16).padStart(2, "0");
  const hexG = averageG.toString(16).padStart(2, "0");
  const hexB = averageB.toString(16).padStart(2, "0");
  return `#${hexR}${hexG}${hexB}`;
}

export function downsampleSquare(a, n) {
  const m = a.length;
  const size = Math.max(1, Math.ceil(m / n));

  const downsampled = Array(n)
    .fill(0)
    .map(() => Array(n).fill(0));

  for (let i = 0; i < n; i++) {
    for (let j = 0; j < n; j++) {
      const startIndex = i * size;
      const endIndex = Math.min((i + 1) * size, m);
      const startJIndex = j * size;
      const endJIndex = Math.min((j + 1) * size, a[0].length);

      const colors = [];
      for (let k = startIndex; k < endIndex; k++) {
        for (let l = startJIndex; l < endJIndex; l++) {
          if (a[k] && a[k][l]) {
            colors.push(a[k][l]);
          }
        }
      }

      if (colors.length > 0) {
        downsampled[i][j] = { backgroundColor: averageColor(colors) };
      }
    }
  }

  return downsampled;
}
