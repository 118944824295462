import React from "react";
import {
  BOT6_INTERACT_MODES,
  COLOR_0,
  COLOR_1,
  COLOR_5,
  DEFAULT_START_X_PERCENT_STRING,
  DEFAULT_START_Y_PERCENT_STRING,
} from "../../../../../constants";
import {
  getXCoordFromXPercentString,
  getYCoordFromYPercentString,
} from "../../../../../coordsMapper";
import { Bot6ExpandButton } from "../Bot6ExpandButton";
import { getState, setState } from "../../../../../reactStateManagement";
import { getDisplayNameFromBot6InteractMode } from "../../../../../utils/aiBoto/bot6Tools";

// TODO: SWITCH TO COORDS
// const DEFAULT_X_COORD = 0;
// const DEFAULT_Y_COORD = 0;

const DEFAULT_BOTO_LEVEL = 1;
const DEFAULT_BOTO_EXP = 0;

const topSectionHeight = 30;
const modeButtonSectionHeight = 30;

const Bot6DatumDisplay = ({ datum, height, isModeSelect, setIsModeSelect }) => {
  const [infoMode, setInfoMode] = React.useState(false);

  if (!datum) {
    return null;
  }
  const x = datum.x || DEFAULT_START_X_PERCENT_STRING;
  const xCoord = getXCoordFromXPercentString(x);

  const y = datum.y || DEFAULT_START_Y_PERCENT_STRING;
  const yCoord = getYCoordFromYPercentString(y);

  const operationalInfoContent = (
    <>
      {"Each interaction costs under or around 0.01 Prime Points. " +
        "A minimum balance of 1 Prime Point is required to interact."}
      <br />
      <br />
      {"Modes: "}
      <br />
      <br />
      {"Interact($) - " +
        "Boto can move and talk, including answering questions."}
      <br />
      <br />
      {"Explore($$) - " +
        "Includes vision, allows for more accurate movement within the metaverse, " +
        "provides descriptions of pixel creations, " +
        "and feedback based on what Boto sees."}
      <br />
    </>
  );

  const botoInfoContent = (
    <>
      {"x: " + xCoord}
      <br />
      {"y: " + yCoord}
      <br />
      {"Level: " +
        (datum.level || DEFAULT_BOTO_LEVEL) +
        ", Exp: " +
        (datum.exp || DEFAULT_BOTO_EXP)}
    </>
  );

  const botoInfoContentElement = (
    <div
      style={{
        // height: "calc(100% - 30px)",
        height: `calc(100% - ${topSectionHeight}px - ${modeButtonSectionHeight}px)`,
        // backgroundColor: "green",

        // backgroundColor: 'black'm

        width: "calc(100% )",

        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "flex-start",
        // alignItems: "center",

        display: "inline-block",

        overflowY: "scroll",
      }}
    >
      {infoMode ? operationalInfoContent : botoInfoContent}
    </div>
  );

  const modeButtonSection = (
    <div
      style={{
        // height: "calc(100% - 30px)",
        height: modeButtonSectionHeight,
        // backgroundColor: "green",

        // backgroundColor: "beige",

        borderTop: `1px solid ${COLOR_5}`,

        boxSizing: "border-box",

        width: "calc(100% )",

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <button
        style={{
          // position: "absolute",
          // right: 0,
          // top: 30,

          width: "calc(100% - 30px)",
          height: "100%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          border: `1px solid ${COLOR_5}`,
          boxSizing: "border-box",

          // backgroundColor: COLOR_0,
          backgroundColor: isModeSelect ? COLOR_5 : COLOR_0,
          color: COLOR_1,
        }}
        onClick={() => {
          setInfoMode(false);
          setIsModeSelect(!isModeSelect);
        }}
      >
        {"Mode"}
      </button>
      <button
        style={{
          // position: "absolute",
          // right: 0,
          // top: 30,

          width: 30,
          height: "100%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          border: `1px solid ${COLOR_5}`,
          boxSizing: "border-box",

          // backgroundColor: COLOR_0,
          backgroundColor: infoMode ? COLOR_5 : COLOR_0,
          color: COLOR_1,
        }}
        onClick={() => {
          setInfoMode(!infoMode);
        }}
      >
        {"i"}
      </button>
    </div>
  );

  let mainContents;

  if (isModeSelect) {
    const modeSelections = [
      {
        value: null,
      },
      {
        value: BOT6_INTERACT_MODES.EXPLORE,
      },
      {
        value: BOT6_INTERACT_MODES.BUILD,
      },
    ].map((selection, index) => {
      return (
        <div
          key={index}
          style={{
            width: "100%",
            height: 25,

            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            marginTop: index === 0 ? 5 : 0,
            marginBottom: 5,
          }}
        >
          <button
            style={{
              width: "80%",
              height: "100%",

              borderRadius: 10,
              border: `1px solid ${COLOR_5}`,
              boxSizing: "border-box",

              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",

              backgroundColor: COLOR_0,
              color: COLOR_1,
            }}
            onClick={() => {
              const tokenIdToBot6InteractMode = getState(
                "tokenIdToBot6InteractMode"
              );

              const newTokenIdToBot6InteractMode = {
                ...tokenIdToBot6InteractMode,
                [datum.secondarySortKey]: selection.value,
              };

              setState(
                ["tokenIdToBot6InteractMode"],
                newTokenIdToBot6InteractMode
              );

              setIsModeSelect(false);
            }}
          >
            {getDisplayNameFromBot6InteractMode(selection.value)}
          </button>
        </div>
      );
    });

    const modeSelectElement = (
      <div
        style={{
          height: `calc(100% - ${topSectionHeight}px - ${modeButtonSectionHeight}px)`,
          width: "100%",

          // backgroundColor: "pink",

          display: "inline-block",
          overflowY: "scroll",
        }}
      >
        {modeSelections}
      </div>
    );
    mainContents = (
      <>
        <div
          style={{
            width: "100%",
            height: topSectionHeight,

            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",

            position: "relative",
          }}
        >
          <div
            style={{
              height: "100%",
              // backgroundColor: "green",

              // backgroundColor: 'black'm

              width: "calc(100% - 30px)",

              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >{`Mode Select`}</div>

          <Bot6ExpandButton />
        </div>

        {infoMode ? botoInfoContentElement : modeSelectElement}

        {modeButtonSection}
      </>
    );
  } else {
    mainContents = (
      <>
        <div
          style={{
            width: "100%",
            height: topSectionHeight,

            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",

            position: "relative",
          }}
        >
          <div
            style={{
              height: "100%",
              // backgroundColor: "green",

              // backgroundColor: 'black'm

              width: "calc(100% - 30px)",

              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >{`Boto ${datum.secondarySortKey} (${datum.sex})`}</div>

          {/* <button
          style={{
            height: "100%",
            // backgroundColor: "black",
            width: 30,

            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            backgroundColor: COLOR_0,
            color: COLOR_1,
            borderColor: COLOR_5,
            borderWidth: 1,
            borderStyle: "solid",

            borderRadius: 10,
          }}
        >
          {"<"}
        </button> */}

          <Bot6ExpandButton />
        </div>
        {botoInfoContentElement}

        {modeButtonSection}
      </>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",

        fontSize: 14,
      }}
    >
      {mainContents}
    </div>
  );
};

export default Bot6DatumDisplay;
