import React from "react";
import { COLOR_0, COLOR_1, COLOR_5, ITEM_TYPES } from "../../../../constants";
import { setState } from "../../../../reactStateManagement";

const AdvBuildButton = ({ type }) => {
  return (
    <button
      style={{
        width: "100%",
        height: 40,

        backgroundColor: COLOR_0,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",
        boxSizing: "border-box",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        setState(["buildMode"], true);
        setState(["putObjectMode"], {
          itemType: ITEM_TYPES.STORE_0,
        });
      }}
    >
      {type}
    </button>
  );
};

const AdvBuild = () => {
  return (
    <div
      style={{
        width: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AdvBuildButton type={ITEM_TYPES.STORE_0} />
    </div>
  );
};

export default AdvBuild;
