import React, {
  //  useState
  useEffect,
} from "react";
import {
  // BOTTOM_SECTION_HEIGHT_MOBILE,
  // BOTTOM_SECTION_HEIGHT_DESKTOP,
  COLOR_0,
  COLOR_1,
  COLOR_2,
  ERASER,
  // COLOR_3,
  // EVENT,
  // EVENT_TYPES,
  Z_INDICES,
} from "../../../constants";
import {
  getState,
  setState,
  // setState
} from "../../../reactStateManagement";
// import { SignInBox } from "./SignInBox";
// import { getEvent } from "../../../utils/getEvent";
import Spacer from "../../../commonComponents/Spacer";
// import handleCmdMessage from "./handleCmdMessage";
import usePreventSpacebarPress from "../../../utils/effects/usePreventSpacebarPress";
import useGameComponent from "../../../utils/effects/useGameComponent";
// import { SignedInBox } from "./SignedInBox";

const MESSAGE_BOX_WIDTH = 175;
// const MESSAGE_BOX_HEIGHT = 150;
// const MESSAGE_BOX_HEIGHT = BOTTOM_SECTION_HEIGHT_MOBILE;
const MAX_MESSAGE_LENGTH = 7;

// const SAMPLE_MESSAGE_DATA = [
//   {
//     text: "hello",
//     sender: "babebabe-babe-4bae-babe-babebabebabe",
//   },
//   {
//     text: "hi",
//     sender: "beefbeef-dead-4bee-dead-deadbeefdead",
//   },

//   {
//     text: "yo",
//     sender: "babebabe-babe-4bae-babe-babebabebabe",
//   },

//   {
//     text: "hey",
//     sender: "beefbeef-dead-4bee-dead-deadbeefdead",
//   },
// ];

const ColorButton = ({
  backgroundColor,
  displayOnlyMode = false,
  eraser = false,
  nObjectPutBackgroundColor,

  mobile,
}) => {
  let bgColor;

  if (eraser) {
    bgColor = "#FFFFFF";
  } else if (backgroundColor === null) {
    bgColor = "#8B0000";
  } else {
    bgColor = backgroundColor;
  }

  let contents = null;

  if (eraser) {
    contents = (
      <div
        style={{
          width: "100%",
          height: "100%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          // backgroundColor: bgColor,
          color: "black",
        }}
      >
        {"E"}
      </div>
    );
  }

  let border;

  if (eraser && nObjectPutBackgroundColor === ERASER) {
    border = "4px solid green";
  } else {
    border = "none";
  }

  return (
    <button
      style={{
        width: mobile ? 30 : 40,
        height: mobile ? 30 : 40,

        backgroundColor: bgColor,

        border,
        outline: "none",

        boxSizing: "border-box",
        padding: 0,
        margin: 0,

        cursor: "pointer",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        if (displayOnlyMode) {
          backgroundColor = "#8B0000";
        }

        if (eraser) {
          if (nObjectPutBackgroundColor === ERASER) {
            const lastSetNObjectPutBackgroundColor = getState(
              "lastSetNObjectPutBackgroundColor"
            );

            setState(
              ["nObjectPutBackgroundColor"],
              lastSetNObjectPutBackgroundColor
            );
          } else {
            setState(["nObjectPutBackgroundColor"], ERASER);
          }
        } else {
          setState(["nObjectPutBackgroundColor"], backgroundColor);
        }
      }}
    >
      {contents}
    </button>
  );
};

const EyeDropperButton = ({
  // backgroundColor,
  // displayOnlyMode = false,

  mobile,
}) => {
  return (
    <button
      style={{
        width: mobile ? 30 : 40,
        height: mobile ? 30 : 40,

        backgroundColor: "black",

        border: "none",
        outline: "none",
        padding: 0,
        margin: 0,

        cursor: "pointer",
      }}
      onClick={async () => {
        // setState(["nObjectPutBackgroundColor"], backgroundColor);

        try {
          const eyeDropper = new window.EyeDropper();

          const color = await eyeDropper.open();

          if (
            typeof color !== "object" ||
            typeof color.sRGBHex !== "string" ||
            color.sRGBHex.length !== 7
          ) {
            throw new Error("Invalid color");
          }

          setState(["nObjectPutBackgroundColor"], color.sRGBHex);
        } catch (err) {
          const isCanceled = err?.message
            ?.toLowerCase()
            .includes("user canceled the selection");

          if (isCanceled) {
            console.log("user canceled eye dropper XXXX");
            return;
          }

          console.log("Error in eye dropper:");
          console.log(err);

          setState(["browserAlertData"], {
            message: "EyeDropper not available in this browser",
          });
        }
      }}
    >
      <img
        src={
          "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/magnifying_glass.png"
        }
        style={{
          width: "100%",
          height: "100%",
        }}
        alt={"eye dropper"}
      />
    </button>
  );
};

const CloseButton = () => {
  const id = "close-npaint-button";

  React.useEffect(() => {
    //     document.getElementById('yourElement').oncontextmenu = function(event) {
    //     event.preventDefault();
    //     event.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available?
    //     event.stopImmediatePropagation();
    //     return false;
    // };

    const $ = window.$;

    const $button = $(`#${id}`);

    const handleContextMenu = function (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      return false;
    };

    $button.bind("contextmenu", handleContextMenu);

    return () => {
      $button.unbind("contextmenu", handleContextMenu);
    };
  }, []);

  React.useEffect(() => {
    // listen for escape key press, b or B
    const $ = window.$;

    const $document = $(document);

    const listenerFunction = (e) => {
      if (e.key === "Escape") {
        setState(["buildMode"], false);
      }
    };

    $document.on("keydown", listenerFunction);

    return () => {
      $document.off("keydown", listenerFunction);
    };
  }, []);

  return (
    <button
      id={id}
      style={{
        position: "absolute",

        top: 0,
        right: 0,

        width: 40,
        height: 40,

        backgroundColor: "red",

        zIndex: 2323232,

        fontSize: 20,
      }}
      onClick={() => {
        // setState(["nPaintMode"], false);
        setState(["buildMode"], false);
      }}
    >
      {"x"}
    </button>
  );
};

const LockButton = () => {
  // align grid with unit width
  const id = "lock-grid-button";

  useGameComponent({
    id,
  });
  // React.useEffect(() => {
  //   //     document.getElementById('yourElement').oncontextmenu = function(event) {
  //   //     event.preventDefault();
  //   //     event.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available?
  //   //     event.stopImmediatePropagation();
  //   //     return false;
  //   // };

  //   const $ = window.$;

  //   const $button = $(`#${id}`);

  //   const handleContextMenu = function (event) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     event.stopImmediatePropagation();
  //     return false;
  //   };

  //   $button.bind("contextmenu", handleContextMenu);

  //   return () => {
  //     $button.unbind("contextmenu", handleContextMenu);
  //   };
  // }, []);

  const quantizeMode = getState("quantizeMode");

  return (
    <button
      id={id}
      style={{
        position: "absolute",

        top: 40,
        right: 0,

        width: 40,
        height: 25,

        // backgroundColor: "orange",
        backgroundColor: quantizeMode ? "#FF0000" : "#8B0000",

        zIndex: 2323232,

        fontSize: 14,
      }}
      onClick={() => {
        // setState(["nPaintMode"], false);
        // setState(["buildMode"], false);

        setState(["quantizeMode"], !quantizeMode);
      }}
    >
      {/* {"lock"} */}
      {"grid"}
    </button>
  );
};

/**
 *  Contains input and button for sending messages
 */
const InputSection = ({ mobile }) => {
  const loading = getState("loading");

  usePreventSpacebarPress({
    id: "select-npaint-color-button",
  });

  useEffect(() => {
    const $ = window.$;

    const $input = $("#npaint-color-input");

    $input.on("keydown", (e) => {
      if (e.key === "Enter") {
        // sendMessage();
      }
    });

    return () => {
      $input.off("keydown");
    };
  }, []);

  useEffect(() => {
    // if not focused and press "t" (for talk), focus on message box

    const $ = window.$;

    const $input = $("#npaint-color-input");

    const $document = $(document);

    const listenerFunction = (e) => {
      if (e.key === "t") {
        setTimeout(() => {
          $input.focus();
        }, 225);
      }
    };

    $document.on("keydown", listenerFunction);

    return () => {
      $document.off("keydown", listenerFunction);
    };
  }, []);

  useEffect(() => {
    // on focus, set state key "focus" to true

    const $ = window.$;

    const $input = $("#npaint-color-input");

    const handleFocus = () => {
      setState(["focus"], true);
    };

    $input.on("focus", handleFocus);

    return () => {
      $input.off("focus", handleFocus);
    };
  }, []);

  useEffect(() => {
    // on blur, set state key "focus" to false

    const $ = window.$;

    const $input = $("#npaint-color-input");

    const handleBlur = () => {
      setState(["focus"], false);
    };

    $input.on("blur", handleBlur);

    return () => {
      $input.off("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    // if focused and press "esc", unfocus message box

    const $ = window.$;

    const $input = $("#npaint-color-input");

    const $document = $(document);

    const listenerFunction = (e) => {
      if (e.key === "Escape") {
        $input.blur();
      }
    };

    $document.on("keydown", listenerFunction);

    return () => {
      $document.off("keydown", listenerFunction);
    };
  }, []);

  useEffect(() => {
    // on focus brighten the message box

    const $ = window.$;

    const $input = $("#npaint-color-input");

    function brightenMessageBox() {
      $input.css("backgroundColor", "#8B0000");
    }

    $input.on("focus", brightenMessageBox);

    function dimMessageBox() {
      $input.css("backgroundColor", "black");
    }

    $input.on("blur", dimMessageBox);

    return () => {
      $input.off("focus", brightenMessageBox);
      $input.off("blur", dimMessageBox);
    };
  }, []);

  // console.log(messages.length)

  return (
    <div
      style={{
        width: "100%",

        height: "25%",
        backgroundColor: "turquoise",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        position: "relative",
      }}
    >
      <input
        id={"npaint-color-input"}
        style={{
          width: "80%",
          height: "100%",

          border: "none",
          outline: "none",
          padding: 0,
          margin: 0,

          backgroundColor: "#d70000",

          color: COLOR_1,
        }}
        placeholder={"press t to type"}
        onFocus={() => {
          setState(["focus"], true);
        }}
        onBlur={() => {
          setState(["focus"], false);
        }}
        onChange={() => {
          const $ = window.$;

          const $input = $("#npaint-color-input");

          let bgColor = $input.val();

          if (bgColor.length > MAX_MESSAGE_LENGTH) {
            return;
          }

          bgColor = bgColor.trim().toLowerCase();

          // get hash count
          const hashCount = bgColor.split("#").length - 1;

          if (hashCount > 1) {
            return;
          }

          if (bgColor[0] !== "#") {
            bgColor = "#" + bgColor;
          }
          // ensure rest is hex

          let hex = bgColor.substring(1);

          for (let i = 0; i < hex.length; i++) {
            const char = hex[i];

            if (isNaN(parseInt(char, 16))) {
              return;
            }
          }

          // let text = e.target.value;

          // text = text.substring(0, MAX_MESSAGE_LENGTH);

          let text = bgColor;

          setState(["nObjectPutBackgroundColorInput"], text);
        }}
        value={getState("nObjectPutBackgroundColorInput")}
      />

      <button
        id={"select-npaint-color-button"}
        style={{
          width: "20%",
          height: "100%",

          border: "none",
          outline: "none",
          padding: 0,
          margin: 0,

          position: "relative",

          backgroundColor: loading ? "grey" : COLOR_0,

          cursor: loading ? "default" : "pointer",

          fontSize: mobile ? 12 : 16,
        }}
        disabled={loading}
        onClick={() => {
          const $ = window.$;

          const $input = $("#npaint-color-input");

          let bgColor = $input.val();

          if (bgColor.length > 7 || bgColor.length < 7) {
            setState(["browserAlertData"], { message: "Invalid color length" });

            return;
          }

          bgColor = bgColor.trim().toLowerCase();

          // get hash count
          const hashCount = bgColor.split("#").length - 1;

          if (hashCount > 1) {
            return;
          }

          if (bgColor[0] !== "#") {
            bgColor = "#" + bgColor;
          }
          // ensure rest is hex

          let hex = bgColor.substring(1);

          for (let i = 0; i < hex.length; i++) {
            const char = hex[i];

            if (isNaN(parseInt(char, 16))) {
              return;
            }
          }

          console.log(`
          
          
            MEGA LOG ${JSON.stringify(
              {
                bgColor,
              },
              null,
              4
            )}
            )
          
          
          `);

          setState(["nObjectPutBackgroundColor"], bgColor);
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            backgroundColor: COLOR_2,
            color: COLOR_1,
          }}
        >
          {"Select"}
        </div>
      </button>
    </div>
  );
};

// const MOBILE_MODE_WIDTH_THRESHOLD = 800;

const NPaint = () => {
  // const loading = getState("loading");
  // const userData = getState("userData");
  const windowWidth = getState("windowWidth");
  const messages = getState("messages");
  const mobile = getState("mobile");
  const nObjectPutBackgroundColor = getState("nObjectPutBackgroundColor");

  // const mobileMode = windowWidth < MOBILE_MODE_WIDTH_THRESHOLD;
  // const mobileMode = getState("mobile");

  // const MESSAGE_BOX_HEIGHT = mobileMode
  //   ? BOTTOM_SECTION_HEIGHT_MOBILE
  //   : BOTTOM_SECTION_HEIGHT_DESKTOP;
  const LOCK_BUTTON_HIDDEN_OVERRIDE = true;

  return (
    <div
      style={{
        // width: mobileMode ? MESSAGE_BOX_WIDTH : MESSAGE_BOX_WIDTH * 1.7,
        width: "50%",
        height: "100%",
        backgroundColor: COLOR_0,

        // position: "absolute",
        bottom: 0,
        left: (windowWidth - MESSAGE_BOX_WIDTH) / 2,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",

        zIndex: Z_INDICES.TOP_LAYER,
      }}
    >
      <CloseButton />
      {LOCK_BUTTON_HIDDEN_OVERRIDE ? null : <LockButton />}

      <div
        style={{
          // width: "100%",
          width: `calc(100% - 40px)`,
          height: "75%",
          backgroundColor: "black",

          display: "flex",
          // wrap
          // flexDirection: "row"
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",

          position: "relative",
        }}
      >
        <ColorButton
          backgroundColor={getState("nObjectPutBackgroundColor")}
          displayOnlyMode={true}
          mobile={mobile}
        />
        <ColorButton backgroundColor={"#FF4D4D"} mobile={mobile} />
        <ColorButton backgroundColor={"#FF8200"} mobile={mobile} />
        <ColorButton backgroundColor={"#FFFF00"} mobile={mobile} />
        <ColorButton backgroundColor={"#00FF00"} mobile={mobile} />
        <ColorButton backgroundColor={"#0000FF"} mobile={mobile} />
        <ColorButton backgroundColor={"#8200FF"} mobile={mobile} />
        {mobile ? null : (
          <EyeDropperButton backgroundColor={"#000000"} mobile={mobile} />
        )}
        <ColorButton
          mobile={mobile}
          eraser={true}
          nObjectPutBackgroundColor={nObjectPutBackgroundColor}
        />
      </div>

      <Spacer width={"100%"} height={4} backgroundColor={COLOR_0} />

      <InputSection messages={messages} mobile={mobile} />
    </div>
  );
};

export { NPaint };
