import {
  //getState,
  setState,
} from "../../../../reactStateManagement";
import getDoPromiseInQueue from "../../../getDoPromiseInQueue";

const doPromiseInQueue = getDoPromiseInQueue();

const _handleBot2Data = (addressToBot2Data) => {
  // const newBot2Data = Object.keys(addressToBot2Data).map((address) => {
  //   return {
  //     address,
  //     data: addressToBot2Data[address],
  //   };
  // });

  // setState("bot2Data", newBot2Data);

  setState("bot2Data", addressToBot2Data);
};

const handleBot2Data = async ({ addressToBot2Data }) => {
  await doPromiseInQueue({
    operation: async () => {
      _handleBot2Data(addressToBot2Data);
    },
  });
};

export { handleBot2Data };
