import React from "react";
import { emitEvent } from "../../../../utils/emitEvent";
import getAuth from "../../../../utils/getAuth";
import { getMTokenData } from "../../../../utils/mToken";
import GenericBot222Button from "./GenericBot222Button";
import { EVENT_TYPES } from "../../../../constants";

const InteractWithBot2Button = () => {
  return (
    <GenericBot222Button
      text={"Interact"}
      onClick={() => {
        // console.log("Create Bot2");
        const auth = getAuth();

        if (!auth) {
          return;
        }

        const mTokenData = getMTokenData();

        if (!mTokenData) {
          alert("No mTokenData - must be signed in");
          return;
        }

        emitEvent({
          data: {
            auth,
            mToken: mTokenData.mToken,
            address: mTokenData.address,
          },
          type: EVENT_TYPES.BOT2_INTERACT,
        });
      }}
    />
  );
};

export default InteractWithBot2Button;
