import React, { useEffect } from "react";
import { getState, setState } from "../../reactStateManagement";

const useMobileSwitchDetector = ({ id }) => {
  const mobile = getState("mobile");
  const [touchInNonMobileModeCount, setTouchInNonMobileModeCount] =
    React.useState(0);
  const [clickInMobileModeCount, setClickInMobileModeCount] = React.useState(0);

  useEffect(() => {
    if (mobile) {
      return;
    }

    const $ = window.$;

    const $arrowButton = $(`#${id}`);

    if ($arrowButton.length === 0) {
      return;
    }

    const handleTouchStart = () => {
      setTouchInNonMobileModeCount(touchInNonMobileModeCount + 1);

      if (touchInNonMobileModeCount >= 1) {
        setState(["mobileWidthThreshold"], 1000000);
        setTouchInNonMobileModeCount(0);
      }
    };

    $arrowButton.on("touchstart", handleTouchStart);

    return () => {
      $arrowButton.off("touchstart", handleTouchStart);
    };
  }, [mobile, touchInNonMobileModeCount]);

  useEffect(() => {
    if (!mobile) {
      return;
    }

    const $ = window.$;

    const $arrowButton = $(`#${id}`);

    if ($arrowButton.length === 0) {
      return;
    }

    const handleMouseDown = () => {
      setClickInMobileModeCount(clickInMobileModeCount + 1);

      if (clickInMobileModeCount >= 1) {
        setState(["mobileWidthThreshold"], 1);
        setClickInMobileModeCount(0);
      }
    };

    $arrowButton.on("mousedown", handleMouseDown);

    return () => {
      $arrowButton.off("mousedown", handleMouseDown);
    };
  }, [mobile, clickInMobileModeCount]);
};

export default useMobileSwitchDetector;
