import { EVENT_TYPES } from "../../constants";
import { emitEvent } from "../emitEvent";
import getAuth from "../getAuth";

export const helloWorld = () => {
  const auth = getAuth();
  emitEvent({
    data: {
      auth,
    },
    type: EVENT_TYPES.HELLO_WORLD,
  });
};
